import { PivotGridState } from '../model/dx-pivot-grid.model';
export const diffPivotGrid = (firstArray: PivotGridState[] | undefined, secondArray: PivotGridState[]) => {
  if (!firstArray) return;
  let isFieldEqual = true;
  firstArray.map((each: any) => {
    const dataField = secondArray.find((eachSecond: any) => eachSecond.dataField === each.dataField);
    if (
      dataField &&
      !(
        dataField?.area === each.area &&
        dataField?.areaIndex === each.areaIndex &&
        dataField?.expanded === each.expanded &&
        dataField?.filterType === each.filterType &&
        dataField?.filterValues === each.filterValues &&
        dataField?.name === each.name &&
        dataField?.sortBy === each.sortBy &&
        dataField?.sortBySummaryField === each.sortBySummaryField &&
        dataField?.sortBySummaryPath === each.sortBySummaryPath &&
        dataField?.sortOrder === each.sortOrder &&
        dataField?.summaryDisplayMode === each.summaryDisplayMode
      )
    ) {
      isFieldEqual = false;
    }
  });
  return isFieldEqual;
};
