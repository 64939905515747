import Snackbar from '@material-ui/core/Snackbar';
import { Notification, SnackBarContent } from '@virtus/components/SnackBarNotification/SnackBarContent';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'src/reducers';
import { NotificationsAction, notificationSelector } from 'src/reducers/notifications';
import { snackbar } from 'src/utils/constants';

interface ReduxProps {
  readonly notification: Notification | null;
}

interface ReduxDispatch {
  closeNotifications: () => any;
}

export const Notifications = ({
  notification,
  closeNotifications,
}: {
  notification: Notification | null;
  closeNotifications: () => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    if (notification?.message) {
      setOpen(true);
    }
  }, [notification]);

  const autoHideDuration = notification?.autoHideDuration
    ? notification.autoHideDuration
    : snackbar.AUTO_HIDE_DURATION_SECS;

  const closeNotification = () => {
    setOpen(false);
    closeNotifications();
  };

  if (!notification || !notification.type) return null;

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      onExited={() => {
        setOpen(false);
        closeNotification();
      }}
      autoHideDuration={autoHideDuration}
      onClose={(_e, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      }}
    >
      {notification && <SnackBarContent notification={notification} closeNotification={closeNotification} />}
    </Snackbar>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  notification: notificationSelector(state),
});

const mapDispatchToProps = (dispatch: any): ReduxDispatch => ({
  closeNotifications: () => dispatch({ type: NotificationsAction.RESET_NOTIFICATION }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
