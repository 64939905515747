import { Button, Menu, MenuItem, withStyles } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { ComponentType, useState } from 'react';
import styled from 'styled-components';

const styles = {
  menuStyle: {
    marginTop: 30,
    borderRadius: '0px',
    backgroundColor: 'transparent',
  },
};

export interface TopRightMenuItemProps {
  routePath?: string;
  text: string;
  icon?: React.SFC<SvgIconProps>;
  onClick: () => void;
  component?: ComponentType;
  isIdleOnClick?: boolean;
}

interface NavigationBarMenuProps {
  topRightMenuItems?: TopRightMenuItemProps[];
  buttonText: string | number;
  buttonIcon: React.ReactType<any>;
  classes: any;
}

const NavigationBarMenu = (props: NavigationBarMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | ((el: Element) => Element) | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const getItemWidth = () => {
    return document.getElementById('top-right-button')
      ? window.getComputedStyle(document.getElementById('top-right-button')!).getPropertyValue('width')
      : 'auto';
  };

  const renderTopRightMenuItems = (_topRightMenuItems: TopRightMenuItemProps[] | undefined) => {
    if (!_topRightMenuItems) return null;
    return _topRightMenuItems.map(menuItem => (
      <MenuItemStyled
        key={`menu-item-${menuItem.text}`}
        width={getItemWidth()}
        onClick={() => {
          menuItem.onClick();
          if (!menuItem.isIdleOnClick) handleClose();
        }}
      >
        <MenuItemText>
          {menuItem.text}
          {menuItem.component}
        </MenuItemText>
      </MenuItemStyled>
    ));
  };

  const { topRightMenuItems, buttonText, buttonIcon, classes } = props;

  return (
    <div>
      <ButtonStyled
        id="top-right-button"
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={handleOpen}
      >
        {buttonText}
        {React.createElement(buttonIcon)}
      </ButtonStyled>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.menuStyle }}
      >
        {renderTopRightMenuItems(topRightMenuItems)}
      </Menu>
    </div>
  );
};

const ButtonStyled = styled(Button)`
  border-radius: 2px;
  padding: 0px;
  padding-left: 6px;
  background-color: rgba(255, 255, 255, 0.14);
  color: white;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: none;
  &:hover {
    background-color: rgba(255, 255, 255, 0.14);
  }
`;
export const MenuItemStyled = styled(MenuItem)<{ width: string; appcolor?: string }>`
  font-size: 14px;
  font-weight: 600;
  min-width: ${props => props.width};
  height: 30px;
  padding: 0px;
  margin: 0px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #ffffff;
  background-color: #333b43;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:focus {
    background-color: #333b43;
  }
  &:hover {
    background: ${props => props.appcolor || 'var(--fis-green)'};
  }
`;

export const MenuItemText = styled.div`
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
`;

export default withStyles(styles)(NavigationBarMenu);
