import Tooltip from '@material-ui/core/Tooltip';
import { ArrowLeft, Close, Refresh } from '@material-ui/icons';
import React from 'react';
import CollapseIcon from 'src/icons/CollapseIcon';
import ExpandIcon from 'src/icons/ExpandIcon';
import HelpIcon from 'src/icons/HelpIcon';
import * as S from './PanelHeader.style';

export interface PanelHeaderProps {
  tooltipText: string;
  title: string;
  onBack?: () => any;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => any;
  hasRefreshButton?: boolean;
  showBackButton?: boolean;
  showCloseBtn?: boolean;
  isExpanded?: boolean;
  headerExpandedChildren?: React.ReactNode;
  onExpand?: () => void;
  onRefresh?: () => void;
  associatedObjectTitle?: string;
}

export const PanelHeader: React.FunctionComponent<PanelHeaderProps> = ({
  title,
  onClose,
  onBack,
  isExpanded,
  onExpand,
  tooltipText,
  onRefresh,
  hasRefreshButton,
  headerExpandedChildren,
  showBackButton = false,
  showCloseBtn = true,
  associatedObjectTitle,
}) => (
  <S.Header data-testid="panel-header">
    <S.Row>
      {showBackButton && (
        <S.IconBtn onClick={onBack} data-testid="back-button" color="inherit">
          <ArrowLeft />
        </S.IconBtn>
      )}
      {showBackButton && associatedObjectTitle ? (
        <div>
          <Tooltip placement="bottom" title={title || ''}>
            <S.Title data-testid="title" color="inherit">
              {title}
            </S.Title>
          </Tooltip>
          <Tooltip placement="bottom" title={associatedObjectTitle || ''}>
            <S.Title data-testid="associatedObjectTitle" color="inherit">
              {associatedObjectTitle}
            </S.Title>
          </Tooltip>
        </div>
      ) : (
        <Tooltip placement="bottom" title={title || ''}>
          <S.Title data-testid="title" color="inherit">
            {title}
          </S.Title>
        </Tooltip>
      )}
    </S.Row>
    <S.Row>
      {isExpanded && headerExpandedChildren}
      {tooltipText && (
        <Tooltip title={tooltipText} placement="top">
          <S.IconBtn color="inherit">
            <HelpIcon />
          </S.IconBtn>
        </Tooltip>
      )}
      {onExpand && (
        <S.IconBtn data-testid={isExpanded ? 'collapse-button' : 'expand-button'} onClick={onExpand} color="inherit">
          {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
        </S.IconBtn>
      )}
      {showCloseBtn && (
        <S.IconBtn onClick={onClose} color="inherit" data-testid="onclose-button">
          <Close />
        </S.IconBtn>
      )}
      {hasRefreshButton && (
        <S.IconBtn onClick={onRefresh} color="inherit">
          <Refresh />
        </S.IconBtn>
      )}
    </S.Row>
  </S.Header>
);
