import React from 'react';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import { CheckboxFilledIcon, CheckboxUncheckedIcon } from '@virtus/components/icons/CheckboxIcons';
import { StyledCheckbox } from 'src/components/forms/form-elements/FormElements.style';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper';
import { CheckboxWrapper } from './CheckboxInput.style';
import { useFieldRule } from 'src/components/forms/hooks/use-field-rule';

// This deals with Glide that expects all value to be string
const getBooleanValue = (value: string | boolean) => {
  if (typeof value !== 'boolean') {
    if (value === 'true') return true;
    return false;
  }
  return value;
};

export const CheckboxInput = ({ field, value = false, onChange, error, formValues }: FormElementInputComponent) => {
  const fieldUpdateFn = (nextValue: any) => {
    onChange({ target: { checked: getBooleanValue(nextValue) } } as any);
  };
  const fieldRule = useFieldRule({
    fieldName: field.displayName,
    fieldRules: field.fieldRules,
    formValues,
    fieldUpdateFn,
  });
  const _value: boolean = getBooleanValue(value);

  const onClick = (e: React.SyntheticEvent) => {
    (e.target as HTMLFormElement).checked = !_value;
    onChange(e);
  };

  const isDisabled = fieldRule.disabled || field.disabled;
  const isRequired = fieldRule.required || field.required;
  const isReadonly = fieldRule.disabled || field.readonly;
  if (fieldRule.hidden) return null;
  return (
    <FormErrorWrapper
      error={error}
      displayName={field.displayName}
      name={field.name}
      disabled={isDisabled}
      required={isRequired}
      readonly={isReadonly}
      disableErrorWarning={field.disableErrorWarning}
    >
      <CheckboxWrapper>
        <StyledCheckbox
          data-testid={field.name}
          name={field.name}
          disabled={isDisabled || isReadonly}
          checked={_value}
          onClick={onClick}
          checkedIcon={<CheckboxFilledIcon disabled={isDisabled || isReadonly} />}
          icon={<CheckboxUncheckedIcon disabled={isDisabled || isReadonly} />}
        />
      </CheckboxWrapper>
    </FormErrorWrapper>
  );
};
