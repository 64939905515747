import React, { createContext, useState } from 'react';

export const Context = createContext({
  useDarkTheme: false,
  toggleGridTheme: () => null,
});

const Provider = ({ children }: { children: React.ReactElement<any> }) => {
  const [useDarkTheme, setUseDarkTheme] = useState(() => localStorage.getItem('useDarkThemeInGrids') === 'true');

  const value = {
    useDarkTheme,
    toggleGridTheme: () => {
      setUseDarkTheme(!useDarkTheme);
      localStorage.setItem('useDarkThemeInGrids', String(!useDarkTheme));
      return null;
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default {
  Provider,
  Consumer: Context.Consumer,
};
