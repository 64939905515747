import styled from 'styled-components';
import { SecondaryButton as SB, PrimaryButton as PB } from '@virtus/components/Buttons';

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  background: var(--bgInspectorFullscreenContent);
  padding: 0 10px;
  border-top: 1px solid #495057;
`;

export const MoreActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MoreActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const PrimaryButton = styled(PB)<{ backgroundcolor?: string }>`
  margin-left: 10px;
  background-color: ${props => props?.backgroundcolor || 'var(--default-blue)'};
  &:hover {
    background-color: ${props => props?.backgroundcolor || 'var(--light-blue)'};
  }
  &:disabled {
    color: var(--text);
    opacity: 0.5;
    background-color: ${props => props?.backgroundcolor || 'var(--default-blue)'};
  }
`;

export const SecondaryButton = styled(SB)<{ backgroundcolor?: string }>`
  background-color: ${props => props?.backgroundcolor || 'var(--error)'};
  &:hover {
    background-color: ${props => props?.backgroundcolor || 'var(--errorLight)'};
  }
`;

export const FooterButtons = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
