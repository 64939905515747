import styled from 'styled-components';

export const StyledToolBarWrapper = styled.div`
  .dx-toolbar .dx-toolbar-items-container {
    display: flex;
    flex-direction: row;
    min-height: 36px;
    height: auto;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .dx-toolbar-before,
  .dx-toolbar-after {
    position: static;
  }
  .dx-button-content {
    padding-top: 6px !important;
  }

  .dx-toolbar {
    border-top: 4px solid ${(props: any) => props.borderTopColor || 'var(--default-blue)'};
    margin-bottom: 0;
    padding: 0 10px;
  }

  .dark-theme.dx-toolbar {
    background-color: #242f39;
    color: #fff;
    padding: 0;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    height: 5px;
  }

  .dark-theme.dx-toolbar .dx-button-mode-text .dx-icon svg path[fill] {
    fill: #fff;
  }
`;
