import React from 'react';
import styled from 'styled-components';

export interface BodyProps {
  children: React.ReactNode;
  className?: string;
}

/** Plain page body adding padding to sit below fixed header and some side padding */
const Body: React.SFC<BodyProps> = ({ children, className = '' }) => (
  <Wrapper className={className}>{children}</Wrapper>
);

/** margin top to match fixed <Header> size */
const Wrapper = styled.div`
  flex: 1;
  padding: 10px;
  height: 95.5vh;
  width: calc(100% - var(--width-inspector));
  flex-direction: column;
  display: flex;
`;

export default Body;
