import { Button, Select, Toolbar, withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { ArrowDropDown, Help } from '@material-ui/icons';
import axios from 'axios';
import React, { ComponentType } from 'react';
import DividerIcon from 'src/icons/DividerIcon';
import { Route } from 'src/page/models/route';
import styled from 'styled-components';
import AppMenu, { AppLink, AppNames } from './components/AppMenu/AppMenu';
import NavigationBarMenu, {
  MenuItemStyled,
  MenuItemText,
  TopRightMenuItemProps,
} from './components/NavigationBarMenu/NavigationBarMenu';

interface Link {
  name: string;
  onlyIcon?: boolean;
  icon?: {
    component: ComponentType;
    props: any;
  };
  routePath: string;
  shortName?: string;
}

interface NavigationBarProps {
  appButton?: Link;
  onAppButtonClick?: (path?: string) => void;
  routes: Route;
  classes: any;
  appMenuValue: any;
  enabledApps: AppNames[];
  appMenuLinks?: AppLink[];
  notifications?: number;
  misc?: any;
  topRightMenuItems?: TopRightMenuItemProps[];
  onLogin?: () => void;
  onLogout?: () => void;
  onRouteChange?: (path: string) => void;
  isLoggedIn: boolean | undefined;
  isSidebarOpen: boolean;
  topRightButtonText: string;
  style?: any;
  path: string;
  navigationBarToolbarDataTestId?: string;
  navigationBarSelectMenuDataTestId?: string;
  navigationBarMenuItemDataTestId?: string;
  appColor: string;
  isHelpVisible?: boolean;
  helpURL?: string;
}

const version = `${new Date().getFullYear().toString().substring(2)}.${Math.floor(new Date().getMonth() / 3) + 1}`;
const helpLink = `Virtus/Glide/${version}/Glide_Help_${version}`;

function HelpLogin(helpURL?: string) {
  axios
    .post(`${helpURL}`, {
      timestamp: String(Math.ceil(Date.now() / 1000)),
      path: `${helpLink}/${window.location.pathname.substring(1).split('-').join('_')}`,
    })
    .then(res => {
      window.open(res.data.login_url);
    });
}

const noop = () => null;

const generateRoutePath = (selectedRoutes: Route[]) => {
  let path = '';
  selectedRoutes.forEach((route: Route) => {
    if (route.path && route.path.length > 0) {
      path += `/${route.path}`;
    }
  });
  return path;
};

const getPathRoute = (subroutes: Route[], pathname: string, deepLevel: number = 0) =>
  subroutes.findIndex(subroute => {
    const currentRoutePathSplitted = pathname.startsWith('/') ? pathname.substr(1).split('/') : pathname.split('/');
    return subroute.path === currentRoutePathSplitted[deepLevel];
  }) || 0;

const matchAnAlias = (aliases: string[], currentPath: string) =>
  Boolean(aliases.findIndex((alias: string) => alias.substr(1) === currentPath) > -1);

const getActiveSubroutesFromPath = (subroutes: Route[], pathname: string) => {
  const currentRoutePathSplitted = pathname.startsWith('/') ? pathname.substr(1).split('/') : pathname.split('/');
  const deepLevel = currentRoutePathSplitted.length;

  let childSubroutes: Route[] | undefined = subroutes;
  const activeSubroutes: number[] = [];

  for (let i = 0; i < deepLevel; i++) {
    if (childSubroutes) {
      const activeSubroute: number = childSubroutes.findIndex(subroute => {
        if (subroute.path === currentRoutePathSplitted[i]) {
          return true;
        }
        if (subroute.aliases && subroute.aliases.length) {
          return matchAnAlias(subroute.aliases, currentRoutePathSplitted[i]);
        }
        return false;
      });
      activeSubroutes.push(activeSubroute);
      childSubroutes = childSubroutes[activeSubroute] ? childSubroutes[activeSubroute].subroutes : undefined;
    }
  }
  return activeSubroutes;
};

const getDefaultRoutes = ({ routes, path }: NavigationBarProps) => {
  if (routes && routes.subroutes) {
    const routeIndexes: number[] = getActiveSubroutesFromPath(routes.subroutes, path);
    const defaultRoutes = [routes];
    routeIndexes.forEach((routeIndex: number, index: number) => {
      defaultRoutes[index].active = routeIndex;
      defaultRoutes.push(defaultRoutes[index].subroutes![routeIndex]);
    });
    return defaultRoutes;
  }
  return [];
};

export class NavigationBar extends React.Component<NavigationBarProps> {
  state = {
    selectedRoutes: getDefaultRoutes(this.props),
  };

  handleSelectRoute = (event: React.ChangeEvent<HTMLSelectElement>, selectedRoute: Route, deepLevel: number) => {
    const selectedRoutes = this.state.selectedRoutes.slice(0, deepLevel);

    const selectElement: Route = event.target.value as any;
    selectElement.active = undefined;

    const selectedRouteWithNewActive = {
      ...selectedRoute,
      ...{ active: selectedRoute.subroutes ? getPathRoute(selectedRoute.subroutes, selectElement.path) : 0 },
    };
    const newSelectedRoutes: Route[] = [...selectedRoutes, ...[selectedRouteWithNewActive], ...[selectElement]];
    this.setState({ selectedRoutes: newSelectedRoutes }, () => {
      if (this.props.onRouteChange) {
        const path = generateRoutePath(newSelectedRoutes);
        this.props.onRouteChange(path);
      }
    });
  };

  componentDidUpdate(prevProps: NavigationBarProps) {
    if (this.props.path !== prevProps.path) {
      this.setState({ selectedRoutes: getDefaultRoutes(this.props) });
    }
  }

  renderRoutes = (classes: any, navigationBarSelectMenuDataTestId: string, navigationBarMenuItemDataTestId: string) =>
    this.state.selectedRoutes.map(
      (selectedRoute: Route, deep: number) =>
        selectedRoute &&
        selectedRoute.subroutes && (
          <RouteSection key={selectedRoute.name}>
            {React.createElement(DividerIcon)}

            <SelectStyled
              data-testid={navigationBarSelectMenuDataTestId}
              value={
                selectedRoute.active !== undefined && selectedRoute.active > -1
                  ? selectedRoute.subroutes[selectedRoute.active]
                  : false
              }
              IconComponent={ArrowDropDownStyled}
              disableUnderline={true}
              onChange={(event: any) => this.handleSelectRoute(event, selectedRoute, deep)}
              classes={{ selectMenu: classes?.selectMenu }}
              MenuProps={{
                classes: { paper: classes?.dropdownStyle },
              }}
            >
              {selectedRoute.subroutes.map(route =>
                this.renderRouteOption(route, { selected: classes?.selected }, navigationBarMenuItemDataTestId),
              )}
            </SelectStyled>
          </RouteSection>
        ),
    );

  renderRouteOption = (route: Route, classes: any, navigationBarMenuItemDataTestId: string) => (
    <MenuItemStyled
      key={route.name}
      value={route as any}
      classes={classes}
      width="auto"
      appcolor={this.props.appColor}
      data-testid={navigationBarMenuItemDataTestId}
    >
      <MenuItemText>
        {route.icon &&
          route.icon.component &&
          route.icon.enableInTopBar &&
          React.createElement(route.icon.component, route.icon.topBarProps)}
        {route.name}
      </MenuItemText>
    </MenuItemStyled>
  );

  renderNotifications = (notifications: number | undefined) => (
    <NotificationBadge>{notifications || 0}</NotificationBadge>
  );

  render() {
    const {
      appButton,
      onAppButtonClick,
      classes,
      isLoggedIn,
      onLogin,
      notifications,
      topRightButtonText,
      topRightMenuItems,
      isSidebarOpen,
      misc,
      appMenuValue,
      enabledApps,
      appMenuLinks,
      routes,
      navigationBarToolbarDataTestId = 'navigation-bar-toolbar',
      navigationBarSelectMenuDataTestId = 'navigation-bar-select-menu',
      navigationBarMenuItemDataTestId = 'navigation-bar-select-menu-item',
      isHelpVisible,
      helpURL,
    } = this.props;

    return (
      <NavigationBarStyled style={this.props.style}>
        <ToolbarStyled>
          {!isSidebarOpen &&
            routes &&
            routes.mainLogo &&
            React.createElement(routes.mainLogo.component, routes.mainLogo.props)}
          {appButton && (
            <AppButton onClick={() => (onAppButtonClick ? onAppButtonClick(appButton.routePath) : noop)}>
              {appButton.icon && React.createElement(appButton.icon.component, appButton.icon.props)}
              {appButton.shortName}
            </AppButton>
          )}
          <ToolbarContent data-testid={navigationBarToolbarDataTestId}>
            <RouteSelection>
              {isLoggedIn &&
                routes &&
                this.renderRoutes(classes, navigationBarSelectMenuDataTestId, navigationBarMenuItemDataTestId)}
            </RouteSelection>
            <FlexRowVerticalCenter>
              <MiscContainer>
                {isLoggedIn && isHelpVisible && (
                  <Tooltip key="help-icon-navigation-bar" title="Help">
                    <IconButton data-testid="help-icon-navigation-bar" onClick={() => HelpLogin(helpURL)}>
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </MiscContainer>
              <MiscContainer>
                {isLoggedIn && misc}
                {isLoggedIn && <AppMenu enabledApps={enabledApps} value={appMenuValue} appMenuLinks={appMenuLinks} />}
              </MiscContainer>
              {isLoggedIn ? (
                <>
                  {notifications && this.renderNotifications(notifications)}

                  {topRightMenuItems && (
                    <NavigationBarMenu
                      buttonText={topRightButtonText}
                      topRightMenuItems={topRightMenuItems}
                      buttonIcon={ArrowDropDown}
                    />
                  )}
                </>
              ) : (
                <Button color="inherit" onClick={onLogin} data-testid="Header-login-btn">
                  Login
                </Button>
              )}
            </FlexRowVerticalCenter>
          </ToolbarContent>
        </ToolbarStyled>
      </NavigationBarStyled>
    );
  }
}

const styles = {
  selected: {
    backgroundColor: `#475662 !important`,
  },
  dropdownStyle: {
    marginTop: 30,
    borderRadius: '0px',
    backgroundColor: '#333b43',
    maxWidth: '400px',
  },
  selectMenu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    paddingRight: '18px',
  },
};

export default withStyles(styles)(NavigationBar);

const SelectStyled = styled(Select)`
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.14);
  color: white;
  text-align: center;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const RouteSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ArrowDropDownStyled = styled(ArrowDropDown)`
  color: white;
`;

const NavigationBarStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ToolbarStyled = styled(Toolbar)`
  width: 100%;
  padding: 3px;
`;

const FlexRowVerticalCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RouteSelection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const ToolbarContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const AppButton = styled.button`
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.14);
  color: white;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: none;
  cursor: pointer;
  border: none;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
`;

const NotificationBadge = styled.div`
  margin-right: 10px;
  cursor: pointer;
  width: 22px;
  height: 22px;
  text-align: center;
`;

const MiscContainer = styled.div`
  display: flex;
  margin-right: 12px;
`;

const IconButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;
const HelpIcon = styled(Help)`
  color: var(--text);
`;
