import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { FunctionComponent } from 'react';

/* eslint-disable max-len */
const SvgNexus: FunctionComponent<SvgIconProps> = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      d="M2.314 5.351l3.037-3.037c.42-.419 1.1-.419 1.519 0l3.037 3.037c.419.42.419 1.1 0 1.519L6.87 9.907c-.42.419-1.1.419-1.519 0L2.314 6.87c-.419-.42-.419-1.1 0-1.519zm0 11.78l3.037-3.038c.42-.419 1.1-.419 1.519 0l3.037 3.037c.419.42.419 1.1 0 1.519L6.87 21.686c-.42.419-1.1.419-1.519 0l-3.037-3.037c-.419-.42-.419-1.1 0-1.519zm11.78-11.78l3.036-3.037c.42-.419 1.1-.419 1.519 0l3.037 3.037c.419.42.419 1.1 0 1.519l-3.037 3.037c-.42.419-1.1.419-1.519 0L14.093 6.87c-.419-.42-.419-1.1 0-1.519zm0 11.78l3.036-3.038c.42-.419 1.1-.419 1.519 0l3.037 3.037c.419.42.419 1.1 0 1.519l-3.037 3.037c-.42.419-1.1.419-1.519 0l-3.037-3.037c-.419-.42-.419-1.1 0-1.519z"
      fill="#F57051"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgNexus;
