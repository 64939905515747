import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const CheckboxFilledIcon: React.SFC<SvgIconProps & { disabled?: boolean }> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={props.disabled ? 'rgba(255, 255, 255, 0.2)' : '#0D83C9'}
        d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2z"
      />
      <path fill={props.disabled ? '#000000' : '#FFFFFF'} d="M10 17l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z" />
    </g>
  </SvgIcon>
);

export const CheckboxIntermediate: React.SFC<SvgIconProps> = props => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <path id="a" d="M0 0h24v24H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        fill="#0D83C9"
        d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
        mask="url(#b)"
      />
      <path fill="#FFF" d="M17 13H7v-2h10z" mask="url(#b)" />
    </g>
  </SvgIcon>
);

export const CheckboxUncheckedIcon: React.SFC<SvgIconProps & { disabled?: boolean }> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={props.disabled ? 'rgba(255, 255, 255, 0.2)' : 'var(--text)'}
        d="M19 5v14H5V5h14zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
      />
    </g>
  </SvgIcon>
);
