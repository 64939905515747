import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import React, { memo, useEffect, useState } from 'react';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import FormErrorWrapper from '../FormErrorWrapper';
import * as S from './DateInput.style';
import DayPicker, { DateUtils } from 'react-day-picker';
import { colors } from '@virtus/components';
import formatDate from '@virtus/common/utils/dateFormater';
import { formattedDate } from 'src/components/forms/form-elements/form-fields/DateInput/DateInput';

const initializeSelectedDate = (value: any) => {
  if (!value) return [];
  if (typeof value === 'string') value = [value];
  return value.map((item: string) => new Date(item));
};

const localizedDisplayDate = (value: string[] | string) => {
  if (Array.isArray(value)) {
    return value.map(item => formattedDate(item).formattedDate);
  }
  return formattedDate(value).formattedDate;
};

export const MultipleDateInput = memo(
  ({ field, value, error, customWidth, onChange, formGroupChildrenStyle }: FormElementInputComponent) => {
    const [open, setOpen] = useState(false);
    const [selectedDays, setSelectedDays] = useState(initializeSelectedDate(value) as any);
    const [displayDates, setDisplayDates] = useState(localizedDisplayDate(value) || ([] as any));

    useEffect(() => {
      setSelectedDays(initializeSelectedDate(value));
      setDisplayDates(localizedDisplayDate(value) || ([] as string[]));
    }, [value]);

    const onDateChange = (day: Date, { selected }: any) => {
      const selectedDaysTemp = field.isSingleDate ? [] : selectedDays;
      if (selected) {
        const selectedIndex = selectedDaysTemp.findIndex((selectedDay: any) => DateUtils.isSameDay(selectedDay, day));
        selectedDaysTemp.splice(selectedIndex, 1);
      } else {
        selectedDaysTemp.push(day);
      }
      setSelectedDays(selectedDaysTemp);

      const _formatedDate: string[] = [];
      const _displayDates: string[] = [];
      selectedDaysTemp.forEach((data: any) => {
        const { date, formattedDate: localizedDate } = formattedDate(new Date(formatDate(data)));
        _formatedDate.push(date);
        _displayDates.push(localizedDate);
      });

      setDisplayDates(_displayDates);

      onChange({
        target: {
          value: [..._formatedDate] as any,
          addEventListener: () => null,
          removeEventListener: () => null,
          dispatchEvent: () => true,
        },
      });

      if (field.isSingleDate) {
        closeCalendar();
      }
    };

    const openCalendar = () => setOpen(true);
    const closeCalendar = () => {
      setOpen(false);
    };

    const modifiersStyles = {
      selected: { color: 'white', backgroundColor: colors.accent },
      today: { color: '#76C8FF', fontWeight: 600 },
      disabled: { cursor: 'not-allowed', color: '#717171' },
    };

    const handleDisabledDays = (date: Date) => date > new Date();

    const defaultSingleDateProps = field.isSingleDate
      ? {
          showOutsideDays: false,
          month: selectedDays[0],
          toMonth: new Date(),
          disabledDays: handleDisabledDays,
          pagedNavigation: true,
        }
      : {};

    return (
      <FormErrorWrapper
        displayName={field.displayName}
        name={field.name}
        customWidth={customWidth}
        formGroupChildrenStyle={formGroupChildrenStyle}
        disableErrorWarning={field.disableErrorWarning}
      >
        <S.DateInputWrapper>
          <S.DateButton
            onClick={openCalendar}
            disabled={false}
            error={Boolean(error) && !(field.readonly || field.disabled)}
          >
            <S.StyledCalendarIcon data-testid={`${field.name}-calendar-icon` || 'calendar-icon'} />
            <S.DateInputElement
              data-testid={`${field.name}-calendar-input` || 'calendar-input'}
              disabled={false}
              value={displayDates}
              readOnly
            />
          </S.DateButton>

          {open && (
            <ClickAwayListener onClickAway={closeCalendar}>
              <S.MultipleCalendarWrapper align={field.calendarAlign}>
                <DayPicker
                  selectedDays={selectedDays}
                  modifiersStyles={modifiersStyles}
                  onDayClick={onDateChange}
                  {...defaultSingleDateProps}
                />
              </S.MultipleCalendarWrapper>
            </ClickAwayListener>
          )}
        </S.DateInputWrapper>
      </FormErrorWrapper>
    );
  },
);
