import { add, sub, addQuarters, subQuarters, format } from 'date-fns';

type DeltaUnit = 'd' | 'w' | 'y' | 'q' | 'm';
type DateDuration = 'years' | 'months' | 'weeks' | 'quarters' | 'days';
type GLIDE_EXPRESSION_UNIT_MAP = {
  [unit in DeltaUnit]: DateDuration;
};

interface DateExpression {
  dateFrom: Date;
  deltaDuration: number;
  deltaUnit: DeltaUnit;
  operator: '-' | '+';
  formatString?: string; // https://date-fns.org/v2.28.0/docs/format
}

const GLIDE_EXPRESSION_UNIT_MAP: GLIDE_EXPRESSION_UNIT_MAP = {
  d: 'days',
  m: 'months',
  w: 'weeks',
  q: 'quarters',
  y: 'years',
};

const DEFAULT_FORMAT_STRING = 'yyyy-MM-dd';

const addExpressionDeltaDays = ({
  dateFrom,
  deltaDuration,
  deltaUnit,
  operator,
  formatString = DEFAULT_FORMAT_STRING,
}: DateExpression) => {
  let evaluatedDate = new Date();
  if (deltaUnit.match(/[d|w|y|m]/)) {
    if (operator === '+') {
      const glideUnitMapElement = GLIDE_EXPRESSION_UNIT_MAP[deltaUnit];
      evaluatedDate = add(dateFrom, { [glideUnitMapElement]: deltaDuration });
    }
    if (operator === '-') {
      const glideUnitMapElement = GLIDE_EXPRESSION_UNIT_MAP[deltaUnit];
      evaluatedDate = sub(dateFrom, { [glideUnitMapElement]: deltaDuration });
    }
  }
  if (deltaUnit === 'q') {
    if (operator === '+') {
      evaluatedDate = addQuarters(dateFrom, deltaDuration);
    }
    if (operator === '-') {
      evaluatedDate = subQuarters(dateFrom, deltaDuration);
    }
  }
  return format(evaluatedDate, formatString);
};
export const operatorDateExpression = (_dateExpression: string, todayDate: Date) => {
  const operator = _dateExpression.includes('-') ? '-' : '+';
  const deltaValue = _dateExpression.split(operator);
  const deltaDuration = parseInt(deltaValue[1]);
  const deltaUnit = deltaValue[1].charAt(deltaValue[1].length - 1) as DeltaUnit;
  return addExpressionDeltaDays({ dateFrom: todayDate, deltaDuration, deltaUnit, operator });
};
// @ts-ignore
export const evaluateDateExpression = (dateExpression: string) => {
  // expression is sometimes escaped for cross compatibility with WPF
  let _dateExpression = unescape(dateExpression);
  const todayDate = new Date();
  if (dateExpression.match(/{|}/)) {
    _dateExpression = dateExpression.substring(1, dateExpression.length - 1);
  }
  if (_dateExpression === 'today') {
    return format(todayDate, DEFAULT_FORMAT_STRING);
  }
  if (_dateExpression.includes('-') || _dateExpression.includes('+'))
    return operatorDateExpression(_dateExpression, todayDate);
};
