import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import { colors } from 'src/theme';
import styled from 'styled-components';

export const DrawerContainer = styled(Drawer)<{ style: object } & any>`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
`;

export const DrawerContent = styled.div<{ width: string }>`
  display: flex;
  font-size: 0.9rem;
  flex-direction: column;
  background-color: --var(bgDark);
  width: ${props => props.width};
  height: 100%;
  border-left: none;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12);
  overflow: hidden;
`;

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props => (props?.style?.height ? props.style.height : 'calc(100vh - 40px)')};
  background-color: var(--bgDark);
`;

export const PaperStyled = styled(Paper)<{ paperStyles?: CSSStyleDeclaration }>`
  border-left: none;
  margin-top: 40px;
  z-index: 0;
  border-radius: 0;
  box-shadow: none;
`;

export const Container = styled.div`
  color: #fff;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  height: 100%;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--text);
  /* Confirm with Johnny */
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  padding: 0.5rem 0;
`;

export const HeaderLabel = styled.span`
  color: ${colors.inspectorSubtitle};
  width: 40%;
`;

export const HeaderValue = styled.span`
  color: white;
`;
