import React, { useState } from 'react';
import * as S from './Search.style';

interface SearchProps {
  onChange: (value: string) => void;
  style?: {
    row?: React.CSSProperties;
    icon?: React.CSSProperties;
    input?: React.CSSProperties;
  };
  onClick?: (e: React.SyntheticEvent) => void;
  disableFlag?: boolean;
}

export const Search = ({ onChange, style, onClick, disableFlag = false }: SearchProps) => {
  const [search, setSearch] = useState<string>('');

  return (
    <S.Row style={style?.row} onClick={onClick}>
      <S.SearchIcon style={style?.icon} />
      <S.Input
        label="Search..."
        data-testid="search-input"
        value={search}
        style={style?.input}
        placeholder="Search"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearch(event.target.value);
          onChange(event.target.value);
        }}
        disabled={disableFlag}
      />
    </S.Row>
  );
};
