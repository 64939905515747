import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import React from 'react';
import styled from 'styled-components';

export type InspectorNavigationProps = {
  disableUp?: boolean;
  disableDown?: boolean;
  onNavigationUp: () => void;
  onNavigationDown: () => void;
};

const Navigation = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ArrowDown = styled(KeyboardArrowDown)`
  color: ${(props: { disabled?: boolean }) => (props.disabled ? 'rgba(255,255,255,0.5)' : '#fff')};
`;

const ArrowUp = styled(KeyboardArrowUp)`
  color: ${(props: { disabled?: boolean }) => (props.disabled ? 'rgba(255,255,255,0.5)' : '#fff')};
`;

const IconButtonStyled = styled(IconButton)`
  padding: 5px;
  width: 36px;
  height: 34px;
`;

const InspectorNavigation: React.SFC<InspectorNavigationProps> = ({
  disableUp,
  disableDown,
  onNavigationUp,
  onNavigationDown,
}) => {
  return (
    <Navigation>
      <IconButtonStyled onClick={onNavigationUp} disabled={disableUp}>
        <ArrowUp disabled={disableUp} />
      </IconButtonStyled>
      <IconButtonStyled onClick={onNavigationDown} disabled={disableDown}>
        <ArrowDown disabled={disableDown} />
      </IconButtonStyled>
    </Navigation>
  );
};

export default InspectorNavigation;
