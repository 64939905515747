import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

/* eslint-disable max-len */
const RunningIcon: React.FunctionComponent<SvgIconProps> = props => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#0d83c9"
      strokeWidth="2"
      r="6"
      strokeDasharray="28.274333882308138 11.42477796076938"
      transform="rotate(289.432 50 50)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </SvgIcon>
);

export default RunningIcon;
