import styled from 'styled-components';

export const SummaryPanelComponent = styled.div`
  height: 50px;
  color: var(--text);
  background-color: var(--background);
  font-size: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

export const FundSelectorWrapper = styled.div`
  margin-right: 20px;
`;
export const DatePickerWrapper = styled.div`
  display: flex;
`;
