import { Dialog as MUIDialog } from '@material-ui/core';
import styled from 'styled-components';

export const ModalButtonText = styled.span`
  text-decoration: underline;
  color: var(--text);
`;

export const ModalButton = styled.div`
  color: var(--default-blue);
  cursor: pointer;
  z-index: 1000;
`;

export const Dialog = styled(MUIDialog)<{ display_type?: string }>`
  height: calc(${props => (props.display_type === 'preview' ? 85 : 100)}vh - 40px);
  margin: 40px auto;
  width: ${props => (props.display_type === 'preview' ? 80 : 100)}vw;
  & > div {
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    margin: 0;
    & > div {
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      margin: 0;
    }
  }
`;
