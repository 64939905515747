import _ from 'lodash';
import { ClientRegion } from 'src/utils/constants';

export interface CreditDetailsUpdateProps {
  type: string;
  creditData: any;
  uri: string;
  action: string;
}

export interface GetCommentaryProps {
  uri: string[];
  type: string;
}

export const mapBlogsDetails = (creditDetails: any, displayName: string) => {
  let analystComments: any;
  creditDetails &&
    Object.entries(creditDetails).forEach(([key, value]: any) => {
      if (key && (!analystComments || analystComments.length === 0)) {
        analystComments =
          value &&
          value.filter((cdNested: any) => {
            if (
              cdNested.hasOwnProperty('client_region') &&
              cdNested.client_region === ClientRegion.BrowserRightRegion &&
              cdNested.display_name === displayName
            ) {
              return !!cdNested.value;
            }
            return false;
          });
      }
    });

  return analystComments && analystComments.length > 0 && analystComments[0]?.value;
};

export const getCommentaryDetails = (type: string, creditData: any): GetCommentaryProps | null => {
  const data = _.chain(creditData)
    .toArray()
    .flatten()
    .filter((item: any) => item.client_region && item.client_region === ClientRegion.BrowserRightRegion)
    .value();
  if (data.length > 0) {
    const matchedData = data.find(item => {
      const { field } = item;
      if (!field) return false;

      const fieldObj: any = Object.values(field)[0];
      return fieldObj?.display_name === type;
    });

    return matchedData ? { uri: matchedData.value, type } : null;
  }
  return null;
};

const isMatchedObject = (data: any, type: string) => {
  const { field } = data;
  if (!field) return false;
  const fieldObj: any = Object.values(field)[0];
  return fieldObj?.display_name === type;
};

export const updateCreditDetailsView = ({ type, creditData, uri, action }: CreditDetailsUpdateProps) => {
  const [commentaryDataKey]: any = Object.entries(creditData).find(([key, value]: any) => {
    return (
      key !== '' &&
      key !== undefined &&
      value &&
      Object.values(value).find((cdNested: any) => {
        return (
          cdNested.hasOwnProperty('client_region') &&
          cdNested.client_region === ClientRegion.BrowserRightRegion &&
          isMatchedObject(cdNested, type)
        );
      })
    );
  });

  const data = creditData[commentaryDataKey].filter(
    (item: any) => item.client_region && item.client_region === ClientRegion.BrowserRightRegion,
  );
  if (data.length > 0) {
    const matchedData = data.map((item: any) => {
      const { field } = item;
      if (!field) return item;

      const fieldObj: any = Object.values(field)[0];
      if (fieldObj?.display_name === type) {
        switch (action) {
          case 'ADD':
            return { ...item, value: [...(item.value || []), ...[uri]] };
          case 'DELETE':
            return { ...item, value: item.value.filter((commentaryUri: string) => commentaryUri !== uri) };
          default:
            return item;
        }
      }
      return item;
    });

    return {
      ...creditData,
      [commentaryDataKey]: creditData[commentaryDataKey].map((item: any) => {
        for (let i = 0, len = matchedData.length; i < len; i++) {
          if (item.display_name === matchedData[i].display_name) {
            return { ...matchedData[i] };
          }
        }
        return item;
      }),
    };
  }
  return { ...creditData };
};
