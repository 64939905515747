import styled from 'styled-components';
import InputBase from '@material-ui/core/InputBase';
import Search from '@material-ui/icons/Search';

export const ListContainer = styled.div`
  overflow-y: auto;
  padding: 0 14px 5px 14px;
  height: 100%;
`;

export const StaticHeader = styled.div`
  padding: 0px 23px 14px 14px;
`;

export const SearchIcon = styled(Search)`
  color: #bfbfbf;
  position: absolute;
  margin-top: 3px;
  margin-left: 5px;
  width: 21px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 0 10px 0;
`;

export const Row = styled.div`
  display: flex;
  height: 35px;
  margin-top: 10px;
`;

export const Input = styled(InputBase)<{ margin: string; label: string } & any>`
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  input {
    text-indent: 23px;
  }
`;

export const Tabs = styled.section`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-start;
  width: 290px;
  margin-left: 15px;
  padding: 8px 0;
  cursor: pointer;
`;

export const Tab = styled.div`
  font-family: 'Nunito Sans', 'Roboto', 'Helvetica Neue', sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  margin-right: 20px;
  cursor: pointer;
  transition: 0.1s all;
  padding-bottom: 2px;

  &:hover {
    box-shadow: inset 0px -2px 0px #ffffff6e;
  }
  &.selected {
    box-shadow: inset 0px -2px 0px #0d83c9;
  }
  &.disabled {
    color: #ddd !important;
    cursor: default;
    &:hover {
      box-shadow: none;
    }
  }
`;
