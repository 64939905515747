import { FormFieldsType } from 'src/components/forms/form-elements/FormElement.model';
import React from 'react';
import { GlideDataContent, GlideDataContentProps } from 'src/components/forms/glide-data-content';
import * as S from './glide-object-inspector.modal.style';
import { PanelHeader } from '@virtus/components/Panel/PanelHeader';

export type ModalInspectorProps = {
  forms: { [key: string]: { formValues: any; formErrors: any; formFields: FormFieldsType } };
  dataTestId?: string;
  isEditing: boolean;
  renderFooter: any;
  onChangeField: (data: { [key: string]: string }, formName: string) => void;
  onGridViewChangesSaved?: GlideDataContentProps['onGridViewChangesSaved'];
  objectEditActions?: GlideDataContentProps['objectEditActions'];
  objectEditInspectors?: GlideDataContentProps['objectEditInspectors'];
  objectCollectionUri: string;
  onForwardLinkClick?: (uri: string, objectType: string | undefined) => void;
  forwardLinkObjectType?: string[];
  selectedRowData?: any;
  onClose: any;
  title: string;
  tooltipText?: string;
};

export const ModalInspector = ({
  objectCollectionUri,
  objectEditActions,
  objectEditInspectors,
  renderFooter,
  dataTestId,
  onChangeField,
  forms,
  onForwardLinkClick,
  forwardLinkObjectType,
  selectedRowData,
  onClose,
  title,
  tooltipText,
  ...props
}: ModalInspectorProps) => {
  const formValues = Object.values(forms).reduce(
    (acc, form) => ({
      ...acc,
      ...form.formValues,
    }),
    {},
  );
  return (
    <S.Dialog open onClose={onClose} display_type="preview">
      <PanelHeader
        showBackButton={false}
        title={title}
        onClose={onClose}
        showCloseBtn={true}
        tooltipText={tooltipText || title}
      />
      <S.ModalWrapper>
        <S.Grid data-testid={dataTestId || 'modal-inspector'}>
          {Object.entries(forms).map(([formGroupName, value]) => (
            <S.Cell takeAllHeightAvailable key={formGroupName}>
              <S.CellHeader>{formGroupName}</S.CellHeader>
              <GlideDataContent
                dataTestId={`${formGroupName}-full`}
                optionalStyles={{ noPadding: true, noBackground: true }}
                hideFooter
                form={value}
                formValues={formValues}
                formName={formGroupName}
                onChangeField={onChangeField}
                uri={objectCollectionUri}
                objectEditActions={objectEditActions}
                objectEditInspectors={objectEditInspectors}
                onForwardLinkClick={onForwardLinkClick}
                forwardLinkObjectType={forwardLinkObjectType}
                selectedRowData={selectedRowData}
                {...props}
              />
            </S.Cell>
          ))}
        </S.Grid>
      </S.ModalWrapper>
      {renderFooter}
    </S.Dialog>
  );
};
