import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import React from 'react';
import { InspectorActionsProps } from './InspectorActions.model';
import { StyledActionsMenu, StyledMenuItem, StyledMenuItemText, TextButton } from './InspectorActions.style';

export const InspectorActions: React.FunctionComponent<InspectorActionsProps> = ({
  actions = [],
  actionButtonText = 'Actions',
  darktheme = false,
  layout,
  style = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <TextButton
        aria-controls="actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        darktheme={darktheme}
        layout={layout}
        style={style ? { position: 'absolute', left: '17px' } : {}}
      >
        {actionButtonText}
        {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </TextButton>
      <StyledActionsMenu
        data-testid="actions-menu"
        id="actions-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map((action, index) => (
          <StyledMenuItem
            {...action.props}
            backgroundcolor={action?.props?.backgroundcolor}
            key={index}
            onClick={e => {
              action.actionFn(e);
              setAnchorEl(null);
            }}
            layout={layout}
          >
            {action.icon && React.createElement(action.icon)}
            <StyledMenuItemText>{action.text}</StyledMenuItemText>
          </StyledMenuItem>
        ))}
      </StyledActionsMenu>
    </div>
  );
};

export default InspectorActions;
