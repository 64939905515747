import Loading from '@virtus/components/Loading';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import { PageProps } from '@virtus/components/page/Page/Page';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { batch, connect, useDispatch } from 'react-redux';
import { ViewBlotter } from 'src/api/queries';
import ActionForm from 'src/components/forms/action-form/action-form';
import {
  ClientViewConfiguration,
  ClientViewConfigurationData,
  ClientViewTypeLookUps,
  GlideViewProps,
  GlideViewReduxDispatch,
  GlideViewReduxProps,
  ViewComponent,
} from 'src/components/glide-view/glide-view.model';
import DxPivotGrid from 'src/components/grids/dx-pivot-grid/dx-pivot-grid';
import DxGridClientView from 'src/components/grids/dxgrid-client-view/dxgrid-client-view';
import { useCart } from 'src/components/inspectors/cart-inspector/hooks/use-cart-inspector';
import ScreenWrapper from 'src/components/screen-wrapper/screen-wrapper';
import { RootState } from 'src/reducers';
import { selectComponents, selectViewComponent } from 'src/reducers/components';
import { activeTabSelector, bottomPanelSelector, selectClientViewBottomPanel, selectCVC } from 'src/reducers/tabs';
import { dispatchActions } from 'src/app/store';
import { GlideViewActions } from 'src/sagas/glide-view.saga';
import OrderSelectionPanel from 'src/components/bottom-panel/order-bottom-panel/order-selection-panel';
import BottomPanel from 'src/components/bottom-panel/bottom-panel';
import * as S from './glide-view.style';
import { GlideObject } from 'src/models/glide/glideObject';
import SummaryPanel from 'src/components/summary-panel/summary-panel';
import { executeAction } from 'src/utils/action-resolver';
import { hypoTestResultDetailsSelector } from 'src/reducers/notifications';
import { TestResultsOverlay } from 'src/components/test-results-overlay/test-results-overlay';
import config from 'src/config';
import { glideQuerySelectorBottomPanel, isPendingQuerySelector } from 'src/api/query';
import DataGrid from 'devextreme-react/data-grid';
import { Dispatch } from 'redux';
import { DataGridActions } from 'src/components/grids/dxgrid-client-view/datagrid.saga';

export const GlideView: React.FunctionComponent<GlideViewProps> = ({
  path,
  isPending,
  clientViewConfiguration,
  clientViewData,
  components,
  clientViewUri,
  orderBottomPanel,
  hypoTestResultDetails,
  bottomPanelResults,
  isLoadingBottomPanelResults,
  instanceSensitiveBottomPanel,
  resetDatagrid,
}) => {
  if (!clientViewUri) return null;
  const dataGridRef = useRef<DataGrid>(null);
  const pivotGridRef = useRef(null);
  const bottomPanelGridRef = useRef(null);

  const pageToolBarProps = {};
  const dispatch = useDispatch();
  const { cartItems } = useCart(dataGridRef);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [gridTitle, setGridTitle] = useState('');
  const isBottomPanelExpanded =
    components?.viewComponents?.[clientViewUri as string]?.['bottomPanelExpandedState']?.['isExpanded'];
  const [showTestResults, setShowTestResults] = useState(false);
  const onCloseTestResults = () => setShowTestResults(false);
  const [testResultName, setTestResultName] = useState('');
  const [isMainGridRef, setIsMainGridRef] = useState(true);

  useEffect(() => {
    if (hypoTestResultDetails) {
      setShowTestResults(hypoTestResultDetails);
    } else {
      setShowTestResults(false);
    }
  }, [hypoTestResultDetails]);

  useEffect(() => {
    console.info('GET CLIENT VIEW:', clientViewUri);
    dispatch({
      type: GlideViewActions.FETCH_CLIENT_VIEW,
      payload: { clientViewUri, clientViewConfiguration },
    });
    return () => {
      resetDatagrid();
    };
  }, [clientViewUri]);

  const pageProps: Partial<PageProps> = {
    pageTitle: clientViewConfiguration.display_name,
    appName: 'Glide',
    testid: clientViewConfiguration.uri,
  };

  const onSettingsClick = (dataGridRef: any) => {
    if (dataGridRef) {
      const uri = clientViewConfiguration?.uri;
      setIsMainGridRef(dataGridRef.current?.props?.id === uri);
    }
    dispatchActions.components.toggleDisplay('layoutManager');
  };

  const onCellClick = (cellData: any, isBottomPanel?: boolean) => {
    const rowData = cellData.data;
    const uri = rowData._uri;
    if (!uri || !rowData) return;
    setSelectedRowData(rowData);
    setGridTitle(rowData['Display Name']);

    // Time series object do not have a display view as we fetch CDO results
    if (clientViewConfiguration.is_time_series || isBottomPanel) {
      executeAction({
        action: { uri: 'instance/actions/hypo_fetch_test_result_details' },
        target_uri: uri,
      });
      setTestResultName(rowData['Test Name'] as string);
      return;
    }

    if (!isBottomPanel) {
      let fieldSchemaObject: any = {};
      fieldSchemaObject = clientViewData.schema.find(
        (eachSchema: any) => eachSchema.display_name === cellData?.column?.dataField,
      );
      const instanceSensitiveFieldName = instanceSensitiveBottomPanel.filter(
        (cvc: ClientViewConfiguration) =>
          cvc?.data?.hasOwnProperty('instance_sensitive_field_name') &&
          cvc?.data?.instance_sensitive_field_name?.lastSplitValue() === fieldSchemaObject?.field_name,
      );
      if (instanceSensitiveFieldName && instanceSensitiveFieldName.length) {
        batch(() => {
          dispatchActions.components.updateView('bottomPanel', clientViewUri, {
            instanceUri: cellData.data._uri,
            instanceSensitiveObject: instanceSensitiveFieldName,
          });
          dispatchActions.components.updateView('inspector', clientViewUri, {
            isCollapsed: true,
          });
          dispatchActions.components.updateView('bottomPanelExpandedState', clientViewConfiguration.uri, {
            isExpanded: true,
          });
        });
        dispatch({
          type: GlideViewActions.OPEN_BOTTOM_LINK,
        });
      } else {
        batch(() => {
          dispatchActions.components.updateView('bottomPanel', clientViewUri, {
            instanceUri: '',
            instanceSensitiveObject: [],
          });
          dispatchActions.components.updateView('bottomPanelExpandedState', clientViewConfiguration.uri, {
            isExpanded: false,
          });
          dispatchActions.components.updateView('inspector', clientViewUri, {
            isCollapsed: false,
            uri: cellData.data._uri,
          });
        });
      }
    }
  };

  const bottomPanelFields = () => {
    if (!orderBottomPanel) return [];
    return (orderBottomPanel as Partial<GlideObject>[]).map((field: Partial<GlideObject>) => ({
      // text: field.display_name,
      // title: field.display_name,
      disabled: false,
      key: field.uri?.lastSplitValue(),
    }));
  };

  const orderBottomPanelContent = orderBottomPanel?.length ? (
    <OrderSelectionPanel
      bottomPanelFields={bottomPanelFields()}
      selectedRowData={selectedRowData}
      clientViewUri={clientViewUri}
    />
  ) : null;

  const onRefresh = () => {
    dispatch({ type: GlideViewActions.FETCH_CLIENT_VIEW });
    dispatchActions.components.updateView('gridLayout', clientViewUri, {
      selectedLayout: components?.viewComponents[clientViewUri]?.gridLayout?.selectedLayout,
      hasChanges: false,
    });
  };

  const Dashboard = React.lazy(() => import(/* webpackPreload: true */ 'src/components/dashboard/dashboard'));

  const viewComponentRender = (renderProps: {
    uri: string;
    dataSource: any;
    actionsCollection?: any;
    gridRef?: any;
    gridTitle?: string;
    gridDescription?: string;
    isBottomPanel?: boolean;
    clientViewConfiguration: ClientViewConfigurationData;
  }): ViewComponent => {
    const handleOnCellClick = (cellData: any) => onCellClick && onCellClick(cellData, renderProps?.isBottomPanel);
    return {
      [ClientViewTypeLookUps.Grid]: (
        <DxGridClientView
          clientViewConfiguration={renderProps?.clientViewConfiguration}
          data-testid={`data-grid-${renderProps?.uri}`}
          dataSource={renderProps?.dataSource}
          cartItems={cartItems}
          onCellClick={handleOnCellClick}
          actionsCollection={renderProps?.actionsCollection}
          dataGridRef={renderProps?.gridRef}
          enableRowDragging={false}
          onRefresh={onRefresh}
          onSettingsClick={onSettingsClick}
          headerText={{ title: renderProps?.gridTitle, description: renderProps?.gridDescription }}
          storageKey={`data-grid-${renderProps?.uri}`}
          scrollingProps={{ rowRenderingMode: 'virtual', showScrollbar: 'always', useNative: true }}
        />
      ),
      [ClientViewTypeLookUps.Pivot]: (
        <DxPivotGrid
          enableStorage
          storageKey={`pivot-grid-${renderProps?.uri}`}
          clientViewConfiguration={clientViewConfiguration}
          refreshHandler={onRefresh}
          useDarkTheme={components.global.isDarkTheme}
          pivotGridData={renderProps?.dataSource}
          onSettingsClick={onSettingsClick}
          showAllFieldsColumn
          showFieldChooser={false}
          ref={pivotGridRef}
        />
      ),
      [ClientViewTypeLookUps.Dashboard]: (
        <Dashboard endpoint={`${config.glide.DASHBOARD_URL}`} initialDashboardId={clientViewUri.lastSplitValue()} />
      ),
      //custom component is a pivot grid with custom SummaryPanel
      [ClientViewTypeLookUps.Custom]: <></>,
    };
  };

  const pendingLoader = (
    <Loading
      show
      full
      type={LoadingIconType.Glide}
      size={LoadingIconSizes.large}
      text={`Loading ${clientViewConfiguration.display_name}...`}
      style={{ height: '90vh' }}
    />
  );

  const getMainRegionContent = useMemo(() => {
    if (
      !components.global.isGomOpen &&
      (isPending || !clientViewData) &&
      clientViewConfiguration.client_view_type !== ClientViewTypeLookUps.Dashboard
    ) {
      return pendingLoader;
    }
    if (!(clientViewConfiguration.client_view_type in ClientViewTypeLookUps)) {
      console.warn(
        `[GlideView] client_view_type ${clientViewConfiguration.client_view_type} not implemented (${clientViewUri}}`,
      );
      return null;
    }
    return (
      <S.ViewContentWrapper>
        {
          viewComponentRender({
            uri: clientViewUri,
            dataSource: clientViewData,
            actionsCollection: clientViewConfiguration?.actions_collection,
            gridRef: dataGridRef,
            clientViewConfiguration: clientViewConfiguration,
          })[clientViewConfiguration.client_view_type]
        }
      </S.ViewContentWrapper>
    );
  }, [
    isPending,
    clientViewUri,
    clientViewData,
    clientViewConfiguration.client_view_type,
    clientViewConfiguration?.actions_collection,
    isLoadingBottomPanelResults,
    bottomPanelResults,
    orderBottomPanelContent,
    gridTitle,
    cartItems,
  ]);
  const instanceSensitiveInfo: ClientViewConfiguration[] =
    components.viewComponents[clientViewUri]?.bottomPanel?.instanceSensitiveObject;
  const getBottomRegionContent = useMemo(() => {
    const loader = isLoadingBottomPanelResults ? (
      <Loading show type={LoadingIconType.Glide} size={LoadingIconSizes.large} style={{ height: '40vh' }} />
    ) : null;
    const bottomPanelContent =
      instanceSensitiveInfo && instanceSensitiveInfo.length > 0
        ? viewComponentRender({
            uri: instanceSensitiveInfo[0]?.uri,
            dataSource: bottomPanelResults ?? {
              data: bottomPanelResults?.data ?? [],
              schema: bottomPanelResults?.schema ?? [],
            },
            actionsCollection: instanceSensitiveInfo[0]?.data?.actions_collection,
            gridRef: bottomPanelGridRef,
            gridTitle: `Compliance Test Results for Scenario ${gridTitle}`,
            gridDescription: 'Select a test result to view its details',
            isBottomPanel: true,
            clientViewConfiguration: bottomPanelResults?.clientViewConfiguration,
          })[instanceSensitiveInfo[0].data.client_view_type]
        : null;
    return (
      (loader || bottomPanelContent || orderBottomPanelContent || isBottomPanelExpanded) && (
        <S.BottomPanelWrapper isBottomPanelExpanded={isBottomPanelExpanded}>
          <S.Divider />
          <BottomPanel clientViewUri={clientViewUri}>
            {loader ?? bottomPanelContent ?? orderBottomPanelContent}
          </BottomPanel>
        </S.BottomPanelWrapper>
      )
    );
  }, [
    isBottomPanelExpanded,
    isLoadingBottomPanelResults,
    bottomPanelResults,
    orderBottomPanelContent,
    instanceSensitiveInfo,
    gridTitle,
  ]);
  const layoutRef = () => {
    if (
      instanceSensitiveInfo &&
      instanceSensitiveInfo[0]?.data?.client_view_type === ClientViewTypeLookUps.Grid &&
      !isMainGridRef
    )
      return bottomPanelGridRef;
    else if (clientViewConfiguration?.client_view_type === ClientViewTypeLookUps.Grid) return dataGridRef;
    else if (clientViewConfiguration?.client_view_type === ClientViewTypeLookUps.Pivot) return pivotGridRef;
    return null;
  };
  return (
    <ScreenWrapper
      pageProps={pageProps}
      pageToolBarProps={pageToolBarProps}
      path={path}
      clientViewData={isMainGridRef ? clientViewData : bottomPanelResults}
      dataRef={layoutRef()}
    >
      {components.actionForm?.visible && <ActionForm targetUris={components.actionForm?.actionUri} />}
      <SummaryPanel />
      {showTestResults && hypoTestResultDetails && testResultName && (
        <TestResultsOverlay
          testResultName={testResultName}
          dataSource={hypoTestResultDetails}
          onCloseModal={onCloseTestResults}
        />
      )}
      <>
        {getMainRegionContent}
        {getBottomRegionContent}
      </>
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: RootState): GlideViewReduxProps => {
  const clientViewUri = activeTabSelector(state);
  const bottomPanelComponent = selectViewComponent(state, 'bottomPanel', clientViewUri);

  return {
    bottomPanelResults: glideQuerySelectorBottomPanel(state, bottomPanelComponent?.clientViewUri),
    clientViewConfiguration: selectCVC(state),
    clientViewData: ViewBlotter.selectClientViewData(state),
    clientViewUri: activeTabSelector(state),
    components: selectComponents(state),
    hypoTestResultDetails: hypoTestResultDetailsSelector(state),
    instanceSensitiveBottomPanel: selectClientViewBottomPanel(state),
    isLoadingBottomPanelResults: isPendingQuerySelector(state, bottomPanelComponent?.clientViewUri),
    isPending: ViewBlotter.isPending(state, activeTabSelector(state)),
    orderBottomPanel: bottomPanelSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): GlideViewReduxDispatch => ({
  resetDatagrid: () => dispatch({ type: DataGridActions.DATAGRID_RESET, payload: null }),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlideView);
