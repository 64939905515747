import styled from 'styled-components';
import { Radio, withStyles } from '@material-ui/core';

export const Column = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 284px;
  height: auto;
`;

export const FormWrapper = styled(Column)`
  padding: 20px;
  height: auto;
  background-color: var(--bgInspectorFullscreenContent);
`;

export const FormLabel = styled.label`
  font-size: 13px;
  margin: 0;
  padding: 0;
  width: 150px;
  color: var(--inspector-label-grey);
`;

export const StyledRadio = withStyles({
  root: {
    color: 'var(--text)',
  },
})(Radio);

export const CrossIcon = styled.span`
  &:after {
    content: '\\00d7';
    cursor: pointer;
    color: var(--text);
    padding: 1px 5px;
    font-size: 18px;
    position: absolute;
    right: 0;
    top: 0;
  }
`;
