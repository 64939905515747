import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const IconButton = styled(Button)`
  padding: 0 !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  color: #fff;
  margin-right: 10px;
`;

export const Title = styled.span`
  color: var(--text);
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
  margin-top: 5px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
  width: 100%;
  background: var(--bgInspectorFullscreenContent);
  padding-left: 20px;
  padding-right: 10px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
