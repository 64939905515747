import { Collapse } from '@material-ui/core';
import UnfoldLess from '@material-ui/icons/UnfoldLess';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import React, { ReactNode, useEffect, useState } from 'react';
import * as S from 'src/Inspector/components/InspectorGroup/InspectorGroup.style';

export interface InspectorGroupProps {
  title: string | React.ReactNode;
  children: ReactNode;
  isExpanded?: boolean;
  onEdit?: any;
  showEdit?: boolean;
  disabled?: boolean;
  customStyles?: any;
  testId?: string;
  onDelete?: any;
  showDelete?: boolean;
}

export const InspectorGroup = ({
  title,
  children,
  testId = '',
  isExpanded = false,
  disabled = false,
  customStyles,
  onEdit,
  showEdit = false,
  onDelete,
  showDelete = false,
}: InspectorGroupProps) => {
  const [expanded, setExpanded] = useState(isExpanded);
  const onClick = () => setExpanded(!expanded);
  const renderChevron = () => (expanded ? <UnfoldLess /> : <UnfoldMore />);

  useEffect(() => setExpanded(isExpanded), [isExpanded]);

  return showEdit ? (
    <S.StyledInspectorGroup data-testid={testId ? testId : 'inspector-group-container'} style={customStyles}>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <S.ActionButton data-testid="edit-view" onClick={onEdit}>
                  <S.EditIcon fontSize="small" />
                </S.ActionButton>
              </td>
              <td style={{ width: '500px' }}>
                <S.StyledHeader data-testid="inspector-group-header" onClick={onClick} disabled={disabled}>
                  {title}
                  {renderChevron()}
                </S.StyledHeader>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <S.Content data-testid="inspector-group-content">{children}</S.Content>
        </Collapse>
      </div>
    </S.StyledInspectorGroup>
  ) : showDelete ? (
    <S.StyledInspectorGroup data-testid={testId ? testId : 'inspector-group-container'} style={customStyles}>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <S.ActionButton data-testid="delete-view" onClick={onDelete}>
                  <S.DeleteIcon fontSize="small" />
                </S.ActionButton>
              </td>
              <td style={{ width: '500px' }}>
                <S.StyledHeader data-testid="inspector-group-header" onClick={onClick} disabled={disabled}>
                  {title}
                  {renderChevron()}
                </S.StyledHeader>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <S.Content data-testid="inspector-group-content">{children}</S.Content>
        </Collapse>
      </div>
    </S.StyledInspectorGroup>
  ) : (
    <S.StyledInspectorGroup data-testid={testId ? testId : 'inspector-group-container'} style={customStyles}>
      <S.StyledHeader data-testid="inspector-group-header" onClick={onClick} disabled={disabled}>
        {title}
        {renderChevron()}
      </S.StyledHeader>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <S.Content data-testid="inspector-group-content">{children}</S.Content>
      </Collapse>
    </S.StyledInspectorGroup>
  );
};

export default InspectorGroup;
