import styled from 'styled-components';

export const FormWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow: overlay;
  padding: 5px;
  height: 100%;
`;
export const FullInspectorWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: overlay;
  padding-right: 40px;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  @supports (-moz-appearance: none) {
    overflow-y: scroll;
  }
`;

export const ModalWrapper = styled.div`
  padding: 10px 13px;
  width: 100%;
  overflow: auto;
`;
