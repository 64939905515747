import styled from 'styled-components';

export const GroupHeading = styled.div`
  padding: 2%;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  background-color: #237bbb;
`;

export const MessagesMainWrapper = styled.div`
  overflow: auto;
  height: 100%;
`;

export const MessagesListWrapper = styled.div`
  padding: 1% 2% 1% 2%;
  color: #fff;
  white-space: pre-wrap;
  overflow: hidden;
  font-size: 14px;
  width: 100%;
`;
