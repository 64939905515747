import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import React, { useCallback, useEffect, useState } from 'react';
import Calendar, { CalendarErrors } from '@virtus/components/Calendar';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import FormErrorWrapper from '../FormErrorWrapper';
import { useFieldRule } from 'src/components/forms/hooks/use-field-rule';
import { zIndex } from 'src/utils/constants';
import * as S from './DateInput.style';

export const formattedDate = (date: Date | string): { date: string; formattedDate: string } => {
  if (date === '' || date === undefined) return { date: CalendarErrors.EMPTY_DATE, formattedDate: '' };
  const isNotValid = isNaN(new Date(date).getTime());
  if (isNotValid) return { date: CalendarErrors.INVALID_DATE, formattedDate: '' };
  const _date = new Date(date);
  const month = `0${_date.getMonth() + 1}`;
  const day = `0${_date.getDate()}`;
  const year = `${_date.getFullYear()}`;
  const _glideDate = `${month.substring(month.length - 2)}/${day.substring(day.length - 2)}/${year}`;
  const _formattedDate = new Date(date).toLocaleDateString(navigator.language);

  return {
    date: _glideDate,
    formattedDate: _formattedDate,
  };
};

export const DateInput = React.memo(
  ({
    field,
    value = '',
    error,
    onChange,
    formValues,
    customWidth,
    formGroupChildrenStyle,
  }: FormElementInputComponent) => {
    const [open, setOpen] = useState(() => false);
    const [dateError, setDateError] = useState(error);
    const [dateObj, setDateObj] = useState(formattedDate(new Date(value)));
    const fieldUpdateFn = useCallback((nextValue: Date) => {
      setDateObj(formattedDate(new Date(nextValue)));
      if (field?.fieldUri) formValues[field.fieldUri] = nextValue;
    }, []);

    const fieldRule = useFieldRule({
      fieldName: field.displayName,
      fieldRules: field.fieldRules,
      formValues,
      fieldUpdateFn,
    });

    useEffect(() => {
      setDateError(error);
    }, [error]);

    useEffect(() => {
      setDateObj(formattedDate(new Date(value)));
    }, [value]);

    const onDateInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const d = new Date(e.target.value);
        const isValidDate = d instanceof Date && !isNaN(d.getTime());
        const value: { date: string; formattedDate: string } = isValidDate
          ? formattedDate(new Date(e.target.value))
          : { date: '', formattedDate: '' };
        setDateObj(value);

        onChange({
          target: {
            value: value.date,
            addEventListener: () => null,
            removeEventListener: () => null,
            dispatchEvent: () => true,
          },
        });
      },
      [onChange],
    );

    const onDateChange = useCallback(
      (v: string) => {
        if (onChange) {
          onChange({
            target: {
              value: v,
              addEventListener: () => null,
              removeEventListener: () => null,
              dispatchEvent: () => true,
            },
          });
          setDateObj(formattedDate(new Date(v)));
        }
        setOpen(false);
      },
      [onChange],
    );

    const openCalendar = useCallback((e?: any) => {
      e.preventDefault();
      setOpen(true);
    }, []);

    const onClickAwayHandler = useCallback(() => setOpen(false), []);

    if (fieldRule.hidden) return null;

    const topProp = field.calendarAlign === 'left' ? '-35px' : '33px';
    const leftProp = field.calendarAlign === 'left' ? '-190px' : '-25px';
    const calendarWrapperStyle: { wrapper: React.CSSProperties } = {
      wrapper: {
        border: '1px solid var(--grey)',
        position: 'absolute',
        width: '270px',
        backgroundColor: 'var(--bg)',
        zIndex: zIndex.Calendar,
        top: topProp,
        left: leftProp,
      },
    };

    return (
      <FormErrorWrapper
        error={dateError}
        displayName={field.displayName}
        name={field.name}
        disabled={fieldRule.disabled || field.disabled}
        required={fieldRule.required || field.required}
        readonly={fieldRule.disabled || field.readonly}
        disableErrorWarning={field.disableErrorWarning}
        customWidth={customWidth}
        formGroupChildrenStyle={formGroupChildrenStyle}
      >
        <S.DateInputWrapper>
          <S.DateButton
            onClick={openCalendar}
            disabled={fieldRule.disabled || field.readonly || field.disabled}
            error={Boolean(dateError) && !(field.readonly || field.disabled)}
          >
            <S.StyledCalendarIcon />
            <S.DateInputElement
              data-testid={`${field.name}-calendar-input` || 'calendar-input'}
              disabled={field.disabled || field.readonly}
              onChange={onDateInputChange}
              value={
                dateObj.date !== CalendarErrors.INVALID_DATE && dateObj.formattedDate
                  ? dateObj.formattedDate
                  : CalendarErrors.EMPTY_DATE
              }
            />
          </S.DateButton>

          {open && (
            <ClickAwayListener onClickAway={onClickAwayHandler}>
              <Calendar
                style={calendarWrapperStyle}
                data-testid={`${field.name}-calendar` || 'calendar'}
                value={dateObj.date}
                onChange={onDateChange}
                onClickAwayHandler={onClickAwayHandler}
              />
            </ClickAwayListener>
          )}
        </S.DateInputWrapper>
      </FormErrorWrapper>
    );
  },
);
