import PanelSearch, { PanelSearchProps } from '@virtus/components/PanelSearch';
import { SearchResult } from '@virtus/components/PanelSearch/models';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { requestAsync, updateEntities } from 'redux-query';
import { InstrumentDisplayView, SearchQuery } from 'src/api/queries';
import { store } from 'src/app/store';
import SearchInspector from 'src/components/inspectors/search-inspector';
import { RootState } from 'src/reducers';

interface ReduxProps {
  readonly selectedResult: any;
  readonly loadingSelectedResult: boolean;
  readonly loadingSearchResults: boolean;
  readonly searchResults: { data: any[] };
}

interface ReduxDispatch {
  getSelectedResultData: (data: { uri: string; name: string; fetchOptions?: any }) => any;
  searchAssets: (assetName: string, assetType: string) => any;
  resetSearchResults: () => void;
}

interface SearchProps
  extends Pick<
    PanelSearchProps,
    'hideHeader' | 'searchType' | 'rowActions' | 'styles' | 'filterResults' | 'addedRowsUris'
  > {
  onClose: () => void;
}

type AvailableInspectors = 'search' | 'searchDetails';

type Props = SearchProps & ReduxProps & ReduxDispatch;

const Search = ({
  hideHeader,
  filterResults,
  addedRowsUris,
  searchType,
  rowActions,
  onClose,
  searchResults,
  selectedResult,
  resetSearchResults,
  loadingSearchResults,
  loadingSelectedResult,
  searchAssets,
  getSelectedResultData,
  styles,
}: Props) => {
  const [inspectorToShow, setInspectorToShow] = useState<AvailableInspectors>('search');

  useEffect(() => {
    resetSearchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchInspectorBack = () => {
    setInspectorToShow('search');
  };

  const onClickSearchResult = (result: SearchResult) => {
    if (!result.uri) return;
    getSelectedResultData(result);
    setInspectorToShow('searchDetails');
  };

  return (
    <>
      <PanelSearch
        hideHeader={hideHeader}
        searchType={searchType}
        onSubmit={(assetName: string, assetType: string) => searchAssets(assetName, assetType)}
        toggleVisibility={onClose}
        onClickSearchResult={onClickSearchResult}
        isVisible={inspectorToShow === 'search'}
        filterResults={filterResults}
        addedRowsUris={addedRowsUris}
        results={searchResults}
        loading={loadingSearchResults || loadingSelectedResult}
        selectStyle={{ width: '120px' }}
        rowActions={rowActions}
        styles={styles}
      />

      {inspectorToShow === 'searchDetails' && (
        <SearchInspector
          searchData={selectedResult.data}
          title={selectedResult.displayName}
          onCloseInspector={onClose}
          loading={loadingSelectedResult}
          onBack={onSearchInspectorBack}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  selectedResult: InstrumentDisplayView.selector(state, 'global') || {},
  searchResults: SearchQuery.selector(state) || { data: [] },
  loadingSearchResults: SearchQuery.pending(state),
  loadingSelectedResult: InstrumentDisplayView.pending(state, 'global'),
});

const mapDispatchToProps = (dispatch: any): ReduxDispatch => ({
  getSelectedResultData: ({ uri, name, fetchOptions }) => {
    store.dispatch(updateEntities({ instrumentDisplayView: () => ({ displayName: name }) }));
    dispatch(requestAsync(InstrumentDisplayView.get({ uri, fetchOptions, activeView: 'global' })));
  },
  resetSearchResults: () => store.dispatch(updateEntities({ searchAssets: () => ({ data: [] }) })),
  searchAssets: (assetName: string, assetType: string) =>
    dispatch(requestAsync(SearchQuery.get({ assetName: assetName, assetType: assetType, cache: false }))),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
