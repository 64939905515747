import { FormFieldsType, FormFieldType, FormValuesType } from 'src/components/forms/form-elements/FormElement.model';
import InspectorFooter from '@virtus/components/Inspector/components/InspectorFooter';
import InspectorHeader from '@virtus/components/Inspector/components/InspectorHeader';
import React, { FunctionComponent } from 'react';
import { GlideObjectManagerField } from 'src/components/glide-object-manager';
import { GlideObjectManagerComponentProps } from 'src/components/glide-object-manager/components/glide-object-manager';
import * as S from './glide-data-content.style';
import { FormField } from 'src/components/forms/form-elements/FormField';
import { ClientRegion } from 'src/utils/constants';

export interface Form {
  formValues: any;
  formErrors: any;
  formFields: FormFieldsType | any;
}
export interface GlideDataContentProps {
  optionalStyles?: S.IOptionalStyles;
  style?: {
    [key: string]: React.CSSProperties;
  };
  hideFooter?: boolean;
  headerTitle?: string;
  isEditing?: boolean;
  dataTestId: string;
  form: Form;
  formValues: { [key: string]: any };
  formName: string;
  onCancel?: (e?: any) => void;
  onSubmitForm?: (formValues: object) => void;
  onChange?: (data: FormValuesType) => void;
  onChangeField: (change: { [key: string]: string }, formName: string) => void;
  onGridViewChangesSaved?: GlideObjectManagerComponentProps['onGridViewChangesSaved'];
  uri?: string;
  objectEditActions?: GlideObjectManagerComponentProps['actions'];
  objectEditInspectors?: GlideObjectManagerComponentProps['inspectors'];
  onTabLinkClick?: (tabsKey: string) => void;
  useGOMGrid?: boolean;
  onForwardLinkClick?: (uri: string, objectType: string | undefined) => void;
  forwardLinkObjectType?: string[];
  activeViewKey?: string;
  selectedRowData?: any;
  primaryButtonLabel?: string;
}

type DropdownOption = { name: string; value: string };
type DropdownOptionEntry = [string, DropdownOption];

export const getKey = (key: string) => {
  if (key === 'Expected Notional') return 'parent_expected_notional';
  if (key === 'Commitment') return 'parent_commited_notional';
  if (key === 'Status') return 'parent_order_status';
  if (key === 'Strategy') return 'parent_fund_allocation_strategy';
  return `parent_${key.replace(/ /g, '_').toLowerCase()}`;
};

/* In this method we will defined what properties in the form
 * are needed to pass down to a GlideObjectManager, based on the field
 */
export const getFormPropsForGlideObjectManager = ({ field, formValues }: { field: string; formValues: any }) => {
  const parentValues = Object.entries(formValues).reduce((acc, [key, value]) => {
    const objectKey = getKey(key);
    return {
      ...acc,
      [objectKey]: value,
    };
  }, {});
  const formProps: any = {};
  if (field === 'Fund Allocations' || field === 'fund_allocations') {
    formProps[field] = {
      orderExpectedNotional:
        formValues.expected_notional ??
        formValues.target_notional ??
        formValues['Expected Notional'] ??
        formValues['Target Notional'],
      orderCommitment: formValues.committed_notional ?? formValues['Commitment'],
      parent: parentValues,
    };
  }
  return formProps[field as keyof typeof formProps];
};

export const getDropdownDefaultValue = (fieldOptions: { [key: string]: DropdownOption }, defaultValueName: string) => {
  const options: DropdownOptionEntry[] = Object.entries(fieldOptions);
  const selectedOption: DropdownOptionEntry | undefined = options.find(
    (element: DropdownOptionEntry) => element[1].name === defaultValueName,
  );
  return selectedOption ? selectedOption[1].value : undefined;
};

/**
 * default value of field is causing editing issue on text box
 * Its also causing issue on some of the checkboxes selection (check/uncheck)
 */
export const getFieldValue = (
  fieldFormValue: string | undefined | string[],
  // field: { dataType: string; defaultValue?: string; options?: { [key: string]: DropdownOption } },
) => fieldFormValue;

export const GlideDataContent: FunctionComponent<GlideDataContentProps> = ({
  form,
  formValues,
  formName,
  objectEditActions,
  objectEditInspectors,
  style = {},
  onChangeField,
  onSubmitForm,
  isEditing,
  onCancel = () => null,
  hideFooter,
  uri,
  optionalStyles = {
    noBackground: false,
    noPadding: false,
  },
  dataTestId,
  onGridViewChangesSaved,
  onTabLinkClick,
  useGOMGrid = true,
  headerTitle,
  onForwardLinkClick,
  forwardLinkObjectType,
  activeViewKey,
  selectedRowData = {},
}) => {
  if (!form) return null;
  const onChangeFormField = (value: string, instance_uri: string) => {
    onChangeField({ [instance_uri]: value }, formName);
  };
  const onTabsButtonClick = (tabsKey: string) => {
    onTabLinkClick && onTabLinkClick(tabsKey);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (onSubmitForm && (Object.keys(form.formErrors).length === 0 || form.formErrors?.valid)) {
      onSubmitForm(form.formValues);
    }
  };

  return (
    <form style={style.form} name="glide-data-content" data-testid={dataTestId} onSubmit={onSubmit}>
      {headerTitle && <InspectorHeader title={headerTitle} onClose={onCancel} onHelp={() => headerTitle} />}
      <S.GlideDataContent optionalStyles={optionalStyles}>
        {Object.keys(form.formFields).map((instance_uri: string) => {
          const field: FormFieldType = form.formFields[instance_uri];
          const fieldFormValue = form.formValues[instance_uri];
          const value = getFieldValue(fieldFormValue);
          const error = form.formErrors.errors ? form.formErrors.errors[instance_uri] : undefined;

          const onChange = (e: any) =>
            onChangeFormField(field.formElementType === 'checkbox' ? e.target.checked : e.target.value, instance_uri);

          field.fieldUri = instance_uri;
          const fieldProps = {
            field,
            onChange,
            value: value as string,
            error,
            formValues,
            isEditing,
          };

          if (field.formElementType === 'tableModal') {
            return (
              <GlideObjectManagerField
                key={field.name}
                fieldName={field.objectFieldName || 'Not provided'}
                uris={value as string[]}
                title={field.name}
                actions={objectEditActions}
                inspectors={objectEditInspectors}
                enableEdit={isEditing}
                objectCollectionUri={uri || ''}
                formProps={getFormPropsForGlideObjectManager({ field: field.name, formValues: selectedRowData })}
                selectedRowData={selectedRowData}
                onGridViewChangesSaved={onGridViewChangesSaved}
                tabsKey={
                  onTabLinkClick && field.client_region === ClientRegion.BrowserRightRegion ? field.displayName : ''
                }
                onTabsClick={onTabsButtonClick}
                useGOMGrid={useGOMGrid}
                renderType={field.renderType ?? null}
              />
            );
          }

          return field.formElementType === 'search' &&
            uri &&
            field?.searchType &&
            forwardLinkObjectType &&
            forwardLinkObjectType.includes(field?.searchType) ? (
            <FormField
              key={instance_uri}
              {...fieldProps}
              // TODO: Remove that...
              onSearchLink={() => onForwardLinkClick && onForwardLinkClick(uri, field?.searchType)}
            />
          ) : (
            <FormField key={instance_uri} {...fieldProps} activeViewKey={activeViewKey} />
          );
        })}
      </S.GlideDataContent>
      {!hideFooter && <InspectorFooter confirmButtonText="Save" />}
    </form>
  );
};
