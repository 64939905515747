import { useEffect, useState } from 'react';
import { FieldRuleType, FieldRule } from 'src/components/forms/form-elements/FormElement.model';

type RuleActionFunction = (rule: FieldRule) => void;
type RuleActions = {
  [key in FieldRuleType]: RuleActionFunction;
};

interface UseFieldRuleProps {
  fieldName: string;
  fieldRules: FieldRule[] | undefined;
  formValues: { [key: string]: any };
  fieldUpdateFn: (nextValue: any) => void;
}
/*
 * Hook that reads fieldRule from field and returns if it is required, disabled or hidden
 * In order to update field value, we receive a fieldUpdateFn as parameter. For now, this is used
 * when receive a rule of type value.
 *
 * ⚠️ To be implemented. Waiting for examples:
 *
 * Filter = 'Filter'
 * Valid = 'Valid'
 */

export const getFunctionParamNames = (func: any) => {
  const STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/gm;
  const ARGUMENT_NAMES = /([^\s,]+)/g;
  const fnStr =
    func &&
    func
      .toString()
      .replace(STRIP_COMMENTS, '')
      .match(/([^=>]+)/)[1]; // taking string until '=>'
  /* below computation will match for object as parameters or no paramter eg. 
  1. if passed : ({loan_trade_Form})=> void - it will return [loan_trade_form]
  2. if passed : ()=> true - it will return null*/
  let result = fnStr && fnStr.slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')'));
  if (result && result.match(/{(.*)}/)) result = result.match(/{(.*)}/)[1].match(ARGUMENT_NAMES);
  if (!result) result = [];
  return result;
};

const validateFieldRuleParams = (rule: any, formValues: any) => {
  let callFunc = true;
  const errorParams: any = [];
  const paramsList = getFunctionParamNames(rule.ruleFn);
  paramsList.forEach((element: string) => {
    if (!formValues.hasOwnProperty(element)) {
      callFunc = false;
      errorParams.push(element);
    }
  });
  return { callFunc, errorParams };
};

export const useFieldRule = ({ fieldName, fieldRules, fieldUpdateFn, formValues }: UseFieldRuleProps) => {
  const [required, setRequired] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [hidden, setHidden] = useState(false);
  const ruleActions: RuleActions = {
    [FieldRuleType.Value]: rule => {
      if (rule.ruleFn && typeof rule.ruleFn === 'function') {
        try {
          const { callFunc, errorParams } = validateFieldRuleParams(rule, formValues);
          const nextValue = rule.ruleFn(formValues);
          return callFunc
            ? fieldUpdateFn(nextValue)
            : console.error(
                `${fieldName}: invalid params in field rule Function is/are:`,
                errorParams,
                `Failed rule function is: ${rule.ruleFn}`,
              );
        } catch (error) {
          console.error(`${fieldName}: Invalid rule or values for Value rule.`, error);
        }
      }
    },
    [FieldRuleType.Required]: rule => {
      if (rule.ruleFn && typeof rule.ruleFn === 'function') {
        try {
          const nextRequired = rule.ruleFn(formValues) as boolean;
          return setRequired(nextRequired);
        } catch (error) {
          console.error(`${fieldName}: Invalid rule or values for Required rule`);
        }
      }
    },
    [FieldRuleType.ReadOnly]: rule => {
      if (rule.ruleFn && typeof rule.ruleFn === 'function') {
        try {
          const nextDisabled = rule.ruleFn(formValues) as boolean;
          return setDisabled(nextDisabled);
        } catch (error) {
          console.error(`${fieldName}: Invalid rule or values for ReadOnly rule`);
        }
      }
    },
    [FieldRuleType.Hidden]: rule => {
      if (rule.ruleFn && typeof rule.ruleFn === 'function') {
        try {
          const nextHidden = rule.ruleFn(formValues) as boolean;
          return setHidden(nextHidden);
        } catch (error) {
          console.error(`${fieldName}: Invalid rule or values for Hidden rule`);
        }
      }
    },
    [FieldRuleType.Filter]: rule => {
      if (rule.ruleFn && typeof rule.ruleFn === 'function') {
        return null; // ⚠️ not implemented yet
      }
      return null;
    },
    [FieldRuleType.Valid]: rule => {
      if (rule.ruleFn && typeof rule.ruleFn === 'function') {
        return null; // ⚠️ not implemented yet
      }
      return null;
    },
  };

  useEffect(() => {
    if (fieldRules) {
      fieldRules.forEach(rule => ruleActions[rule.ruleType as FieldRuleType](rule));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRules, formValues]);

  return { required, disabled, hidden };
};
