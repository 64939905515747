import { actionTypes, requestAsync } from 'redux-query';
import { put, putResolve, select, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/actions';
import {
  GlideNotificationTypes,
  MUTATE_START_NOTIFICATION,
  MUTATE_SUCCESS_NOTIFICATION,
  NOTIF_ACTION,
  NotificationsAction,
  ReduxQueryActionType,
} from 'src/reducers/notifications';
import { ActionResult } from 'src/utils/action-resolver';
import { glideQuery } from 'src/api/query';
import { selectCVC } from 'src/reducers/tabs';
import { isEmpty } from 'lodash';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { glideQuerySelector } from 'src/api/query';
import { GOI_STORE_VIEW_PROP } from 'src/components/inspectors/glide-object-inspector/glide-object-inspector';

interface ActionResolved {
  payload: ActionResult;
}

export function* mutateStart(action: any) {
  // Custom notification
  if (action?.meta?.notification && action.meta.notification.hasOwnProperty(actionTypes.MUTATE_START)) {
    yield put({
      type: NotificationsAction.MUTATE_START,
      payload: { title: action.meta.notification[actionTypes.MUTATE_START] } || 'Saving...',
    });
  } else {
    // Generic notification
    yield put({
      type: NotificationsAction.MUTATE_START,
      payload: MUTATE_START_NOTIFICATION(),
    });
  }
}

/**
 * Update the GOM dataGridContent, client view grid and GOI when GOM datagrid content is updated
 * @param action
 */
export function* refreshRelatedObjects(action: any) {
  // Update the GOM
  const { object_uri } = action.body;
  const { object_field_name } = action.meta;
  yield putResolve(
    requestAsync(
      glideQuery({
        endpoint: '/glide/object',
        body: { object_uri, object_field_name },
        queryKey: 'dataGridContent',
      }),
    ),
  );

  const cvc: ClientViewConfigurationData = process?.env?.JEST_WORKER_ID
    ? { uri: 'cvc_test_uri' }
    : yield select(selectCVC);
  yield putResolve(
    requestAsync(
      glideQuery({
        endpoint: '/glide/view',
        body: { uri: cvc.uri },
      }),
    ),
  );

  // Update the GOI
  const inspectorData = process?.env?.JEST_WORKER_ID
    ? { uri: 'inspector_uri' }
    : yield select(glideQuerySelector, cvc.uri, GOI_STORE_VIEW_PROP);
  if (inspectorData?.uri) {
    yield putResolve(
      requestAsync(
        glideQuery({
          endpoint: '/glide/display-view/groups',
          body: {
            uri: inspectorData.uri,
            fetch_options: 'workflow_transitions',
            expand_prop: 'actions',
          },
          storeViewName: cvc.uri,
          storeViewProp: GOI_STORE_VIEW_PROP,
          queryKey: 'GOI',
        }),
      ),
    );
  }
}

export function* mutateSuccess(action: any) {
  // Custom notification
  if (action?.meta?.notification && action.meta.notification.hasOwnProperty(actionTypes.MUTATE_SUCCESS)) {
    yield put({
      type: NotificationsAction.MUTATE_SUCCESS,
      payload: { title: action.meta.notification[actionTypes.MUTATE_SUCCESS] } || 'Saved',
    });
  } else {
    // Generic notification
    yield put({
      type: NotificationsAction.MUTATE_SUCCESS,
      payload: MUTATE_SUCCESS_NOTIFICATION(),
    });
  }

  if (
    // Dispatched for collection using static actions (edge case for grid without a consistent schema like hypo scenario order)
    action.queryKey === 'deleteObjectInCollection' ||
    // Generic update dispatched by DX batch edit (most GOM datagrid)
    // Only dispatched if rows have changed (deleted) or added
    (action.queryKey === 'dataGridContent' && !(isEmpty(action.meta.changedRows) && isEmpty(action.meta.newRows)))
  ) {
    yield* refreshRelatedObjects(action);
  }
}

export function* requestFailure(action: any) {
  yield put({
    type: NotificationsAction.ERROR_NOTIFICATION,
    payload: { errorMessage: action.responseBody?.error_message },
  });
}

// Generate notification as an action of GlideNotificationTypes
export function* actionResolved(action: ActionResolved | any) {
  const glideNotificationType = GlideNotificationTypes[action?.payload?.resolved_entity_type as GlideNotificationTypes];

  if (action?.payload?.data?.action_uri === 'instance/actions/delete_object') {
    const clientViewConfiguration = yield select(selectCVC);
    yield putResolve(
      requestAsync(
        glideQuery({
          endpoint: '/glide/view',
          body: {
            uri: clientViewConfiguration.uri,
          },
        }),
      ),
    );

    // TODO for GOM refresh: check global flag about GOM being opened: https://virtusllc.visualstudio.com/AlphaKinetic/_workitems/edit/96697
  }

  if (!glideNotificationType) {
    yield put({
      type: NOTIF_ACTION(GlideNotificationTypes.action_resolved),
      payload: action.payload,
    });
  } else {
    yield put({
      type: NOTIF_ACTION(glideNotificationType),
      payload: action.payload,
    });
  }
}

export function* clearNotifications() {
  yield put({
    type: actions.errors.RESET,
  });
}

function* watchClearNotification() {
  yield takeLatest(actions.notifications.RESET, clearNotifications);
}

function* watchRequestStart() {
  yield takeLatest(ReduxQueryActionType.MUTATE_START, mutateStart);
}

function* watchRequestSuccess() {
  yield takeLatest(ReduxQueryActionType.MUTATE_SUCCESS, mutateSuccess);
}

function* watchRequestFailure() {
  yield takeLatest([ReduxQueryActionType.REQUEST_FAILURE, ReduxQueryActionType.MUTATE_FAILURE], requestFailure);
}

function* watchActionResolved() {
  yield takeLatest(NotificationsAction.ACTION_RESOLVED_NOTIFICATION, actionResolved);
}

export default [
  watchClearNotification,
  watchRequestStart,
  watchRequestSuccess,
  watchRequestFailure,
  watchActionResolved,
];
