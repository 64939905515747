import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { MenuItem } from './Select.model';
import { MenuItemStyled, MenuItemTextStyled, SelectStyled, CheckboxStyled } from './Select.style';

type SelectType = {
  disabled?: boolean;
  dataTestId?: string;
  menuItems: MenuItem[];
  onChange: (e: any) => void;
  classes: any;
  value?: string | string[];
  error?: boolean;
  style?: any;
  open?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  multiple?: boolean;
  renderValues?: (data: any) => string;
};

const Select = ({
  disabled,
  menuItems,
  onChange,
  value,
  classes: { selectMenu, icon, dropdownStyle },
  error,
  style,
  dataTestId,
  open,
  onClose,
  onOpen,
  multiple,
  renderValues,
}: SelectType) => {
  return (
    <SelectStyled
      open={open}
      data-testid={dataTestId || 'select'}
      disabled={disabled}
      value={value}
      disableUnderline
      onChange={onChange}
      onClose={onClose}
      onOpen={onOpen}
      error={error}
      classes={{ selectMenu }}
      IconComponent={disabled ? () => null : undefined}
      inputProps={{ classes: { icon } }}
      MenuProps={{ classes: { paper: dropdownStyle } }}
      style={style}
      multiple={multiple}
      renderValue={renderValues}
    >
      {menuItems.map((item: MenuItem) => (
        <MenuItemStyled key={item.value} value={multiple ? item.name : item.value}>
          {multiple && <CheckboxStyled checked={Array.isArray(value) && value?.includes(item.name)} />}
          <MenuItemTextStyled disabled={disabled}>{item.name}</MenuItemTextStyled>
        </MenuItemStyled>
      ))}
    </SelectStyled>
  );
};

const styles = {
  disabled: {
    color: 'var(--accent-grey)',
  },
  dropdownStyle: {
    marginTop: 30,
    borderRadius: '0px',
    backgroundColor: 'var(--accent-darkblue)',
    maxWidth: '400px',
    color: 'var(--red)',
  },
  icon: {
    color: 'var(--text)',
  },
  selectMenu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    paddingRight: '18px',
    '&:hover': {
      borderBottom: '0px',
    },
  },
};

export default withStyles(styles)(Select);
