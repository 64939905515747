import React, { useEffect, useState } from 'react';
import { Modal } from '@virtus/components/index';
import InputWithErrorHandling from '@virtus/components/InputWithErrorHandling';
import { PrimaryButton, TertiaryButton } from '@virtus/components/Buttons';
import * as S from './marketmap-authentication-form.style';
import { MarketmapCredentialsType } from 'src/models/marketmap/marketmap.model';

export type MarketmapAuthenticationFormPros = {
  displayDialog: boolean;
  onLivePriceTurnOff?: () => void;
  setMarketmapCredentials?: (_marketmapUser: MarketmapCredentialsType) => void;
  loggedInError?: string;
  isMarketmapConnectedToServer?: boolean;
};

const user_initial_state = { marketmapU: '', marketmapP: '', marketmapGroup: '' };

const MarketmapAuthenticationForm = React.memo(
  ({
    displayDialog,
    loggedInError,
    isMarketmapConnectedToServer,
    onLivePriceTurnOff,
    setMarketmapCredentials,
  }: MarketmapAuthenticationFormPros) => {
    const [marketmapUser, setMarketmapUser] = useState<MarketmapCredentialsType>(user_initial_state);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
      setShow(displayDialog);
    }, [displayDialog]);

    useEffect(() => {
      if (loggedInError) {
        setShow(true);
      }
    }, [loggedInError]);

    const resetForm = () => {
      setMarketmapUser(user_initial_state);
      setShow(false);
    };

    const onModalCloseHandler = () => {
      resetForm();
      if (onLivePriceTurnOff) {
        onLivePriceTurnOff();
      }
    };

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (setMarketmapCredentials) {
        setMarketmapCredentials(marketmapUser);
      }
      setShow(false);
    };

    const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputField = event.target.name;
      setMarketmapUser({
        ...marketmapUser,
        [inputField]: event.target.value,
      });
    };

    const modalFooter = (
      <S.FooterBtnWrapper>
        <TertiaryButton
          style={{ marginRight: '10px' }}
          onClick={onModalCloseHandler}
          data-testid="marketmap-auth-cancel-btn"
        >
          Cancel
        </TertiaryButton>
        <PrimaryButton type="submit" data-testid="marketmap-auth-ok-btn" disabled={!isMarketmapConnectedToServer}>
          Ok
        </PrimaryButton>
      </S.FooterBtnWrapper>
    );

    return (
      <Modal
        title="Market Data Authentication"
        onClose={onModalCloseHandler}
        show={show}
        ariaLabelledBy="marketmap-authentication-form"
        isDraggable={true}
      >
        <S.FormWrapper>
          <form
            id="marketmap-authentication-form"
            data-testid="marketmap-authentication-form"
            onSubmit={onSubmitHandler}
          >
            <S.FormControl>
              <S.Label htmlFor="marketmap-auth-username">User Name</S.Label>
              <InputWithErrorHandling
                id="marketmap-auth-username"
                showError={false}
                value={marketmapUser.marketmapU}
                inputName="marketmapU"
                onChange={inputChangeHandler}
              />
            </S.FormControl>

            <S.FormControl>
              <S.Label htmlFor="marketmap-auth-password">Password</S.Label>
              <InputWithErrorHandling
                id="marketmap-auth-password"
                showError={false}
                value={marketmapUser.marketmapP}
                inputName="marketmapP"
                onChange={inputChangeHandler}
                type="password"
              />
            </S.FormControl>

            <S.FormControl>
              <S.Label htmlFor="marketmap-auth-usergroup">User Group</S.Label>
              <InputWithErrorHandling
                id="marketmap-auth-usergroup"
                showError={false}
                value={marketmapUser.marketmapGroup}
                inputName="marketmapGroup"
                onChange={inputChangeHandler}
              />
            </S.FormControl>
            <S.ErrorBlock>
              {!isMarketmapConnectedToServer ? (
                <S.ErrorMsg>Failed to connect to marketmap terminals, please refresh page again...</S.ErrorMsg>
              ) : null}
              {loggedInError && isMarketmapConnectedToServer ? <S.ErrorMsg>{loggedInError}</S.ErrorMsg> : null}
            </S.ErrorBlock>
            {modalFooter}
          </form>
        </S.FormWrapper>
      </Modal>
    );
  },
);

export default MarketmapAuthenticationForm;
