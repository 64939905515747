import { formatMultiplierXMLParser } from '@virtus/common/utils/format-multiplier-xml-parser/formatMultiplierXMLParser';
import { GlideMappedDataTypes } from 'src/components/forms/form-elements';
import { FormEntity } from 'src/components/forms/glide-data-content/glide-data-content.model';
import { GlideDisplayView, ViewElement } from 'src/models/api/viewElement';
import { ActionArguments, Argument } from 'src/models/order/glideActionArguments.api.model';
import SearchService from 'src/services/search.service';
import { getFormFieldValue } from '../common-mapper-functions';

export interface GlideFormElementTypeParams {
  dataType: string;
  fieldName: string;
  hasLookUps: boolean;
  isMultipleSelect?: boolean;
}

export const getFormElementType = ({
  dataType,
  fieldName,
  hasLookUps,
  isMultipleSelect,
}: GlideFormElementTypeParams): GlideMappedDataTypes => {
  switch (dataType) {
    case 'bit':
      return 'checkbox';
    case 'decimal':
      return 'number';
    case 'datetime':
      return 'calendar';
    case 'largestring':
    case 'string':
      return 'text';
    case 'object': {
      if (fieldName.toLowerCase() === 'position') return 'toggler';
      if (isMultipleSelect) return 'multipleselect';
      if (!hasLookUps) return 'search';
      return 'select';
    }
    case 'objectcollection':
      return 'tableModal';
    default:
      console.warn(`Unhandled data type dataType ${dataType}. Defaulting to decimal`);
      return 'number';
  }
};

const getOptionColor = (itemName: string) => {
  if (itemName.toLowerCase() === 'sell') return 'var(--red)';
  if (itemName.toLowerCase() === 'buy') return 'var(--green)';
  return undefined;
};

export const getOptions = (lookups?: { [key: string]: string }) => {
  if (!lookups) return {};
  const nextLookups: any = {};
  Object.entries(lookups).forEach(([key, value]: any) => {
    nextLookups[key] = {
      name: value,
      value: key,
      color: getOptionColor(value),
    };
  });
  return nextLookups;
};

const getBooleanValue = (value: string | undefined) => Boolean(value === 'true');

const getDefaultUserFromInspector = (
  inspectorData: GlideDisplayView,
  field: string,
  lookups: { [key: string]: unknown },
  elementType: string,
) => {
  let defaultUser = '';
  let mergeInspectorGroup: ViewElement[] = [];
  for (const key in inspectorData) {
    mergeInspectorGroup = [...mergeInspectorGroup, ...inspectorData[key]];
  }
  const filteredField = mergeInspectorGroup.find(item => item.field?.[field as string]);
  if (elementType === 'search') return filteredField?.value;
  for (const key in lookups) {
    if (lookups[key] === filteredField?.value) {
      defaultUser = key;
      break;
    }
  }
  return defaultUser;
};

const getDefaultValue = (
  dataType: string,
  elementType: string,
  defaultValue?: any,
  inspectorData?: GlideDisplayView,
  lookups?: { [key: string]: unknown },
  field?: string,
) => {
  if (dataType === 'bit') return getBooleanValue(defaultValue as string);
  if (dataType === 'object' && defaultValue) {
    if (defaultValue === '{user}') {
      if (inspectorData && field && lookups) {
        return getDefaultUserFromInspector(inspectorData, field, lookups, elementType);
      } else {
        return Object.values(defaultValue)[0];
      }
    }
  }
  if (defaultValue === '{today}') return new Date();
  return defaultValue || '';
};

export const compareOrder = (key1: string, key2: string, elements: { [key: string]: Argument }) => {
  const el1Order = elements[key1]?.ordering;
  const el2Order = elements[key2]?.ordering;
  if (!el1Order || !el2Order) {
    return 0;
  } else {
    if (el1Order < el2Order) {
      return -1;
    }
    if (el1Order > el2Order) {
      return 1;
    }
    return 0;
  }
};

export const mapActionArgumentsToActionForm = (
  actionArguments: ActionArguments,
  actionDisplayView?: any,
  customActionOverride?: { [key: string]: any },
  inspectorData?: GlideDisplayView,
) => {
  if (!actionArguments) {
    return {
      name: '',
      formFields: {},
      actionUri: '',
    };
  }
  const elements = actionArguments?.arguments;
  const displayViewFDD = actionDisplayView?.display_view?.field_display_definitions;
  const sortedArgumentKeys =
    actionArguments?.view && displayViewFDD
      ? Object.keys(elements).sort((a, b) =>
          compareOrder(
            a,
            b,
            displayViewFDD.reduce((acc: any, item: any) => {
              if (item['argument']) {
                acc[item['argument']] = item;
              }
              return acc;
            }, {}),
          ),
        )
      : Object.keys(elements).sort((a, b) => compareOrder(a, b, elements));

  const getLookUpOptions = (
    fieldFromDisplayView: any,
    argument: any,
    customActionOverride: any,
    argument_uri: string,
  ) => {
    let options = {};
    if (fieldFromDisplayView.options) {
      options = fieldFromDisplayView.options;
    } else if (Object.keys(getOptions(argument?.lookups)).length) {
      options = getOptions(argument?.lookups);
    } else if (
      customActionOverride &&
      customActionOverride.hasOwnProperty(argument_uri) &&
      Object.keys(customActionOverride[argument_uri]?.lookups).length
    ) {
      options = customActionOverride[argument_uri]?.lookups;
    }
    return options;
  };

  const mappedActionEntity: FormEntity = {
    name: actionArguments?.display_name,
    actionUri: actionArguments?.action_uri,
    formFields: sortedArgumentKeys.reduce((accumulatedFields, argument_uri) => {
      const fieldFromDisplayView = getFormFieldFromDisplayView(argument_uri, actionDisplayView, customActionOverride);
      const argument: Argument = actionArguments?.arguments[argument_uri];
      const formElementType = getFormElementType({
        dataType: argument.argument_type.toLowerCase(),
        fieldName: argument.display_name,
        hasLookUps:
          argument.hasOwnProperty('lookups') ||
          (customActionOverride && (customActionOverride as any)[argument_uri]?.hasOwnProperty('lookups')),
        isMultipleSelect: argument?.is_multiple_select,
      });
      const dataType = argument.argument_type.toLowerCase();
      const object_type = argument.object_type_?.toLowerCase();
      const searchType = object_type ? object_type.replace('object_types/', '') : '';
      return {
        ...accumulatedFields,
        [argument_uri]: {
          name: fieldFromDisplayView?.displayName ?? argument.argument_name,
          displayName: fieldFromDisplayView?.displayName ?? argument.display_name,
          required: fieldFromDisplayView?.required ?? argument.argument_required,
          dataType: fieldFromDisplayView?.dataType ?? dataType,
          searchType: fieldFromDisplayView.searchType ?? searchType,
          searchService: SearchService().search,
          formElementType: fieldFromDisplayView?.formElementType ?? formElementType,
          readonly: false,
          defaultValue:
            fieldFromDisplayView?.defaultValue ??
            getDefaultValue(
              dataType,
              formElementType,
              argument?.argument_default as string,
              inspectorData,
              argument?.lookups,
              argument?.field,
            ),
          options: getLookUpOptions(fieldFromDisplayView, argument, customActionOverride, argument_uri),
          formatMultiplier: argument.format_multiplier
            ? formatMultiplierXMLParser(argument.format_multiplier)
            : undefined,
          hidden: fieldFromDisplayView?.isHidden ?? false,
          isMultipleSelect: argument?.is_multiple_select,
        },
      };
    }, {}),
  };

  return mappedActionEntity;
};

const getFormFieldFromDisplayView = (argument_uri: string, actionDisplayView: any, customActionOverride = {}) => {
  const fieldFromDisplayView = actionDisplayView?.display_view?.field_display_definitions.find(
    (item: any) => item?.argument === argument_uri,
  );

  if (!fieldFromDisplayView) return {};

  const instance_uri = Object.keys(fieldFromDisplayView.field)[0];
  const firstField: any = Object.values(fieldFromDisplayView.field)[0];
  const dataType = firstField.data_type.replace('lookups/', '');
  const object_type = firstField.object_type || firstField.object_type_;
  const searchType = object_type ? object_type.replace('object_types/', '') : '';
  const name = firstField.display_name;
  const style = fieldFromDisplayView.style
    ? (Object.values(fieldFromDisplayView.style)[0] as any)
    : { is_editable: true, is_hidden: false };
  const defaultValue =
    (customActionOverride as any)?.[instance_uri]?.defaultValue ||
    fieldFromDisplayView.value ||
    fieldFromDisplayView?.default_value;
  const renderType = fieldFromDisplayView.render_type ?? null;
  let format = '';
  if (firstField.hasOwnProperty('format')) {
    format = firstField.format.uri
      ? firstField.format.uri.replace('lookups/', '')
      : firstField.format.replace('lookups/', '');
  }

  return {
    name,
    displayName: name,
    dataType,
    defaultValue: getFormFieldValue({ dataType, defaultValue }),
    formElementType: getFormElementType({
      dataType,
      fieldName: firstField.display_name,
      hasLookUps: (customActionOverride as any)?.[instance_uri]?.lookups || firstField?.lookups,
    }),
    required: Boolean(firstField.required_field),
    format,
    searchType,
    searchService: SearchService().search,
    options: (customActionOverride as any)?.[instance_uri]?.lookups || getOptions(firstField.lookups),
    renderType,
    isHidden: style.is_hidden,
  };
};
