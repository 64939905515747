import React from 'react';
import BasicButton from '@virtus/components/Buttons/Basic/BasicButton';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SummaryButton = styled(BasicButton)<{ backgroundcolor?: string }>`
  background-color: ${props => props.backgroundcolor};
  height: 20px;
  max-height: 20px;
  min-width: 40px;
  max-width: 40px;
  width: 40px;
`;

export const SummaryButtonLabel = styled.span`
  padding: 0 10px;
  color: var(--text);
`;

export interface SummaryButtonsProps {
  id: string;
  buttonText: string | number;
  buttonLabel: string;
  onButtonClick: (e: any, id: string) => void;
  backgroundcolor?: string;
}

const SummaryButtons = ({ id, buttonText, buttonLabel, onButtonClick, backgroundcolor }: SummaryButtonsProps) => {
  return (
    <Wrapper>
      <SummaryButton onClick={(e: any) => onButtonClick(e, id)} backgroundcolor={backgroundcolor}>
        {buttonText}
      </SummaryButton>
      <SummaryButtonLabel>{buttonLabel}</SummaryButtonLabel>
    </Wrapper>
  );
};

export default SummaryButtons;
