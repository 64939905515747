import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

/* eslint-disable max-len */
const CreateFromTemplateIcon: React.FunctionComponent<SvgIconProps> = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#FFF"
        d="M7 3c-1.1 0-1.99.9-1.99 2L5 19c0 1.1.89 2 1.99 2H17c1.1 0 2-.9 2-2V7l-4-4H7zm9 12h-3v3h-2v-3H8v-2h3v-3h2v3h3v2zm-2-7V4.5L17.5 8H14z"
      />
    </g>
  </SvgIcon>
);

export default CreateFromTemplateIcon;
