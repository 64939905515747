/* eslint-disable max-len */
import styled from 'styled-components';
import { DataGrid as DxDataGrid } from 'devextreme-react/data-grid';
import { layoutRegion } from '../glide-object-manager.model';

export const DataGrid = styled(DxDataGrid)`
  width: 100%;
  height: inherit;
`;

export const GridWrapper = styled.div<{
  edit?: boolean;
  layout?: layoutRegion;
}>`
  background: 'var(--bgLight)';
  width: 100%;
  height: 100%;
  padding: ${props => (props.layout !== layoutRegion.BOTTOM ? '10px' : '0px')};
  & .dark-theme {
    background: 'var(--darkblue)';
  }
  & .dark-theme .dx-icon-trash {
    padding: 4px;
    color: var(--grey) !important;
    &:hover {
      color: var(--red) !important;
    }
  }

  & .dark-theme .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-data-row > td {
    height: 30px;
    padding: 0px 7px 0px 7px;
  }

  &
    .dark-theme
    .dx-datagrid
    .dx-datagrid-content
    .dx-datagrid-table
    .dx-data-row
    > td[role='gridcell']
    > .numeric-cell {
    background: ${props => (props.edit ? 'rgba(255, 255, 255, 0.1)' : 'inherit')};
    padding: 4px;
    border-radius: 3px;
  }
  &
    .dark-theme
    .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
    td {
    background-color: rgba(13, 131, 201, 0.1);
    border-top: 1px solid var(--default-blue);
    border-bottom: 1px solid var(--default-blue);
  }
  & .dx-datagrid-search-panel {
    height: 28px;
    display: flex;
    align-items: center;
  }
  & .dx-toolbar .dx-toolbar-items-container {
    display: flex;
    flex-direction: row;
    min-height: 36px;
    height: auto;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  & .dx-toolbar-before,
  .dx-toolbar-after {
    position: static;
  }
`;
