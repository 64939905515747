import React, { useEffect } from 'react';
import { Modal } from '@virtus/components';
import * as S from 'src/components/overlay-groups/overlay-group.style';
import { GlideDataContent } from 'src/components/forms/glide-data-content';
import InspectorGroup from '@virtus/components/Inspector/components/InspectorGroup';
import { useFormGroup } from 'src/components/forms/hooks/useFormGroup';
import { mapDetailsToFormElements } from 'src/mappers/common-mapper-functions';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import { useDispatch } from 'react-redux';
import { NotificationsAction } from 'src/reducers/notifications';
import { backDropClick, escapeKeyDown } from 'src/utils/constants';
export interface OverlayGroupsProps {
  show: boolean;
  title: string;
  onCloseCallback: () => void;
  primaryButton?: { text?: string; action?: (data?: any) => void; disabled?: true };
  secondaryButton?: { text?: string; action?: (data?: any) => void; disabled?: true };
  expandAction?: () => void;
  formGroups: any;
  objectKey?: string;
  formGroupMapper?: any;
  cancelButton?: { text?: string; action?: (data?: any) => void; disabled?: true };
}

const OverlayGroups = ({
  show = false,
  title,
  primaryButton,
  onCloseCallback,
  formGroups,
  objectKey,
  formGroupMapper,
  cancelButton,
}: OverlayGroupsProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (formGroups && Object.keys(formGroups).length > 0) {
      let groupDetailsInfo: any;
      if (formGroupMapper) {
        groupDetailsInfo = formGroupMapper(formGroups);
      } else {
        groupDetailsInfo = mapDetailsToFormElements(formGroups.data);
      }
      setFormGroups(groupDetailsInfo.formGroups);
    }
  }, [formGroups]);

  const onValidationError = (errorMessage: string) => {
    dispatch({
      type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
      payload: { errorMessage },
    });
  };

  const onSubmit = (formChanges: any) => {
    if (Object.keys(formChanges).length > 0) {
      primaryButton?.action && primaryButton?.action(formChanges);
    }
  };
  const { onChangeField, setFormGroups, formGroupsState, onSave } = useFormGroup({
    onSubmit,
    onValidationError,
    objectKey: objectKey ?? 'neworder',
    initialEditing: true,
  });

  const handleOnSubmitAction = () => {
    onSave();
  };
  const onCancelOrder = () => {
    cancelButton?.action && cancelButton?.action();
  };

  const onClose = (_: any, reason?: string) => {
    if (reason === backDropClick || reason === escapeKeyDown || reason === undefined) {
      onCloseCallback();
    }
  };

  const { DialogComponent: QuitAddInstrumentDialog } = useConfirmationDialog({
    onClick: onCloseCallback,
    headerText: 'Save change',
    bodyTextContent: `Are you sure you want to close ${title} form window?`,
  });

  const overlayPrimaryButton = {
    text: primaryButton?.text ?? 'Submit',
    action: handleOnSubmitAction,
    disabled: primaryButton?.disabled,
  };
  const overlayCancelButton = {
    text: cancelButton?.text ?? 'Cancel Order',
    action: onCancelOrder,
    disabled: cancelButton?.disabled,
  };

  const groupForm: any = () => {
    if (formGroupsState) {
      return Object.entries(formGroupsState).map(([formGroupName, value]: any, index: number) => (
        <InspectorGroup isExpanded={index === 0} title={formGroupName} key={formGroupName}>
          <GlideDataContent
            isEditing
            formName={formGroupName}
            dataTestId="overlay-group-form"
            form={value}
            formValues={value.formValues}
            onChangeField={onChangeField}
            hideFooter={true}
            uri={formGroups.uri}
          />
        </InspectorGroup>
      ));
    }
    return null;
  };
  const dialogStyle = { height: '80vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' };
  return (
    <>
      <QuitAddInstrumentDialog />
      <Modal
        title={title}
        onClose={onClose}
        show={show}
        isDraggable={true}
        primaryButton={overlayPrimaryButton}
        cancelButton={overlayCancelButton}
        maxWidth="md"
        fullWidth={true}
        dialogStyle={dialogStyle}
        headerStyle={{ zIndex: 99999 }}
        disableBackdropClick={false}
      >
        <S.Wrapper>{groupForm()}</S.Wrapper>
      </Modal>
    </>
  );
};

export default OverlayGroups;
