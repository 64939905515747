import { Action } from 'redux';
import { RootState } from './rootReducer';
import { FormFieldType } from 'src/components/forms/form-elements/FormElement.model';

export enum FormsAction {
  SET_FORM = 'SET_FORM',
  SET_DEFAULT_VALUES = 'SET_DEFAULT_VALUES',
  RESET_FORM = 'RESET_FORM',
  VALIDATE_FORM = 'VALIDATE_FORM',
}

export interface FormData {
  formFields: FormFieldType;
  formValues: object;
  formErrors: object;
  hasChanged: boolean;
  formChanges: object;
}

export interface FormActionType extends Action {
  formData: FormData;
  fieldRules?: object;
  defaultValues?: object;
  mapFieldRulesFn?: (formData: FormData, fieldRules: object) => FormData;
}

export const FormInitialState: FormData = {
  formFields: {} as FormFieldType,
  formValues: {},
  formErrors: {},
  formChanges: {},
  hasChanged: false,
};

export const formData = (state: FormData = FormInitialState, action: FormActionType) => {
  switch (action.type) {
    case FormsAction.SET_FORM:
      return {
        ...state,
        ...action.formData,
      };
    case FormsAction.SET_DEFAULT_VALUES:
      return state;
    case FormsAction.VALIDATE_FORM:
      return state;
    case FormsAction.RESET_FORM:
      return { ...state, ...FormInitialState };
    default:
      return state;
  }
};

export const formsSelector = (state: RootState) => state.formData;
