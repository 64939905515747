import { Delete, Edit } from '@material-ui/icons';
import { colors } from 'src/theme';
import { IconButton } from 'src/tool-bar/tool-bar.style';
import styled from 'styled-components';

export const StyledInspectorGroup = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.text};
  padding: 0.4rem 0;
  font-size: 12px;
  border-bottom: solid 1px ${colors.grey};
`;

export const StyledHeader = styled.div`
  color: ${(props: { disabled?: boolean }) => (props.disabled ? 'rgba(255,255,255,0.5)' : '#fff')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
`;

export const Content = styled.div`
  padding-top: 10px;
`;

export const ActionButton = styled(IconButton)`
  width: 25px;
  height: 20px;
  padding: 2px;
  margin-bottom: 5px;
  color: var(--text);
  background-color: inherit;
  border-radius: 0px;
`;

export const EditIcon: any = styled(Edit)`
  width: 15px;
  height: 15px;
  padding: 0px;
  color: var(--text);
  margin-right: 5px;
`;

export const DeleteIcon: any = styled(Delete)`
  width: 15px;
  height: 15px;
  padding: 0px;
  color: var(--text);
  margin-right: 5px;
`;
