import { DXActionButtonIcons } from 'src/components/grids/grid-action-column/grid-action-column';
import { groupedDataColumn } from 'src/utils/constants';
import { ILayout } from 'src/components/grids/dxgrid-client-view/templates/Layouts/Layouts.model';
import { dispatchActions } from 'src/app/store';
import isEqual from 'lodash/isEqual';
import { ComponentPropsType } from 'src/reducers/components';
interface FooterSummary {
  e: any;
  targetCompareVal: number;
  checkEquality?: boolean;
}

export const randomStringKey = (length: number, chars: string) => {
  let mask = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
  let result = '';
  for (let i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
  return result;
};

export const commonRowAction = (clickCb: (e: any) => void): any[] => {
  return [
    {
      text: 'Info',
      icon: DXActionButtonIcons.info,
      onClick: clickCb,
    },
  ];
};

export const deleteDataGridColumn = (schema: any, fieldName: string) => {
  if (schema && schema?.find((x: any) => x.dataField === fieldName)) {
    schema.map((x: any, index: number) => {
      if (x.dataField === fieldName) schema.splice(index, 1);
    });
  }
};

export const highlightSummaryFooter = ({ e, targetCompareVal, checkEquality = false }: FooterSummary) => {
  const currentTotalValue = e.component.getTotalSummaryValue(e.column.caption);
  e.cellElement.classList.toggle(
    'highlight-in-red',
    checkEquality
      ? currentTotalValue !== targetCompareVal
      : currentTotalValue > targetCompareVal || currentTotalValue < 0 || !currentTotalValue,
  );
  e.cellElement.classList.toggle(
    'highlight-in-green',
    checkEquality
      ? currentTotalValue === targetCompareVal
      : currentTotalValue <= targetCompareVal && currentTotalValue >= 0 && currentTotalValue,
  );
};

export const footerSummaryFields = ({ column, e, formProps }: any) => {
  switch (column) {
    case 'Allocation %':
      highlightSummaryFooter({ e, targetCompareVal: 100, checkEquality: true });
      break;
    case 'Target Notional':
    case 'Expected Notional':
      highlightSummaryFooter({ e, targetCompareVal: formProps?.orderExpectedNotional });
      break;
    case 'Commitment':
      highlightSummaryFooter({ e, targetCompareVal: formProps?.orderCommitment });
      break;
  }
};

export const getSortedData = (data: any, prop: string, isAsc: boolean) => {
  return data.sort((a: any, b: any) => (a[prop] < b[prop] ? -1 : 1) * (isAsc ? 1 : -1));
};

export const addDataGridColumn = (schema: any, field: any) => {
  if (schema && schema.length && !schema?.find((x: any) => x.dataField === field.dataField)) schema.push(field);
};

export const onColumnGrouped = (e: any) => {
  if (e.name !== 'columns' || !e.fullName.includes('groupIndex')) return;
  const groupedColumns = e.component
    .state()
    .columns?.filter((column: any) => column.groupIndex !== undefined)
    .map((column: any) => column.dataField);
  if (e.value !== undefined) {
    const columnOptions = e.component.columnOption(groupedDataColumn.dataField);
    // Check if a column is grouped
    if (!columnOptions) {
      e.component.addColumn(groupedDataColumn);
    }
  } else if (e.previousValue !== undefined) {
    //Delete column when grouping filter is removed
    if (groupedColumns.length === 1) {
      e.component.deleteColumn(groupedDataColumn.dataField);
      e.component.clearGrouping();
    }
  }
};

export const moveSelectedToFirstPosition = (layout: ILayout | undefined, layouts: ILayout[] | undefined) => {
  if (!layout || !layouts) return [];
  const layoutIndex = layouts.findIndex(e => e.uri === layout.uri);
  const nextLayoutsOrdered = [...layouts];
  const removed = nextLayoutsOrdered.splice(layoutIndex, 1)[0];
  nextLayoutsOrdered.splice(0, 0, removed);
  return nextLayoutsOrdered;
};

export enum HTTPMETHOD {
  'POST' = 'POST',
  'GET' = 'GET',
  'PUT' = 'PUT',
  'DELETE' = 'DELETE',
}

export const gridFilterChanges = (
  event: any,
  components: ComponentPropsType,
  clientViewUri: string,
  layoutUri: string,
) => {
  if (components?.viewComponents[clientViewUri]?.gridLayout?.selectedLayout?.uri === layoutUri) {
    if (
      event.fullName.includes('visibleIndex') ||
      event.fullName.includes('fixed') ||
      event.fullName.includes('sortOrder') ||
      event.fullName.includes('groupIndex') ||
      event.fullName.includes('filterValues')
    ) {
      if (!isEqual(event.value, event.previousValue)) {
        dispatchActions.components.updateView('gridLayout', clientViewUri, {
          hasChanges: true,
        });
      }
    }
    /*On refresh icon click we can`t check previous value as above conditions because it is resetting to previously selected value.
      So using selectedLayout component state to compare search panel and pageSize values.
      This issue is with these two fields (searchPanel.text, pageSize) only.
    */
    if (event.fullName.includes('searchPanel.text')) {
      if (!isEqual(components?.viewComponents[clientViewUri]?.gridLayout?.selectedLayout?.searchText, event.value)) {
        dispatchActions.components.updateView('gridLayout', clientViewUri, {
          hasChanges: true,
        });
      } else {
        dispatchActions.components.updateView('gridLayout', clientViewUri, {
          hasChanges: false,
        });
      }
    } else if (event.fullName.includes('pageSize')) {
      if (!isEqual(components?.viewComponents[clientViewUri]?.gridLayout?.selectedLayout?.pageSize, event.value)) {
        dispatchActions.components.updateView('gridLayout', clientViewUri, {
          hasChanges: true,
        });
      } else {
        dispatchActions.components.updateView('gridLayout', clientViewUri, {
          hasChanges: false,
        });
      }
    }
    if (event.fullName === 'filterValue') {
      if (!isEqual(components?.viewComponents[clientViewUri]?.gridLayout?.selectedLayout?.filterValue, event.value)) {
        dispatchActions.components.updateView('gridLayout', clientViewUri, {
          hasChanges: true,
        });
      } else {
        dispatchActions.components.updateView('gridLayout', clientViewUri, {
          hasChanges: false,
        });
      }
    }
  }
};
