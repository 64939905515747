/* eslint-disable no-confusing-arrow */
import { ClientViewTypeLookUps } from 'src/components/glide-view/glide-view.model';
import { RootState } from 'src/reducers/rootReducer';

export interface GridState {
  currentDataGridRef: any;
}

interface GridActionPayload {
  client_view_type: string;
  gridRef?: any;
  gridState?: any;
}

export enum GridActions {
  SET_GRID_STATE = 'SET_GRID_STATE',
}

const initialState = (): GridState => {
  return {
    currentDataGridRef: {},
  };
};

export const grids = (
  state: GridState = initialState(),
  action: {
    type: GridActions;
    payload: GridActionPayload;
  },
) => {
  switch (action.type) {
    case GridActions.SET_GRID_STATE: {
      if (action.payload?.client_view_type === ClientViewTypeLookUps.Pivot) {
        action.payload?.gridRef?.current?.instance?.getDataSource().state(action.payload?.gridState);
        return {
          ...state,
          currentDataGridRef: action.payload?.gridRef?.current?.instance
            ?.getDataSource()
            .state(action.payload?.gridState),
        };
      } else {
        action.payload?.gridRef?.current?.instance?.state(action.payload?.gridState);
        return {
          ...state,
          currentDataGridRef: action.payload?.gridState,
        };
      }
    }
    default:
      return state;
  }
};

export const gridSelector = (state: RootState) => state.grids;
