import { endpoints } from 'src/api/constants';
import capitalize from 'lodash/capitalize';
import { parseWorkflowTransitions } from 'src/mappers/common-mapper-functions';
import { mapFieldRules } from 'src/api/field-rules/field-rules.mapper';
import { sanitizeGridData } from 'src/mappers/orders/sanitize-order-data';

export interface getDisplayViewGroupProps {
  viewName: string;
  uri: string;
  fetch_options?: string;
  transformFn?: any;
  expand_prop?: string;
}

export type GetDisplayViewType = {
  uri: string;
  activeView: string;
  fetchOptions?: string;
};

const defaultDealsDisplayView = null;
const fieldRulesMapping = (body: any) => {
  let fieldRules: any = null;
  if (body?.field_rules && body.field_rules !== '[]') {
    fieldRules =
      typeof body.field_rules === 'string'
        ? mapFieldRules(JSON.parse(body.field_rules))
        : mapFieldRules(body.field_rules);
  }
  return fieldRules;
};
const evalDataObject = (data: any, uri: string) => {
  if (!data || !data.length) return [];
  const cvcIndex = uri ? uri.indexOf('client_view_configuration') : -1;
  if (cvcIndex > -1) return sanitizeGridData(JSON.parse(data));
  else return JSON.parse(data);
};

export const parseDisplayViewData = (body: any) => ({
  data: evalDataObject(body?.data, body?.uri),
  schema: body.schema,
  uri: body.uri,
  displayName: body.display_name,
  displayView: body?.display_view ? JSON.parse(body.display_view) : undefined,
  displayViewData: body?.display_view ? JSON.parse(body.display_view)?.data : {},
  fieldRules: fieldRulesMapping(body),
  workflowActions: body?.workflow_transitions ? parseWorkflowTransitions(JSON.parse(body.workflow_transitions)) : [],
  webLayouts: body?.web_layouts?.length > 0 ? JSON.parse(body.web_layouts) : undefined,
  clientViewConfiguration:
    body?.client_view_configuration?.length > 0 ? JSON.parse(body.client_view_configuration)?.data : undefined,
});

export const getDisplayViewGroup = ({
  viewName,
  uri,
  fetch_options,
  transformFn,
  expand_prop = 'actions',
}: getDisplayViewGroupProps) => ({
  url: endpoints.displayView.groups,
  transform: (body: any) => ({
    views: {
      [`clientData_${viewName}`]: { inspectorData: transformFn ? transformFn(body) : parseDisplayViewData(body) },
    },
  }),
  options: { method: 'GET' },
  body: { uri, fetch_options, expand_prop },
  queryKey: `get${capitalize(viewName)}DisplayView`,
  force: true,
  update: {
    views: (prev: any, next: any) => ({
      ...prev,
      [`clientData_${viewName}`]: {
        ...prev[`clientData_${viewName}`],
        inspectorData: next[`clientData_${viewName}`].inspectorData,
      },
    }),
  },
});
export const displayViewGroupSelector = (state: any, viewName: string) =>
  state.entities.views?.[`clientData_${viewName}`]?.inspectorData || defaultDealsDisplayView;

export const isPending = (state: any, viewName: string) => {
  const queryKey = `get${capitalize(viewName)}DisplayView`;
  return state.queries[queryKey] && state.queries[queryKey].isPending;
};

export const getDisplayView = (uri: string) => ({
  url: endpoints.displayView.root,
  transform: (body: any) => {
    return {
      displayViewData: {
        data: {
          ...(body.data ? JSON.parse(body.data) : {}),
        },
        display_view: body?.display_view ?? {},
        fieldRules: body?.field_rules ?? body.field_rules,
      },
    };
  },
  options: { method: 'GET' },
  body: { uri },
  queryKey: `getDisplayView`,
  force: true,
  update: {
    displayViewData: (prev: any, next: any) => ({ [uri]: next, ...prev }),
  },
});

export const displayViewSelector = (state: any) => state.entities.displayViewData || defaultDealsDisplayView;

export const isDVPending = (state: any) => state.queries.getDisplayView && state.queries.getDisplayView.isPending;
