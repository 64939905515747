import React from 'react';
import { HtmlEditor as DXHtmlEditor, Toolbar, Item } from 'devextreme-react/html-editor';
import styled from 'styled-components';

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = [
  'Arial',
  'Courier New',
  'Georgia',
  'Impact',
  'Lucida Console',
  'Tahoma',
  'Times New Roman',
  'Verdana',
];
const headerValues = [false, 1, 2, 3, 4, 5];

// FIXME: Untill upgradation to version 20.2 onwards fro Devextreme widgets. Used below to show scrollbar for toolbar
const ToolbarWrapper = styled.div`
  .dx-toolbar .dx-toolbar-before {
    display: inline-flex;
  }
`;

export interface HtmlEditorProps {
  onValueChanged: (e?: any) => void;
  content: string;
  placeholder?: string;
  error?: boolean;
}
export const HtmlEditor: React.FunctionComponent<HtmlEditorProps> = ({
  onValueChanged,
  content,
  placeholder,
  error,
}) => {
  return (
    <React.Fragment>
      <ToolbarWrapper className="widget-container">
        <DXHtmlEditor
          valueType="html"
          style={{ color: 'grey', background: 'var(--text)', height: '100%', border: error && '2px solid red' }}
          placeholder={placeholder}
          value={content}
          onValueChanged={onValueChanged}
        >
          <Toolbar>
            <Item formatName="undo" />
            <Item formatName="redo" />
            <Item formatName="bold" />
            <Item formatName="italic" />
            <Item formatName="strike" />
            <Item formatName="underline" />
            <Item formatName="alignLeft" />
            <Item formatName="alignCenter" />
            <Item formatName="alignRight" />
            <Item formatName="alignJustify" />
            <Item formatName="orderedList" />
            <Item formatName="bulletList" />
            <Item formatName="size" formatValues={sizeValues} />
            <Item formatName="font" formatValues={fontValues} />
            <Item formatName="header" formatValues={headerValues} />
            <Item formatName="color" />
            <Item formatName="background" />
            <Item formatName="link" />
            <Item formatName="image" />
            <Item formatName="clear" />
            <Item formatName="codeBlock" />
            <Item formatName="blockquote" />
          </Toolbar>
        </DXHtmlEditor>
      </ToolbarWrapper>
    </React.Fragment>
  );
};
