import { ModalButton } from 'src/components/forms/form-elements/FormElements.style';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper/FormErrorWrapper';
import OpenDialogIcon from '@virtus/components/icons/OpenDialogIcon';
import React, { useState, useEffect } from 'react';
import { Changes } from './model';
import GlideObjectManager, { GlideObjectManagerProps } from './container/glide-object-manager.container';
import { GlideObjectCommentaryContainer } from './container/glide-object-commentary.container';
import * as S from './style';
import { layoutRegion } from './components/glide-object-manager.model';
export interface GlideObjectManagerFieldProps
  extends Omit<
    GlideObjectManagerProps,
    | 'loading'
    | 'getDataGrid'
    | 'updateDataGridRows'
    | 'deleteDataGridRows'
    | 'dataGrid'
    | 'dataGridLoading'
    | 'onBack'
    | 'gridData'
    | 'rowData'
    | 'getRowData'
  > {
  title: string;
  isTable?: boolean;
  enableEdit?: boolean;
  uris: string[] | undefined;
  tabsKey: string;
  onTabsClick: (tabsKey: any) => void;
  useGOMGrid: boolean;
  renderType?: string | null;
  selectedRowData?: { [key: string]: any };
}

export const GlideObjectManagerField = ({
  title,
  fieldName,
  actions,
  inspectors,
  uris,
  isTable,
  enableEdit,
  formProps,
  onGridViewChangesSaved,
  objectCollectionUri,
  tabsKey,
  onTabsClick,
  useGOMGrid,
  renderType = null,
  selectedRowData,
}: GlideObjectManagerFieldProps) => {
  const [showModal, setShowModal] = useState(false);
  const [amountOfElements, setAmountOfElements] = useState(uris?.length ?? 0);
  const [displayType, setDisplayType] = useState<string>('preview');

  const onShowModal = (e: any) => {
    e.preventDefault();
    setShowModal(true);
  };

  useEffect(() => {
    setAmountOfElements(uris?.length ?? 0);
  }, [uris, uris?.length]);

  const handleOnGridViewChangesSaved = (changes: Changes) => {
    setAmountOfElements(changes.currentAmountOfItems);
    if (onGridViewChangesSaved) {
      onGridViewChangesSaved(changes);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setDisplayType('preview');
  };

  const handleExpansion = () => {
    setDisplayType('fullscreen');
  };

  return (
    <>
      {isTable ? (
        <TableButton fieldName={fieldName} onClick={(e?: any) => onShowModal(e)} />
      ) : (
        <TableModalFormField
          title={title}
          onClick={(e?: any) => (tabsKey ? onTabsClick({ key: tabsKey, renderType }) : onShowModal(e))}
          enableEdit={enableEdit}
          fieldName={fieldName}
          amountOfElements={amountOfElements}
        />
      )}
      <S.Dialog open={showModal} onClose={handleCloseModal} display_type={displayType}>
        {!useGOMGrid ? (
          <GlideObjectCommentaryContainer
            commentaryUris={uris as string[]}
            creditUri={objectCollectionUri}
            componentType={title}
            onBack={handleCloseModal}
            handleExpansion={handleExpansion}
          />
        ) : (
          <GlideObjectManager
            layout={layoutRegion.POPUP}
            actions={actions}
            inspectors={inspectors}
            fieldName={fieldName}
            enableEdit={enableEdit}
            formProps={formProps}
            selectedRowData={selectedRowData}
            objectCollectionUri={objectCollectionUri}
            onBack={handleCloseModal}
            onGridViewChangesSaved={handleOnGridViewChangesSaved}
            displayType={displayType}
            handleExpansion={handleExpansion}
          />
        )}
      </S.Dialog>
    </>
  );
};

interface TableButtonProps {
  fieldName: string;
  onClick: (e?: any) => void;
}

const TableButton = ({ fieldName, onClick }: TableButtonProps) => {
  if (!fieldName) return null;
  return (
    <S.ModalButton onClick={onClick} key={fieldName}>
      {fieldName}
    </S.ModalButton>
  );
};

interface TableModalFormField {
  title: string;
  onClick: (e?: any) => void;
  enableEdit?: boolean;
  fieldName: string;
  amountOfElements: number;
}
const TableModalFormField = ({ title, onClick, enableEdit, fieldName, amountOfElements }: TableModalFormField) => (
  <FormErrorWrapper error="" displayName={title} name={title} disabled={false} required={false} readonly={true}>
    <ModalButton
      color="var(--textDark)"
      onClick={onClick}
      editIsEnabled={enableEdit}
      title={`object-manager-button-${fieldName}`}
    >
      <S.ModalButtonText>View ({amountOfElements})</S.ModalButtonText>
      <OpenDialogIcon style={{ width: '20px' }} />
    </ModalButton>
  </FormErrorWrapper>
);

export default GlideObjectManagerField;
