import { InputBase } from '@material-ui/core';
import styled, { css } from 'styled-components';

const commentaryPadding = css`
  padding-top: 5px;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex: 3;
  padding: 20px 20px 0 20px;
  flex-direction: column;
  font-family: 'Nunito Sans', 'Roboto', 'Helvetica Neue', sans-serif;
  color: white;
  height: 100%;
  width: 100%;
  background-color: var(--bgInspectorFullscreenContent);
`;

export const TitleWrapper = styled.div`
  padding: 10px 0;
  font-size: 24px;
  font-weight: 600;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const SubTitleWrapper = styled.div`
  ${commentaryPadding}
  display: flex;

  & > span:nth-of-type(1) {
    flex-basis: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const TextWrapper = styled.span<{ size?: number; weight?: string | number; color?: any }>`
  margin-right: 20px;
  font-size: ${({ size }) => size || 12}px;
  font-weight: ${({ weight }) => weight || 'normal'};
  color: ${({ color }) => color || 'inherit'};
`;

export const CategoryWrapper = styled.div`
  ${commentaryPadding}
  font-size: 14px;
`;

export const ContentWrapper = styled.div`
  padding: 5px 0;
  margin-top: 20px;
  font-size: 14px;
  overflow-y: scroll;
  flex: 1;
`;

export const Input = styled(InputBase)`
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 2%;
`;
