import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 14px;
  position: relative;
  left: -5px;
  width: 14px;
  line-height: 14px;
`;

export const IconButton = styled.button`
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  padding: 0;
`;

export const MenuItem = styled.div`
  min-width: 150px;
  max-height: 280px;
  overflow-y: auto;
  margin-bottom: 40px;
  padding-left: 10px;
  border-bottom: 2px solid var(--dropdownHoveredLabel);
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-right: 10px;
  position: relative;
  line-height: 14px;
`;

export const PortfolioDropdownContentWrapper = styled.div`
  overflow: hidden;
  min-height: 300px;
`;

export const PortfolioDropdownFooter = styled.div`
  width: 100%;
  position: absolute;
  bottom: 10px;
`;
