import { RouteComponentProps } from '@reach/router';
import { Page as Screen } from '@virtus/components/page';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import GlideObjectInspector from 'src/components/inspectors/glide-object-inspector/glide-object-inspector';
import { RootState } from 'src/reducers';
import { ComponentProps, selectComponents, selectViewComponent } from 'src/reducers/components';
import { activeTabSelector, subRoutesSelector, TabsAction, viewsSelector } from 'src/reducers/tabs';
import GlideToolbar, { GlideToolbarProps } from './components/glide-toolbar/glide-toolbar';
import Notifications from './components/Notifications/Notifications';
import Search from './components/Search';
import MarketmapAuthenticationForm from 'src/components/grids/dxgrid-client-view/templates/marketmap-authentication-form/marketmap-authentication-form';
import { GlideBody, SearchIcon } from './screen-wrapper.style';
import { useMarketmap } from 'src/hooks/use-marketmap';
import { dispatchActions } from 'src/app/store';
import { ColumnManagerInspector } from 'src/components/inspectors/column-manager-inspector/column-manager-inspector';
import NotificationOverlayMessages from 'src/components/screen-wrapper/components/overlay-notifications/overlay-notifications';
import { ImportInstrumentsOverlayTitle } from 'src/utils/constants';
import { NotificationsAction, notificationSelector } from 'src/reducers/notifications';
import { GlobalOrderPopup } from 'src/components/portfolio/components/global-order/global-order-popup';
import AddInstrumentOverlay from 'src/components/portfolio/components/add-instrument/add-instrument';
import LayoutManagerInspectorComponent from '../inspectors/layout-manager-inspector/layout-manager-inspector';
import { ClientView } from 'src/api/views/client-view';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import { glideInspectorStyle } from 'src/components/inspectors/glide-inspector/glide-inspector';
import { PaperStyled } from 'src/components/inspectors/glide-inspector/glide-inspector.style';
import NewObject from 'src/components/forms/new-object/new-object';

export type RenderProps = { notification: Notification | null };

interface OwnProps {
  pageProps: any;
  children?: any;
  clientViewData?: ClientView;
  dataRef: any;
  // Obsolete
  collapseWrapper?: boolean;
  render?: (props?: RenderProps) => JSX.Element;
  pageInspector?: (props?: any) => JSX.Element | null;
  isExpanded?: boolean;
  path?: string;
  subRoutes?: any;
  onClickTab?: (uri: string, path: string) => void;
}

interface NotificationProps {
  notification: Notification | null;
}

interface ReduxProps {
  views: { [viewKey: string]: any };
  clientViewUri: string;
  subRoutes: any;
  notification: any;
  relativeInspector: any;
  relativeColumnManager: any;
}

interface NotificationDispatchProps {
  closeMessageModal: () => void;
}
export type ScreenWrapperProps = RouteComponentProps &
  OwnProps &
  Partial<GlideToolbarProps> &
  ComponentProps &
  ReduxProps &
  NotificationProps &
  NotificationDispatchProps;

const onClickTabDefault = () => console.info('Action not defined');

const ScreenWrapper: FunctionComponent<ScreenWrapperProps> = ({
  children,
  onClickTab = onClickTabDefault,
  dataRef,
  // pageInspector,
  views = { '': '' },
  clientViewUri,
  pageProps,
  pageToolBarProps,
  components,
  extraIconButtons = [],
  links,
  path,
  subRoutes,
  notification,
  closeMessageModal,
  relativeInspector,
  relativeColumnManager,
}) => {
  // console.info(`[Screen-WRAPPER] category ${path}`);
  const {
    isLoggedInToMarketmap,
    showMMAuthenticationForm,
    isMarketmapConnectedToServer,
  } = components.livePriceToggleState;

  const { loggedInError, turnOffLivePriceButton, setMarketmapCredentials } = useMarketmap();
  const toggleColumnManagerInspector = () =>
    dispatchActions.components.updateView('columnManagerInspector', components.global.currentClientViewUri, {
      visible: false,
    });
  const toggleSearchInspector = () => dispatchActions.components.toggleDisplay('searchInspector');
  const toggleLayoutInspector = () => dispatchActions.components.toggleDisplay('layoutManager');
  const onTabClick = (tab: any) => {
    const matchedRoute = subRoutes.find((subRoute: any) => subRoute?.path?.replace('/', '') === tab);
    onClickTab && onClickTab(matchedRoute?.uri, path as string);
  };

  const viewComponents = components.viewComponents[clientViewUri];
  const closeInstrumentModal = () =>
    dispatchActions.components.update('global', { importInstrumentOverlay: { visible: false } });

  return (
    <Screen {...pageProps}>
      {/*modal components*/}
      <MarketmapAuthenticationForm
        displayDialog={showMMAuthenticationForm && isLoggedInToMarketmap ? false : showMMAuthenticationForm}
        onLivePriceTurnOff={turnOffLivePriceButton}
        setMarketmapCredentials={setMarketmapCredentials}
        loggedInError={loggedInError}
        isMarketmapConnectedToServer={isMarketmapConnectedToServer}
      />
      <AddInstrumentOverlay
        displayOverlay={components.global.importInstrumentOverlay.visible}
        modalCloseHandler={closeInstrumentModal}
      />
      <GlobalOrderPopup />
      {notification && notification?.messageList && notification?.showMessagesOnPopup && (
        <NotificationOverlayMessages
          show={notification?.showMessagesOnPopup}
          notification={notification}
          title={ImportInstrumentsOverlayTitle}
          primaryButton={{ text: 'Ok', action: closeMessageModal }}
          onCloseCallback={closeMessageModal}
        />
      )}
      {/*Glide views*/}
      <GlideBody>
        {!components?.summaryPanel?.visible && (
          <GlideToolbar
            pageToolBarProps={pageToolBarProps}
            views={views}
            links={links}
            activeViewKey={clientViewUri}
            onClickTab={onTabClick}
            extraIconButtons={[
              ...extraIconButtons,
              { Icon: SearchIcon, onClick: toggleSearchInspector, testId: 'search-button', tooltip: 'Global search' },
            ]}
            isInspectorCollapsed={relativeInspector?.isCollapsed}
            isColumnManagerVisible={relativeColumnManager?.visible}
            isSearchInspectorVisible={components.searchInspector?.visible}
            isLayoutManagerVisible={components.layoutManager?.visible}
          />
        )}
        {children}
      </GlideBody>
      {components.searchInspector?.visible && <Search onClose={toggleSearchInspector} />}
      {components?.layoutManager?.visible && (
        <LayoutManagerInspectorComponent
          clientViewUri={dataRef?.current?.props?.id ?? clientViewUri}
          loadingIcon={{ type: LoadingIconType.Glide, size: LoadingIconSizes.large }}
          style={glideInspectorStyle}
          paperProps={{ component: PaperStyled }}
          dataGridRef={dataRef}
          onClose={toggleLayoutInspector}
        />
      )}
      {components.newObject?.visible && <NewObject newActionUri={components.newObject?.actionUri} showAsModal />}
      {/* render global action selector here */}
      {components?.globalActions?.visible}
      {components.viewComponents[components.global.currentClientViewUri]?.columnManagerInspector?.visible && (
        <ColumnManagerInspector
          onClose={toggleColumnManagerInspector}
          isExpanded={false}
          {...components.viewComponents[components.global.currentClientViewUri].columnManagerInspector.props}
        />
      )}
      {/*The root inspector will always take precedence (ie: create new order notif)*/}
      <GlideObjectInspector
        clientViewUri={clientViewUri}
        // first check if static component has been requested (ie: new entity needs updating)
        uri={components?.inspector?.uri || viewComponents?.inspector?.uri}
      />
      <Notifications />
    </Screen>
  );
};

const mapStateToProps = (state: RootState, { path }: any): ComponentProps & ReduxProps => ({
  components: selectComponents(state),
  views: viewsSelector(state, path),
  subRoutes: subRoutesSelector(state, path),
  clientViewUri: activeTabSelector(state),
  notification: notificationSelector(state),
  relativeInspector: selectViewComponent(state, 'inspector', activeTabSelector(state)),
  relativeColumnManager: selectViewComponent(state, 'columnManagerInspector', activeTabSelector(state)),
});

const mapDispatchToProps = (dispatch: any): NotificationDispatchProps | any => ({
  onClickTab: (viewUri: string, path: string) =>
    dispatch({ type: TabsAction.CHANGE_VIEW, data: { fullPath: path, activeView: viewUri } }),
  closeMessageModal: () => dispatch({ type: NotificationsAction.RESET_NOTIFICATION }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenWrapper);
