import styled from 'styled-components';

export const TitleWithEllipsis = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: initial;
`;

const getDarkBackground = (dark?: boolean) => (dark ? 'var(--grey)' : 'white');

// reusable css for dropdown on grid toolbar
export const LayoutButton = styled.button<{ active?: boolean; dark?: boolean }>`
  display: flex;
  align-items: center;
  border: ${props => (props.dark ? 'none' : '1px solid var(--accent-grey)')};
  color: ${props => (props.active || props.dark ? 'var(--text)' : '#5a5d5f')};
  background: ${props => (props.active ? 'var(--default-blue)' : getDarkBackground(props.dark))};
  height: 28px;
  font-size: 14px;
  font-family: 'Nunito Sans', 'Roboto', 'Helvetica Neue', sans-serif;
  white-space: nowrap;
  cursor: pointer;
`;
