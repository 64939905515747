import React from 'react';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper';
import Select, { MenuItem } from './components/Select';
import { useFieldRule } from 'src/components/forms/hooks/use-field-rule';

export const SelectInput = ({
  field,
  value = '',
  onChange,
  error,
  formValues,
  customWidth,
}: FormElementInputComponent) => {
  const getOptionsValue = (options: MenuItem[]) =>
    options.find((option: MenuItem) => option.name == value || option.value == value)?.value;

  const selectedValue = field.options ? getOptionsValue(Object.values(field.options)) : '';

  const fieldUpdateFn = (nextValue: any) => {
    const event: Partial<React.SyntheticEvent> = {
      target: {
        value: nextValue,
      } as HTMLInputElement,
    };
    onChange(event);
  };

  const fieldRule = useFieldRule({
    fieldName: field.displayName,
    fieldRules: field.fieldRules,
    formValues,
    fieldUpdateFn,
  });
  if (fieldRule.hidden) return null;

  return (
    <FormErrorWrapper
      error={error}
      displayName={field.displayName}
      name={field.name}
      disabled={fieldRule.disabled || field.disabled}
      required={fieldRule.required || field.required}
      readonly={fieldRule.disabled || field.readonly}
      disableErrorWarning={field.disableErrorWarning}
      customWidth={customWidth}
    >
      <Select
        dataTestId={`${field.name}-select`}
        disabled={fieldRule.disabled || field.disabled || field.readonly}
        value={selectedValue || ''}
        error={Boolean(error) && !(field.readonly || field.disabled)}
        onChange={onChange}
        menuItems={field.options ? Object.values(field.options) : []}
      />
    </FormErrorWrapper>
  );
};
