import InspectorGroup from '@virtus/components/Inspector/components/InspectorGroup';
import React from 'react';
import { FormFieldsType } from 'src/components/forms/form-elements/FormElement.model';
import { GlideDataContent, GlideDataContentProps } from 'src/components/forms/glide-data-content';
import { PaperProps } from '@material-ui/core/Paper';
import Loading from '@virtus/components/Loading';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import { PanelHeader } from '@virtus/components/Panel/PanelHeader';
import Collapser from 'src/components/collapser/collapser';
import * as S from './glide-object-inspector.default.style';

export type DefaultInspectorProps = {
  isEditing: boolean;
  dataTestId: string;
  title: string;
  renderFooter: any;
  forms: { [key: string]: { formValues: any; formErrors: any; formFields: FormFieldsType } };
  onChangeField: (data: { [key: string]: string }, formName: string) => void;
  objectCollectionUri: string;
  style?: any;
  objectEditActions?: GlideDataContentProps['objectEditActions'];
  objectEditInspectors?: GlideDataContentProps['objectEditInspectors'];
  onChange?: (data: any) => void;
  useGOMGrid?: boolean;
  generateTitle?: (formGroupName: string, formValues: any) => string;
  onForwardLinkClick?: (uri: string, objectType: string | undefined) => void;
  forwardLinkObjectType?: string[];
  onExpand: any;
  loading: boolean;
  onBack?: any;
  onClose?: any;
  loadingIcon: {
    type: LoadingIconType;
    size: LoadingIconSizes;
  };
  children?: any;
  open?: boolean;
  type?: string;
  portfolio?: any;
  height?: string;
  paperProps?: any;
  tooltipText?: string;
  associatedObjectTitle?: string;
};

const DefaultInspector = ({
  isEditing = false,
  dataTestId,
  renderFooter,
  forms,
  onBack,
  title,
  loading,
  onClose,
  onExpand,
  onChangeField,
  tooltipText,
  loadingIcon,
  objectCollectionUri,
  objectEditActions,
  objectEditInspectors,
  generateTitle,
  useGOMGrid = true,
  onForwardLinkClick,
  forwardLinkObjectType,
}: DefaultInspectorProps) => {
  const formValues = Object.values(forms).reduce(
    (acc, form) => ({
      ...acc,
      ...form.formValues,
    }),
    {},
  );
  return (
    <Collapser componentName="inspector">
      <S.DrawerContainer
        open
        variant="persistent"
        anchor="right"
        PaperProps={{ component: S.PaperStyled as React.ComponentClass<PaperProps> }}
      >
        <S.DrawerContent data-testid={dataTestId || 'default-inspector'}>
          <S.InspectorWrapper>
            <PanelHeader
              showBackButton={false}
              onBack={onBack}
              title={title}
              onClose={onClose}
              showCloseBtn={true}
              tooltipText={tooltipText || title}
              onExpand={onExpand}
            />
            {loading ? (
              <Loading show={true} type={loadingIcon.type} size={loadingIcon.size} full />
            ) : (
              <S.RowsWrapper data-testid={dataTestId}>
                {Object.entries(forms).map(([formGroupName, value]) => (
                  <InspectorGroup
                    isExpanded
                    title={generateTitle ? generateTitle(formGroupName, value) : formGroupName}
                    key={formGroupName}
                  >
                    <GlideDataContent
                      dataTestId={`${formGroupName}-default`}
                      isEditing={isEditing}
                      optionalStyles={{ noPadding: true, noBackground: true }}
                      uri={objectCollectionUri}
                      objectEditActions={objectEditActions}
                      hideFooter
                      onChangeField={onChangeField}
                      form={value}
                      formValues={formValues}
                      formName={formGroupName}
                      useGOMGrid={useGOMGrid}
                      objectEditInspectors={objectEditInspectors}
                      onForwardLinkClick={onForwardLinkClick}
                      forwardLinkObjectType={forwardLinkObjectType}
                    />
                  </InspectorGroup>
                ))}
              </S.RowsWrapper>
            )}
          </S.InspectorWrapper>
        </S.DrawerContent>
        {renderFooter}
      </S.DrawerContainer>
    </Collapser>
  );
};

export default DefaultInspector;
