import React, { useState, useEffect } from 'react';
import { Modal } from '@virtus/components';
import * as S from './add-instrument.style';
import Select from 'src/components/forms/form-elements/form-fields/SelectInput/components/Select';
import Tooltip from '@material-ui/core/Tooltip';
import { StyledWarningIcon } from 'src/components/forms/form-elements/FormElements.style';
import { useDispatch } from 'react-redux';
import { actions } from 'src/reducers/actions';

export interface AddInstrumentOverlayProps {
  modalCloseHandler: () => void;
  displayOverlay: boolean;
}

type AddinstrumentType = 'import_instruments' | 'create_manually';
interface AddInstrumentStateProps {
  addType: AddinstrumentType;
  identifier_type: string;
  identifiers: string;
}

interface IdentifiersListType {
  value: string;
  name: string;
}

interface RadioButtonsType {
  value: AddinstrumentType;
  display_name: string;
  disabled?: boolean;
  selected?: boolean;
  visible?: boolean;
}

const RadioButtons: RadioButtonsType[] = [
  { value: 'import_instruments', display_name: 'Import Instruments', disabled: false, selected: true },
  { value: 'create_manually', display_name: 'Create Manually', disabled: true, selected: false, visible: false },
];

const IdentifierList: IdentifiersListType[] = [{ value: 'marketmap_id', name: 'MarketMap ID' }];

const initialState: AddInstrumentStateProps = {
  addType: 'import_instruments',
  identifier_type: 'marketmap_id',
  identifiers: '',
};

const AddInstrumentOverlay = ({ modalCloseHandler, displayOverlay = false }: AddInstrumentOverlayProps) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [modalState, setModalState] = useState<AddInstrumentStateProps>(initialState);
  const [errorsInstrumentForm, setErrorInstrumentForm] = useState({ identifiers: false });
  const resetAllFields = () => {
    setModalState(initialState);
  };
  const validateInstrumentForm = () => {
    let valid = true;
    if (!modalState.identifiers.trim()) {
      setErrorInstrumentForm({ ...errorsInstrumentForm, identifiers: true });
      valid = false;
    }
    return valid;
  };

  const importAllFields = () => {
    const isInstrumentFormValid = validateInstrumentForm();
    if (!isInstrumentFormValid) return;

    const identifiers = modalState.identifiers.trim().replace(/\n/g, ',');
    setErrorInstrumentForm({ ...errorsInstrumentForm, identifiers: false });
    dispatch({
      type: actions.action.EXECUTE_ACTION,
      payload: {
        targetUri: ['instance/mapping/market_map_data_imports'],
        arguments: { 'instance/argument/marketdataids': identifiers },
        actionUri: 'instance/actions/marketmap_feed',
      },
    });
    closeAddInstrumentModal();
  };

  const secondaryButton = { text: 'Reset', action: resetAllFields };
  const primaryButton = { text: 'Import', action: importAllFields };

  const closeAddInstrumentModal = () => {
    modalCloseHandler();
    setErrorInstrumentForm({ ...errorsInstrumentForm, identifiers: false });
  };

  const handleFieldChange = (e: any) => {
    const field = e?.target.name;
    if (field === 'identifiers' && errorsInstrumentForm.identifiers && e.target.value)
      setErrorInstrumentForm({ ...errorsInstrumentForm, identifiers: false });
    if (field) {
      setModalState({ ...modalState, [field]: e.target.value });
    }
  };

  useEffect(() => {
    setShow(displayOverlay);
    if (displayOverlay) {
      setModalState({ ...initialState });
    }
  }, [displayOverlay]);

  const radioGroup = (
    <S.StyledFieldWrapper>
      <S.StyledLabel>Creation mode</S.StyledLabel>
      <S.StyledRadioGroup
        data-testid="add-instrument-add-type"
        name="addType"
        value={modalState.addType}
        onChange={handleFieldChange}
      >
        {RadioButtons.map((item, index) => (
          <S.StyledRadioWrapper
            key={index}
            className={item.disabled ? 'radio-button-disabled' : ''}
            style={item.hasOwnProperty('visible') && !item?.visible ? { visibility: 'hidden' } : {}}
          >
            <S.StyledRadio checked={item.selected} disableRipple disabled={item.disabled} value={item.value} />
            <S.StyledLabel>{item.display_name}</S.StyledLabel>
          </S.StyledRadioWrapper>
        ))}
      </S.StyledRadioGroup>
    </S.StyledFieldWrapper>
  );

  const selectInput = (
    <S.StyledFieldWrapper>
      <S.StyledLabel>Identifier Type</S.StyledLabel>
      <Select
        dataTestId="add-instrument-identifiers-select"
        style={{ width: '40%', backgroundColor: 'var(--bg)' }}
        disabled={true}
        value={modalState.identifier_type}
        onChange={handleFieldChange}
        menuItems={IdentifierList}
      />
    </S.StyledFieldWrapper>
  );

  const textarea = (
    <S.StyledFieldWrapper>
      <S.StyledLabel>Identifiers</S.StyledLabel>
      <div style={{ display: 'flex' }}>
        {errorsInstrumentForm.identifiers && (
          <S.Error>
            <Tooltip
              title="Identifiers are required"
              style={{
                background: 'var(--red)',
                color: 'var(--text)',
                fontSize: '25px',
                marginRight: '12px',
              }}
            >
              <StyledWarningIcon data-testid="alert-icon" />
            </Tooltip>
          </S.Error>
        )}
        <S.StyledTextField
          data-testid="add-instrument-identifiers"
          value={modalState.identifiers}
          name="identifiers"
          onChange={handleFieldChange}
          style={{ border: errorsInstrumentForm.identifiers ? 'solid 1px #F20000' : '', width: '100%' }}
          rows={8}
          multiline
        />
      </div>
    </S.StyledFieldWrapper>
  );

  return (
    <>
      <Modal
        title="Add Instruments"
        onClose={closeAddInstrumentModal}
        show={show}
        isDraggable={true}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        disableBackdropClick
      >
        <S.FormWrapper>
          <form data-testid="add-instrument-form">
            {radioGroup}
            {selectInput}
            {textarea}
          </form>
        </S.FormWrapper>
      </Modal>
    </>
  );
};

export default AddInstrumentOverlay;
