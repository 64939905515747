import { ValidationRules } from '@virtus/components/DxDataGrid';
import numeral from 'numeral';

export const validateSum = (num1: number, num2: number, checkEquality?: boolean) => {
  return checkEquality ? num1 === num2 : num1 <= num2;
};

export const getNumber = (num: any) => {
  return isNaN(num) ? 0 : num;
};

type FormProps = {
  orderCommitment: number;
  orderExpectedNotional: number;
};

type ExternalProps = {
  dataGridRef: any;
  formProps: FormProps;
};

type FieldRuleType = {
  externalProps: ExternalProps;
};

type ValidationCallBackParams = {
  column: any;
  data: any;
  rule: any;
  validator: any;
  value: string | number;
};

type ValidationRule = {
  type: 'required' | 'numeric' | 'range' | 'stringLength' | 'custom' | 'compare' | 'pattern' | 'email' | 'async';
  validationCallback?: (params: ValidationCallBackParams) => boolean | string | void;
  message?: String;
  min?: number;
  max?: number;
};
/*
 * We convert to two digits decimal as a workaround when sum of values using
 * decimals is higher than the field value.
 *
 * Example:
 *
 * We have a list of fund allocations, and the sum of its commitment value cannot
 * be higher than the order commitment (and the same rule for notional)
 *
 * The problem is that we receive this value for Order commitment: 2,000,000
 * And the fund allocations commitment sum is: 2,000,000.0000007
 *
 * That values are populated by the backend side, and it causes that when users goes
 * to edit fund allocations, without doing any change, they see an error
 * "Fund allocations commitment sum cannot be higher that order commitment",
 * which is true due 2,000,000.0000007 is higher than  2,000,000.
 *
 * We thought about round decimals in the frontend side, but that will
 * cause errors due users would expect validator will warn them
 * about invalid values if they add decimals that make fund allocations
 * commitment sum be higher than order commitment when edit fund allocations.
 *
 * Solutions:
 * A) Make fund allocations commitment sum match  order commitment when an order is created
 * B) Make order commitment match fund allocations sum when an order is created
 *
 * Workaround:
 *
 * C) Round in the frontend side to two decimals. If user edits fund allocations commitment.
 * Probably this would work in most of the cases, but we must keep in mind that this is not a
 * frontend error and the values must be fixed or the validation rule requirements changed.
 */
export const convertValuesToTwoDigitsDecimal = (num1: number | string, num2: number | string) => {
  const _num1 = parseFloat(numeral(num1).format('0.00'));
  const _num2 = parseFloat(numeral(num2).format('0.00'));
  return { columnSum: _num1, fieldValue: _num2 };
};

type Validators = {
  [key: string]: {
    [key: string]: ({ externalProps }: FieldRuleType) => ValidationRules[];
  };
};

const requiredRule: ValidationRule = {
  type: 'required',
  message: 'This value is required',
};

const rangeRule: ValidationRule = {
  type: 'range',
  min: 0,
  message: 'Enter atleast minimum value 0',
};

export const commitmentValidator = (sum: number, externalProps: any) => {
  const { columnSum, fieldValue } = convertValuesToTwoDigitsDecimal(sum, externalProps?.formProps?.orderCommitment);

  const valid = validateSum(columnSum, fieldValue);
  return { columnSum, fieldValue, valid };
};

export const expectedNotionalValidator = (sum: number, externalProps: any) => {
  const { columnSum, fieldValue } = convertValuesToTwoDigitsDecimal(
    sum,
    externalProps?.formProps?.orderExpectedNotional,
  );
  const valid = validateSum(columnSum, fieldValue);

  return { columnSum, fieldValue, valid };
};

export const targetNotionalValidator = (sum: number, externalProps: any) => {
  const { columnSum, fieldValue } = convertValuesToTwoDigitsDecimal(
    sum,
    externalProps?.formProps?.orderExpectedNotional,
  );
  const valid = validateSum(columnSum, fieldValue);
  return { columnSum, fieldValue, valid };
};

export const allocationValidator = (sum: number) => {
  const { columnSum, fieldValue } = convertValuesToTwoDigitsDecimal(sum, 100);
  const valid = validateSum(columnSum, fieldValue);
  return { columnSum, fieldValue, valid };
};

const getFieldSummation = ({ externalProps }: FieldRuleType, params: any) => {
  const gridInstanceMethod = { ...externalProps.dataGridRef.current.instance };
  const totalRows = gridInstanceMethod.totalCount();
  return [...Array(totalRows).keys()].reduce((sum, currentVal) => {
    return sum + gridInstanceMethod.cellValue(currentVal, params.column.dataField);
  }, 0);
};

/*  
  ℹ️ validationCallback is the name of the property for DXGrid:
  https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxValidator/Validation_Rules/AsyncRule/ 

  We return a promise due we need to use setTimeout to get the summary value from the grid reference after
  it is updated. When the validator is called by DXGrid, it executes the promise and uses the resolved value 
  to determine if it is valid or not. That is something that I cannot change it is internal DXGrid behavior.

  Why this is needed?:

  The summary is updated after the validator was fired, so without the promise we will use the sum value that
  was in the grid before the cell was edited.

  In order to fix that, I created an async validator to fire the validator after the sum is calculated by dx grid, 
  so that way the cell will be invalid if the value causes validator fail.
*/
export const commitmentRule = ({ externalProps }: FieldRuleType): ValidationRule => ({
  type: 'custom',
  message: `Commitment column sum cannot be higher than order commitment ${Number(
    getNumber(externalProps?.formProps?.orderCommitment),
  ).toLocaleString()}`,
  validationCallback: (params: ValidationCallBackParams) => {
    const commitment_sum = getFieldSummation({ externalProps }, params);
    return validateSum(commitment_sum, getNumber(externalProps.formProps?.orderCommitment));
  },
});

export const expectedNotionalRule = ({ externalProps }: FieldRuleType): ValidationRule => ({
  type: 'custom',
  message: `Expected notional column sum cannot be higher than order expected notional ${Number(
    getNumber(externalProps?.formProps?.orderExpectedNotional),
  ).toLocaleString()}`,
  validationCallback: (params: ValidationCallBackParams) => {
    const expected_notional_sum = getFieldSummation({ externalProps }, params);
    return validateSum(expected_notional_sum, getNumber(externalProps.formProps?.orderExpectedNotional));
  },
});

export const targetNotionalRule = ({ externalProps }: FieldRuleType): ValidationRule => ({
  type: 'custom',
  message: `Target notional column sum cannot be higher than order target notional ${Number(
    getNumber(externalProps?.formProps?.orderExpectedNotional),
  ).toLocaleString()}`,
  validationCallback: (params: ValidationCallBackParams) => {
    const target_notional_sum = getFieldSummation({ externalProps }, params);
    return validateSum(target_notional_sum, getNumber(externalProps.formProps?.orderExpectedNotional));
  },
});

export const allocationRule = ({ externalProps }: FieldRuleType): ValidationRule => {
  return {
    type: 'custom',
    message: 'Allocation percentage column sum must be equal to 100%',
    validationCallback: (params: ValidationCallBackParams) => {
      const allocation_perc_sum = getFieldSummation({ externalProps }, params);
      return validateSum(allocation_perc_sum, 100, true);
    },
  };
};

const validators: Validators = {
  fund_allocations: {
    Commitment: ({ externalProps }) => [requiredRule, commitmentRule({ externalProps })],

    'Expected Notional': ({ externalProps }) => [requiredRule, expectedNotionalRule({ externalProps })],

    'Target Notional': ({ externalProps }) => [requiredRule, targetNotionalRule({ externalProps })],

    'Allocation %': ({ externalProps }) => [requiredRule, rangeRule, allocationRule({ externalProps })],

    Fund: () => [requiredRule],
  },
};

export default validators;
