import React, { useEffect } from 'react';
import * as theme from 'src/theme';
import styled from 'styled-components';

export interface PageProps {
  pageTitle: string;
  children: React.ReactNode;
  /* pass in test id if you want to pick out `<Page>` in a test using data-testid */
  style?: React.CSSProperties;
  testid?: string;
  /** optionally have app name in title eg "Glide | Home" */
  appName?: string;
}

/**
 * Page sets document title eg "Glide | Home" etc
 *
 * Add `<Body>` perhaps as children
 *
 * Note:`<Header>` is fixed element at top of `<App>` and not a child of `<Page>`
 */

const useDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

const getAppName = (appName?: string) => (appName ? `${appName} | ` : '');
const getTitle = (pageTitle: string, appName?: string) => `${getAppName(appName)}${pageTitle}`;
const Page: React.FunctionComponent<PageProps> = ({ appName, pageTitle, children, testid, style }) => {
  useDocumentTitle(getTitle(pageTitle, appName));
  return (
    <PageContent data-testid={testid || 'Page-children'} style={style}>
      {children}
    </PageContent>
  );
};

export default Page;

const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* padding-top: ${theme.variables.headerHeight}; */
  height: 100%;
`;
