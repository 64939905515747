import { CheckboxFilledIcon } from 'src/icons/CheckboxIcons';
import React from 'react';
import * as S from './column-row.style';
import DropdownMenu, { DropdownMenuItem, DropdownMenuItemText } from 'src/DropdownMenu';

interface ColumnRowProps {
  toggleChecked: () => void;
  value: string;
  actions: any[];
  isDragging: boolean;
  checked?: boolean;
  fixed?: boolean;
}

export const ColumnRow = ({ checked, toggleChecked, value, actions, isDragging, fixed }: ColumnRowProps) => {
  return (
    <S.Row isDragging={isDragging} data-testid="column-row">
      <S.RowContainer>
        <S.BlueCheck
          data-testid={`${value}-check`}
          checked={Boolean(checked)}
          onChange={toggleChecked}
          value={value}
          checkedIcon={<CheckboxFilledIcon />}
        />
        <S.Column>{value}</S.Column>
      </S.RowContainer>
      {fixed && <S.LockIcon />}
      {actions.length !== 0 && (
        <DropdownMenu light menuMarginTop={0}>
          {actions.map(action => (
            <DropdownMenuItem key={action.text} onClick={action.onClick}>
              <action.icon style={{ fontSize: '14px', marginRight: '5px' }} />
              <DropdownMenuItemText>{action.text}</DropdownMenuItemText>
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      )}
    </S.Row>
  );
};
