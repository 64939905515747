/* eslint-disable max-len */
export const filterIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
<g fill="none" fill-rule="evenodd">
    <path d="M0 0h24v24H0z"/>
    <path fill="#000" d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/>
</g>
</svg>`;

export const resetIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
<g fill="none" fill-rule="evenodd">
    <path d="M0 0h24v24H0z"/>
    <path fill="#000" d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"/>
</g>
</svg>
`;

export const exportIcon = encodeURIComponent(`<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 30 30'>
<g fill='none' fill-rule='evenodd'>
    <path d='M0 0h24v24H0z'/>
    <path fill='#000' d='M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z'/>
</g>
</svg>`);

export const columnManagementIcon = `<svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox='0 0 22 22'>
<g fill="none" fill-rule="evenodd">
    <path d="M0 0h16v16H0z"/>
    <path fill="#000" d="M13.977 8.784c.032-.256.056-.512.056-.784s-.024-.528-.056-.784l1.688-1.32a.404.404 0 0 0 .096-.512l-1.6-2.768a.402.402 0 0 0-.488-.176l-1.992.8a5.845 5.845 0 0 0-1.352-.784l-.304-2.12A.39.39 0 0 0 9.633 0h-3.2a.39.39 0 0 0-.392.336l-.304 2.12c-.488.2-.936.472-1.352.784l-1.992-.8a.39.39 0 0 0-.488.176l-1.6 2.768a.394.394 0 0 0 .096.512l1.688 1.32c-.032.256-.056.52-.056.784s.024.528.056.784l-1.688 1.32a.404.404 0 0 0-.096.512l1.6 2.768c.096.176.312.24.488.176l1.992-.8c.416.32.864.584 1.352.784l.304 2.12a.39.39 0 0 0 .392.336h3.2a.39.39 0 0 0 .392-.336l.304-2.12c.488-.2.936-.472 1.352-.784l1.992.8a.39.39 0 0 0 .488-.176l1.6-2.768a.404.404 0 0 0-.096-.512l-1.688-1.32zM8.033 10.8a2.803 2.803 0 0 1-2.8-2.8c0-1.544 1.256-2.8 2.8-2.8 1.544 0 2.8 1.256 2.8 2.8 0 1.544-1.256 2.8-2.8 2.8z"/>
</g>
</svg>`;
