import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import sortBy from 'lodash/sortBy';
import { GlobalActionObject } from 'src/models/actions';

export type GroupedListProps = {
  data: GlobalActionObject[];
  onChange: (event: object, value: any, reason: string) => void;
  label?: string;
};

export const GroupedList: React.FC<GroupedListProps> = ({ data, onChange, label }) => {
  const inputRender = (params: any) => <StyledTextField label={label} variant="filled" {...params} />;
  return (
    <StyledAutoComplete
      data-testid="global-actions"
      options={sortBy(data, o => o.data.category)}
      groupBy={(option: any) => option.data.category}
      getOptionLabel={(option: any) => option.data.display_name}
      renderInput={inputRender}
      onChange={onChange}
    />
  );
};

const commonStyle = { color: 'white' };
export const StyledAutoComplete: any = withStyles({
  root: {
    width: '17vw',
    height: '8vh',
  },
  paper: {
    backgroundColor: 'var(--bg)',
    ...commonStyle,
  },
  groupLabel: {
    backgroundColor: 'var(--bg)',
    ...commonStyle,
  },
  clearIndicatorDirty: commonStyle,
  popupIndicator: commonStyle,
})(Autocomplete);

export const StyledTextField: any = styled(TextField)`
  & > label {
    color: rgba(255, 255, 255, 0.54);
  }
  & > div {
    background-color: var(--bg);
    color: white;
    font-size: 14px;
  }
`;
