import { IconButton, Typography } from '@material-ui/core';
import { colors } from 'src/theme';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #28333c;
  padding: 25px 12px 25px 25px;
  height: 56px;
  width: 100%;
`;

export const Title = styled(Typography)`
  color: ${colors.text};
  text-transform: capitalize;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 70%;
`;

export const IconBtn = styled(IconButton)`
  color: ${colors.bgLight};
  cursor: pointer;
  padding: 5px;
  width: 36px;
  height: 34px;
`;
