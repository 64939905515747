import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

/* eslint-disable max-len */
const OpenDialogIcon: React.SFC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon width="18px" height="16px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="DROP-DESIGNS-HERE" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Hypos-Multi-Select---add-to-scenario-4-Copy" transform="translate(-1397.000000, -144.000000)">
        <g id="Group-23-Copy-5" transform="translate(970.000000, 40.000000)">
          <g id="Group-41" transform="translate(20.000000, 64.000000)">
            <g id="Group-37" transform="translate(114.000000, 32.000000)">
              <g id="open_dialog" transform="translate(290.000000, 4.000000)">
                <polygon id="frame" points="0 0 24 0 24 24 0 24" />
                <path
                  d="M19,4 L5,4 C3.89,4 3,4.9 3,6 L3,18 C3,19.1 3.89,20 5,20 L9,20 L9,18 L5,18 L5,8 L19,8 L19,18 L15,18 L15,20 L19,20 C20.1,20 21,19.1 21,18 L21,6 C21,4.9 20.11,4 19,4 L19,4 Z M12,10 L8,14 L11,14 L11,20 L13,20 L13,14 L16,14 L12,10 L12,10 Z"
                  id="shape"
                  fill="#FFFFFF"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
/* eslint-enable max-len */
export default OpenDialogIcon;
