import InputBase from '@material-ui/core/InputBase';
import Search from '@material-ui/icons/Search';
import styled from 'styled-components';

export const Input = styled(InputBase)<{ margin: string; label: string } & any>`
  color: var(--text);
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  input {
    text-indent: 23px;
    font-size: 0.875rem;
  }
`;

export const Row = styled.div`
  display: flex;
  height: 35px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
`;

export const SearchIcon = styled(Search)`
  color: var(--text);
  opacity: 0.3;
  position: absolute;
  margin-top: 3px;
  margin-left: 5px;
  width: 21px;
`;
