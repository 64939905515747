import React, { useState, useEffect } from 'react';
import * as S from './column-manager.style';
import { ColumnRow } from './components/column-row';
import { DxGridInternalStateColumn } from 'src/DxDataGrid/model/DxDataGrid.model';
import { Draggable, Droppable, DragDropContext, DropResult } from 'react-beautiful-dnd';
import { VerticalAlignBottom, VerticalAlignTop, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import DropdownMenu, { DropdownMenuItem, DropdownMenuItemText } from 'src/DropdownMenu';

interface IconProps {
  style: React.CSSProperties;
}

type DropDownOptions = {
  label: string;
  onClick: () => void;
};

export interface ColumnManagerProps {
  columns: DxGridInternalStateColumn[];
  dropdownOptions?: DropDownOptions[];
  onUpdateColumns: (columns: DxGridInternalStateColumn[]) => void;
  excludedColumns?: string[];
}

const reorder = (list: DxGridInternalStateColumn[], startIndex: number, endIndex: number) => {
  const result = [...list];
  const [removed]: any = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result.map((column, index) => ({ ...column, visibleIndex: index }));
};

const sortByGridOrder = (columns: DxGridInternalStateColumn[]) => {
  const sortedColumns = [...columns];
  sortedColumns.sort((a, b) => a.visibleIndex - b.visibleIndex);
  return sortedColumns;
};

const sortByAZ = (columns: DxGridInternalStateColumn[]) => {
  const sortedColumns = [...columns];
  sortedColumns.sort((a, b) => {
    if (a.dataField < b.dataField) {
      return -1;
    }
    if (a.dataField > b.dataField) {
      return 1;
    }
    return 0;
  });
  return sortedColumns;
};

const fixColumn = (
  updatedColumn: DxGridInternalStateColumn,
  filteredColumns: DxGridInternalStateColumn[],
  position: 'left' | 'right',
) =>
  filteredColumns.map(column => {
    if (column.dataField === updatedColumn.dataField) {
      column.fixed = true;
      column.fixedPosition = position;
    }
    return column;
  });

const moveColumnToTheTop = (
  column: DxGridInternalStateColumn,
  columns: DxGridInternalStateColumn[],
  search: string,
) => {
  const reorderedColumns: any = reorder(columns, column.visibleIndex, 0);
  const filteredColumns = getFilteredColumns(reorderedColumns, search);
  return { reorderedColumns, filteredColumns };
};

const moveColumnToTheBottom = (
  column: DxGridInternalStateColumn,
  columns: DxGridInternalStateColumn[],
  search: string,
) => {
  const reorderedColumns: any = reorder(columns, column.visibleIndex, columns.length);
  const filteredColumns = getFilteredColumns(reorderedColumns, search);
  return { reorderedColumns, filteredColumns };
};

const getFilteredColumns = (columns: DxGridInternalStateColumn[], search: string) => {
  if (search === '') return columns;

  const filteredColumns = columns.filter(column => column.dataField.toLowerCase().includes(search.toLowerCase()));
  return filteredColumns;
};

const getColumnVisibility = (updatedColumn: DxGridInternalStateColumn, currentColumn: DxGridInternalStateColumn) => {
  if (currentColumn.dataField === 'Order Date') {
    return true;
  }
  if (currentColumn.dataField === updatedColumn.dataField) {
    return !currentColumn.visible;
  }
  return currentColumn.visible;
};

export const ColumnManager = (props: ColumnManagerProps) => {
  const [columns, setColumns] = useState<DxGridInternalStateColumn[]>([]);
  const [filteredColumns, setFilteredColumns] = useState<DxGridInternalStateColumn[]>([]);
  const [search, setSearch] = useState(() => '');
  const [tabIndex, setTabIndex] = useState(0);

  const handleOnToggleChecked = (updatedColumn: DxGridInternalStateColumn) => {
    const nextColumns = columns.map(column => {
      return {
        ...column,
        visible: getColumnVisibility(updatedColumn, column),
      };
    });
    setColumns(nextColumns);
    props.onUpdateColumns(nextColumns);
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (destination && destination.index >= 0) {
      const reordered: any = reorder(columns, source.index, destination.index);
      setColumns(reordered);
      props.onUpdateColumns(reordered);
    }
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const getInitialSortedColumns = () => sortByGridOrder(props.columns);

  useEffect(() => {
    const _filteredColumns = getFilteredColumns(columns, search);
    setFilteredColumns(_filteredColumns);
  }, [search, columns]);

  useEffect(() => {
    const initialColumns = getInitialSortedColumns();
    setColumns(initialColumns);
    setFilteredColumns(initialColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.columns]);

  const updateColumns = (
    nextColumns: DxGridInternalStateColumn[],
    nextFilteredColumns: DxGridInternalStateColumn[],
  ) => {
    setColumns(nextColumns);
    setFilteredColumns(nextFilteredColumns);
    props.onUpdateColumns(nextColumns);
  };

  /* Column Actions */
  const onMoveToTheTop = (column: DxGridInternalStateColumn) => {
    const { reorderedColumns, filteredColumns: _filteredColumns } = moveColumnToTheTop(column, columns, search);
    updateColumns(reorderedColumns, _filteredColumns);
  };

  const onMoveToTheBottom = (column: DxGridInternalStateColumn) => {
    const { reorderedColumns, filteredColumns: _filteredColumns } = moveColumnToTheBottom(column, columns, search);
    updateColumns(reorderedColumns, _filteredColumns);
  };

  const onFixToTheTop = (updatedColumn: DxGridInternalStateColumn) => {
    const { reorderedColumns, filteredColumns: _filteredColumns } = moveColumnToTheTop(updatedColumn, columns, search);
    const nextFilteredColumns = fixColumn(updatedColumn, _filteredColumns, 'left');
    updateColumns(reorderedColumns, nextFilteredColumns);
  };

  const onFixToTheBottom = (updatedColumn: DxGridInternalStateColumn) => {
    const { reorderedColumns, filteredColumns: _filteredColumns } = moveColumnToTheBottom(
      updatedColumn,
      columns,
      search,
    );
    const nextFilteredColumns = fixColumn(updatedColumn, _filteredColumns, 'right');
    updateColumns(reorderedColumns, nextFilteredColumns);
  };

  const onUnfix = (updatedColumn: DxGridInternalStateColumn) => {
    const nextColumns = columns.map(column => {
      if (column.dataField === updatedColumn.dataField) {
        column.fixed = false;
        column.fixedPosition = undefined;
      }
      return column;
    });
    setColumns(nextColumns);
    props.onUpdateColumns(nextColumns);
  };

  /* Tab Actions */
  const onGridOrderTabClick = () => {
    const sortedColumns = sortByGridOrder(columns);
    const _filteredColumns = getFilteredColumns(sortedColumns, search);
    updateColumns(sortedColumns, _filteredColumns);
    setTabIndex(0);
  };

  const onAlphabeticalTabClick = () => {
    const sortedColumns = sortByAZ(columns);
    const _filteredColumns = getFilteredColumns(sortedColumns, search);
    updateColumns(sortedColumns, _filteredColumns);
    setTabIndex(1);
  };

  /* Column Manager dropdown actions */
  const onSelectAll = () => {
    const nextColumns = columns.map(column => {
      column.visible = true;
      return column;
    });
    setColumns(nextColumns);
    props.onUpdateColumns(nextColumns);
  };

  const onDeselectAll = () => {
    const nextColumns = columns.map(column => {
      column.visible = false;
      return column;
    });
    setColumns(nextColumns);
    props.onUpdateColumns(nextColumns);
  };

  const onDiscardChanges = () => {
    const isOnAlphabeticalTab = tabIndex === 1;
    const initialColumns = isOnAlphabeticalTab ? props.columns : getInitialSortedColumns();
    setColumns(initialColumns);
    props.onUpdateColumns(initialColumns);
  };

  const onUnLockAll = () => {
    const nextColumns = columns.map(column => {
      column.fixed = false;
      return column;
    });
    setColumns(nextColumns);
    props.onUpdateColumns(nextColumns);
  };

  const isSearching = search !== '';
  const isOnAlphabeticalTab = tabIndex === 1;

  const defaultDropdownOptions = [
    ...[
      {
        label: 'Select All',
        onClick: () => onSelectAll(),
      },
      {
        label: 'Deselect All',
        onClick: () => onDeselectAll(),
      },
    ],
    ...(!isOnAlphabeticalTab
      ? [
          {
            label: 'Discard Changes',
            onClick: () => onDiscardChanges(),
          },
        ]
      : []),
    ...(columns.some(column => column.fixed)
      ? [
          {
            label: 'Unlock All',
            onClick: () => onUnLockAll(),
          },
        ]
      : []),
  ];

  return (
    <S.Container>
      <S.Tabs>
        <S.Tab className={`${tabIndex === 0 ? 'selected' : ''}`} onClick={onGridOrderTabClick}>
          Grid Order
        </S.Tab>
        <S.Tab className={`${tabIndex === 1 ? 'selected' : ''}`} onClick={onAlphabeticalTabClick}>
          Alphabetical
        </S.Tab>
      </S.Tabs>
      <S.StaticHeader>
        <S.Row>
          <S.SearchIcon />
          <S.Input
            id="search"
            label="Search..."
            datatestid="columnSearch"
            value={search}
            placeholder="Search Columns"
            onChange={onSearchChange}
          />
          <DropdownMenu light>
            {[...defaultDropdownOptions, ...(props.dropdownOptions ?? [])].map((action: any) => (
              <DropdownMenuItem key={`column-management-action-button-${action.label}`} onClick={action.onClick}>
                <DropdownMenuItemText>{action.label}</DropdownMenuItemText>
              </DropdownMenuItem>
            ))}
          </DropdownMenu>
        </S.Row>
      </S.StaticHeader>
      <DragDropContext onDragEnd={onDragEnd}>
        <S.ListContainer>
          <Droppable droppableId="columnList">
            {dropProvided => (
              <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                {filteredColumns.map(column => {
                  if (props?.excludedColumns && props?.excludedColumns.includes(column.dataField)) {
                    return;
                  }
                  const onToggleChecked = () => handleOnToggleChecked(column);

                  const fixedActions = [
                    {
                      text: 'Unfix',
                      icon: (iconProps: IconProps) => <VerticalAlignBottom {...iconProps} />,
                      onClick: () => onUnfix(column),
                    },
                  ];
                  const unFixedActions = [
                    {
                      text: 'Fix to the top',
                      icon: (iconProps: IconProps) => <VerticalAlignTop {...iconProps} />,
                      onClick: () => onFixToTheTop(column),
                    },
                    {
                      text: 'Fix to the bottom',
                      icon: (iconProps: IconProps) => <VerticalAlignBottom {...iconProps} />,
                      onClick: () => onFixToTheBottom(column),
                    },
                  ];
                  const columnRowActions =
                    tabIndex === 0
                      ? [
                          ...[
                            {
                              text: 'Move to the top',
                              icon: (iconProps: IconProps) => <KeyboardArrowUp {...iconProps} />,
                              onClick: () => onMoveToTheTop(column),
                            },
                            {
                              text: 'Move to the bottom',
                              icon: (iconProps: IconProps) => <KeyboardArrowDown {...iconProps} />,
                              onClick: () => onMoveToTheBottom(column),
                            },
                          ],
                          ...(column.fixed ? fixedActions : unFixedActions),
                        ]
                      : [];

                  return (
                    <Draggable
                      key={column.dataField}
                      draggableId={column.dataField}
                      isDragDisabled={isSearching || isOnAlphabeticalTab || column.fixed}
                      index={column.visibleIndex}
                    >
                      {(dragProvided, dragSnapshot) => (
                        // @ts-ignore
                        <div
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        >
                          <ColumnRow
                            fixed={column.fixed}
                            value={column.dataField}
                            checked={column.visible}
                            isDragging={dragSnapshot.isDragging}
                            toggleChecked={onToggleChecked}
                            actions={columnRowActions}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </S.ListContainer>
      </DragDropContext>
    </S.Container>
  );
};
