import IconButton from '@material-ui/core/IconButton';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import SuccessInfo from '@material-ui/icons/CheckCircle';
import IconClose from '@material-ui/icons/Close';
import IconError from '@material-ui/icons/Error';
import IconInfo from '@material-ui/icons/Info';
import IconWarning from '@material-ui/icons/Warning';
import React, { FunctionComponent } from 'react';
import * as S from './SnackBarContent.style';

export type NotificationType = 'error' | 'info' | 'success' | 'warning';

export type Notification = {
  type: NotificationType;
  title: string;
  message: string;
  messageList?: any;
  showMessagesOnPopup?: boolean;
  actionLinks?: ActionLink[];
  autoHideDuration?: number; // in ms
  payloadData?: any;
};

export type ActionLink = {
  onClick: (e: any) => void;
  title: string;
};

interface SnackBarContentProps {
  notification: Notification;
  closeNotification: () => void;
  dataTestId?: string;
}

interface IconNotification {
  [key: string]: React.ComponentType<SvgIconProps>;
}

const iconNotification: IconNotification = {
  success: SuccessInfo,
  info: IconInfo,
  warning: IconWarning,
  error: IconError,
};

export const SnackBarContent: FunctionComponent<SnackBarContentProps> = ({
  notification,
  closeNotification,
  dataTestId = 'notification',
}) => {
  if (!notification.message) return null;

  const NotificationIcon: React.ComponentType<SvgIconProps> = iconNotification[notification.type];

  return (
    <S.SnackbarContent
      message={
        <S.Container data-testid={dataTestId}>
          <S.Header type={notification.type}>
            <NotificationIcon style={{ color: 'white' }} />
          </S.Header>
          <S.Body>
            <S.Title>{notification.title}</S.Title>
            <S.Message>{notification.message}</S.Message>
            {notification.actionLinks && (
              <ul>
                {notification.actionLinks.map((link: ActionLink) => (
                  <li key={link.title}>
                    <a onClick={link.onClick}>{link.title}</a>
                  </li>
                ))}
              </ul>
            )}
          </S.Body>
        </S.Container>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={closeNotification}>
          <IconClose fontSize="small" />
        </IconButton>,
      ]}
    />
  );
};
