import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  background: var(--bgInspectorFullscreenContent);
  padding: 0 10px;
  border-top: 1px solid #495057;
`;
