import React from 'react';
import { FormElementInputComponent, FormFieldType } from 'src/components/forms/form-elements';
import { StyledInput } from 'src/components/forms/form-elements/FormElements.style';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper';
import { applyMultiplier } from './DefaultMultiplier';
import { useFieldRule } from 'src/components/forms/hooks/use-field-rule';
import { formatNumber } from 'src/utils/formatters';
import { FieldRule } from 'src/components/forms/form-elements/FormElement.model';

const convertValueToLocale = (value: any) => value.toLocaleString(navigator.language);

const checkPercentageFieldEdited = (field: FormFieldType) =>
  field.format && field.format.includes('p') && !field.isEdited;

export const DecimalInput = ({
  field,
  value = '',
  onChange,
  error,
  style,
  hideLabel,
  isInATable,
  formValues,
  isEditing,
}: FormElementInputComponent & { isInATable?: boolean; isEditing?: boolean }) => {
  const fieldRules = field.fieldRules;
  const fieldUpdateFn = (nextValue: any) => {
    const multipliedValue = applyMultiplier(nextValue);
    const event: Partial<React.SyntheticEvent> = {
      target: {
        value: convertValueToLocale(multipliedValue),
      } as HTMLInputElement,
    };
    onChange(event);
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const multipliedValue = applyMultiplier(e.target.value);
    e.target.value = convertValueToLocale(multipliedValue);
    if (onChange) {
      onChange(e);
    }
    field.isEdited = true;
  };
  let nonNumericValue;
  const isValidValue = value || value === 0;
  if ((field.readonly || field.disabled) && isValidValue) {
    if (field.format && checkPercentageFieldEdited(field)) {
      nonNumericValue = formatNumber(Number(value), field.format);
    } else {
      /*  First condition is for the datatype long where the fields do not require comma.
          max & min fraction digits are for displaying value with 2 decimal places & with comma */
      nonNumericValue =
        field.dataType !== 'decimal'
          ? value
          : field.format &&
            Number(value).toLocaleString(navigator.language, {
              maximumFractionDigits: +field.format?.charAt(field.format.length - 1),
              minimumFractionDigits: +field.format?.charAt(field.format.length - 1),
            });
    }
  } else {
    if (
      field.format &&
      checkPercentageFieldEdited(field) &&
      !fieldRules?.find((fieldRule: FieldRule) => fieldRule.ruleType === 'Value')
    ) {
      nonNumericValue = (value * 100).toFixed(+field.format?.charAt(field.format.length - 1));
    } else {
      nonNumericValue = isValidValue ? value : '';
    }
  }

  const fieldRule = useFieldRule({
    fieldName: field.displayName,
    fieldRules,
    formValues,
    fieldUpdateFn,
  });
  if (fieldRule.hidden) return null;

  const displayValue = typeof value === 'number' ? value.toLocaleString(navigator.language) : nonNumericValue;

  return (
    <FormErrorWrapper
      error={error}
      displayName={field.displayName}
      name={field.name}
      key={field.name}
      disabled={fieldRule.disabled || field.disabled}
      required={fieldRule.required || field.required}
      readonly={fieldRule.disabled || field.readonly}
      hideLabel={hideLabel}
      disableErrorWarning={field.disableErrorWarning}
    >
      <StyledInput
        autoComplete="off"
        data-testid={`${field.name}-decimal` || 'decimal'}
        disabled={fieldRule.disabled || field.disabled || field.readonly}
        error={Boolean(error) && !(field.readonly || field.disabled)}
        onChange={handleOnChange}
        inputProps={{
          style: {
            textAlign: isInATable ? 'right' : 'left',
          },
        }}
        style={style}
        value={displayValue}
        name={field.name}
        placeholder={!value && value !== 0 && !isEditing ? '' : field.displayName || '0'}
      />
    </FormErrorWrapper>
  );
};
