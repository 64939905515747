import React, { useMemo } from 'react';
import Toolbar from 'devextreme-react/toolbar';
import { resetIcon } from '@virtus/components/DxDataGrid/icons/svgicons';
import { StyledToolBarWrapper } from './pivot-toolbar.styles';
import sortBy from 'lodash/sortBy';

export interface PivotTollBarProps {
  getCustomToolBarItems?: any[];
  getGridTemplates?: any[];
  useDarkTheme?: boolean;
  useAllFieldsPane?: boolean;
  refreshHandler: () => void;
  toggleAllFieldsPane?: () => void;
  toggleFieldChooser?: () => void;
  toolbarIconsOrder?: string[];
  toolbarItemsFromConfig?: any[];
  exportPivotData?: () => void;
  enableExport?: boolean;
}

const DEFAULT_TOOLBAR_ICONS_ORDER = [
  'portfolioManagerButton',
  'toggleAllFieldsPane',
  'fieldChooser',
  'switchComponent',
  'refreshButton',
  'modellingButton',
  'portfolioOrderDropdownBtn',
  'portfolioAddDropdownBtn',
  'portfolioProposedOrderButton',
  'exportButton',
  'layout',
];

export const PivotToolBar = React.memo(
  ({
    getGridTemplates,
    getCustomToolBarItems,
    useDarkTheme,
    refreshHandler,
    toggleAllFieldsPane,
    toggleFieldChooser,
    exportPivotData,
    useAllFieldsPane = false,
    toolbarIconsOrder = DEFAULT_TOOLBAR_ICONS_ORDER,
    toolbarItemsFromConfig,
    enableExport,
  }: PivotTollBarProps) => {
    const defaultItems: any[] = useMemo(
      () => [
        {
          name: 'refreshButton',
          location: 'after',
          locateInMenu: 'never',
          widget: 'dxButton',
          cssClass: 'vertical-separator-line',
          options: {
            icon: resetIcon,
            hint: 'Refresh',
            onClick: refreshHandler,
            elementAttr: { 'data-testid': 'toolbar-grid-refresh-btn' },
          },
        },
      ],
      [refreshHandler],
    );

    if (useAllFieldsPane && toggleAllFieldsPane) {
      defaultItems.push({
        name: 'toggleAllFieldsPane',
        location: 'before',
        locateInMenu: 'never',
        widget: 'dxButton',
        options: {
          icon: 'smalliconslayout',
          hint: 'Show All Fields',
          onClick: toggleAllFieldsPane,
          elementAttr: { 'data-testid': 'toolbar-show-all-fields-btn' },
        },
      });
    }

    defaultItems.push({
      name: 'fieldChooser',
      location: 'before',
      locateInMenu: 'never',
      widget: 'dxButton',
      options: {
        icon: 'columnchooser',
        hint: 'Fields Chooser',
        onClick: toggleFieldChooser,
        elementAttr: { 'data-testid': 'toolbar-field-chooser-btn' },
      },
    });

    if (!enableExport) {
      defaultItems.push({
        name: 'exportButton',
        location: 'after',
        locateInMenu: 'never',
        widget: 'dxButton',
        cssClass: 'vertical-separator-line',
        options: {
          icon: 'export',
          hint: 'Export to excel',
          onClick: exportPivotData,
          elementAttr: { 'data-testid': 'pivot-grid-toolbar-export-btn' },
        },
      });
    }

    const items = useMemo(() => {
      let toolbarItem = getCustomToolBarItems ? [...getCustomToolBarItems, ...defaultItems] : [...defaultItems];
      toolbarItem = toolbarItemsFromConfig ? [...toolbarItem, ...(toolbarItemsFromConfig as any[])] : toolbarItem;
      toolbarItem = sortBy(toolbarItem, (btn: any) => toolbarIconsOrder.indexOf(btn.name));
      return toolbarItem;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCustomToolBarItems, defaultItems]);

    return (
      <StyledToolBarWrapper>
        <Toolbar className={useDarkTheme ? 'dark-theme' : ''} items={items}>
          {getGridTemplates && getGridTemplates.map((template: any) => template)}
        </Toolbar>
      </StyledToolBarWrapper>
    );
  },
);
