export const endpoints = {
  order: {
    actionArguments: `/glide/order/action-arguments`,
    actions: `/glide/order/actions`,
    fundAllocations: `/glide/order/fund-allocations`,
    instrumentBehaviourActions: `glide/order/actions`,
    workflowTransition: `/glide/order/workflow-transition`,
    update: `/glide/order/update`,
    newOrderInstrument: `/glide/order/new`,
  },
  views: {
    clientUniverse: `/glide/views/client-universe`,
    hypos: `/glide/views/hypos`,
    orderBlotter: `/glide/views/order-blotter`,
    layouts: `/glide/views/layouts`,
    loanUniverse: `/glide/views/loan-universe`,
    credits: `/glide/views/credit-details`,
    positions: 'glide/views/portfolio',
  },
  clientViews: {
    root: `/glide/view`,
  },
  hypo: {
    scenarios: `/glide/hypo/scenarios`,
    testResults: `/glide/hypo/test-results`,
  },
  displayView: {
    root: `/glide/display-view`,
    groups: `/glide/display-view/groups`,
  },
  dataGrid: {
    root: `/glide/views/datagrid`,
  },
  search: {
    root: `/glide/search`,
  },
  gsearch: {
    root: `/gsearch`,
  },
  gget: {
    root: `/glide/gget`,
  },
  resolveAction: {
    root: `/glide/resolve-action`,
  },
  update: {
    root: `/glide/update`,
    rootObject: `/glide/object/update`,
  },
  getCollection: {
    rootObject: `/glide/object`,
  },
  environments: {
    root: `/glide/client-envs`,
  },
  new: {
    root: `/glide/new`,
  },
  delete: {
    root: `/glide/delete`,
    rootObject: `/glide/object/delete`,
  },
  portfolio: {
    root: `/glide/portfolio/orders`,
  },
};
