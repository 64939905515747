import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

export const MenuItemStyled = withStyles({
  root: {
    height: 36,
    margin: 0,
    padding: 2,
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2.14,
    letterSpacing: -0.2,
    color: 'var(--text)',
    backgroundColor: 'var(--label)',
    '&:hover': {
      backgroundColor: 'var(--grey)',
    },
  },
})(MenuItem);

export const MenuItemTextStyled = styled.div<{ disabled?: boolean }>`
  text-transform: capitalize;
  display: block;
  text-align: left;
  padding: 6px 0 6px 10px;
  flex: 1;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: -0.2px;
  color: var(--text);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SelectStyled = styled(Select)<{
  value: any;
  classes: any;
  onChange: any;
  disabled?: boolean;
  error?: boolean;
}>`
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
  background-color: ${props => (props.disabled ? 'transparent' : 'var(--grey)')};
  border: ${props => (props.error ? '2px solid var(--red)' : 'none')};
  color: white;
  text-align: center;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const CheckboxStyled = withStyles({
  root: {
    '&$checked': {
      color: 'var(--default-blue)',
    },
  },
  checked: {},
})(Checkbox);
