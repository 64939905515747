import { authSelectors, GlideSession } from '@virtus/common/auth/reducer';
import { sortBy } from 'lodash';
import { actionTypes, mutateAsync, requestAsync } from 'redux-query';
import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects';
import { ActionArguments, DisplayViewApi, Glide } from 'src/api/queries';
import { ACTION_FORM_OVERRIDE_ACTIONS, actions } from 'src/reducers/actions';
import { updateComponentAction } from 'src/reducers/components';
import { selectedFunds } from 'src/reducers/portfolio';
import { executeAction } from 'src/utils/action-resolver';
import { glideQuery, glideQuerySelectorViewName, selectGridSchema } from 'src/api/query';
import { selectActionArguments } from 'src/api/queries/ActionArguments';

interface ActionPayload {
  payload: { action: any; targetUri?: string; clientViewConfiguration?: any };
}

interface ExecuteActionType {
  payload: {
    arguments: any;
    targetUri: string;
    actionUri: string;
    glideSession: GlideSession;
  };
}

export function* getActionArguments(actionUri: string) {
  let data: any;
  const actionArguments = yield select(ActionArguments.selectActionArguments);
  if (!actionArguments || !actionArguments.hasOwnProperty(actionUri)) {
    data = yield putResolve(requestAsync(ActionArguments.get(actionUri)));
  }

  if (data?.body?.view) {
    yield putResolve(requestAsync(DisplayViewApi.getDisplayView(actionUri)));
  }
}

export function* actionResolve(action: ActionPayload): any {
  const {
    targetUri,
    action: { uri: actionUri },
  } = action.payload;
  const globalActionUri = action.payload.action?.data?.action;
  if (globalActionUri) {
    yield putResolve(requestAsync(DisplayViewApi.getDisplayView(globalActionUri)));
    yield put(updateComponentAction('newObject', { visible: true, actionUri: globalActionUri, target_uri: targetUri }));
  } else if (action.payload.action?.data?.arguments) {
    let payload = null;
    if (actionUri === 'instance/actions/add_order_to_scenario') {
      yield putResolve(
        mutateAsync(
          glideQuery({
            endpoint: `/glide/hypo/scenarios`,
            options: { method: 'GET' },
            body: {},
            storeViewName: 'hypoScenarios',
            meta: {
              notification: {
                [actionTypes.MUTATE_START]: `Fetching hypo scenarios`,
                [actionTypes.MUTATE_SUCCESS]: `Hypo scenarios are available`,
              },
            },
            // Empty scenarios responses have no body
            transform: (body: any) => ({
              views: {
                ['hypoScenarios']: {
                  data: body.data?.length > 0 ? JSON.parse(body?.data) : {},
                  schema: body?.schema,
                },
              },
            }),
          }),
        ),
      );
      const hypoScenarios = yield select(glideQuerySelectorViewName, 'hypoScenarios');

      payload = {
        'instance/argument/add_to_hypo_scenario_target_scenario': {
          lookups:
            hypoScenarios?.data?.length > 0 &&
            hypoScenarios.data.reduce((acc: any, item: any) => {
              if (!acc[item._uri]) {
                acc[item._uri] = { name: item['Display Name'], value: item._uri };
              }
              return acc;
            }, {}),
        },
      };
    }
    if (actionUri === 'instance/actions/fund_modeling') {
      const portfolios = yield select(Glide.selector, 'portfolios');
      const funds = yield select(selectedFunds, action.payload?.clientViewConfiguration);
      payload = {
        'fields/modeling_portfolio': {
          lookups: (funds ?? portfolios)?.reduce((acc: any, item: { data: any; uri: string }) => {
            if (!acc[item.uri]) {
              acc[item.uri] = { name: item.data.display_name, value: item.uri };
            }
            return acc;
          }, {}),
          defaultValue: funds?.length === 1 && funds[0]?.uri,
        },
        'fields/model_id': {
          lookups: sortBy(portfolios, 'data.display_name')?.reduce((acc, item) => {
            if (!acc[item.uri]) {
              acc[item.uri] = { name: item?.data?.display_name, value: item.uri };
            }
            return acc;
          }, {}),
        },
        primaryActionButton: {
          label: 'Run',
        },
      };
    }
    if (payload) {
      yield putResolve({
        type: ACTION_FORM_OVERRIDE_ACTIONS.SET_ACTION_OVERRIDE_OBJECT,
        payload: { [actionUri]: payload },
      });
    }
    yield call(getActionArguments, actionUri);
    yield put(updateComponentAction('actionForm', { visible: true, actionUri, target_uri: targetUri }));
  } else {
    const glideSession = yield select(authSelectors.glideSession);

    // If we're passing any percent values, we need to divide them first

    const executeActionArguments = {
      action: { uri: actionUri },
      target_uri: targetUri as string,
      glideSession,
    };
    yield call(executeAction, executeActionArguments);
  }
}

function* handlePercentValues(actionArguments: any, actionArgumentsExpanded: any, actionUri: string) {
  const viewGridSchema = yield select(selectGridSchema);

  for (const k in actionArguments) {
    // Get field name from action arguments
    const actionFieldName = actionArgumentsExpanded[actionUri]?.arguments[k]?.field?.replace('fields/', '');

    if (actionFieldName) {
      // lookup matching field data type
      const fieldSchemas = viewGridSchema.filter((schema: any) => schema.field_name.includes(actionFieldName));

      if (fieldSchemas.length > 0) {
        const fieldSchema = fieldSchemas[0];
        // Apply division is percentage field (check for either uri or display name
        const isPercentField = fieldSchema.format?.includes('lookups/p') || fieldSchema.format?.includes('P');
        if (isPercentField) {
          console.info(`dividing percent value ${k} to: ${Number(actionArguments[k]) / 100}`);
          actionArguments[k] = Number(actionArguments[k]) / 100;
        }
      }
    }
  }

  return actionArguments;
}

export function* executeActionWithArgument(action: ExecuteActionType) {
  const glideSession = yield select(authSelectors.glideSession);
  const actionArgumentsExpanded = yield select(selectActionArguments);
  const { targetUri, arguments: actionArguments, actionUri } = action.payload;
  //Condition is changed to avoid conflict of target uri data received in payload or from component state.
  let target_uri = targetUri;
  // It is special case where we need to set target uri with selected model fund uri.
  if (actionUri === 'instance/actions/fund_modeling') {
    target_uri = (actionArguments as any)['instance/argument/modelid'];

    // TODO: As of now setting modelType to 'F'. Remove this once core api is accepting uri reference instead of abbrevations.
    if ((actionArguments as any)['instance/argument/modeltype']) {
      (actionArguments as any)['instance/argument/modeltype'] = 'F';
    }
  }
  const actionArgumentFormatted = yield* handlePercentValues(actionArguments, actionArgumentsExpanded, actionUri);

  const executeActionArguments = {
    action: { uri: actionUri, arguments: actionArgumentFormatted },
    target_uri,
    glideSession,
  };
  yield call(executeAction, executeActionArguments);
}

function* watchActionResolve() {
  yield takeLatest<any>(actions.action.DISPATCH_ACTION, actionResolve);
}

function* watchExecuteAction() {
  yield takeLatest<any>(actions.action.EXECUTE_ACTION, executeActionWithArgument);
}

export default [watchActionResolve, watchExecuteAction];
