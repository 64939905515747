import { ClickAwayListener, Drawer } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { Close, Search } from '@material-ui/icons';
import React, { useReducer } from 'react';
import BackIcon from 'src/icons/BackIcon';
import * as theme from 'src/theme';
import styled from 'styled-components';
import { MenuClose } from '../../icons';
import { Route } from '../models/route';
import SideMenuItem from './SideMenuItem';
import SideMenuList from './SideMenuList';

const searchRoutes = (_routes: Route[], searchText: string, foundRoutes: any[] = []) => {
  _routes.forEach((route: Route) => {
    if (!route.subroutes) {
      if (route.name.toLowerCase().includes(searchText.toLowerCase())) {
        foundRoutes.push(route);
      }
    } else {
      if (route.name.toLowerCase().includes(searchText.toLowerCase())) {
        foundRoutes.push(route);
      }
      searchRoutes(route.subroutes, searchText, foundRoutes);
    }
  });
  return foundRoutes;
};

interface HeaderState {
  search: string;
  navigationRoutes: {
    previous?: Route[];
    current: Route;
  };
}

const searchRoutesReducer = (_state: HeaderState, action: any) => {
  const { search, routes } = action.payload;
  switch (action.type) {
    case 'openSubroute': {
      const previous = _state.navigationRoutes.previous
        ? [..._state.navigationRoutes.previous, ...[_state.navigationRoutes.current]]
        : [];
      return {
        ..._state,
        ...{
          navigationRoutes: {
            previous,
            current: routes,
          },
        },
      };
    }
    case 'onSubrouteBack': {
      const previous = _state.navigationRoutes.previous ? [..._state.navigationRoutes.previous] : [];
      const current = previous.pop();
      return {
        ..._state,
        ...{
          navigationRoutes: {
            previous,
            current,
          },
        },
      };
    }
    case 'setSearchValue': {
      const { subroutes, ...nextRoutes } = routes;
      const filteredRoutes = searchRoutes(routes.subroutes, search);
      nextRoutes.subroutes = filteredRoutes;
      return {
        search,
        navigationRoutes: { previous: [], current: nextRoutes },
      };
    }
    case 'resetSearch':
      return {
        search: '',
        navigationRoutes: { current: routes, previous: [] },
      };
    default:
      return _state;
  }
};

interface SidemenuProps {
  isOpen: boolean;
  onClose: () => void;
  routes: any;
  onRouteChange: (path: string, routes?: any) => void;
  menuItemColor?: string;
  appButton?: any;
}

const Sidemenu = ({
  isOpen,
  onClose,
  routes,
  onRouteChange,
  menuItemColor = 'var(--fis-green)',
  appButton,
}: SidemenuProps) => {
  const initialState = {
    search: '',
    navigationRoutes: {
      previous: [],
      current: routes,
    },
  };
  const [state, dispatch] = useReducer(searchRoutesReducer, initialState);
  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    return dispatch({
      type: 'setSearchValue',
      payload: { search: value, routes },
    });
  };

  const onMenuBack = () => dispatch({ type: 'onSubrouteBack', payload: { routes } });

  return (
    <DrawerContainer variant="persistent" anchor="left" open={isOpen} onClose={onClose}>
      {isOpen && (
        <ClickAwayListener
          onClickAway={() => {
            dispatch({ type: 'resetSearch', payload: { routes } });
            onClose();
          }}
        >
          <Background tabIndex={0} role="button" data-testid="sidemenu-content">
            <DrawerContent>
              <MenuLevel>
                <SideMenuHeader>
                  {appButton && (
                    <AppIcon>
                      {appButton.icon &&
                        React.createElement(appButton.icon.component, {
                          ...appButton.icon.props,
                          style: { width: '20px', height: '20px', marginRight: '8px' },
                        })}
                      {appButton.name}
                    </AppIcon>
                  )}
                  <CloseButton
                    data-testid="sidemenu-close-btn"
                    onClick={() => {
                      dispatch({ type: 'resetSearch', payload: { routes } });
                      onClose();
                    }}
                  >
                    <MenuClose />
                  </CloseButton>
                </SideMenuHeader>
                <SearchContainer>
                  <SearchIcon />
                  <Input
                    id="search"
                    label="Search..."
                    data-testid="sidemenu-search-input"
                    value={state.search}
                    autoComplete="off"
                    placeholder="Search"
                    onChange={onSearchChange}
                  />
                  <CloseIcon
                    data-testid="sidemenu-clear-btn"
                    onClick={() =>
                      dispatch({
                        type: 'resetSearch',
                        payload: { routes },
                      })
                    }
                  />
                </SearchContainer>
                {state.navigationRoutes.current && (
                  <MenuLevelContent>
                    {state.navigationRoutes.previous && state.navigationRoutes.previous.length > 0 && (
                      <SideMenuItem
                        path=""
                        fullPath=""
                        data-testid="sidemenu-back-button"
                        icon={{ component: BackIcon, enableInSideBar: true }}
                        name={state.navigationRoutes.current.context || state.navigationRoutes.current.name}
                        context={state.navigationRoutes.current.context}
                        style={{ backgroundColor: menuItemColor }}
                        onClick={onMenuBack}
                      />
                    )}
                    <SideMenuList
                      menuItemColor={menuItemColor}
                      routes={state.navigationRoutes.current}
                      onClick={(fullPath?: string, subroute?: Route) => {
                        if (subroute && subroute.subroutes) {
                          return dispatch({ type: 'openSubroute', payload: { routes: subroute } });
                        }
                        if (fullPath) {
                          dispatch({ type: 'resetSearch', payload: { routes } });
                          onRouteChange(fullPath, routes);
                        }

                        onClose();
                      }}
                    />
                  </MenuLevelContent>
                )}
              </MenuLevel>
            </DrawerContent>
          </Background>
        </ClickAwayListener>
      )}
    </DrawerContainer>
  );
};

export default Sidemenu;

const SearchContainer = styled.div`
  display: flex;
  height: 32px;
  margin: 6px;
`;

const SearchIcon = styled(Search)`
  color: #bfbfbf;
  position: absolute;
  margin-top: 6px;
  margin-left: 5px;
  width: 21px;
  font-size: 22px;
`;

const CloseIcon = styled(Close)`
  color: #bfbfbf;
  position: absolute;
  margin-top: 6px;
  cursor: pointer;
  right: 8px;
  width: 21px;
  font-size: 22px;
`;

const Input = styled(InputBase)<{ margin: string; label: string } & any>`
  color: var(--text);
  background: rgba(255, 255, 255, 0.14);
  width: 100%;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  input {
    text-indent: 23px;
  }
`;

const SideMenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${theme.variables.headerHeight};
  padding-top: 4px;
  padding-left: 6px;
  padding-right: 6px;
  border-bottom: 1px solid var(--accentDarkBlue);
`;

const CloseButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
`;

const DrawerContainer = styled(Drawer)`
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  left: 0;
  > div {
    border: 0;
  }
`;

const DrawerContent = styled.div`
  display: flex;
  flex-direction: row;
  background-color: var(--bgDark);
`;

const MenuLevel = styled.div`
  display: flex;
  flex-direction: column;
  border-left: none;
  height: 100vh;
  min-width: 275px;
  width: auto;
  overflow: hidden;
`;

const MenuLevelContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--darkblue);
`;

const AppIcon = styled.button`
  border-radius: 2px;
  font-size: 16px;
  background: transparent;
  color: white;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: none;
  cursor: pointer;
  border: none;
  font-weight: 600;
  box-shadow: none;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
`;
