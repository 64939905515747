import { put, putResolve, select, takeLatest } from 'redux-saga/effects';
import { glideQuery } from 'src/api/query';
import { selectCVCUri } from 'src/reducers/tabs';
import { ILayout } from 'src/components/grids/dxgrid-client-view/templates/Layouts/Layouts.model';
import { actionTypes, mutateAsync } from 'redux-query';
import { HTTPMETHOD } from 'src/utils/common';
import { Action, createAction } from 'src/reducers/createAction';
import { isJson } from 'src/app/App';
import { updateComponentViewAction } from 'src/reducers/components';

export enum LayoutActions {
  CREATE_LAYOUT = 'CREATE_LAYOUT',
  UPDATE_LAYOUT = 'UPDATE_LAYOUT',
  DELETE_LAYOUT = 'DELETE_LAYOUT',
}

export type Layout = {
  layoutToSave: ILayout;
  uri: string;
  isRenamed?: boolean;
};

type CreateLayoutAction = Action<typeof LayoutActions.CREATE_LAYOUT, Layout>;
type UpdateLayoutAction = Action<typeof LayoutActions.UPDATE_LAYOUT, Layout>;
type DeleteLayoutAction = Action<typeof LayoutActions.DELETE_LAYOUT, Layout>;

export type LayoutAction = CreateLayoutAction | UpdateLayoutAction | DeleteLayoutAction;

export const createLayout = (layout: Layout): LayoutAction => createAction(LayoutActions.CREATE_LAYOUT, layout);
export const updateLayout = (layout: Layout): LayoutAction => createAction(LayoutActions.UPDATE_LAYOUT, layout);
export const deleteLayout = (layout: Layout): LayoutAction => createAction(LayoutActions.DELETE_LAYOUT, layout);

export function* create_layout(action: LayoutAction): any {
  const clientViewConfiguration = yield select(selectCVCUri(action.payload?.uri));
  if (!clientViewConfiguration) {
    throw Error('missing clientViewConfiguration, payload.uri:' + action.payload?.uri);
  }
  const httpMethod = HTTPMETHOD.POST;
  const layout: ILayout = action?.payload?.layoutToSave;
  const transform = (response: any) => ({
    views: {
      [clientViewConfiguration.uri]: { webLayouts: isJson(response) ? JSON.parse(response) : [] },
    },
  });
  const update = {
    views: (prev: any, next: any) => ({
      ...prev,
      [clientViewConfiguration.uri]: {
        ...prev[clientViewConfiguration.uri],
        webLayouts: next[clientViewConfiguration.uri].webLayouts,
      },
    }),
  };
  localStorage.setItem(
    `selected-layout-${clientViewConfiguration.uri}-${clientViewConfiguration.client_view_type}`,
    layout?.data?.name,
  );
  const queryParams = {
    display_view_uri: clientViewConfiguration.view,
    web_layout_data: layout.data.json_layout ? JSON.stringify(layout.data.json_layout) : '',
    web_layout_name: layout.data.name,
    client_view_type: clientViewConfiguration.client_view_type,
  };
  const mutateStartLabel = 'Creating layout...';
  const mutateSuccessLabel = 'Layout created successfully';

  yield putResolve(
    mutateAsync(
      glideQuery({
        endpoint: `/glide/views/layouts`,
        body: queryParams,
        options: { method: httpMethod },
        update,
        transform,
        meta: {
          notification: {
            [actionTypes.MUTATE_START]: mutateStartLabel,
            [actionTypes.MUTATE_SUCCESS]: mutateSuccessLabel,
          },
        },
      }),
    ),
  );

  yield put(updateComponentViewAction('gridLayout', clientViewConfiguration.uri, { hasChanges: false }));
}

export function* update_layout(action: LayoutAction): any {
  const clientViewConfiguration = yield select(selectCVCUri(action.payload?.uri));
  const layout: ILayout = action?.payload?.layoutToSave;
  if (!action?.payload?.isRenamed) {
    localStorage.setItem(
      `selected-layout-${clientViewConfiguration.uri}-${clientViewConfiguration.client_view_type}`,
      layout?.uri,
    );
  }
  const httpMethod = HTTPMETHOD.PUT;
  const queryParams = {
    web_layout_uri: layout.uri,
    web_layout_data: JSON.stringify(layout.data.json_layout ? layout.data.json_layout : {}),
    web_layout_name: layout.data.name,
  };
  const update = {
    views: (prevData: any) => ({
      ...prevData,
      [clientViewConfiguration.uri]: {
        ...prevData[clientViewConfiguration.uri],
        webLayouts: prevData[clientViewConfiguration.uri].webLayouts.map((e: ILayout) => {
          if (e.uri === layout.uri) return layout;
          return e;
        }),
      },
    }),
  };
  const mutateStartLabel = 'Updating layout...';
  const mutateSuccessLabel = 'Layout updated successfully';

  yield putResolve(
    mutateAsync(
      glideQuery({
        endpoint: `/glide/views/layouts`,
        body: queryParams,
        options: { method: httpMethod },
        update,
        meta: {
          notification: {
            [actionTypes.MUTATE_START]: mutateStartLabel,
            [actionTypes.MUTATE_SUCCESS]: mutateSuccessLabel,
          },
        },
      }),
    ),
  );
}

export function* delete_layout(action: LayoutAction): any {
  const clientViewConfiguration = yield select(selectCVCUri(action.payload?.uri));
  if (!clientViewConfiguration) {
    throw Error('missing clientViewConfiguration, payload.uri:' + action.payload?.uri);
  }
  const layout: ILayout = action?.payload?.layoutToSave;
  const update = {
    views: (prevData: any) => ({
      ...prevData,
      [clientViewConfiguration.uri]: {
        ...prevData[clientViewConfiguration.uri],
        webLayouts: prevData[clientViewConfiguration.uri].webLayouts.filter((e: ILayout) => e.uri !== layout.uri),
      },
    }),
  };
  const httpMethod = HTTPMETHOD.DELETE;
  const queryParams = {
    web_layout_uri: layout.uri,
    display_view_uri: clientViewConfiguration.view,
    client_view_type: clientViewConfiguration.client_view_type,
  };
  const mutateStartLabel = 'Deleting layout...';
  const mutateSuccessLabel = 'Layout deleted successfully';

  yield putResolve(
    mutateAsync(
      glideQuery({
        endpoint: `/glide/views/layouts`,
        body: queryParams,
        options: { method: httpMethod },
        update,
        meta: {
          notification: {
            [actionTypes.MUTATE_START]: mutateStartLabel,
            [actionTypes.MUTATE_SUCCESS]: mutateSuccessLabel,
          },
        },
      }),
    ),
  );
}

export function* watchCreateLayouts() {
  yield takeLatest<any>(LayoutActions.CREATE_LAYOUT, create_layout);
}

export function* watchUpdateLayouts() {
  yield takeLatest<any>(LayoutActions.UPDATE_LAYOUT, update_layout);
}

export function* watchDeleteLayouts() {
  yield takeLatest<any>(LayoutActions.DELETE_LAYOUT, delete_layout);
}

export default [watchCreateLayouts, watchUpdateLayouts, watchDeleteLayouts];
