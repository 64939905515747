/**
 * This hook is created for invoking field rules that comes from rest API on grid cell value.
 * The hook is useful for updating different cell values based on editing of one of the cell that belongs to the same row.
 */

import { useCallback, useState } from 'react';
import { GlideAPIFieldRule } from 'src/api/field-rules/field-rules.model';
import { getFunctionParamNames } from 'src/components/forms/hooks/use-field-rule';

interface GridData {
  data: any;
  fieldRules?: any;
  schema: any;
}

interface GridFieldRule extends GlideAPIFieldRule {
  [key: string]: any;
  ruleType: string;
  ruleFn: () => any;
}

const getFieldSchema = (gridData: any, dataField: string) => {
  return gridData.schema.find((item: any) => item.dataField === dataField);
};

const setParameterValues = (
  e: any,
  paramsList: string[],
  actualParam: { [key: string]: any },
  selectedRowData: any,
  gridData: any,
) => {
  paramsList.forEach((param: string) => {
    if (param.indexOf('parent') < 0) {
      const rowData = e.row.isNewRow && !e.row.oldData ? 0 : e.row.oldData[param];
      actualParam[param] = param === e.dataField ? e.row.data[param] : rowData;
      const filteredField = getFieldSchema(gridData, e.dataField);
      if (filteredField?.format?.toLowerCase().includes('p')) actualParam[param] /= 100;
    } else {
      let formatParamData = param.split('parent_')[1];
      formatParamData = gridData.schema.filter((item: any) => item.dataField === formatParamData)[0].display_name;
      actualParam[param] = selectedRowData && selectedRowData[formatParamData];
    }
  });
};

export const useGridFieldRule = () => {
  const [mappedFieldRules, setMappedFieldRules] = useState<any>(null);
  const [gridData, setGridData] = useState<any>(null);
  const getSourceField = (isOnLoadCellUpdate: boolean | undefined, rule: GridFieldRule) => {
    if (isOnLoadCellUpdate) {
      return rule.field_rule_target.lastSplitValue();
    } else {
      const fieldRuleInputs = rule.field_rule_inputs?.filter((data: string) => data != rule.field_rule_target);
      if (fieldRuleInputs && fieldRuleInputs.length) return fieldRuleInputs[0].lastSplitValue();
      else return rule.field_rule_target.lastSplitValue();
    }
  };

  /**
   * This function is used for mapping field rules based on target field/source field
   * Source field is the grid cell where user has updated value in cell
   * Target field is the grid cell which should be updated automatically based on update in source field
   * Currently we need to update cell value only hence considering ruleType = 'Value' only at the moment
   * For validation we can use validationRules property of column similar to Glide object manager
   */
  const mapGridFieldRules = useCallback((gridData: GridData, isOnLoadCellUpdate?: boolean, schema?: any) => {
    setGridData(gridData);
    if (gridData?.fieldRules) {
      const fieldRulesObj = {} as any;
      Object.values(gridData.fieldRules).forEach((fieldArr: any) => {
        fieldArr
          .filter((fieldRule: GridFieldRule) => fieldRule.ruleType === 'Value')
          .forEach((rule: GridFieldRule) => {
            const sourceField = getSourceField(isOnLoadCellUpdate, rule);
            fieldRulesObj[sourceField] = fieldRulesObj[sourceField]
              ? [...fieldRulesObj[sourceField], { [rule.field_rule_target]: rule.ruleFn }]
              : [{ [rule.field_rule_target]: rule.ruleFn }];
          });
      });
      fieldRulesObj['schema'] = schema;
      setMappedFieldRules(fieldRulesObj);
    }
  }, []);

  /**
   * This method is used to update cell values (target ones) when there is any update in source field
   */
  const updateCellValues = useCallback(
    (e: any, selectedRowData: any) => {
      Object.keys(mappedFieldRules)
        .filter(data => data === e.dataField)
        .forEach(field => {
          const fieldRules = mappedFieldRules[field];
          fieldRules.forEach((rule: GridFieldRule) => {
            for (const key in rule) {
              const field_to_update = key.lastSplitValue();
              const ruleFn = rule[key];
              const paramsList = getFunctionParamNames(ruleFn);
              const actualParam: { [key: string]: number } = {};
              setParameterValues(e, paramsList, actualParam, selectedRowData, gridData);
              let val = ruleFn(actualParam);
              const filteredField = getFieldSchema(gridData, field_to_update);
              if (filteredField?.format?.toLowerCase().includes('p')) val *= 100;
              e.component.cellValue(e.row.rowIndex, field_to_update, val);
            }
          });
        });
    },
    [gridData, mappedFieldRules],
  );

  return { mapGridFieldRules, updateCellValues };
};
