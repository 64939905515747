import format from 'date-fns/format';
import numeral from 'numeral';
/** format large number like AUM for home page (eg 1.7B) */
export const formatLargeNumber = (num: number | null | undefined) => {
  if (num == null) {
    throw new Error('Null/Undefined number passed to formatLargeNumber');
  }
  // nicer to have uppercase abbreviations like 10M
  return numeral(num).format('0.0a').toUpperCase();
};

/** without display view just use this to format 2dp number  */
export const formatNumberDefault = (num: number | null | undefined) => formatNumber(num, 'n2', undefined);

/**
 * Convert C# numeric format to numeral style and format the number
 * https://docs.microsoft.com/en-us/dotnet/standard/base-types/standard-numeric-format-strings
 * http://numeraljs.com/
 *
 * TODO: find a JS library that understands c# numeric formats
 */
export const formatNumber = (
  num: number | null | undefined,
  formatString: string,
  isCellEditable?: boolean | undefined,
): string => {
  if (num == null) throw new Error('Null/Undefined number passed to formatNumber');

  // convert the formatString to numeral js type format
  const numeralFormat = getNumeralFormat(formatString);
  // now format with numeral js
  /**
   * This condition is added for columns like Coupon, Floor on Assets page
   * where we dont want the value to divide by 100 while formatting
   * If those columns in future are editable we may need to think some other solution
   */
  if (isCellEditable) {
    if (formatString.includes('p')) num = num / 100;
  }
  return numeral(num).format(numeralFormat);
};

/**
 * Convert C# numeric format to numeral style
 * https://docs.microsoft.com/en-us/dotnet/standard/base-types/standard-numeric-format-strings
 *
 * TODO: find a JS library that understands c# numeric formats
 */
const getNumeralFormat = (formatString: string): string => {
  if (!formatString) throw new Error(`Empty format string`);

  // formatString will be something like n2 or p6

  const [type, ...precisionString] = formatString.toLowerCase();
  const precision = parseInt(precisionString.join(''), 10);

  // convert to numeral js type
  let numeralFormat = null;
  switch (type) {
    case 'd':
      numeralFormat = `0,0${addPrecision(precision)}`;
      break;
    case 'n':
      numeralFormat = `0,0${addPrecision(precision)}`;
      break;
    case 'p':
      numeralFormat = `0${addPrecision(precision)}%`;
      break;

    default:
      throw new Error(`Unknown numeric format string: ${formatString}`);
  }

  return numeralFormat;
};

/**
 * Convert C# numeric format to Excel format
 */
export const getExcelNumeralFormat = (formatString: string = 'd2'): string =>
  `#,##${getNumeralFormat(formatString).replace('0,0', '0')}`;

/**
 * add appropriate number of zero numeral format
 */
const addPrecision = (precision: number): string => {
  if (isNaN(precision)) throw new Error(`Precision it is not a number: ${precision}`);
  return precision > 0 ? `.${'0'.repeat(precision)}` : '';
};

/**
 * Convert C# date format to date-fns style and format the date (keeps UTC)
 *
 * https://docs.microsoft.com/en-us/dotnet/standard/base-types/custom-date-and-time-format-strings
 * https://date-fns.org/v1.29.0/docs/format
 *
 * TODO: find a JS library that understands c# date formats
 */
export const formatDate = (isoDate: string | Date, formatString: string) => {
  if (!isoDate) {
    throw new Error('Empty iso date string passed to formatDate');
  }
  if (!formatString) {
    throw new Error(`Empty format string passed for iso date: ${isoDate}`);
  }
  // formatString will be something like 'dd/MM/yyyy HH:mm:ss'
  const findD = new RegExp(/d/g);
  const findY = new RegExp(/y/g);
  const jsFormat = formatString.replace(findD, 'D').replace(findY, 'Y');

  return format(getUTCDate(isoDate), jsFormat);
};

/** date-fns does not have UTC formatting functions (yet) so convert date to utc before format */
const getUTCDate = (isoDate: any) => new Date(isoDate).toUTCString() as any;

/** split on space and trim to desired length with a ... */
export const trimWords = (str: string, maxLength: number): string => {
  if (!str) {
    return '';
  }
  if (maxLength < 1) {
    throw new Error('maxLength must be > 1');
  }
  const words = str.split(' ');
  if (words.length > maxLength) {
    const trimmed = str.split(' ', maxLength);
    return `${trimmed.join(' ')}...`;
  }
  return str;
};

/** without display view just use this to format time in UK format */
export const formatTimeDefault = (d: Date | string) => formatDate(d, 'dd/MM/yyyy HH:mm');
/** without display view just use this to format time in UK format */

/** without display view just use this to format time in UK format */
export const formatDateDefault = (d: Date | string) => formatDate(d, 'dd/MM/yyyy');

export const formatUserName = (username: string) => {
  if (username && username.includes('/')) {
    const usernameSplit = username.split('/');
    return usernameSplit.length > 0 ? usernameSplit[1] : usernameSplit[0];
  }
  return username;
};
