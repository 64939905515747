import React from 'react';
import { PositionWrapper } from './PositionCellRender.style';

interface CellColor {
  [position: string]: string;
}

interface PositionCellRenderProps {
  value: string;
  isDarkTheme?: boolean;
}

const lightTextColor: CellColor = {
  ['Sell']: 'var(--errorLight)',
  ['Buy']: 'var(--fis-green)',
};

const darkTextColor: CellColor = {
  ['Sell']: 'var(--red)',
  ['Buy']: 'var(--dark-green)',
};

export const PositionCellRender = ({ value, isDarkTheme }: PositionCellRenderProps) => (
  <PositionWrapper color={isDarkTheme ? lightTextColor[value] : darkTextColor[value]}>{value}</PositionWrapper>
);
