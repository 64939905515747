import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { Lock, Info } from '@material-ui/icons';

export const Column = styled.div`
  font-size: 13px;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
`;

export const RowButton = styled(Button)`
  color: #fff;
  min-width: 29px;
  padding: 6px 4px;
  opacity: ${props => (props.disabled ? '0' : '1')};
  transition: 0.2s all;
`;

export const InfoButton = styled(RowButton)`
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;
export const InfoIcon = styled(Info)`
  width: 18px;
  height: 18px;
`;

export const LockIcon = styled(Lock)`
  width: 18px;
  height: 18px;
  color: var(--accent-grey);
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const Row = styled.div<{ isDragging?: boolean }>`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 43px;
  background-color: ${props => (props.isDragging ? 'var(--blue)' : 'none')};
  &:hover .infoButton {
    opacity: 1;
  }
`;

export const BlueCheck = styled(Checkbox)`
  color: #0d83c9;
  padding: 0;
`;
