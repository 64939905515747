import styled from 'styled-components';
import { InputBase } from '@material-ui/core';
export const TagStyle = styled.div`
  background-color: #28333c;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  order: 1;
  width: 100%;
  overflow-wrap: break-word;
  text-align: center;
`;
export const DateStyle = styled.div`
  margin-left: 8px;
  color: #97a0a7;
  font-size: 12px;
  order: 2;
`;
export const BlogOuterWrapper = styled.div`
  flex: 2.4;
  background-color: --var(bgDark);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;
export const CommentButton = styled.div`
  align-self: center;
  font-size: 12px;
`;
export const BlogInnerWrapper = styled.div<{ fromModal?: boolean }>`
  padding: ${props => (props.fromModal ? '0 10px' : '0 6px')};
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;
export const BlogTitleWrapper = styled.div`
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  width: 100%;
`;
export const ListItem = styled.div`
  overflow-y: scroll;
  flex: 1;
  background-color: --var(bgDark);
  height: 100%;
  display: block;
  overflow-x: hidden;
`;
export const GroupHeading = styled.div`
  padding: 2%;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  background-color: #237bbb;
  line-height: 7px;
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  margin-bottom: 0;
`;
export const BlogAuthorWrapper = styled.div`
  color: #fff;
  font-size: 11px;
`;
export const BlogCommentsWrapper = styled.div`
  color: #97a0a7;
  font-size: 12px;
`;
export const BlogLeftSection = styled.div`
  flex: 3;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: normal;
  line-break: strict;
  hyphens: none;
  color: #fff;
`;
export const BlogRightSection = styled.div`
  flex: 2;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 10px;
`;
export const BlogSection = styled.div`
  width: 100%;
  padding: 2%;
  height: fit-content;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: row wrap;
`;
export const StyledSearchInput = styled.div`
  display: flex;
  align-items: center;
  border: 1px;
  padding: 10px 10px 5px 10px;
  svg:nth-of-type(1) {
    height: 31px;
    background-color: var(--label);
  }

  svg:nth-of-type(2) {
    height: 31px;
    right: 0;
    background-color: var(--label);
  }
`;
export const SelectedItemStyle = styled.div`
  margin-right: 5px;
  width: 3px;
  background-color: #0d83c9;
`;
export const StyledInput = styled(InputBase)<{ margin: string; label: string; flex: string } & any>`
  color: var(--text);
  width: auto;
  height: auto;
  background-color: #495158;
  padding: 0 10px;
  ${props => (props.flex ? `flex: ${props.flex}` : '')}
`;
