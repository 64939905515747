// import BasicButton from '@virtus/components/Buttons/Basic/BasicButton';
import styled from 'styled-components';
import { SecondaryButton as SB } from '@virtus/components/Buttons';

export interface IOptionalStyles {
  noPadding?: boolean;
  noBackground?: boolean;
}

export const GlideDataContent = styled.div<{ optionalStyles: IOptionalStyles }>`
  bottom: 0;
  background-color: ${({ optionalStyles }) => {
    return optionalStyles.noBackground ? 'inherit' : 'var(--bgInspectorFullscreenContent)';
  }};
  display: flex;
  flex-direction: column;
  color: var(--text);
  width: 100%;
  padding: ${({ optionalStyles }) => (optionalStyles.noPadding ? '0' : '15px 20px 11px 20px')};
  label {
    text-transform: capitalize;
  }
`;

export const CancelButton = styled(SB)<{ backgroundcolor?: string }>`
  background-color: ${props => props?.backgroundcolor || 'var(--error)'};
  &:hover {
    background-color: ${props => props?.backgroundcolor || 'var(--errorLight)'};
  }
`;
