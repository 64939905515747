import styled from 'styled-components';

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0px 20px;
`;

export const NewLayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ScrollableContent = styled.div`
  height: 100%;
  overflow: overlay;
`;
