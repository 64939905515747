import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { requestAsync } from 'redux-query';
import { authSelectors, GlideSession } from '@virtus/common/auth/reducer';
import { List } from '@material-ui/core';
import { Modal } from '@virtus/components';
import StorageIcon from '@material-ui/icons/Storage';
import { ClientEnvironments } from 'src/api/queries';
import { RootState } from 'src/reducers/rootReducer';
import startCase from 'lodash/startCase';
import * as S from './db-selector-overlay.style';
import { PanelHeader } from '@virtus/components/Panel/PanelHeader';

interface ReduxProps {
  clientEnvironments?: { client_envs: string[] };
  isLoadingClientEnvironments?: boolean;
  glideSession?: GlideSession;
}

interface ReduxDispatch {
  getClientEnvironments?: () => void;
}
export interface DbSelectorOverlayProps {
  show: boolean;
  onClose: () => void;
  currentEnv?: string;
  onSelect?: () => void;
}
type Props = DbSelectorOverlayProps & ReduxProps & ReduxDispatch;

const defaultClientEnvironments = {
  client_envs: [
    'glide-dev',
    'glide-qa',
    'demo',
    'rcm-test',
    'hayfin-test',
    'greywolf-test',
    'serone-test',
    'telos-test',
    'rcm',
    'hayfin',
    'fsi',
    'greywolf',
    'serone',
    'commerzbank',
    'mjx',
    'telos',
  ],
};

export const DbSelectorOverlay = ({
  show,
  onClose,
  onSelect,
  currentEnv,
  clientEnvironments,
  getClientEnvironments,
  glideSession,
}: Props) => {
  useEffect(() => {
    if (glideSession?.token) {
      getClientEnvironments && getClientEnvironments();
    }
  }, [getClientEnvironments, glideSession?.token]);

  const dbList = () => {
    if (clientEnvironments && clientEnvironments.client_envs.length > 0) {
      return clientEnvironments;
    }
    return defaultClientEnvironments;
  };

  const setDb = (dbKey: string) => {
    window.location.reload();
    localStorage.setItem('client_env', dbKey);
    onClose();
  };

  const onClickHandler = (db: string) => {
    if (onSelect) {
      onSelect();
    } else {
      setDb(db);
    }
  };
  const header = () => (
    <PanelHeader
      title="Select Client Environment"
      onClose={onClose}
      tooltipText="This is an admin feature (only available for admin on test env) shortcuts: Win: [alt+?] OSX: [cmd+/]"
    />
  );

  return (
    <Modal
      show={show}
      onClose={onClose}
      header={header()}
      headerStyle={{ padding: 0 }}
      fullWidth
      footer={`Current Environment : ${startCase(currentEnv ? currentEnv : 'Demo')}`}
    >
      <List data-testid="db-list">
        {dbList()?.client_envs.map((db, i) => {
          return (
            <S.ListItem selected={db === currentEnv} key={`list_${db}_${i}`} button onClick={() => onClickHandler(db)}>
              <S.ListItemIcon>
                <StorageIcon />
              </S.ListItemIcon>
              <S.TextContainer data-testid="row-text">
                <p>{startCase(db)}</p>
              </S.TextContainer>
            </S.ListItem>
          );
        })}
      </List>
    </Modal>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  clientEnvironments: ClientEnvironments.selector(state) || { client_envs: [] },
  isLoadingClientEnvironments: ClientEnvironments.pending(state),
  glideSession: authSelectors.glideSession(state),
});

const mapDispatchToProps = (dispatch: any): ReduxDispatch => ({
  getClientEnvironments: () => dispatch(requestAsync(ClientEnvironments.get())),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(DbSelectorOverlay);
