import Button from '@material-ui/core/Button';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import styled from 'styled-components';
import { layoutRegion } from './InspectorActions.model';
const ActionsMenu = (props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
);
export const StyledActionsMenu = withStyles({
  paper: {
    marginTop: -31,
    borderRadius: '2px',
    backgroundColor: '#3c434b',
  },
})(ActionsMenu);
const evalColor = (darktheme?: boolean, layout?: layoutRegion) => {
  if (!layout || layout === layoutRegion.POPUP) {
    return 'var(--text)';
  } else {
    return darktheme ? 'var(--text)' : 'var(--textDark)';
  }
};
export const TextButton = styled(Button)<{ darktheme?: boolean; layout?: layoutRegion }>`
  color: ${({ darktheme = false, layout }) => evalColor(darktheme, layout)} !important;
  text-transform: none;
  padding: 0px;
  &[disabled] {
    color: var(--text);
    box-shadow: none;
    opacity: 0.5;
  }
`;

export const StyledMenuItem = styled(MenuItem)<{ disabled?: boolean; backgroundcolor?: string; layout?: layoutRegion }>`
  width: ${({ layout }) => (layout === layoutRegion.POPUP || layout === layoutRegion.BOTTOM ? 'auto' : '160px')};
  height: 32px;
  margin: 0;
  padding: 6px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.14;
  letter-spacing: -0.2px;
  color: ${({ disabled = false }) => (disabled ? 'var(--grey)' : 'var(--text)')} !important;
  pointer-events: ${({ disabled = false }) => (disabled ? 'none' : 'auto')};

  &:hover {
    background-color: ${props => props.backgroundcolor || 'var(--listItemHoverDark)'};
  }
`;

export const StyledMenuItemText = styled.div`
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: -0.2px;
  color: var(--text);
`;
