import styled from 'styled-components';
import { Dialog as MUIDialog, Button } from '@material-ui/core';

export const Dialog = styled(MUIDialog)`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    margin: 0;
    & > div {
      width: 80%;
      height: 80%;
      max-height: 80%;
      max-width: 80%;
      margin: 0;
    }
  }
`;

export const DXClassesOverride = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  & > :nth-child(2) {
    width: 100%;
    height: 100%;
  }
  .dx-tabs-wrapper {
    background-color: var(--bgInspectorFullscreenContent);

    .dx-tab {
      color: var(--text);
      background-color: var(--bgInspectorFullscreenContent);
      display: table-cell;
      .dx-tab-content {
        text-transform: capitalize;
        font-size: 14px;
      }
    }
    .dx-tab-selected {
      color: var(--light-blue);
      background-color: var(--bgInspectorFullscreenContent);
    }
  }
`;

export const IconButton = styled(Button)`
  padding: 0 !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  color: #fff;
  margin-right: 10px;
`;

export const Title = styled.span`
  color: var(--text);
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  width: 100%;
  background: var(--bgInspectorFullscreenContent);
  padding-left: 12px;
  padding-right: 10px;
`;

export const ItemComponent = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px;
  background: var(--bgInspectorFullscreenContent);
`;
