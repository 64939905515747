import arrayToObject from '@virtus/common/utils/arrayToObject';
import { isEmptyString } from '@virtus/common/utils/isEmptyString';
import DataGrid, { GroupItem, Summary } from 'devextreme-react/data-grid';
import React from 'react';
import { DxSummary } from 'src/DxDataGrid/DxDataGrid';
import { NexusSchema, SummaryDataTypeProps, VirtusSummaryOptions } from 'src/DxDataGrid/utils/mapSchemaVirtus';
import { parseDataTypeValue } from 'src/parsers/parseDataTypeValueVirtus';

// generate the Summary GroupItem customizeText prop using the parseDataTypeValue
export const getCustomizeText = (col: NexusSchema) => ({ value }: { value: any }) =>
  parseDataTypeValue(col.DataTypeName, value, col.NumericPrecision, col.ColumnName, '');

// Summary calculateCustomSummary prop to handle the empty cells sum as 0.00
export const calculateCustomSummary = (options: {
  component: DataGrid['instance'];
  groupIndex: number;
  name: string;
  summaryProcess: 'start' | 'calculate' | 'finalize';
  totalValue: any;
  value: any;
}) => {
  if (options.name === 'custom-sum') {
    switch (options.summaryProcess) {
      case 'start':
        options.totalValue = null;
        break;
      case 'calculate': {
        if (options.value == null || isEmptyString(options.value)) break;
        const value = Number(options.value);
        if (!isNaN(value)) options.totalValue += value;
        break;
      }
    }
  }
};

const renderGroupItem = (
  key: string | number,
  column: NexusSchema,
  { name, summaryType, displayFormat }: DxSummary | SummaryDataTypeProps,
) => {
  return (
    <GroupItem
      key={key}
      column={column.ColumnName}
      name={name}
      summaryType={summaryType}
      customizeText={getCustomizeText(column)}
      displayFormat={displayFormat}
      alignByColumn={true}
    />
  );
};

// Render the Summary GroupItems based on the summaryKeyProps
const renderSummaryKeyGroupItems = (dataSourceSchema: NexusSchema[] = [], summaryKeyProps: DxSummary[] = []) => {
  const schemaByColumnName = arrayToObject(dataSourceSchema, 'ColumnName');
  return summaryKeyProps.map((gi: DxSummary, i) => {
    if (!(gi.column in schemaByColumnName)) return;
    const column = schemaByColumnName[gi.column];
    return renderGroupItem(`summary-key-prop-${i}-${gi.column}`, column, gi);
  });
};

// Render the Summary GroupItems based on the summaryDataTypeProps
const renderSummaryDataTypeGroupItems = (
  dataSourceSchema: NexusSchema[] = [],
  summaryDataTypeProps: SummaryDataTypeProps[] = [],
) => {
  return summaryDataTypeProps.map(summaryDataTypeProp =>
    dataSourceSchema
      .filter((col: NexusSchema) => col.DataTypeName === summaryDataTypeProp.dataType)
      .map((col: NexusSchema, i) =>
        renderGroupItem(`summary-data-type-props-${i}-${col.ColumnName}`, col, summaryDataTypeProp),
      ),
  );
};

/**
 * Generate the summary: Use the default id, optionally add custom key summary
 *  and allow for default summary for data types
 * @param dataSourceSchema
 * @param options
 */
export const getSummaryVirtus = (
  dataSourceSchema: NexusSchema[],
  { summaryKeyProps, summaryDataTypeProps }: VirtusSummaryOptions,
) => {
  const columnIndex = dataSourceSchema.find((col: NexusSchema) => {
    const name = col.ColumnName;
    return name && !name.includes('_') && name.toLowerCase() !== name;
  });

  return (
    <Summary calculateCustomSummary={calculateCustomSummary} skipEmptyValues={true}>
      {/*Add summary group for default key by default*/}
      {columnIndex && <GroupItem column={columnIndex.ColumnName} summaryType="count" />}

      {/*Add custom summary keys */}
      {summaryKeyProps && renderSummaryKeyGroupItems(dataSourceSchema, summaryKeyProps)}

      {/*Create default summary grouping by data type*/}
      {summaryDataTypeProps && renderSummaryDataTypeGroupItems(dataSourceSchema, summaryDataTypeProps)}
    </Summary>
  );
};
