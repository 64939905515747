import { endpoints } from 'src/api/constants';
import { GlideObject } from 'src/models/glide/glideObject';
import { RootState } from 'src/reducers/rootReducer';

export interface GGTimeSeriesProps {
  uri: string;
  date?: string | string[] | string[][];
  fields?: any[];
  freq?: string;
}
const defaultGlideTimeSeries = { data: [], schema: [] };

export interface GetDisplayViewProps {
  uri: string[];
  cache?: boolean;
  expand_prop?: string[];
}
export interface TimeSeriesType {
  data: GlideObject[];
  fields?: any[] | undefined;
}
export const transformGlideTimeSeries = ({ data, fields }: TimeSeriesType) => {
  if (!data?.length) {
    return [];
  }
  const _data: any = {};
  data.forEach((item: any) => {
    if (!_data['Trade Field']) {
      _data['Trade Field'] = fields;
    }
    _data[item.date] = item.data;
  });

  const result = _data['Trade Field'].map((_: any, index: number) => {
    const data: any = {};
    for (const item in _data) {
      if (item !== 'Trade Field') {
        const key = item.split('T')[0];
        const fieldName = _data['Trade Field'][index]['name'];
        data[key] = _data[item][fieldName];
      } else {
        data[item] = _data[item][index]['name'];
      }
    }
    return data;
  });
  return result;
};

export const getGlideTimeSeries = ({ uri, date, fields, freq = 'B' }: GGTimeSeriesProps) => {
  const today = new Date().toISOString();
  return {
    url: `${endpoints.gget.root}`,
    transform: (body: GlideObject[]) => ({
      glide_time_series: { data: transformGlideTimeSeries({ data: body, fields }), schema: [] },
    }),
    body: {
      uri: `"${uri}"`,
      date: date ? JSON.stringify(date) : today,
      field: fields ? fields.map((obj: any) => obj.name).toString() : '',
      freq,
    },
    force: true,
    options: { method: 'GET' },
    params: {},
    queryKey: 'getGlideTimeSeries',
    update: {
      glide_time_series: (_: any, next: any) => next,
    },
  };
};

export const getGGDisplayView = ({ uri, cache = true, expand_prop }: GetDisplayViewProps) => {
  return {
    url: `${endpoints.gget.root}`,
    body: {
      uri,
      expand_prop,
    },
    transform: (body: GlideObject[]) => ({ display_views: [...body] }),
    force: !cache, // cache the request
    options: { method: 'POST' },
    params: {},
    queryKey: 'display_views',
    update: {
      display_views: (_: any, next: any) => next,
    },
  };
};

export const selector = (state: RootState) => state.entities.glide_time_series || defaultGlideTimeSeries;

export const isPending = (state: RootState) =>
  state.queries.getGlideTimeSeries && state.queries.getGlideTimeSeries.isPending;

export const ggDisplayViewSelector = (state: RootState) => state.entities.display_views;

export const isPendingDisplayView = (state: RootState) =>
  state.queries.display_views && state.queries.display_views.isPending;
