export type buttons = 'send' | 'clear' | 'edit' | 'add' | 'save' | 'close' | 'trash';

export interface ToolBarDisableButtons {
  [key: string]: boolean;
}

export enum ToolBarButtons {
  EDITBUTTON = 'edit',
  CLOSEBUTTON = 'closeButton',
  SAVEBUTTON = 'saveButton',
}

export interface GridEditProps {
  edit?: boolean;
  editMode?: 'batch' | 'cell' | 'row' | 'form' | 'popup';
  allowUpdating?: boolean;
  allowDeleting?: boolean;
  allowAdding?: boolean;
  allowSelection?: boolean;
  allowMultipleSelection?: boolean;
}

export enum EditableTabKeys {
  'ORDER_EXECUTION_DETAILS' = 'order_execution_details',
  'TRADES' = 'trades',
  'COUNTERPARTY_ALLOCATIONS' = 'counterparty_allocations',
  'FUND_ALLOCATIONS' = 'fund_allocations',
  'CAPITAL_STRUCTURE' = 'capital_structure',
  'ORDER_ALLOCATIONS' = 'order_allocations',
  'OVERRIDES' = 'overrides',
  'HYPOTHETICAL_SCENARIO_OVERRIDES' = 'hypothetical_scenario_overrides',
}

export enum layoutRegion {
  BOTTOM = 'bottom',
  POPUP = 'popup',
  INSPECTOR = 'inspector',
}
export const buttonIconProps: { [key: string]: any } = {
  Edit: {
    name: 'orderSelectionEditButton',
    dataTestId: 'edit-btn-order-selection',
    disabledKey: ToolBarButtons.EDITBUTTON,
    icon: 'edit',
    hint: 'Edit',
    location: 'after',
  },
  Cancel: {
    name: 'orderSelectionCloseButton',
    dataTestId: 'cancel-btn-order-selection',
    disabledKey: ToolBarButtons.CLOSEBUTTON,
    icon: 'close',
    hint: 'Cancel',
  },
  Save: {
    name: 'saveButton',
    dataTestId: 'save-btn-order-selection',
    disabledKey: ToolBarButtons.SAVEBUTTON,
    icon: 'save',
    hint: 'Save',
  },
};

type PrimaryActionConfigType = {
  [key in EditableTabKeys]: {
    edit: boolean;
    allowUpdating: boolean;
    allowAdding?: boolean;
    allowDeleting?: boolean;
  };
};
const allowEditing = {
  edit: true,
  allowUpdating: true,
};
const allowAdding = {
  ...allowEditing,
  allowAdding: true,
};
const allowDeleting = {
  ...allowAdding,
  allowDeleting: true,
};
export const primaryActionConfig: PrimaryActionConfigType = {
  [EditableTabKeys.ORDER_EXECUTION_DETAILS]: allowAdding,
  [EditableTabKeys.COUNTERPARTY_ALLOCATIONS]: allowAdding,
  [EditableTabKeys.TRADES]: allowDeleting,
  [EditableTabKeys.ORDER_ALLOCATIONS]: allowDeleting,
  [EditableTabKeys.CAPITAL_STRUCTURE]: allowAdding,
  [EditableTabKeys.FUND_ALLOCATIONS]: allowDeleting,
  [EditableTabKeys.OVERRIDES]: allowEditing,
  [EditableTabKeys.HYPOTHETICAL_SCENARIO_OVERRIDES]: allowEditing,
};

export const otherActionsConfig = {
  name: 'otherActionsButtons',
  template: 'otherActionsTemplate',
  options: {
    hint: 'Other Actions',
    elementAttr: {
      'data-testid': `other-actions-dropdown-btn`,
    },
  },
  location: 'after',
  disabled: false,
};

export const toolBarIconsOrder = [
  'groupPanel',
  'searchButton',
  'searchPanel',
  'refreshButton',
  'otherActionsButtons',
  'addRowButton',
  'saveButton',
  'orderSelectionCloseButton',
  'exportButton',
  'columnChooserButton',
];

export const ButtonsToExclude = ['Save'];
