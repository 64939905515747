import { RootState } from 'src/reducers/rootReducer';

export interface IPortfolioState {
  selectedFundURIs: any;
}

export const initialState = (): IPortfolioState => ({
  selectedFundURIs: {
    portfolio: JSON.parse(localStorage.getItem('selectedFundURIs_portfolio')!) || [{ uri: 'EMPTY_GRID' }],
    portfolio_analyzer: JSON.parse(localStorage.getItem('selectedFundURIs_portfolio_analyzer')!) || [
      { uri: 'EMPTY_GRID' },
    ],
    proposed_order_blotter: JSON.parse(localStorage.getItem('selectedFundURIs_proposed_order_blotter')!) || [
      { uri: 'EMPTY_GRID' },
    ],
    compliance_dashboard: JSON.parse(localStorage.getItem('selectedFundURIs_compliance_dashboard')!),
  },
});

export enum PortfolioActionTypes {
  STORE_SELECTED_PORTFOLIOS = 'STORE_SELECTED_PORTFOLIOS',
  RESET_STORED_PORTFOLIO = 'RESET_STORED_PORTFOLIO',
}

export const portfolios = (
  state: IPortfolioState = initialState(),
  action: { type: PortfolioActionTypes; payload?: any },
) => {
  switch (action.type) {
    case PortfolioActionTypes.STORE_SELECTED_PORTFOLIOS:
      localStorage.setItem(
        `selectedFundURIs_${action.payload.portfolio_type}`,
        JSON.stringify(action.payload.selectedFundURIs),
      );
      return {
        ...state,
        selectedFundURIs: {
          ...state.selectedFundURIs,
          [action.payload.portfolio_type]: action.payload.selectedFundURIs,
        },
      };
    case PortfolioActionTypes.RESET_STORED_PORTFOLIO:
      return { ...state, ...initialState() };
    default:
      return state;
  }
};

export const selectedFundURIs = (state: RootState, clientViewConfiguration: any) =>
  state.portfolios.selectedFundURIs[clientViewConfiguration?.uri?.lastSplitValue()]?.map(
    (item: { uri: string; display_name: any }) => item.uri,
  );

export const selectedFunds = (state: RootState, clientViewConfiguration: any) =>
  state.portfolios.selectedFundURIs[clientViewConfiguration?.uri?.lastSplitValue()];
