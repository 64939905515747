import { endpoints } from 'src/api/constants';
import { mutationApiCall } from 'src/api/mutations/mutationfunctions';
import { GlideAPIFieldRules } from 'src/api/field-rules/field-rules.model';

type WorkflowUpdateType = {
  uri: string;
  transition_uri: string;
  view?: string;
  comment?: string;
};

export type OrderDisplayViewAPIBodyType = {
  data: string;
  display_name: string;
  uri: string;
  display_view: string;
  workflow_transitions?: string;
  workflow_status_history?: string;
  field_rules: GlideAPIFieldRules;
};

export const generateOrderDisplayViewData = (body: OrderDisplayViewAPIBodyType) => {
  return {
    data: {
      ...(body.data ? JSON.parse(body.data) : {}),
      workflow_status_history: body.workflow_status_history ? JSON.parse(body.workflow_status_history) : [],
      workflow_transitions: body.workflow_transitions ? JSON.parse(body.workflow_transitions) : [],
    },
    fieldRules: body.field_rules,
    uri: body.uri,
    displayView: JSON.parse(body.display_view),
    displayName: body.display_name,
  };
};

export const put = ({ uri, transition_uri, view = 'order_blotter', comment }: WorkflowUpdateType) => {
  return mutationApiCall({
    url: endpoints.order.workflowTransition,
    data: { uri, transition_uri, comment },
    queryKey: 'executeWorkflowTransitionMutation',
    callBackTransform: {
      transformFunc: (body: OrderDisplayViewAPIBodyType) => ({
        [`clientData_${view}`]: { inspectorData: generateOrderDisplayViewData(body) },
      }),
      transformReturnKey: 'views',
    },
    updateData: {
      updateKey: 'views',
      updateCallBack: (prev: any, next: any) => ({
        ...prev,
        [`clientData_${view}`]: {
          ...prev[`clientData_${view}`],
          inspectorData: next[`clientData_${view}`].inspectorData,
        },
      }),
    },
  });
};
