import React from 'react';
import { formatNumber } from '@virtus/glide/src/utils/formatters';
import * as S from './price-change-cell-render.style';

const toggleHighlighterClass = (cellData: any, className: string) => {
  const cellElement = cellData.component.getCellElement(cellData.rowIndex, cellData.columnIndex);
  if (cellElement) {
    cellElement.classList.toggle('highlight-red-background', className === 'dec');
    cellElement.classList.toggle('highlight-green-background', className === 'inc');
  }
};

const toggleClassName = (netChangeVal: string) => {
  let className = '';
  if (netChangeVal.indexOf('-') > -1) {
    className = 'dec';
  } else if (parseFloat(netChangeVal) > 0) {
    className = 'inc';
  }
  return className;
};

export const PriceChangeCellRender = ({
  cellData,
  value,
  format = 'N4',
}: {
  cellData: any;
  value?: any;
  format?: string;
}) => {
  let cellDataVal = null;
  let formattedLastPriceVal = '';
  let netChangeVal = null;
  let changedCell = null;
  if (cellData.value) {
    cellDataVal = cellData.value.toString().split(' ');
    formattedLastPriceVal = formatNumber(cellDataVal[0], format.toLowerCase());
    netChangeVal = cellDataVal[1];
  } else if (value) {
    formattedLastPriceVal = formatNumber(value, format.toLowerCase());
  }

  if (netChangeVal && netChangeVal !== '0') {
    const className = toggleClassName(netChangeVal);
    if (className) {
      toggleHighlighterClass(cellData, className);
    }
    changedCell = (
      <S.Container className={className} title={formattedLastPriceVal}>
        <S.Arrow className={className}></S.Arrow>
        <S.NetChange className={className}>{netChangeVal}</S.NetChange>
        <S.CurrentVal>{formattedLastPriceVal}</S.CurrentVal>
      </S.Container>
    );
  }

  return changedCell ? changedCell : formattedLastPriceVal ? <>{formattedLastPriceVal}</> : null;
};
