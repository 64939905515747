import { put, takeLatest } from 'redux-saga/effects';
import { GlideRoute, TabsAction } from 'src/reducers/tabs';
import { executeLoadTests } from './time-series/time-series.saga';

export interface ChangeViewPayload {
  data: { fullPath: string; activeView: string };
}

export interface OpenTabPayload {
  path?: string;
  routes?: GlideRoute[];
}

export enum TabActions {
  OPEN_VIEW = 'OPEN_VIEW',
}

export function* open_View(action: any) {
  yield put({
    type: TabsAction.OPEN_TAB,
    path: action.data.path,
  });

  yield put({
    type: TabsAction.CHANGE_VIEW,
    data: { activeView: action.data.activeView },
  });
}

export function* change_open_view(_action: ChangeViewPayload): any {
  yield put(executeLoadTests());
}

export function* watchOpenView() {
  yield takeLatest<any>(TabActions.OPEN_VIEW, open_View);
}

export function* watchChangeView() {
  yield takeLatest<any>(TabsAction.CHANGE_VIEW, change_open_view);
}

export function* watchOpenTab() {
  yield takeLatest<any>(TabsAction.OPEN_TAB, change_open_view);
}

export default [watchOpenView, watchChangeView, watchOpenTab];
