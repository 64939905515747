import { endpoints } from 'src/api/constants';
import { Scenario } from 'src/models/scenario/scenario';
import { RootState } from 'src/reducers/rootReducer';

export const get = () => ({
  url: endpoints.hypo.scenarios,
  transform: (body: any) => {
    const scenarios = JSON.parse(body.data);
    const scenariosWithUriId = scenarios.map((scenario: Scenario) => ({
      ...scenario,
      uriId: Number(scenario._uri.replace('instance/hypothetical_scenarios/', '')),
    }));
    return {
      views: {
        clientData_hypo_scenarios: {
          data: scenariosWithUriId,
          schema: body.schema,
        },
      },
    };
  },
  options: { method: 'GET' },
  queryKey: 'get_hypo_scenarios',
  force: true,
  update: {
    views: (prev: any, next: any) => ({
      ...prev,
      clientData_hypo_scenarios: {
        inspectorData: prev?.clientData_hypo_scenarios?.inspectorData,
        collapseWrapperOpen: prev?.clientData_hypo_scenarios?.collapseWrapperOpen,
        ...next?.clientData_hypo_scenarios,
      },
    }),
  },
});

export const selector = (state: RootState) => state.entities.views?.clientData_hypo_scenarios;

export const pending = (state: RootState) =>
  state.queries.get_hypo_scenarios && state.queries.get_hypo_scenarios.isPending;
