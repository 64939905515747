import React, { useState } from 'react';
import { GlideInspector, GlideInspectorProps } from 'src/components/inspectors/glide-inspector';
import { ColumnManager, ColumnManagerProps } from '@virtus/components/column-manager/column-manager';
import InspectorFooter from '@virtus/components/Inspector/components/InspectorFooter';
import { groupedDataColumn } from 'src/utils/constants';
export interface ColumnManagerInspectorProps extends GlideInspectorProps, Omit<ColumnManagerProps, 'onUpdateColumns'> {
  onSave: (columns: ColumnManagerProps['columns']) => void;
}

export const ColumnManagerInspector = ({
  columns,
  onBack,
  onSave,
  ...glideInspectorProps
}: ColumnManagerInspectorProps) => {
  const [columnsToUpdate, setColumnsToUpdate] = useState<ColumnManagerProps['columns']>([]);

  const onUpdateColumns = (updatedColumns: ColumnManagerProps['columns']) => setColumnsToUpdate(updatedColumns);

  const onApply = () => onSave(columnsToUpdate);

  return (
    <GlideInspector onBack={onBack} {...glideInspectorProps}>
      <ColumnManager
        columns={columns}
        onUpdateColumns={onUpdateColumns}
        excludedColumns={[groupedDataColumn.dataField]}
      />
      <InspectorFooter disabled={columnsToUpdate.length === 0} hideCancel onApply={onApply} confirmButtonText="Save" />
    </GlideInspector>
  );
};
