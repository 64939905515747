import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';

export const DrawerContainer = styled(Drawer)<{ style: object } & any>`
  display: flex;
  flex-direction: column;
  right: 0;
  height: ${props => (props?.style?.height ? props.style.height : 'calc(100vh - 74px)')};
  background-color: var(--bgDark);
`;

export const DrawerContent = styled.div`
  display: flex;
  font-size: 0.9rem;
  flex-direction: column;
  background-color: var(--bgDark);
  width: var(--width-inspector);
  height: 100%;
  border-left: none;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12);
  overflow: hidden;
`;

export const InspectorWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  flex-direction: column;
`;

export const PaperStyled = styled(Paper)<{ paperStyles?: CSSStyleDeclaration }>`
  border-left: none;
  height: inherit;
  margin-top: 72px;
  z-index: 0;
  border-radius: 0;
  box-shadow: none;
`;

export const RowsWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow: overlay;
  padding: 5px 25px 25px 25px;
  height: 100%;
`;
