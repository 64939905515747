import styled from 'styled-components';
import BasicButton from '../Basic/BasicButton';

const PrimaryButton = styled(BasicButton)`
  background-color: var(--default-blue);
  &:hover {
    background-color: var(--light-blue);
  }
  &:disabled {
    color: var(--text);
    opacity: 0.5;
    background-color: var(--default-blue);
  }
`;

export default PrimaryButton;
