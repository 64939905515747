import { InputBase } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import styled from 'styled-components';

export const StyledPanelSearch = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  color: var(--text);
  background: var(--bgInspectorFullscreenContent);
  align-self: flex-end;
  width: var(--width-inspector);
  height: 100%;
  [data-testid='inspector-group-container'] {
    border-bottom: 0;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  flex: 1;
`;

export const SearchResultsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  svg {
    width: 32px;
    height: 32px;
  }
`;

export const StyledResult = styled.li`
  list-style-type: none;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  letter-spacing: -0.18px;
  cursor: pointer;
`;

export const StyledInput = styled(InputBase)<{ margin: string; label: string; flex: string } & any>`
  color: var(--text);
  width: auto;
  height: auto;
  ${props => (props.flex ? `flex: ${props.flex}` : '')}
`;

export const WarningIcon = styled(Warning)`
  font-size: 14px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-flow: row;
  padding: 25px;
  && {
    input {
      background-color: var(--label);
      color: var(--text);
      font-size: 14px;
      height: auto;
      padding: 5px;
    }
  }
`;

export const StyledSearchInput = styled.div`
  position: relative;
  margin: 0 1px;
  display: flex;
  align-items: center;
  flex: 1;

  svg:nth-of-type(1) {
    height: 29px;
    background-color: var(--label);
  }

  svg:nth-of-type(2) {
    height: 29px;
    right: 0;
    background-color: var(--label);
  }
`;

export const RowsWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow: overlay;
  padding: 5px 25px 16px 25px;
`;
