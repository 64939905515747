import React from 'react';

const DeleteIcon: React.SFC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none">
        <path d="M0 0h16v16H0z" />
        <path
          fill="#F20000"
          d="M12.002 15c.551 0 .998-.456.998-.995V5H4v9.005c0 .55.446.995.998.995h7.004zM10 1H7L6 2H3v2h11V2h-3l-1-1z"
        />
      </g>
    </svg>
  );
};

export default DeleteIcon;
