import { endpoints } from 'src/api/constants';
import { RootState } from 'src/reducers/rootReducer';
import { GlideAPIFieldRules } from 'src/api/field-rules/field-rules.model';
import { GlideObject } from 'src/models/glide/glideObject';
import { actionTypes } from 'redux-query';
import { updateCreditDetailsView } from 'src/components/inspectors/credit-details-inspector/components/fullscreen-inspector/fullscreen-inspector.mapper';
import { ClientRegion, DISPLAY_VIEW } from 'src/utils/constants';
export interface DisplayView {
  data: any;
  uri: string;
  date: string;
}
export interface CommentaryData {
  uri: any;
  type: string;
}
export type UpdateCommentsProps = {
  object_uri: string;
  [key: string]: object | string;
};

export interface DefaultCommentaryRequestProps {
  type: string;
  creditUri: string;
  fromModal: boolean;
  inspectorOpen: boolean;
}
export interface DeleteCommentaryRequestProps extends DefaultCommentaryRequestProps {
  uri: string;
}
export interface AddCommentaryRequestProps extends DefaultCommentaryRequestProps {
  body: any;
}

const defaultGlideTimeSeries = { data: [], schema: [] };

const defaultCommentaryData = {};
const creditDashboardKey = `clientData_${DISPLAY_VIEW.CREDIT}`;
export const transformGlideListObject = (data: GlideObject[], type: string) => {
  const listObject: any = [];
  data &&
    data.map((eachListData: GlideObject, index: number) => {
      listObject.push({
        id: index,
        title: eachListData.data.analyst_comment && eachListData.data.analyst_comment.replace(/<[^>]+>/g, ''),
        username: eachListData.data.analyst,
        comments: eachListData.data.analyst_comment,
        category: eachListData.data.analyst_comment_type,
        commentdate: eachListData.data.hasOwnProperty('analyst_comment_date')
          ? new Date(eachListData.data.analyst_comment_date).toISOString()
          : new Date(eachListData.date).toISOString(),
        commenturi: eachListData.uri,
        displayname: eachListData.data.display_name,
        creditdetails: eachListData.data.credit_details,
      });
    });
  return { [type]: listObject };
};

export type CreditDisplayViewAPIBodyType = {
  data: string;
  display_name: string;
  uri: string;
  field_rules: GlideAPIFieldRules;
  display_view: string;
};
export const mapBlogsDetails = (creditDetails: any, displayName: string) => {
  let analystComments: any;
  creditDetails &&
    Object.entries(creditDetails).forEach(([key, value]: any) => {
      if (key && (!analystComments || analystComments.length === 0)) {
        analystComments =
          value &&
          value.filter((cdNested: any) => {
            if (
              cdNested.hasOwnProperty('client_region') &&
              cdNested.client_region === ClientRegion.BrowserRightRegion &&
              cdNested.display_name === displayName
            ) {
              return !!cdNested.value;
            }
            return false;
          });
      }
    });

  return analystComments && analystComments.length > 0 && analystComments[0]?.value;
};

export const getGlideCommentaryData = ({ uri, type }: CommentaryData) => {
  return {
    url: `${endpoints.gget.root}`,
    transform: (body: GlideObject[]) => ({
      glide_commentary_data: { ...transformGlideListObject(body, type) },
    }),
    body: {
      uri: uri || [],
    },
    force: true,
    options: { method: 'POST' },
    params: {},
    queryKey: 'getGlideCommentaryData',
    update: {
      glide_commentary_data: (prev: any, next: any) => ({ ...prev, ...next }),
    },
  };
};

export const updateAnalystCommentsCreditDetails = (body: UpdateCommentsProps) => ({
  url: `${endpoints.update.rootObject}`,
  options: {
    method: 'PUT',
  },
  queryKey: 'updateAnalystCommentsCreditDetails',
  body,
  force: true,
});

export const CommentarySelector = (state: RootState, type: string) =>
  (state.entities.glide_commentary_data && state.entities.glide_commentary_data[type]) || defaultCommentaryData;

export const CommentaryUrisSelector = (state: RootState) => state.entities.commentaryUris || defaultGlideTimeSeries;
export const updateComments = (existing: any, body: any, type: string) => {
  const newData = existing[type].map((d: any) => {
    if (d.commenturi == Object.keys(body)[0]) {
      d.category = body[Object.keys(body)[0]].analyst_comment_type;
      d.comments = body[Object.keys(body)[0]].analyst_comment;
      d.username = body[Object.keys(body)[0]].analyst;
      d.title = body[Object.keys(body)[0]].analyst_comment.replace(/<[^>]+>/g, '');
      return d;
    } else {
      return d;
    }
  });
  return { ...existing, [type]: newData };
};
export const updateCommentary = (body: any, type: string) => ({
  url: `${endpoints.update.root}`,
  options: {
    method: 'PUT',
  },
  body,
  force: true,
  queryKey: 'updateCommentary',
  update: {
    glide_commentary_data: (existing: any) => {
      return updateComments(existing, body, type);
    },
  },
  meta: {
    notification: {
      [actionTypes.MUTATE_SUCCESS]: {
        title: 'Comment has been updated.',
      },
    },
  },
});

const updateCommentaryAfterAdd = (
  body: any,
  comment_type: string,
  fromModal: boolean,
  inspectorOpen: boolean,
  creditUri: string,
) => {
  if (fromModal && !inspectorOpen) {
    return {
      glide_commentary_data: (existing: any, next: any) => {
        if (next && next.uri && next.uri.length > 0 && next.uri[0]) {
          existing[comment_type].push({
            id: existing[comment_type].length,
            title: body.object_data.analyst_comment.replace(/<[^>]+>/g, ''),
            comments: body.object_data.analyst_comment,
            commentdate: new Date().toISOString(),
            commenturi: next.uri[0],
            username: body.object_data.analyst,
            category: body.object_data.analyst_comment_type,
          });
          newUri = next.uri[0];
          return { ...existing, [comment_type]: [...existing[comment_type]] };
        }
      },
    };
  }
  return {
    glide_commentary_data: (existing: any, next: any) => {
      if (next && next.uri && next.uri.length > 0 && next.uri[0]) {
        existing[comment_type].push({
          id: existing[comment_type].length,
          title: body.object_data.analyst_comment.replace(/<[^>]+>/g, ''),
          username: body.object_data.analyst,
          comments: body.object_data.analyst_comment,
          category: body.object_data.analyst_comment_type,
          commentdate: new Date().toISOString(),
          commenturi: next.uri[0],
        });
        newUri = next.uri[0];
        return { ...existing, [comment_type]: existing[comment_type] };
      }
    },
    views: (prev: any) => {
      if (prev[creditDashboardKey] && prev[creditDashboardKey]?.inspectorData?.uri !== creditUri) return prev;

      const newData = updateCreditDetailsView({
        type: comment_type,
        creditData: prev[creditDashboardKey]?.inspectorData?.data,
        uri: newUri,
        action: 'ADD',
      });
      newUri = '';
      return {
        ...prev,
        [creditDashboardKey]: {
          ...prev[creditDashboardKey],
          inspectorData: { ...prev[creditDashboardKey].inspectorData, data: newData },
        },
      };
    },
  };
};

// Global Object to get newly added comments URI.
let newUri: string = '';

//Submit new comments Blogs/KPIs
export const submitNewComments = ({
  body,
  type,
  inspectorOpen,
  creditUri,
  fromModal = false,
}: AddCommentaryRequestProps) => ({
  url: `${endpoints.new.root}`,
  options: {
    method: 'POST',
  },
  transform: (body: any) => ({
    glide_commentary_data: body,
  }),
  body,
  force: true,
  queryKey: 'gNew',
  update: {
    ...updateCommentaryAfterAdd(body, type, fromModal, inspectorOpen, creditUri),
  },
});

export const newCommentaryUri = (state: RootState) => state.entities.newCommentaryUris;
export const isNewCommentaryPending = (state: RootState) => state.queries.gNew && state.queries.gNew.isPending;

export const isPending = (state: RootState) =>
  state.queries.getGlideCommentaryData && state.queries.getGlideCommentaryData.isPending;

const optimisticUpdateAfterDelete = (
  uri: string,
  type: string,
  fromModal: boolean,
  inspectorOpen: boolean,
  creditUri: string,
) => {
  if (fromModal && !inspectorOpen) {
    return {
      glide_commentary_data: (prevData: any) => {
        prevData[type] = prevData[type].filter((item: any) => item.commenturi !== uri);
        return { ...prevData, [type]: prevData[type] };
      },
    };
  }
  return {
    glide_commentary_data: (prevData: any) => {
      prevData[type] = prevData[type].filter((item: any) => item.commenturi !== uri);
      return { ...prevData, [type]: prevData[type] };
    },
    views: (prevData: any) => {
      if (prevData[creditDashboardKey] && prevData[creditDashboardKey]?.inspectorData?.uri !== creditUri) {
        return prevData;
      }
      return {
        ...prevData,
        [creditDashboardKey]: {
          ...prevData[creditDashboardKey],
          inspectorData: {
            ...prevData[creditDashboardKey].inspectorData,
            data: updateCreditDetailsView({
              creditData: prevData[creditDashboardKey].inspectorData.data,
              action: 'DELETE',
              type,
              uri,
            }),
          },
        },
      };
    },
  };
};

export const deleteCommentary = ({
  uri,
  type,
  inspectorOpen,
  creditUri,
  fromModal = false,
}: DeleteCommentaryRequestProps) => ({
  url: `${endpoints.delete.root}`,
  options: {
    method: 'DELETE',
  },
  body: {
    uris: [uri],
  },
  force: true,
  queryKey: 'deleteCommentary',
  optimisticUpdate: {
    ...optimisticUpdateAfterDelete(uri, type, fromModal, inspectorOpen, creditUri),
  },
  rollback: {
    // @ts-ignore
    glide_commentary_data: (initialData: any, _: any) => initialData,
    views: (initialData: any, _: any) => initialData,
  },
});
