import { SvgIconProps } from '@material-ui/core/SvgIcon';

export interface InspectorAction {
  text: string;
  icon?: React.ComponentType<SvgIconProps>;
  actionFn: (payload: any) => void;
  props?: any;
  primary?: boolean;
  uri?: string;
}
export enum layoutRegion {
  BOTTOM = 'bottom',
  POPUP = 'popup',
  INSPECTOR = 'inspector',
}
export interface InspectorActionsProps {
  actions: InspectorAction[];
  actionButtonText?: string;
  darktheme?: boolean;
  layout?: layoutRegion;
  style?: boolean;
}
