import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import * as S from './time-series-date-picker.style';
import InspectorFooter from '@virtus/components/Inspector/components/InspectorFooter';
import { FormField } from '../forms/form-elements/FormField';
import { DateConfig } from '../date-picker/date-picker';

export interface TimeSeriesDatePickerActions {
  inspectorOnApply: (params: any) => void;
  inspectorOnCancel: () => void;
  dateObj: DateConfig;
  onChange: (params: any) => void;
  calendarAlign?: string;
  singleDateSelection?: boolean;
  dateFieldLabel?: string;
  error?: string;
}

export const TimeSeriesDatePicker = ({
  inspectorOnApply,
  inspectorOnCancel,
  onChange,
  dateObj,
  calendarAlign = 'right',
  singleDateSelection,
  dateFieldLabel = 'As of Date:',
  error = '',
}: TimeSeriesDatePickerActions) => {
  const [dropDownValue, setDropDownValue] = useState(dateObj.frequency);
  const [dateConfig, setDateConfig] = useState<Partial<DateConfig>>({
    singleDate: dateObj.singleDate,
    toDate: dateObj.toDate,
    fromDate: dateObj.fromDate,
  });

  useEffect(() => {
    setDateConfig((prevState: Partial<DateConfig>) => ({ ...prevState, ...dateObj }));
  }, [dateObj]);

  const handleDropDown = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDropDownValue((event.target as HTMLInputElement).value);
  };

  const [value, setValue] = React.useState(dateObj.dateType);
  const handleDateSetupRadio = (event: React.ChangeEvent<{}>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleSingleDateSetup = (event: any) => {
    // VERACODE restricted output
    // console.info('New selected date', event);
    if (singleDateSelection) {
      setDateConfig({ ...dateConfig, singleDate: event.target.value[event.target.value.length - 1] });
      onChange({
        ...dateConfig,
        singleDate: [event.target.value[event.target.value.length - 1]],
        dateType: value,
        frequency: dropDownValue,
      });
    } else {
      setDateConfig({ ...dateConfig, singleDate: event.target.value });
      onChange({ ...dateConfig, singleDate: event.target.value, dateType: value, frequency: dropDownValue });
    }
  };

  const handleRangeToDateSetup = (event: React.ChangeEvent<HTMLInputElement>) => {
    // VERACODE restricted output
    // console.info('New selected to date', event);
    setDateConfig({ ...dateConfig, toDate: event.target.value });
    onChange({ ...dateConfig, toDate: event.target.value, dateType: value, frequency: dropDownValue });
  };

  const handleRangeFromDateSetup = (event: React.ChangeEvent<HTMLInputElement>) => {
    // VERACODE restricted output
    // console.info('New selected from date', event);
    setDateConfig({ ...dateConfig, fromDate: event.target.value });
    onChange({ ...dateConfig, fromDate: event.target.value, dateType: value, frequency: dropDownValue });
  };

  const onApplyClick = () => {
    inspectorOnApply({ ...dateConfig, dateType: value, frequency: dropDownValue });
  };
  const customWidth = 'auto';
  const singleDateSetup = (
    <FormField
      field={{
        dataType: 'date',
        name: 'date-input',
        displayName: dateFieldLabel,
        formElementType: 'calendarMultiple',
        required: false,
        defaultValue: undefined,
        fieldRules: [],
        readonly: false,
        disabled: false,
        calendarAlign,
        isSingleDate: true,
        disableErrorWarning: true,
      }}
      value={dateConfig.singleDate as string}
      error={error}
      onChange={handleSingleDateSetup}
      formValues={{}}
      customWidth={customWidth}
      formGroupChildrenStyle={{ width: 'auto', padding: '0 10px' }}
    />
  );

  const rangeDateSetup = (
    <>
      <FormField
        field={{
          formElementType: 'select',
          name: 'Frequency',
          displayName: 'Frequency',
          dataType: 'text',
          required: false,
          readonly: false,
          defaultValue: '',
          options: {
            Daily: {
              name: 'Daily',
              value: 'B',
            },
            Weekly: {
              name: 'Weekly',
              value: 'W',
            },
            Monthly: {
              name: 'Monthly',
              value: 'BM',
            },
            Quarterly: {
              name: 'Quarterly',
              value: 'BQ',
            },
            Annually: {
              name: 'Annually',
              value: 'BY',
            },
            MonthEnds: {
              name: 'MonthEnds',
              value: 'M',
            },
            YearEnds: {
              name: 'YearEnds',
              value: 'Y',
            },
          },
          calendarAlign,
        }}
        value={dropDownValue}
        error=""
        onChange={handleDropDown}
        formValues={{}}
        customWidth={customWidth}
      />
      <FormField
        field={{
          dataType: 'date',
          name: 'date-input',
          displayName: 'From',
          formElementType: 'calendar',
          required: false,
          defaultValue: undefined,
          fieldRules: [],
          readonly: false,
          calendarAlign,
        }}
        value={dateConfig.fromDate as string}
        error=""
        onChange={handleRangeFromDateSetup}
        formValues={{}}
        customWidth={customWidth}
      />
      <FormField
        field={{
          dataType: 'date',
          name: 'date-input',
          displayName: 'To',
          formElementType: 'calendar',
          required: false,
          defaultValue: undefined,
          fieldRules: [],
          readonly: false,
          calendarAlign,
        }}
        value={dateConfig.toDate as string}
        error=""
        onChange={handleRangeToDateSetup}
        formValues={{}}
        customWidth={customWidth}
      />
    </>
  );

  if (!singleDateSelection) {
    return (
      <S.Column>
        <S.FormWrapper>
          <S.FormLabel>Date Setup</S.FormLabel>
          <FormControl>
            <RadioGroup row name="dateSetupRadio" value={value} onChange={handleDateSetupRadio}>
              <FormControlLabel
                control={<S.StyledRadio color="primary" />}
                value="single"
                label={<S.FormLabel>Single</S.FormLabel>}
              />
              <FormControlLabel
                control={<S.StyledRadio color="primary" />}
                value="range"
                label={<S.FormLabel>Range</S.FormLabel>}
              />
            </RadioGroup>
          </FormControl>
          {value === 'range' ? rangeDateSetup : singleDateSetup}
        </S.FormWrapper>
        <InspectorFooter disabled={false} confirmButtonText="Add" onApply={onApplyClick} onCancel={inspectorOnCancel} />
      </S.Column>
    );
  }

  return singleDateSetup;
};
