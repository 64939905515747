import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects';
import { requestAsync, updateEntities } from 'redux-query';
import { formattedDate } from 'src/components/forms/form-elements/form-fields/DateInput/DateInput';
import { selectedFundURIs } from 'src/reducers/portfolio';
import { glideQuery, GlideRequestBody } from 'src/api/query';
import { selectClientViewBottomPanel, selectCVC } from 'src/reducers/tabs';
import { selectViewComponent, updateComponentViewAction } from 'src/reducers/components';
import { selectClientViewData } from 'src/api/views/client-view/ClientView';
import {
  ClientViewConfiguration,
  ClientViewConfigurationData,
  ClientViewTypeLookUps,
} from 'src/components/glide-view/glide-view.model';
import { Glide } from 'src/api/queries';

export enum GlideViewActions {
  FETCH_CLIENT_VIEW = 'FETCH_CLIENT_VIEW',
  OPEN_BOTTOM_LINK = 'OPEN_BOTTOM_LINK',
  UPDATE_SCENARIO_RESULT_COLUMN = 'UPDATE_SCENARIO_RESULT_COLUMN',
  SAVE_LAYOUT = 'SAVE_LAYOUT',
  OPEN_BOTTOM_PANEL = 'OPEN_BOTTOM_PANEL',
}

export function* fetchClientView(action: any) {
  const clientViewConfiguration: ClientViewConfigurationData = yield select(selectCVC);
  const selectedFundsUri: any[] = yield select(selectedFundURIs, clientViewConfiguration);
  const hasFundSelector =
    clientViewConfiguration.client_view_type === ClientViewTypeLookUps.Custom ||
    clientViewConfiguration.uri.includes('portfolio');
  const hasBottomPanel = action?.bottomPanelUri && action?.instanceUri && action?.fieldNames;
  let queryParams: GlideRequestBody = { uri: clientViewConfiguration.uri };

  if (clientViewConfiguration.is_time_series) {
    const datePicker = yield select(selectViewComponent, 'datePicker', clientViewConfiguration.uri);
    const datePicked = datePicker?.data?.singleDate;
    if (datePicked) {
      // pass dateConfig to API
      queryParams['date'] = new Date(datePicked + 'UTC').toISOString();
    }
  }

  if (hasFundSelector) {
    const portfolios = yield select(Glide.selector, 'portfolios');
    // populate funds if needed
    if (!portfolios) {
      yield putResolve(
        requestAsync(
          glideQuery({
            endpoint: '/gsearch',
            transform: (response: any) => {
              return { portfolios: response };
            },
            options: { method: 'POST' },
            body: { object_type: 'funds', return_all_fields: true },
            update: {
              portfolios: (_: any, next: any) => next,
            },
          }),
        ),
      );
    }
  }

  if (hasBottomPanel) {
    // pass bottom panel CVC with filter value as selected row instance uri to API
    queryParams['uri'] = action?.bottomPanelUri;
    queryParams[action?.fieldNames] = action?.instanceUri;
  }

  if (
    clientViewConfiguration.uri === 'instance/client_view_configuration/proposed_order_blotter' &&
    selectedFundsUri[0] === 'EMPTY_GRID'
  )
    return;

  if (selectedFundsUri) {
    queryParams = {
      uri: clientViewConfiguration.uri,
      fund: selectedFundsUri.join(','),
      order_date: formattedDate(new Date()).formattedDate,
    };
  }

  yield putResolve(
    requestAsync(
      glideQuery({
        endpoint: '/glide/view',
        body: queryParams,
      }),
    ),
  );
}

function* openComplianceTestResultBottomPanel(action: any) {
  const hypoScenarioCvc = yield select(selectCVC);
  const bottomPanelCvc = yield select(selectClientViewBottomPanel);

  const instanceSensitiveObject = bottomPanelCvc.filter(
    (cvc: ClientViewConfiguration) =>
      cvc.data?.hasOwnProperty('instance_sensitive_field_name') &&
      cvc.data?.instance_sensitive_field_name?.includes('fields/compliance_test_current_result'),
  );
  yield put(
    updateComponentViewAction('bottomPanel', hypoScenarioCvc.uri, {
      instanceUri: action?.payload?._uri,
      instanceSensitiveObject,
    }),
  );
  yield call(openLink);
}

function* openLink() {
  const clientViewConfiguration = yield select(selectCVC);
  // TODO: selecting a row should update components.viewComponents.instanceUri
  const { instanceUri, instanceSensitiveObject } = yield select(
    selectViewComponent,
    'bottomPanel',
    clientViewConfiguration.uri,
  );

  // No instance uri means no instance sensitive views need to populate content
  if (!instanceUri || !instanceSensitiveObject || !instanceSensitiveObject.length) return;

  // Find the matching instance sensitive panel
  if (instanceSensitiveObject && instanceSensitiveObject.length === 1) {
    const filterUri = instanceSensitiveObject[0]?.data?.filter_uri?.split('?');
    const searchParams = new URLSearchParams(filterUri[1]);
    const fieldNames = searchParams.get('fieldnames');
    yield call(fetchClientView, {
      fieldNames,
      instanceUri,
      bottomPanelUri: instanceSensitiveObject[0]?.uri,
    });
    // update view component state so that glide-views reacts to that state by rendering the bottom panel
    yield put(
      updateComponentViewAction('bottomPanel', clientViewConfiguration.uri, {
        isVisible: true,
        clientViewUri: instanceSensitiveObject[0]?.uri,
      }),
    );
    yield put(
      updateComponentViewAction('inspector', clientViewConfiguration.uri, {
        isCollapsed: true,
      }),
    );
    yield put(
      updateComponentViewAction('bottomPanelExpandedState', clientViewConfiguration.uri, {
        isExpanded: true,
      }),
    );
  } else if (instanceSensitiveObject.length > 1) {
    console.warn(`[config error] multiple bottom panels found for ${instanceUri}. Cannot render!`);
  }
}

export function* updateScenarioResultColumn(action: any) {
  const clientViewData = yield select(selectClientViewData);
  const values: any = Object.values(action.payload.data);
  const payload = JSON.parse(values);
  clientViewData['data'] = clientViewData.data.map((eachData: any) => {
    if (eachData._uri === payload._uri) {
      eachData['Current Result'] = payload.hasOwnProperty('Current Result') && payload['Current Result'];
    }
    return eachData;
  });

  updateEntities({
    views: (prev: any, _: any) => ({
      ...prev,
      [clientViewData.uri]: {
        ...prev?.[clientViewData.uri],
        ...clientViewData,
      },
    }),
  });
  yield put(
    updateComponentViewAction('hypoScenario', clientViewData.uri, {
      runStatus: action.payload.resolved_entity_type,
      runStatusUri: action.payload.resolved_entity_uri,
    }),
  );
}

export function* watchFetchClientView() {
  yield takeLatest<any>(GlideViewActions.FETCH_CLIENT_VIEW, fetchClientView);
}

export function* watchOpenLink() {
  yield takeLatest<any>(GlideViewActions.OPEN_BOTTOM_LINK, openLink);
}
export function* watchRunHypoScenario() {
  yield takeLatest<any>(GlideViewActions.UPDATE_SCENARIO_RESULT_COLUMN, updateScenarioResultColumn);
}
export function* watchOpenBottomPanel() {
  yield takeLatest<any>(GlideViewActions.OPEN_BOTTOM_PANEL, openComplianceTestResultBottomPanel);
}
export default [watchFetchClientView, watchOpenLink, watchRunHypoScenario, watchOpenBottomPanel];
