import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import { ArrowDropDown, Clear, Done, Save, Settings } from '@material-ui/icons';
import { authSelectors, GlideSession } from '@virtus/common/auth/reducer';
import { ConfirmInputButton, SecondaryButton } from '@virtus/components/Buttons';
import DropdownMenu, { DropdownMenuItem, DropdownMenuItemText } from '@virtus/components/DropdownMenu';
import { LayoutsIcon } from '@virtus/components/icons';
import { Search } from '@virtus/components/Search/Search';
import isEqual from 'lodash/isEqual';
import React, { useState, useEffect } from 'react';
import { StyledInput } from 'src/components/forms/form-elements/FormElements.style';
import { ClientViewTypeLookUps } from 'src/components/glide-view/glide-view.model';
import { ILayout, WebLayouts } from 'src/components/grids/dxgrid-client-view/templates/Layouts/Layouts.model';
import * as S from './Layouts.style';
import { connect } from 'react-redux';
import { RootState } from 'src/reducers';
import { layoutSelector } from 'src/api/query.selector';
import { moveSelectedToFirstPosition } from 'src/utils/common';
import { Dispatch } from 'redux';
import { Components, selectComponents } from 'src/reducers/components';
import { dispatchActions } from 'src/app/store';
import { createLayout, Layout, updateLayout } from 'src/sagas/layouts/layouts.saga';
import { PivotLayout } from 'src/components/grids/dx-pivot-grid/model/dx-pivot-grid.model';

export interface LayoutsTemplateRenderProps {
  clientViewUri: string;
  onSettingsClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  dataGridRef?: React.RefObject<any>;
  updateColumnManagerColumns?: () => void;
  changePivotGridLayout?: (layout: PivotLayout) => void;
}
export interface LayoutTemplateReduxDispatch {
  createLayout: (layout: Layout) => void;
  updateLayout: (layout: Layout) => void;
}

const getDropdownText = (nbLayouts: number, activeLayout?: ILayout) => {
  if (activeLayout?.data?.name) return activeLayout?.data?.name;
  if (nbLayouts && nbLayouts !== 0) return `${nbLayouts} layouts`;
  return 'Layouts';
};

export interface LayoutTemplateReduxProps {
  components: Components;
  glideSession: GlideSession;
  webLayouts: WebLayouts;
}

type LayoutsTemplateRender = LayoutsTemplateRenderProps & LayoutTemplateReduxProps & LayoutTemplateReduxDispatch;

export const LayoutsTemplateRender = ({
  onSettingsClick,
  dataGridRef,
  glideSession,
  updateColumnManagerColumns,
  webLayouts,
  changePivotGridLayout,
  components,
  clientViewUri,
  createLayout,
  updateLayout,
}: LayoutsTemplateRender) => {
  const viewUri = clientViewUri;
  const client_view_type = webLayouts?.clientViewType;
  if (
    !viewUri ||
    !dataGridRef ||
    (client_view_type === 'Grid' && !dataGridRef?.current?.instance.hasOwnProperty('state'))
  ) {
    return null;
  }

  const [isCreatingANewLayout, setIsCreatingANewLayout] = useState(false);
  const [newLayoutName, setNewLayoutName] = useState('');
  const [visibleLayouts, setVisibleLayouts] = useState<ILayout[] | undefined>(undefined);
  const [selectedLayout, setSelectedLayout] = useState<ILayout | undefined>(undefined);
  const [openMenu, setOpenMenu] = useState<any>(null);

  useEffect(() => {
    if (
      (!visibleLayouts || !isEqual(visibleLayouts, webLayouts?.layouts)) &&
      webLayouts?.layoutSelected &&
      webLayouts?.layouts
    ) {
      setVisibleLayouts([...moveSelectedToFirstPosition(webLayouts?.layoutSelected, webLayouts?.layouts)]);
    }
    if (webLayouts?.layoutSelected) {
      setSelectedLayout(webLayouts?.layoutSelected);
    }
    if (!webLayouts?.layoutSelected) {
      if (client_view_type === ClientViewTypeLookUps.Pivot) {
        setSelectedLayout(dataGridRef.current.instance.getDataSource().state());
        dataGridRef.current.instance.getDataSource().state(null);
      } else {
        if (!components.viewComponents[viewUri]?.gridLayout?.hasChanges) {
          dataGridRef.current.instance.state(null);
        }
      }
    }
  }, [webLayouts?.layoutSelected, webLayouts?.layouts, viewUri]);

  useEffect(() => {
    if (selectedLayout && Object.keys(selectedLayout).length !== 0) {
      const gridState =
        client_view_type === ClientViewTypeLookUps.Pivot
          ? dataGridRef.current.instance.getDataSource().state()
          : dataGridRef.current.instance?.state();

      if (gridState?.uri !== selectedLayout?.uri) {
        if (client_view_type === ClientViewTypeLookUps.Pivot) {
          dataGridRef.current.instance.getDataSource().state({
            ...selectedLayout.data?.json_layout,
            uri: selectedLayout.uri,
          });
          changePivotGridLayout &&
            changePivotGridLayout({
              ...selectedLayout?.data?.json_layout,
              uri: selectedLayout?.uri,
            });
        } else {
          dispatchActions.components.updateView('gridLayout', viewUri, {
            selectedLayout: {
              ...selectedLayout?.data?.json_layout,
              uri: selectedLayout?.uri,
            },
          });

          dataGridRef.current.instance.state({
            ...selectedLayout?.data?.json_layout,
            uri: selectedLayout?.uri,
          });
        }
        updateColumnManagerColumns && updateColumnManagerColumns();
      }
    }
  }, [selectedLayout?.uri, dataGridRef.current]);

  const onShowNewLayoutInput = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setIsCreatingANewLayout(true);
  };

  const updateLayouts = (webLayouts: ILayout[], layout: ILayout) =>
    webLayouts.map((e: ILayout) => {
      if (e.uri === layout.uri) return layout;
      return e;
    });

  const onKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();

    if (event.key === 'Enter') {
      onSaveNewLayout();
    }
    if (event.key === 'Escape') {
      setIsCreatingANewLayout(false);
      setOpenMenu(null);
    }
  };

  const onFilterChange = (value: string) => {
    if (!webLayouts?.layouts || !selectedLayout) return;
    if (!value) {
      setVisibleLayouts([...moveSelectedToFirstPosition(selectedLayout, webLayouts?.layouts)]);
      return;
    }
    const nextVisibleLayouts = webLayouts?.layouts.filter((layout: ILayout) =>
      layout.data.name.toLowerCase().includes(value.toLowerCase()),
    );
    setVisibleLayouts([...nextVisibleLayouts]);
  };

  const onClearLayoutClick = () => {
    client_view_type === ClientViewTypeLookUps.Pivot
      ? dataGridRef.current.instance.getDataSource().state(selectedLayout?.data?.json_layout)
      : dataGridRef.current.instance.state({
          ...selectedLayout?.data?.json_layout,
          uri: selectedLayout?.uri,
        });

    changePivotGridLayout && changePivotGridLayout(selectedLayout?.data?.json_layout);

    layoutChangeGridUpdates(selectedLayout);

    dispatchActions.components.updateView('gridLayout', viewUri, {
      hasChanges: false,
    });

    client_view_type !== ClientViewTypeLookUps.Pivot
      ? dataGridRef.current.instance.searchByText(selectedLayout?.data.json_layout.searchText)
      : null;
  };

  const onCloseNewLayout = () => {
    setIsCreatingANewLayout(false);
    setNewLayoutName('');
  };

  const layoutChangeGridUpdates = (layout: ILayout | undefined) => {
    if (client_view_type === ClientViewTypeLookUps.Pivot) return;
    dataGridRef.current.instance.searchByText(layout?.data?.json_layout?.searchText);
    if (!layout?.data?.json_layout?.filterValue) dataGridRef.current.instance.clearFilter('row');
  };

  const handleLayoutClick = (layout: ILayout) => {
    if (!webLayouts?.layouts) return;
    localStorage.setItem(`selected-layout-${viewUri}-${client_view_type}`, layout?.uri as string);
    setSelectedLayout(layout);
    client_view_type === ClientViewTypeLookUps.Pivot
      ? dataGridRef.current.instance.getDataSource().state({ ...layout?.data?.json_layout, uri: layout?.uri })
      : dataGridRef.current.instance.state({
          ...layout?.data?.json_layout,
          uri: selectedLayout?.uri,
        });
    layoutChangeGridUpdates(layout);
    setVisibleLayouts([...moveSelectedToFirstPosition(layout, webLayouts?.layouts)]);
    dispatchActions.components.updateView('gridLayout', viewUri, {
      hasChanges: false,
    });
  };

  const onSaveChanges = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (selectedLayout && visibleLayouts) {
      const layoutToSave = {
        ...selectedLayout,
        data: {
          ...selectedLayout.data,
          json_layout:
            client_view_type === ClientViewTypeLookUps.Pivot
              ? dataGridRef.current.instance.getDataSource().state()
              : dataGridRef.current.instance.state(),
        },
      };
      setVisibleLayouts([...updateLayouts(visibleLayouts, layoutToSave)]);
      client_view_type === ClientViewTypeLookUps.Pivot
        ? dataGridRef.current.instance.getDataSource().state(layoutToSave.data.json_layout)
        : dataGridRef.current.instance.state(layoutToSave.data.json_layout);

      updateLayout({ layoutToSave: layoutToSave, uri: clientViewUri as string });
      dispatchActions.components.updateView('gridLayout', viewUri, {
        hasChanges: false,
      });
    } else {
      if (components.viewComponents[viewUri]?.gridLayout?.hasChanges) {
        setIsCreatingANewLayout(true);
        setOpenMenu(e.currentTarget);
      }
    }
  };

  const onSaveNewLayout = () => {
    if (newLayoutName) {
      const currentGridConfig =
        client_view_type === ClientViewTypeLookUps.Pivot
          ? dataGridRef.current.instance.getDataSource().state()
          : dataGridRef.current.instance.state();
      const layoutToSave = {
        data: {
          name: newLayoutName,
          json_layout: currentGridConfig ?? {},
        },
        uri: newLayoutName,
        date: new Date().toISOString(),
      };
      createLayout({ layoutToSave: layoutToSave, uri: clientViewUri as string });
      setNewLayoutName('');
    }
    onCloseNewLayout();
  };

  const defaultLayoutName = 'Default';
  const activeLayoutName = selectedLayout?.data?.name;
  const isDefaultLayoutSet = activeLayoutName === defaultLayoutName;
  const isDemoAdminUser = glideSession?.user?.roles?.includes('administrator');
  const disableSaveOnDefaultEdit = isDefaultLayoutSet ? !isDemoAdminUser : false;
  const gridHasChanges = !components?.viewComponents[viewUri]?.gridLayout?.hasChanges;
  const onClose = () => {
    setIsCreatingANewLayout(false);
    setOpenMenu(null);
  };
  const onNewTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => setNewLayoutName(e.target.value);
  return (
    <S.LayoutButtonWrapper>
      <Badge color="secondary" variant="dot" invisible={gridHasChanges} className="layout-button-badge">
        <DropdownMenu
          button={
            <S.LayoutButton data-testid="layout-dropdown" dark={components.global.isDarkTheme}>
              <LayoutsIcon style={{ height: '14px' }} />
              {getDropdownText(webLayouts?.layouts ? webLayouts?.layouts?.length : 0, selectedLayout)} <ArrowDropDown />
            </S.LayoutButton>
          }
          onClose={onClose}
          customAnchorEl={openMenu}
          fixAnchor={openMenu ? true : false}
        >
          {/* DROPDOWN FILTER */}
          <DropdownMenuItem
            style={{
              minWidth: '240px',
              maxWidth: '400px',
              backgroundColor: 'var(--bgDark)',
            }}
          >
            <Search
              data-testid="layouts-filter-input"
              style={{ input: { backgroundColor: 'var(--label)' } }}
              onChange={onFilterChange}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          </DropdownMenuItem>
          {/* LAYOUTS LIST */}
          <div style={{ maxHeight: '270px', overflow: 'overlay' }}>
            {visibleLayouts &&
              visibleLayouts.map((layout: any) => (
                <DropdownMenuItem
                  style={{ height: '32px' }}
                  active={Boolean(selectedLayout && layout.data.name === selectedLayout?.data?.name)}
                  key={layout.uri}
                  data-testid="dropdown-option"
                  onClick={() => handleLayoutClick(layout)}
                >
                  <DropdownMenuItemText>{layout.data.name}</DropdownMenuItemText>
                </DropdownMenuItem>
              ))}
          </div>
          {/* DROPDOWN MENU FOOTER */}
          <div style={{ minWidth: '240px', backgroundColor: 'var(--bgDark)' }}>
            <DropdownMenuItem
              style={{
                minWidth: '100px',
                maxWidth: '400px',
                paddingTop: visibleLayouts?.length ? '10px' : '0px',
                paddingBottom: '10px',
              }}
            >
              {isCreatingANewLayout ? (
                <S.LayoutDropdownButtonWrapper>
                  <StyledInput
                    data-testid="new-layout-input"
                    onChange={onNewTextChanged}
                    onKeyDown={onKeyDown}
                    onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  />
                  <ConfirmInputButton
                    disabled={newLayoutName === '' || newLayoutName === 'Default'}
                    onClick={onSaveNewLayout}
                  >
                    <Done style={{ color: 'var(--text)', fontSize: '16px' }} />
                  </ConfirmInputButton>
                </S.LayoutDropdownButtonWrapper>
              ) : (
                <S.LayoutDropdownButtonWrapper>
                  <SecondaryButton data-testid="new-layout-btn" onClick={onShowNewLayoutInput}>
                    + Create Layout
                  </SecondaryButton>
                  <S.IconButton onClick={onSettingsClick} data-testid="layout-manager-btn">
                    <Settings style={{ width: '22px', color: 'var(--text)' }} />
                  </S.IconButton>
                </S.LayoutDropdownButtonWrapper>
              )}
            </DropdownMenuItem>
          </div>
        </DropdownMenu>
      </Badge>

      {!gridHasChanges && (
        <S.SaveCancelBtnWrapper className="save-cancel-btn-wrapper">
          <S.IconButton
            style={{ padding: '1px', backgroundColor: 'var(--fis-green)' }}
            onClick={onSaveChanges}
            disabled={disableSaveOnDefaultEdit}
            data-testid="layoutsave-btn"
          >
            <Tooltip title="Save layout changes">
              <Save
                style={{
                  width: '22px',
                  color: disableSaveOnDefaultEdit ? 'var(--accent-grey)' : 'var(--bgLight)',
                }}
              />
            </Tooltip>
          </S.IconButton>

          <S.IconButton
            style={{
              padding: '1px',
              backgroundColor: 'var(--grey)',
              marginLeft: '5px',
            }}
            onClick={onClearLayoutClick}
            data-testid="layoutdiscard-btn"
          >
            <Tooltip title="Discard layout changes">
              <Clear style={{ width: '24px', color: 'var(--bgLight)' }} />
            </Tooltip>
          </S.IconButton>
        </S.SaveCancelBtnWrapper>
      )}
    </S.LayoutButtonWrapper>
  );
};

const mapStateToProps = (state: RootState, ownProps: any): LayoutTemplateReduxProps => ({
  glideSession: authSelectors.glideSession(state),
  webLayouts: layoutSelector(state, ownProps.clientViewUri),
  components: selectComponents(state),
});

const mapDispatchToProps = (dispatch: Dispatch): LayoutTemplateReduxDispatch => ({
  createLayout: (layout: Layout) => dispatch(createLayout(layout)),
  updateLayout: (layout: Layout) => dispatch(updateLayout(layout)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutsTemplateRender);
