import styled from 'styled-components';
import { zIndex } from 'src/utils/constants';
import Button from '@material-ui/core/Button';
import React, { FunctionComponent } from 'react';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { dispatchActions } from 'src/app/store';
import { RootState } from 'src/reducers';
import { ComponentName, Components, selectComponents } from 'src/reducers/components';
import { activeTabSelector } from 'src/reducers/tabs';
import { connect } from 'react-redux';

export const CollapseWrapper = styled.div`
  z-index: ${zIndex.CollapseWrapper};
  width: var(--width-inspector);
`;

export const CollapseButtonWrapper = styled.div<{ isExpanded: boolean }>`
  position: absolute;
  right: ${props => (props.isExpanded ? '469px' : '1px')};
`;

export const CollapseButton = styled(Button)`
  height: 25vh;
  width: 18px;
  min-width: 18px;
  padding: 1px;
  z-index: ${zIndex.CollapseWrapperBtn};
  border-radius: inherit;
  background-color: var(--blue);
  top: 33vh;
  &:hover {
    background-color: var(--light-blue);
  }
`;

interface ReduxProps {
  clientViewUri: string;
  components: Components;
}

interface CollapserProps {
  componentName: ComponentName;
  children: any;
}

const Collapser: FunctionComponent<CollapserProps & ReduxProps> = ({
  componentName,
  children,
  clientViewUri,
  components,
}) => {
  const component = components.viewComponents?.[clientViewUri]?.[componentName] || components?.[componentName];
  const isCollapsed = component.isCollapsed;
  const onTogglePanel = () =>
    dispatchActions.components.updateView(componentName, clientViewUri, { isCollapsed: !isCollapsed });

  return (
    <>
      <CollapseButtonWrapper isExpanded={!isCollapsed}>
        <CollapseButton onClick={onTogglePanel} disableRipple>
          {isCollapsed ? <ChevronLeft style={{ color: 'white' }} /> : <ChevronRight style={{ color: 'white' }} />}
        </CollapseButton>
      </CollapseButtonWrapper>
      {!isCollapsed && <CollapseWrapper>{children}</CollapseWrapper>}
    </>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  components: selectComponents(state),
  clientViewUri: activeTabSelector(state),
});

export default connect(mapStateToProps)(Collapser);
