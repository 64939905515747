import List from '@material-ui/core/List';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { Route } from 'src/page/models/route';
import SideMenuItem from './SideMenuItem';

const SideMenuList: React.SFC<{
  routes: Route;
  onClick: (fullPath?: string, subroute?: Route) => void;
  menuItemColor?: string;
}> = ({ routes, onClick, menuItemColor }) => {
  const subroutes = routes.subroutes && sortBy(routes.subroutes, route => route.name);
  return (
    <List data-testid="sidemenu-list">
      {subroutes?.map((route, index) => (
        <SideMenuItem
          data-testid="sidemenu-item"
          {...route}
          key={`route_${route.name}_${index}`}
          onClick={onClick}
          menuItemColor={menuItemColor}
        />
      ))}
    </List>
  );
};

export default SideMenuList;
