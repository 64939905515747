import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import * as S from './style';
import { InspectorFooter } from '@virtus/components/Inspector';
import { TertiaryButton } from '@virtus/components/Buttons';
import HtmlEditor from '@virtus/components/HTMLEditor';
import SelectMenu from '@virtus/components/SelectMenu';
import styled from 'styled-components';
import { StyledWarningIcon } from '../forms/form-elements/FormElements.style';
import { mutateAsync, requestAsync } from 'redux-query';
import { Glide, GlideCommentaryApi } from 'src/api/queries';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RootState } from 'src/reducers';
import { authSelectors, GlideSession } from '@virtus/common/auth/reducer';
import { formatUserName } from 'src/utils/formatters';
import { ANALYST_COMMENT_CATEGORY_MAP, ANALYST_COMMENT_TYPE_MAP } from './glide-commentary.util';
export type GlideCommentaryProps = {
  title: string;
  username: string;
  comments: string;
  commentdate: number;
  category: string;
  creditUri: string;
  addComment?: boolean;
  placeholder?: string;
  commentaryDataCallback?: (commentaryDataCallbackData?: any) => void;
  componentType?: string;
  commenturi?: string;
  fromModal: boolean;
  inspectorOpen?: boolean;
};

export interface DefaultCommentaryRequestProps {
  type: string;
  creditUri: string;
  fromModal: boolean;
  inspectorOpen: boolean;
}
export interface DeleteCommentaryRequestProps extends DefaultCommentaryRequestProps {
  uri: string;
}
export interface AddCommentaryRequestProps extends DefaultCommentaryRequestProps {
  body: any;
}

export interface ReduxProps {
  readonly commentaryData: any;
  readonly newCommentaryUri: any;
  readonly glideSession: GlideSession;
  readonly isCreditDetailsUriFetching: boolean;
}
export interface ReduxDispatch {
  addCommentaryData: (data: AddCommentaryRequestProps) => any;
  updateCommentaryData: (data: any, type: string) => any;
  deleteCommentary: (data: DeleteCommentaryRequestProps) => any;
}

const GlideCommentaryComponent = ({
  title,
  username,
  comments,
  commentdate,
  category,
  addComment,
  placeholder,
  commentaryDataCallback,
  commentaryData,
  updateCommentaryData,
  addCommentaryData,
  glideSession,
  deleteCommentary,
  creditUri,
  fromModal,
  isCreditDetailsUriFetching,
  componentType = '',
  commenturi = '',
  inspectorOpen = false,
}: GlideCommentaryProps & ReduxDispatch & ReduxProps) => {
  const AdditionalActions = () => (
    <TertiaryButton disabled={addComment} onClick={onDelete}>
      Delete
    </TertiaryButton>
  );
  const [isEditing, setEditing] = useState(false);
  const onDelete = () => {
    if (!commenturi) return;
    deleteCommentary({
      uri: commenturi,
      type: ANALYST_COMMENT_TYPE_MAP[componentType as ANALYST_COMMENT_TYPE_MAP],
      inspectorOpen,
      fromModal,
      creditUri,
    }).then(
      () =>
        commentaryDataCallback &&
        commentaryDataCallback({
          category: ANALYST_COMMENT_TYPE_MAP[componentType as ANALYST_COMMENT_TYPE_MAP],
        }),
      setOpenEditor(false),
    );
  };
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const showEditor = () => addComment || openEditor;
  const primaryButtonText = () => (isEditing && showEditor() ? 'Update' : 'Add');

  const [htmlEditorValue, setHtmlEditorValue] = useState('');
  const onContentChanged = (e: any) => {
    setErrorComments(false);
    setHtmlEditorValue(e.value);
  };

  const [commentCategory, setcommentCategory] = useState<number>(0);
  const handleCategory = (event: any) => {
    setcommentCategory(event.target.value);
  };
  const [errorComments, setErrorComments] = useState(false);

  useEffect(() => {
    if (addComment) {
      setHtmlEditorValue('');
      const index = categoryDropDownList
        ? categoryDropDownList.findIndex(
            item => item.text === ANALYST_COMMENT_CATEGORY_MAP[componentType as ANALYST_COMMENT_CATEGORY_MAP],
          )
        : 0;
      setcommentCategory(index);
    }
  }, [addComment]);

  const AddComments = () =>
    addCommentaryData({
      body: {
        object_type: 'analyst_comment',
        object_data: {
          analyst: 'users/' + glideSession.username,
          analyst_comment: htmlEditorValue,
          analyst_comment_type:
            ANALYST_COMMENT_CATEGORY_MAP[categoryDropDownList[commentCategory].text as ANALYST_COMMENT_CATEGORY_MAP],
        },
      },
      type: ANALYST_COMMENT_TYPE_MAP[categoryDropDownList[commentCategory].text as ANALYST_COMMENT_TYPE_MAP],
      inspectorOpen,
      fromModal,
      creditUri,
    });

  const UpdateComments = () =>
    updateCommentaryData(
      {
        [commenturi ? commenturi : '']: {
          analyst: 'users/' + glideSession.username,
          analyst_comment: htmlEditorValue,
          analyst_comment_type:
            ANALYST_COMMENT_CATEGORY_MAP[categoryDropDownList[commentCategory].text as ANALYST_COMMENT_CATEGORY_MAP],
        },
      },
      ANALYST_COMMENT_TYPE_MAP[componentType as ANALYST_COMMENT_TYPE_MAP],
    );
  const onApply = async () => {
    if (!htmlEditorValue.replace(/<[^>]+>/g, '')) {
      return setErrorComments(true);
    }

    if (primaryButtonText() === 'Add') {
      AddComments().then((data: any) => {
        const uri = data ?? data.body ?? data.body.uri ?? null;
        if (uri) {
          commentaryDataCallback &&
            commentaryDataCallback({
              category:
                ANALYST_COMMENT_TYPE_MAP[categoryDropDownList[commentCategory].text as ANALYST_COMMENT_TYPE_MAP],
              htmlEditorData: htmlEditorValue,
              uri: uri[0],
              username: 'users/' + glideSession.username,
            });
        }
      });
    } else {
      setOpenEditor(false);
      setEditing(false);
      UpdateComments().then(
        () =>
          commentaryDataCallback &&
          commentaryDataCallback({
            category: ANALYST_COMMENT_TYPE_MAP[categoryDropDownList[commentCategory].text as ANALYST_COMMENT_TYPE_MAP],
            htmlEditorData: htmlEditorValue,
            uri: commenturi,
            username: 'users/' + glideSession.username,
          }),
      );
    }

    setErrorComments(false);
  };
  const onCancel = () => {
    commentaryDataCallback && commentaryDataCallback();
    setOpenEditor(false);
    setEditing(false);
    setHtmlEditorValue('');
    setErrorComments(false);
  };

  const categoryDropdownData = () => {
    const categoryDataDuplicateCheck: string[] = [];
    const categoryData: any = [];
    commentaryData && commentaryData.length > 0
      ? commentaryData.forEach((data: any) => {
          if (data.category) {
            if (categoryDataDuplicateCheck.includes(data.category) === false) {
              categoryDataDuplicateCheck.push(data.category);
              categoryData.push({ id: categoryData.length, text: data.category });
            }
          }
        })
      : categoryData.push({ id: 0, text: 'Blog' }, { id: 1, text: 'KPIs' });
    if (!categoryDataDuplicateCheck.includes('Covenant')) {
      categoryData.push({ id: categoryData.length, text: 'Covenant' });
    }
    return categoryData;
  };

  const categoryDropDownList: { id: number; text: string }[] = categoryDropdownData();
  const formatComments = (comments: string) => {
    if (comments) {
      const commentsSplitted = comments.split('--');
      return commentsSplitted[0];
    }
    return '';
  };
  const Error = styled.div`
    width: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
  `;

  const onEdit = () => {
    setOpenEditor(true);
    setHtmlEditorValue(comments);
    setEditing(true);
  };

  return (
    <>
      {showEditor() ? (
        <S.DetailsWrapper>
          <S.Input value={htmlEditorValue.replace(/<[^>]+>/g, '')} placeholder="Add Title" readOnly />
          <SelectMenu
            value={commentCategory}
            onChange={handleCategory}
            name="groupByBlogs"
            options={categoryDropDownList}
            valueKey="id"
            displayKey="text"
            style={{ width: '130px', marginBottom: '2%' }}
            classes={{ paperStyle: 'miui-select-paper-style' }}
          />
          <div style={{ display: 'flex', flex: 1 }}>
            {errorComments && (
              <Error>
                <Tooltip
                  title="Comment is required"
                  style={{
                    background: 'var(--red)',
                    color: 'var(--text)',
                    fontSize: '25px',
                    marginRight: '12px',
                  }}
                >
                  <StyledWarningIcon data-testid="alert-icon" />
                </Tooltip>
              </Error>
            )}
            <HtmlEditor
              onValueChanged={onContentChanged}
              content={htmlEditorValue}
              placeholder={placeholder}
              error={errorComments}
            />
          </div>
          <InspectorFooter
            onCancel={onCancel}
            disabled={false}
            onApply={onApply}
            confirmButtonText={primaryButtonText()}
            hideCancel={false}
            children={AdditionalActions()}
          />
        </S.DetailsWrapper>
      ) : (
        comments && (
          <S.DetailsWrapper>
            <S.TitleWrapper>
              <Tooltip title={title}>
                <span>{title}</span>
              </Tooltip>
            </S.TitleWrapper>
            <S.SubTitleWrapper>
              <S.TextWrapper weight="normal" size={14}>
                {formatUserName(username)}
              </S.TextWrapper>
              <S.TextWrapper weight={300} size={14} color="var(--subtitle)">
                {new Date(commentdate).toLocaleString()}
              </S.TextWrapper>
            </S.SubTitleWrapper>
            <S.CategoryWrapper>
              <S.TextWrapper>{category}</S.TextWrapper>
            </S.CategoryWrapper>
            <S.ContentWrapper dangerouslySetInnerHTML={{ __html: formatComments(comments) }} />
            <InspectorFooter
              onCancel={onCancel}
              disabled={isCreditDetailsUriFetching}
              onApply={onEdit}
              confirmButtonText="Edit"
              hideCancel={true}
              children={AdditionalActions()}
            />
          </S.DetailsWrapper>
        )
      )}
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: any): ReduxProps => {
  const { componentType } = ownProps;
  return {
    commentaryData: GlideCommentaryApi.CommentarySelector(state, componentType),
    newCommentaryUri: GlideCommentaryApi.newCommentaryUri(state) || {},
    glideSession: authSelectors.glideSession(state),
    isCreditDetailsUriFetching: Glide.instanceUripending(state, 'credit_detail'),
  };
};

const mapDispatchToProps = (dispatch: any): ReduxDispatch => ({
  updateCommentaryData: (body: any, type: string) =>
    dispatch(mutateAsync(GlideCommentaryApi.updateCommentary(body, type))),
  addCommentaryData: (requestPayload: AddCommentaryRequestProps) =>
    dispatch(requestAsync(GlideCommentaryApi.submitNewComments(requestPayload))),
  deleteCommentary: (requestPayload: DeleteCommentaryRequestProps) =>
    dispatch(mutateAsync(GlideCommentaryApi.deleteCommentary(requestPayload))),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(GlideCommentaryComponent);
