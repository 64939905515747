import { SnackbarContent as MUISnackbarContent } from '@material-ui/core';
import { NotificationType } from 'src/SnackBarNotification/SnackBarContent';
import styled from 'styled-components';

export const SnackbarContent = styled(MUISnackbarContent)`
  /* Override MUI default */
  max-width: fit-content; /* disable MUI break points */
  & > div {
    padding: 0;
    font-size: 14px;
  }

  button:hover {
    background-color: transparent;
  }

  [aria-label='close'] {
    position: absolute;
    top: 3px;
    right: 10px;
  }

  /* Styled content */
  color: var(--notificationText);
  margin: 1em 1.4em;
  padding: 0;
  max-height: fit-content;
  background: var(--bgLight);

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: space-between;
    color: var(--default-blue);
    width: 100%;
    cursor: pointer;
    li {
      margin-left: 1rem;
    }
    li:first-of-type {
      margin-left: 0;
    }
  }
`;

export const Container = styled.div`
  font-size: 1em;
  display: flex;
  flex-direction: row;
`;

const headerBgColor: { [key in NotificationType]: string } = {
  info: 'var(--default-blue)',
  error: 'var(--red)',
  warning: 'var(--virtus-orange)',
  success: 'var(--green)',
};

export const Header = styled.div<{ type: NotificationType }>`
  display: flex;
  width: 28px;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  background-color: ${props => headerBgColor[props.type || 'info']};
`;

export const Body = styled.div`
  flex-direction: column;
  padding: 0.4rem;
  background: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-weight: bold;
  margin-right: 2em;
`;

export const Message = styled.div`
  white-space: pre-line;
`;
