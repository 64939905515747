import Button, { ButtonProps } from '@material-ui/core/Button';
import styled from 'styled-components';

export type BasicButtonProps = ButtonProps;

const BasicButton = styled(Button)<{ disableMarginLeft?: boolean; backgroundColor?: string }>`
  color: var(--text);
  text-transform: none;
  height: 28px;
  max-height: 28px;
  border-radius: 2px;
  margin: 0 5px;
  &:disabled {
    background-color: ${props => props.backgroundColor || 'var(--grey)'};
    color: var(--subtitle);
  }
` as React.ComponentType<BasicButtonProps>;

export default BasicButton;
