import NetworkInterface from '@virtus/common/auth/NetworkInterface';
import { MockRoute } from '@virtus/common/utils/loadMockData';
import { applyMiddleware, bindActionCreators, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  mutateAsync,
  NetworkInterface as ReduxQueryNetworkInterface,
  queryMiddleware,
  requestAsync,
} from 'redux-query';
import createSagaMiddleware from 'redux-saga';
import config from 'src/config';
import rootReducer, { RootState } from 'src/reducers/rootReducer';
import { runAllSagas } from 'src/sagas/rootSaga';
import {
  resetComponentAction,
  toggleComponentDisplayAction,
  toggleComponentViewDisplayAction,
  updateComponentAction,
  updateComponentViewAction,
} from 'src/reducers/components';
import { glideQuery, GlideQueryStatic, GlideQueryView } from 'src/api/query';

export const getQueries = (state: RootState) => state.queries;
export const getEntities = (state: RootState) => state.entities;
export let store: Store;

/**
 * Initialises common redux dispatches
 */
export let dispatchActions: any;

/** setup saga and return store ready for <Provider /> */
export const initStore = (mockRoutes?: MockRoute): Store => {
  const sagaMiddleware = createSagaMiddleware();
  const portalNetworkInterface: NetworkInterface = new NetworkInterface({ ...config, mockRoutes });
  const reduxQueryMiddleware = queryMiddleware(
    portalNetworkInterface.reduxQueryNetworkInterface.bind(portalNetworkInterface) as ReduxQueryNetworkInterface,
    getQueries,
    getEntities,
  );
  const middleware = [reduxQueryMiddleware, sagaMiddleware];
  //TODO: restrict to internal envs
  // @ts-ignore
  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
  store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));
  runAllSagas(sagaMiddleware);
  dispatchActions = {
    components: bindActionCreators(
      {
        update: updateComponentAction,
        updateView: updateComponentViewAction,
        toggleDisplay: toggleComponentDisplayAction,
        toggleViewDisplay: toggleComponentViewDisplayAction,
        reset: resetComponentAction,
      },
      store.dispatch,
    ),
    db: bindActionCreators(
      {
        fetch: (glideQueryParams: GlideQueryStatic) => requestAsync(glideQuery(glideQueryParams)),
        fetchView: (glideQueryParams: GlideQueryView) => requestAsync(glideQuery(glideQueryParams)),
        update: (glideQueryParams: GlideQueryStatic) => mutateAsync(glideQuery(glideQueryParams)),
        updateView: (glideQueryParams: GlideQueryView) => mutateAsync(glideQuery(glideQueryParams)),
      },
      store.dispatch,
    ),
  };
  return store;
};
