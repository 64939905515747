import React, { Dispatch, SetStateAction, useState, useRef, useEffect } from 'react';
import { SelectedRowDataType } from 'src/components/grids/dxgrid-client-view';

interface UseCart {
  cartItems: SelectedRowDataType[];
  setCartItems: Dispatch<SetStateAction<SelectedRowDataType[]>>;
  onDeleteCartItem: (assetToDelete: SelectedRowDataType) => void;
  onDeleteAllCartItems: () => void;
  onAddToCart: (cellData: any) => void;
  setSelectedRowIndexes: Dispatch<SetStateAction<number[]>>;
}

export const useCart = (dataGridRef: React.Ref<any>): UseCart => {
  const [cartItems, setCartItems] = useState<SelectedRowDataType[]>([]);
  const [selectedRowIndexes, setSelectedRowIndexes] = useState<number[]>([]);

  const selectedRowRef = useRef(selectedRowIndexes);

  useEffect(() => {
    selectedRowRef.current = selectedRowIndexes;
  }, [selectedRowIndexes]);

  const onDeleteCartItem = (assetToDelete: SelectedRowDataType) => {
    (dataGridRef as any).current.instance.repaintRows([assetToDelete.id]);
    setCartItems((oldCartItems: SelectedRowDataType[]) =>
      oldCartItems.filter((asset: SelectedRowDataType) => asset._uri !== assetToDelete._uri),
    );
  };

  const onDeleteAllCartItems = () => {
    (dataGridRef as any).current.instance.selectRowsByIndexes([], true);
    setCartItems([]);
    (dataGridRef as any).current.instance.refresh();
  };

  const onAddToCart = (cellData: any) => {
    const rowData = cellData.row.data;
    const rowIndex = cellData.row.rowIndex;

    if (selectedRowRef.current.findIndex(el => el === rowIndex) > -1) {
      cellData.event.target.classList.replace('dx-icon-trash', 'dx-icon-plus');
      cellData.event.target.title = 'Add';
      setSelectedRowIndexes(oldSelectedRowIndexes => oldSelectedRowIndexes.filter(el => el !== rowIndex));
      onDeleteCartItem(rowData);
    } else {
      setSelectedRowIndexes(oldSelectedRowIndexes => [...oldSelectedRowIndexes, rowIndex]);
      cellData.event.target.classList.replace('dx-icon-plus', 'dx-icon-trash');
      cellData.event.target.title = 'Delete';

      setCartItems((oldCartItems: SelectedRowDataType[]) => [
        ...oldCartItems,
        {
          'Display Name': rowData['Display Name'],
          id: rowData['_id_'],
          _uri: rowData._uri,
        },
      ]);
    }
  };

  return {
    onDeleteAllCartItems,
    onDeleteCartItem,
    cartItems,
    setCartItems,
    onAddToCart,
    setSelectedRowIndexes,
  };
};
