/** colors used in apps */
export const colors = {
  bg: '#293a49',
  bgDark: '#1c252e',
  bgHeader: '#f8f8f8',
  bgLight: '#ffffff',
  light: '#f57051',
  accent: '#0d83c9',
  text: '#ffffff',
  textDark: '#000',
  errorLight: '#c0392b',
  error: '#af0319',
  errorDark: '#7b1427',
  errorPink: 'rgba(242, 0, 0, .08)',
  link: '#16b3d3',
  reset: '#08679e',
  subtitle: 'rgba(255,255,255,.5)',
  dataGridHeaderText: 'rgba(0, 0, 0, 0.54)',
  resetButton: '#08679e',
  inspectorSubtitle: 'rgba(255,255,255,.5)',
  dataGridExpandedRow: '#d6edf1',
  dataGridInnerBorder: '#ddd',
  dataGridSelectedRow: '#E6F2F9',
  blue: '#0d83c9',
  label: 'rgba(255,255,255,0.2)',
  grey: 'rgba(255, 255, 255, 0.2)',
  highlightBlue: 'rgba(13, 131, 201, 0.1)',
  // Zeplin color palette
  nexusOrange: '#f57051',
  defaultBlue: '#0d83c9',
  lightBlue: '#22a0f4',
  darkBlue: '#1c252e',
  accentDarkBlue: '#333b43',
  background: '#293a49',
  accentGray: '#fafafa',
  red: '#f20000',
  green: '#01b494',
  glideYellow: '#f39200',
};

export const fontFamily = '"Nunito Sans", "Roboto", "Helvetica Neue", sans-serif';
/** various common css sizes (eg font size) */
export const variables = {
  colWidth: '150px',
  cellHeight: '48px',
  checkboxFontSize: '16px',
  headerIconFontSize: '30px',
  headerHeight: '40px',
  iconColWidth: '40px',
  inspectorHeaderHeight: '48px',
  inspectorFooterHeight: '48px',
};
