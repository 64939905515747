import React, { CSSProperties, FC, ReactNode } from 'react';
import { PrimaryButton } from 'src/Buttons';
import { Footer } from './InspectorFooter.style';
import CancelButton from 'src/Buttons/CancelButton/CancelButton';

export type InspectorFooterProps = {
  onApply?: () => void;
  onCancel?: () => void;
  disabled?: boolean;
  hideCancel?: boolean;
  hideConfirm?: boolean;
  confirmButtonText?: string;
  actions?: ReactNode;
  hideActions?: boolean;
  children?: ReactNode;
  buttonsContainerStyle?: CSSProperties;
};

export const DefaultButtonsContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
};

const InspectorFooter: FC<InspectorFooterProps> = ({
  onCancel,
  onApply,
  disabled,
  hideCancel,
  hideConfirm,
  confirmButtonText = 'Apply',
  actions,
  hideActions,
}) => {
  return (
    <Footer data-testid="inspector-footer">
      {!hideActions && actions && <section>{actions}</section>}
      {!hideCancel && (
        <CancelButton data-testid="secondary-btn" onClick={onCancel} color="inherit">
          Cancel
        </CancelButton>
      )}
      {!hideConfirm && (
        <PrimaryButton
          data-testid="primary-btn"
          onClick={onApply}
          disabled={disabled}
          variant="contained"
          color="secondary"
          type="submit"
        >
          {confirmButtonText}
        </PrimaryButton>
      )}
    </Footer>
  );
};

export default InspectorFooter;
