export const DEFAULT_PAGE_SIZES = [20, 50, 100, 200, 500, 1000];
export const populatePageSizes = (recordsAmount: number, pageSizesArray: number[] = DEFAULT_PAGE_SIZES) => {
  let hasAll = false;
  const newOptions = pageSizesArray.reduce((prev: any, curr: number) => {
    if (hasAll) return prev;
    if (recordsAmount / curr <= 1) {
      hasAll = true;
    }
    prev.push(curr);
    return prev;
  }, []);
  return newOptions;
};
