import arrayToObject from '@virtus/common/utils/arrayToObject';
import ExcelJS, { Alignment, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import flow from 'lodash/flow';
import merge from 'lodash/merge';
import {
  CustomExportParams,
  DxDataGridInstance,
  ExcelCellTransform,
  ExportColumn,
  ExportDataSource,
  ExportSchemaColumn,
  SummaryCell,
} from 'src/DxDataGrid/utils/exportDataGrid/exportDataGrid.model';
import { DataSourceType } from 'src/DxDataGrid/utils/mapSchema';
import { dataTypeMap, NexusDataType, parseDataTypeValue } from 'src/DxDataGrid/utils/mapSchemaVirtus';
import { getCellExcelNumeralFormat, getHorizontalAlignment } from 'src/ExportToExcel/ExportToExcel';
import {
  DefaultColumnWidth,
  DescriptionConfig,
  FooterInfoConfig,
  GroupCellConfig,
  HeaderCellConfig,
  TitleConfig,
} from 'src/ExportToExcel/ExportToExcelConfig';
import { getNexusDataType } from 'src/parsers/parseDataTypeValueVirtus';

const COLUMN_HEADER_CONFIG = {
  alignment: HeaderCellConfig.alignment,
  border: HeaderCellConfig.border,
  font: HeaderCellConfig.font,
  style: HeaderCellConfig.style,
};

const GROUP_CONFIG = {
  alignment: GroupCellConfig.alignment,
  border: GroupCellConfig.border,
  font: GroupCellConfig.font,
  style: GroupCellConfig.style,
};

const FOOTER_INFO_CONFIG = {
  border: FooterInfoConfig.border,
  font: FooterInfoConfig.font,
};

const columnsToDefaultSchema = (columns: ExportColumn[] = []) =>
  columns.map(
    ({ caption, name, accessor, dataField, dataType = 'varchar', alignment }): ExportSchemaColumn => {
      return {
        ColumnName: (caption || name || accessor || dataField) as string,
        DataTypeName: dataType as string,
        HorizontalAlignment: alignment,
      };
    },
  );

const dataToDefaultSchema = (data: Array<{ [key: string]: any }>) => {
  return data?.length
    ? (Object.keys(data[0]).map(ColumnName => ({ ColumnName, DataTypeName: 'varchar' })) as ExportSchemaColumn[])
    : [];
};

const getSchema = (dataSource?: ExportDataSource, columns?: ExportColumn[]): ExportSchemaColumn[] => {
  const { data, schema: schemaSrc } = (dataSource || {}) as {
    data: Array<{ [key: string]: any }>;
    schema: ExportSchemaColumn[];
  };
  // use, by priority order, the schema itself, the columns or the data
  return schemaSrc ?? (columns ? columnsToDefaultSchema(columns) : data ? dataToDefaultSchema(data) : []);
};

const getDxGridExportFileName = (dataGrid?: DxDataGridInstance) => {
  const { fileName = 'DataGrid' } = dataGrid ? dataGrid.option('export') : {};
  return `${fileName}.xlsx`;
};

// returns the schemaColumns by worksheet column index
const getSchemaColByWorksheetColNumber = (
  worksheet: Worksheet,
  schema: ExportSchemaColumn[] = [],
): { [colNumber: number]: ExportSchemaColumn } => {
  const schemaByColumnName = arrayToObject(schema, 'ColumnName');

  return worksheet.columns.reduce((acc: { [colNumber: number]: ExportSchemaColumn }, column) => {
    // columns without data, like the recon summary placeholder at the right
    if (!column?.values?.length) return acc;

    const schColumn = schemaByColumnName[column.key as keyof typeof schemaByColumnName];

    acc[column.number as keyof typeof acc] = schColumn || {};
    return acc;
  }, {});
};

export const composeDefaultCustomExcelCellTransform = (...transformFuncs: ExcelCellTransform[]) =>
  flow(defaultExcelCellTransform, ...transformFuncs) as ExcelCellTransform;

export const defaultExcelCellTransform: ExcelCellTransform = params => {
  const { worksheet, dataGrid, schema = [], dataRow = 1, groupRows = [] } = params;

  if (!dataGrid) return params;

  const isHeadless: boolean = dataGrid.option('headless');
  const schemaColByColumnNumber = getSchemaColByWorksheetColNumber(worksheet, schema);

  worksheet.eachRow((row, rowNumber) => {
    const isColumnHeaderRow = !isHeadless && rowNumber === dataRow;
    const isGroupRow = groupRows?.includes(rowNumber);

    row.eachCell((cell, colNumber) => {
      if (isColumnHeaderRow) {
        merge(cell, COLUMN_HEADER_CONFIG);
        return;
      }

      if (isGroupRow) merge(cell, GROUP_CONFIG);

      const schColumn = schemaColByColumnNumber[colNumber];

      const { ColumnName = '', DataTypeName = 'varchar', NumericPrecision, HorizontalAlignment } = schColumn;

      const nexusDataType = getNexusDataType(ColumnName, DataTypeName as NexusDataType);
      const dataType = dataTypeMap[nexusDataType];

      cell.alignment = {
        horizontal: (HorizontalAlignment || getHorizontalAlignment(dataType)) as Alignment['horizontal'],
      };

      // apply number format if needed
      switch (dataType) {
        case 'number':
          cell.model.type = 2;
          cell.numFmt = getCellExcelNumeralFormat(DataTypeName as NexusDataType, ColumnName, NumericPrecision);
          break;
        case 'date':
        case 'datetime':
          cell.model.type = 3;
          cell.value = parseDataTypeValue(DataTypeName, cell.value);
          break;
        case 'boolean':
          cell.model.type = 9;
          cell.value = parseDataTypeValue(DataTypeName, cell.value);
          break;
      }
    });
  });

  return params;
};

export const exportDataGrid = async ({
  dataGrid,
  worksheet,
  topLeftCell = { row: 1, column: 1 },
}: {
  dataGrid?: DxDataGridInstance;
  worksheet: ExcelJS.Worksheet;
  topLeftCell: { row: number; column: number };
}) => {
  if (!dataGrid) throw new Error("Incorrect arguments: 'dataGrid' is null");

  const visibleColumns = dataGrid.getVisibleColumns();

  const result = { from: { ...topLeftCell }, to: { ...topLeftCell } };
  result.to.column += visibleColumns.length;

  let currentColumnIndex = result.from.column;

  const isHeadless = Boolean(dataGrid.option('headless'));

  worksheet.columns = visibleColumns.map(({ name, dataField }) => ({
    key: dataField ?? name,
  }));

  if (!isHeadless) {
    const headerRow = worksheet.getRow(result.to.row);
    for (const { caption, name, dataField } of visibleColumns) {
      headerRow.getCell(currentColumnIndex).value = (caption ?? name ?? dataField) as string;
      currentColumnIndex++;
    }
    result.to.row++;
  }

  const items = await dataGrid.getController('data').loadAll();

  const groupRows = []; // to be able to style the group rows

  for (const item of items) {
    const dataRow = worksheet.getRow(result.to.row);
    currentColumnIndex = result.from.column;
    if (item.rowType === 'group') {
      groupRows.push(result.to.row);

      item.summaryCells.forEach((summaryCell: SummaryCell[], index: number) => {
        const excelCell = dataRow.getCell(currentColumnIndex);
        if (index === item.groupIndex) {
          excelCell.value = item.values ? item.values[index] : null;
        } else if (summaryCell.length) {
          const [cell] = summaryCell;
          excelCell.value = cell.value;
        }

        // Must have a non-nil value to apply the fill style
        excelCell.value = excelCell.value == null ? '' : excelCell.value;

        currentColumnIndex++;
      });
    } else if (item.rowType === 'data' && item.values) {
      for (const value of item.values) {
        dataRow.getCell(currentColumnIndex).value = value;
        currentColumnIndex++;
      }
    }

    result.to.row++;
  }

  result.to.row--;

  return groupRows;
};

export const customExporting = async ({
  exportParams,
  dataSourceType,
  dataSource,
  columns,
  gridTitle,
  gridDescription,
  footerInfo,
  sheetName = 'Main Sheet',
  excelCellTransform = defaultExcelCellTransform,
  onCustomExportPreparing,
  onCustomExported,
}: CustomExportParams) => {
  if (onCustomExportPreparing) onCustomExportPreparing(exportParams);

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);
  const DATA_ROW = gridTitle && gridDescription ? 3 : gridTitle || gridDescription ? 2 : 1;

  const groupRows: number[] = await exportDataGrid({
    worksheet,
    dataGrid: exportParams.component,
    topLeftCell: { row: DATA_ROW, column: 1 },
  });

  if (gridTitle) {
    // title row
    Object.assign(worksheet.getRow(1).getCell(1), {
      value: gridTitle,
      font: TitleConfig.font,
    });
  }

  if (gridDescription) {
    // description row
    const descriptionRow = gridTitle ? 2 : 1;
    Object.assign(worksheet.getRow(descriptionRow).getCell(1), {
      value: gridDescription,
      font: DescriptionConfig.font,
    });
  }

  if (footerInfo) {
    const footerRow = worksheet.rowCount + 1;

    Object.assign(worksheet.getRow(footerRow).getCell(1), { value: footerInfo });

    for (let i = 1; i <= worksheet.columnCount; i++) {
      Object.assign(worksheet.getRow(footerRow).getCell(i), FOOTER_INFO_CONFIG);
    }
  }

  // custom transformations
  if (dataSourceType === DataSourceType.VIRTUS && excelCellTransform) {
    // we can send excelCellTransform as null to use the in-built export, the customizeExcelCell, etc
    if (excelCellTransform) {
      const schema = getSchema(dataSource, columns);
      excelCellTransform({ worksheet, dataGrid: exportParams.component, schema, dataRow: DATA_ROW, groupRows });
    }
  }

  // add the column width
  // @TODO use the dxDataGrid column width or the schema
  worksheet.columns.forEach(column => (column.width = DefaultColumnWidth));

  // write the file
  const buffer: any = await workbook.xlsx.writeBuffer();
  const fileName = getDxGridExportFileName(exportParams.component);
  saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);

  // cancel the DevExtreme in-built exporting:
  // IMPORTANT: this will avoid of using the customizeExcelCell and other internal export hooks
  exportParams.cancel = true;

  if (onCustomExported) onCustomExported(exportParams);
};
