import styled, { css } from 'styled-components';
import { Tooltip, withStyles } from '@material-ui/core';

export const GlideHeader = styled.div`
  margin-top: 41px;
  background-color: var(--bgDark);
`;

export const Tab = styled.div<{ highlighted: boolean }>`
  display: flex;
  float: left;
  cursor: pointer;
  padding: 7px;
  line-height: 19px;
  font-size: 14px;
  color: white;
  ${props =>
    props.highlighted === true &&
    css`
      background-color: var(--bg);
    `};
  :hover {
    background-color: var(--bg);
  }
  i.dx-icon.dx-icon-close {
    font-size: 18px;
    margin-left: 5px;
  }
  i.dx-icon.dx-icon-close:hover {
    cursor: default;
    border-radius: 20px;
    background-color: var(--blue);
  }
`;

export const DashBoardTab = styled.div<{ active: boolean }>`
  float: left;
  cursor: pointer;
  padding: 7px;
  line-height: 19px;
  background-color: var(--bgDark);
  ${props =>
    props.active === true &&
    css`
      background-color: var(--bg);
    `};
  :hover {
    background-color: var(--bg);
  }
`;

export const StyledTooltip = withStyles({
  tooltip: {
    marginTop: '-5px',
    whiteSpace: 'pre-line',
    maxWidth: 'none',
  },
})(Tooltip);
