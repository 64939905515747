/* tslint:disable:max-line-length */
/**
 * Set Components props in app
 *
 * Type of components:
 *  - static components: most components except view components
 *  - view components: inspector, layout manager. States are saved for the client view
 *
 */
import { RootState } from 'src/reducers/rootReducer';
import { clone, merge } from 'lodash';
import { DateConfig } from 'src/components/date-picker/date-picker';
import { GlideObject } from 'src/models/glide/glideObject';

// New components need to be registered
export type ComponentName =
  | 'actionForm'
  | 'bottomPanel'
  | 'bottomPanelExpandedState'
  | 'columnGrouped'
  | 'columnManagerInspector'
  | 'creditDetailsInspector'
  | 'datePicker'
  | 'dealsDetailInspector'
  | 'dealsInspector'
  | 'form'
  | 'global'
  | 'inspector'
  | 'layoutManager'
  | 'livePriceToggleState'
  | 'marketmapCredentials'
  | 'searchInspector'
  | 'showLayoutManagerInspector' // TODO: Remove showLayoutManagerInspector & datePicker once we get rid of hard coded pages/views
  | 'summaryPanel'
  | 'viewComponents'
  | 'hypoScenario'
  | 'newObject'
  | 'globalActions'
  | 'gridLayout'
  | 'baseLineDate'
  | 'currentDate'
  | 'fundSelector'
  | 'marketMap';

export type ComponentPropsType = {
  [key in ComponentName]: any;
};

export interface ComponentProps {
  components: ComponentPropsType;
}

export interface ComponentPayload {
  componentName: ComponentName;
  clientViewUri?: string;
  props?: {
    [key: string]: any;
  };
  visible?: boolean;
  view?: { [uri: string]: object };
}

export enum ComponentActionTypes {
  // Generic actions applies to all components
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  UPDATE_COMPONENT = 'UPDATE_COMPONENT',
  UPDATE_COMPONENT_VIEW = 'UPDATE_COMPONENT_VIEW',
  TOGGLE_COMPONENT_VISIBILITY = 'TOGGLE_COMPONENT_VISIBILITY',
  TOGGLE_VIEW_COMPONENT_VISIBILITY = 'TOGGLE_VIEW_COMPONENT_VISIBILITY',
  RESET = 'RESET',
  RESET_COMPONENT = 'RESET_COMPONENT',
}

export interface LivePriceViewProps {
  reqCountId: number;
  isMarketmapConnectedToServer: boolean;
  isLoggedInToMarketmap: boolean;
  marketMapToggleStatus: any;
  showMMAuthenticationForm: boolean;
  livePriceKeys: (string | number)[];
}

export interface LivePriceViewProps {
  reqCountId: number;
  isMarketmapConnectedToServer: boolean;
  isLoggedInToMarketmap: boolean;
  marketMapToggleStatus: any;
}

export interface DatePickerProps {
  date: any[];
  dateConfig: DateConfig;
  visible: boolean;
  count: number;
}

export interface FundSelectorComponentProps {
  visible: boolean;
}

export interface Components {
  actionForm: { visible: boolean; actionUri: string };
  newObject: { visible: boolean; actionUri: string };
  activeClientViewGrid: {};
  bottomPanel: {
    isVisible: boolean;
    instanceUri: string;
    clientViewUri: string;
  };
  bottomPanelExpandedState: { isExpanded: boolean };
  columnGrouped: { isExpanded: boolean; isGrouped: boolean };
  columnManagerInspector: { visible: boolean; props: {} };
  contextMenu: { visible: boolean };
  creditDetailsInspector: { visible: boolean; tabs: any[] };
  dashboard: { dashboardId: string };
  datePicker: DatePickerProps;
  dealsDetailInspector: { visible: boolean };
  dealsInspector: { visible: boolean };
  form: {};
  objects: GlideObject[];
  global: {
    importInstrumentOverlay: { visible: boolean };
    orders: {
      rowData: any;
      active: boolean;
      disabled: boolean;
      displayOverlay: boolean;
    };
    currentClientViewUri: string;
    isDarkTheme: boolean;
    isGomOpen: boolean;
  };
  inspector: {
    isExpanded: boolean;
    isCollapsed: boolean;
    isEdit: boolean;
    uri: string;
  };
  issuerDetailsInspector: { visible: boolean };
  layoutManager: { visible: boolean };
  globalActions: { visible: boolean };
  livePriceToggleState: {
    livePriceKeys: any[];
    marketMapToggleStatus: {};
    reqCountId: number;
    isLoggedInToMarketmap: boolean;
    isMarketmapConnectedToServer: boolean;
    showMMAuthenticationForm: boolean;
  };
  marketmapCredentials: {
    marketmapP: string;
    marketmapGroup: string;
    marketmapU: string;
  };
  searchInspector: { visible: boolean };
  showLayoutManagerInspector: { visible: boolean };
  summaryPanel: { visible: boolean };
  // TODO: define the interface for what can be stored in viewComponents
  viewComponents: any;
  hypoScenario: { runStatus: string; runStatusUri: string };
  gridLayout: {
    hasChanges: boolean;
    selectedLayout:
      | {
          allowedPageSizes: number[];
          columns: any[];
          filterPanel: { filterEnabled: boolean };
          filterValue: any;
          pageIndex: number;
          pageSize: number;
          saveChanges: boolean;
          searchText: string;
          selectedRowKeys: string[];
          uri: string;
        }
      | undefined;
  };
  baseLineDate: DatePickerProps;
  currentDate: DatePickerProps;
  fundSelector: FundSelectorComponentProps;
  marketMap: { visible: boolean };
}

export const datePickerInitialState: DatePickerProps = {
  visible: false,
  count: 0,
  date: [],
  dateConfig: {
    prevSingleDate: '',
    singleDate: '',
    toDate: '',
    fromDate: '',
    frequency: '',
    dateType: 'single',
  },
};

export const componentsInitialState: Components = {
  // Static inspector used for links in notifications
  inspector: {
    uri: '',
    isExpanded: false,
    isCollapsed: true,
    isEdit: false,
  },
  bottomPanel: { isVisible: false, instanceUri: '', clientViewUri: '' },
  actionForm: { visible: false, actionUri: '' },
  newObject: { visible: false, actionUri: '' },
  activeClientViewGrid: {},
  searchInspector: { visible: false },
  creditDetailsInspector: { visible: false, tabs: [] },
  dealsInspector: { visible: false },
  contextMenu: { visible: false },
  columnManagerInspector: {
    visible: false,
    props: {},
  },
  datePicker: { ...datePickerInitialState },
  summaryPanel: { visible: false },
  showLayoutManagerInspector: { visible: false },
  layoutManager: { visible: false },
  globalActions: { visible: false },
  dashboard: { dashboardId: '' },
  dealsDetailInspector: { visible: false },
  issuerDetailsInspector: { visible: false },
  bottomPanelExpandedState: { isExpanded: false },
  form: {},
  livePriceToggleState: {
    reqCountId: 0,
    isMarketmapConnectedToServer: false,
    isLoggedInToMarketmap: false,
    marketMapToggleStatus: {},
    showMMAuthenticationForm: false,
    livePriceKeys: [],
  },
  marketmapCredentials: {
    marketmapU: '',
    marketmapP: '',
    marketmapGroup: '',
  },
  viewComponents: {},
  objects: [],
  global: {
    orders: {
      disabled: true,
      rowData: {},
      active: false,
      displayOverlay: false,
    },
    importInstrumentOverlay: { visible: false },
    currentClientViewUri: '',
    isDarkTheme: false,
    isGomOpen: false,
  },
  columnGrouped: { isGrouped: false, isExpanded: false },
  hypoScenario: { runStatus: '', runStatusUri: '' },
  gridLayout: {
    hasChanges: false,
    selectedLayout: undefined,
  },
  baseLineDate: { ...datePickerInitialState },
  currentDate: { ...datePickerInitialState },
  fundSelector: { visible: false },
  marketMap: { visible: false },
};

export const components = (
  state: Components = componentsInitialState,
  action: { type: ComponentActionTypes; payload: ComponentPayload },
) => {
  switch (action.type) {
    case ComponentActionTypes.RESET:
      return componentsInitialState;
    case ComponentActionTypes.RESET_COMPONENT: {
      const { componentName } = action.payload;
      return { ...state, [state[componentName]]: componentsInitialState[componentName] };
    }
    case ComponentActionTypes.UPDATE_COMPONENT: {
      const { componentName, props } = action.payload;
      const newState = clone(state);
      merge(newState[componentName], props);
      return newState;
    }
    case ComponentActionTypes.UPDATE_COMPONENT_VIEW: {
      const { componentName, props, clientViewUri } = action.payload;
      const newState = clone(state);
      // Reset static inspector that may have been by notification
      if (componentName === 'inspector' && state['inspector'].uri !== '') {
        // console.log('reset static component', initialState['inspector']);
        merge(newState, { inspector: { uri: '' } });
      }
      merge(newState.viewComponents, {
        [clientViewUri as string]: { [componentName]: { ...props } },
      });
      return newState;
    }
    case ComponentActionTypes.TOGGLE_COMPONENT_VISIBILITY: {
      const { componentName, visible, props } = action.payload;
      const newState = clone(state);
      const isVisible = visible !== undefined ? visible : !state[componentName]?.visible;
      merge(newState[componentName], { visible: isVisible, ...props });

      // Reset target_uri when closing inspector
      if (componentName === 'inspector') {
        merge(newState['actionForm'], { target_uri: null });
      }
      return newState;
    }
    case ComponentActionTypes.TOGGLE_VIEW_COMPONENT_VISIBILITY: {
      const { componentName, props, clientViewUri, visible } = action.payload;
      const newState = clone(state);
      const isVisible =
        visible !== undefined ? visible : !state.viewComponents[clientViewUri as string]?.[componentName]?.visible;
      merge(newState.viewComponents, {
        [clientViewUri as string]: {
          [componentName]: { visible: isVisible, ...props },
        },
      });
      return newState;
    }
    default:
      return state;
  }
};

export const updateComponentViewAction = (componentName: ComponentName, clientViewUri: string, props: object) => ({
  type: ComponentActionTypes.UPDATE_COMPONENT_VIEW,
  payload: { componentName, clientViewUri, props },
});

export const updateComponentAction = (componentName: ComponentName, props: object) => ({
  type: ComponentActionTypes.UPDATE_COMPONENT,
  payload: { componentName, props },
});

export const toggleComponentDisplayAction = (
  componentName: ComponentName,
  visible?: boolean,
  props?: { isExpanded: boolean },
) => ({
  type: ComponentActionTypes.TOGGLE_COMPONENT_VISIBILITY,
  payload: { componentName, visible, props },
});

export const resetComponentAction = (componentName: ComponentName) => ({
  type: ComponentActionTypes.RESET_COMPONENT,
  payload: { componentName },
});

export const toggleComponentViewDisplayAction = (
  componentName: ComponentName,
  clientViewUri: string,
  visible?: boolean,
) => ({
  type: ComponentActionTypes.TOGGLE_VIEW_COMPONENT_VISIBILITY,
  payload: { componentName, clientViewUri, visible },
});

export const selectComponents = (state: RootState): Components => state.components;
export const selectViewComponent = (state: RootState | any, componentName: ComponentName, clientViewUri: string) => {
  // console.log('state.components.viewComponents', state.components.viewComponents);
  // console.log(
  //   'state.components.viewComponents?.[componentName]?.[clientViewUri]',
  //   state.components.viewComponents?.[clientViewUri]?.[componentName],
  // );
  return state.components.viewComponents?.[clientViewUri]?.[componentName];
};
