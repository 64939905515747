import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = () => ({
  progress: {
    width: '16px !important',
    height: '16px !important',
    color: 'var(--default-blue)',
  },
});

const RunningIconAnimated = withStyles(styles)(({ classes, ...props }: any) => {
  return <CircularProgress {...props} className={classes.progress} />;
});

export default RunningIconAnimated;
