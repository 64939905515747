import { takeLatest } from 'redux-saga/effects';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';

export enum DataGridActions {
  DATAGRID_RESET = 'DATAGRID_RESET',
  DATAGRID_ROW_SELECTION = 'DATAGRID_ROW_SELECTION',
}
/**
 * 💡 All logic related to datagrid row click can be handled over here.
 */
export function* handleRowSelection(): Generator<HTMLDivElement | null | undefined, void, HTMLDivElement> {
  // ∎ Enable selectCart component when row is clicked.
  const selectCartElement = yield document.querySelector('#selectedRowAction')?.closest('.dx-item.dx-toolbar-item');
  selectCartElement && selectCartElement.classList.remove('dx-state-disabled');
}

/**
 * 💡 All logic related to datagrid reset (when glide-view component unmounts) can be handled over here.
 */
export function* handleReset(): Generator<
  HTMLDivElement | null | undefined,
  void,
  HTMLDivElement & ClientViewConfigurationData
> {
  // ∎ Switching between views should disable selectCart component.
  const selectCartElement = yield document
    .querySelector('#selectedRowAction')
    ?.closest('.dx-item.dx-toolbar-item') as HTMLDivElement;
  selectCartElement && selectCartElement.classList.add('dx-state-disabled');
}

export function* watchDatagridRowSelection() {
  yield takeLatest(DataGridActions.DATAGRID_ROW_SELECTION, handleRowSelection);
}

export function* watchDatagridReset() {
  yield takeLatest(DataGridActions.DATAGRID_RESET, handleReset);
}

export default [watchDatagridRowSelection, watchDatagridReset];
