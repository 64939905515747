import { selectCVC } from 'src/reducers/tabs';
import { RootState } from 'src/reducers/rootReducer';

export const getSortedPageLayouts = (pageLayouts: any, clientViewUri: string) => {
  const layoutsOrderStr = localStorage.getItem(`${clientViewUri}-layouts-order`);
  if (!layoutsOrderStr) return pageLayouts;

  const layoutsOrder = layoutsOrderStr ? JSON.parse(layoutsOrderStr) : undefined;
  const pageLayoutsOrdered = pageLayouts.sort((a: any, b: any) => {
    const A = a.uri;
    const B = b.uri;
    return layoutsOrder.indexOf(A) - layoutsOrder.indexOf(B);
  });

  return pageLayoutsOrdered;
};

export const layoutSelector = (state: RootState, clientViewUri: string) => {
  const clientViewConfiguration = selectCVC(state, clientViewUri);
  const pageLayouts = state.entities?.views[clientViewUri]?.webLayouts;
  const client_view_type = clientViewConfiguration?.client_view_type;
  const storedSelectedLayoutValue = localStorage.getItem(`selected-layout-${clientViewUri}-${client_view_type}`);
  // If no layout is selected
  const pageLayoutsOrdered = getSortedPageLayouts(pageLayouts, clientViewUri);
  if (pageLayoutsOrdered?.length) {
    const firstLayoutInList = pageLayoutsOrdered[0];
    const storedSelectedLayout = pageLayoutsOrdered.find(
      (layout: any) => layout.uri === storedSelectedLayoutValue || layout.data.name === storedSelectedLayoutValue,
    );
    const layoutToSelect = storedSelectedLayoutValue ? storedSelectedLayout : firstLayoutInList;
    if (layoutToSelect) {
      return {
        layoutSelected: layoutToSelect,
        layouts: pageLayouts,
        viewUri: clientViewUri,
        clientViewType: client_view_type,
      };
    } else {
      return {
        layoutSelected: firstLayoutInList,
        layouts: pageLayouts,
        viewUri: clientViewUri,
        clientViewType: client_view_type,
      };
    }
  }
  return { layoutSelected: undefined, layouts: pageLayouts, viewUri: clientViewUri, clientViewType: client_view_type };
};
