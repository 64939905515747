import styled from 'styled-components';
import { Body } from '@virtus/components/page';
import { Search } from '@material-ui/icons';

export const SearchIcon = styled(Search)`
  margin-left: 6px;
  margin-right: 6px;
  width: 20px;
  height: 20px;
  color: var(--text);
`;

export const GlideBody = styled(Body)`
  height: 87.5vh;
`;
