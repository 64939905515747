import { PivotGrid } from 'devextreme-react/pivot-grid';

import { Texts } from 'devextreme-react/pivot-grid-field-chooser';
import styled from 'styled-components';

/**
 * !!STYLES HERE AFFECT GRIDS IN ALL APPS!!
 * Do not change unless you want to set new styles for all apps
 * it requires approval from all leads (dev, product, design) from Nexus/Glide/Genesis.
 * Please discuss on #dx-datagrid first
 *
 * If you want to override grid styling for your app. You can include a css in your App.ts
 * ie: glide/src/app/App/App.css
 */

export const StyledWrapper = styled.div<{ useDarkTheme?: string }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  flex: 1;

  &.dark-theme {
    background-color: var(--bgDark);
  }
`;

export const StyledPivotGridWrapper = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  overflow: auto;
`;
export const StyledDxPivotGrid = styled(PivotGrid)<{ borderTopColor?: string }>`
  height: 100%;
  overflow: auto !important;
  table.dx-word-wrap {
    table-layout: fixed !important;

    .dx-pivotgrid-fields-area.dx-area-fields.dx-pivotgrid-drag-action {
      max-width: unset !important;
      overflow: auto !important;
    }

    td.dx-area-description-cell {
      overflow: auto !important;

      .dx-pivotgrid-fields-area.dx-area-fields.dx-pivotgrid-drag-action {
        width: auto !important;
      }
    }

    .dx-scrollable-container {
      overflow: auto !important;
    }

    .dx-pivotgrid-horizontal-headers.dx-pivotgrid-area.dx-scrollable {
      width: 100% !important;
      height: 100% !important;
    }

    .dx-pivotgrid-area.dx-pivotgrid-area-data.dx-scrollable {
      width: 100% !important;
      height: 100% !important;
    }

    .dx-pivotgrid-vertical-headers.dx-pivotgrid-area.dx-scrollable {
      height: 100% !important;
    }
  }
`;

export const StyledPivotGridFieldChooser = styled.div`
  min-width: 30%;
  .side-field-chooser.dx-pivotgridfieldchooser {
    height: 100%;
  }

  .side-field-chooser.dx-pivotgridfieldchooser .dx-area-fields-header {
    display: none;
  }
  .side-field-chooser > .dx-pivotgridfieldchooser-container > .dx-col:first-child {
    width: 100%;
  }

  .side-field-chooser > .dx-pivotgridfieldchooser-container > .dx-col:last-child {
    display: none;
  }
  .side-field-chooser.dx-pivotgridfieldchooser .dx-area .dx-area-fields {
    margin-top: 0;
  }
`;

export const StyledTexts = styled(Texts)``;

export const PageStyledPivotGridWrapper = styled.div`
  flex: 2;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

export const PageStyledFiledChooser = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  .side-field-chooser.dx-pivotgridfieldchooser {
    height: 100%;
  }
  .side-field-chooser > .dx-pivotgridfieldchooser-container > .dx-col:first-child {
    width: 100%;
  }

  .side-field-chooser > .dx-pivotgridfieldchooser-container > .dx-col:last-child {
    display: none;
  }
`;

export const StyledRowsWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow: overlay;
  padding: 0 0 0 15px;
`;

export const StyledPivotViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  border-top: 1px solid var(--grey);
`;

export const StyledToolBarWrapper = styled.div`
  width: 100%;
`;

export const StyledTabWrapper = styled.div`
  height: 100%;
  &.dark-theme {
    background-color: var(--bg);
  }
  &.dark-theme .dx-tabs-wrapper {
    background-color: #363640;
    color: white;
  }

  &.dark-theme .dx-tabs-wrapper .dx-tab {
    background-color: #363640;
    padding-bottom: 12px;
    color: white;
  }
`;
