import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import styled from 'styled-components';

export const DropdownButton = styled.div<{ dark?: boolean }>`
  display: flex;
  align-items: center;
  border: ${props => (props.dark ? 'none' : '1px solid var(--accent-grey)')};
  background: ${props => (props.dark ? 'var(--grey)' : 'white')};
  color: ${props => (props.dark ? 'white' : 'var(--datagridHeaderText)')};
  height: 28px;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
  margin-left: 5px;
  width: 100%;
`;

export const DropdownButtonText = styled.div<{ showBorder?: boolean; disabled?: boolean }>`
  font-family: 'Nunito Sans', 'Roboto', 'Helvetica Neue', sans-serif;
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  border-right: ${props => (props.showBorder ? `1px solid var(--accent-grey)` : 'none')};
  height: 28px;
  width: 100%;
  opacity: ${props => (props.disabled ? `0.5` : ``)};
  cursor: ${props => (props.disabled ? `default` : `pointer`)};
`;

export const ArrowDropDownIcon = styled(ArrowDropDown)`
  height: 26px;
  &:hover {
    background-color: var(--grey-hover);
  }
`;
