export type OptionElement = {
  name: string;
  value: string;
  color?: string;
};

export type FormFieldOptions = {
  [key: string]: OptionElement;
};

export type FormInputs =
  | 'DateInput'
  | 'DecimalInput'
  | 'SearchInput'
  | 'SelectInput'
  | 'TogglerInput'
  | 'MultipleDateInput';

export enum FieldRuleType {
  Filter = 'Filter',
  ReadOnly = 'Read Only',
  Required = 'Required',
  Valid = 'Valid',
  Value = 'Value',
  Hidden = 'Hidden',
}
export type FieldRule = {
  ruleFn: (...args: any[]) => boolean | string | number | undefined | null;
  ruleType: FieldRuleType;
  [key: string]: any;
};

export interface FormFieldType {
  dataType: string;
  formElementType: string;
  required: boolean;
  readonly: boolean;
  defaultValue: any;
  name: string;
  displayName: string;
  id?: string;
  options?: FormFieldOptions;
  disabled?: boolean;
  formatMultiplier?: Array<{ key: string; value: string }>;
  searchType?: string;
  searchService?: any;
  hidden?: boolean;
  disableErrorWarning?: boolean;
  data?: any[];
  columns?: any[];
  objectFieldName?: string;
  client_region?: string;
  fieldRules?: FieldRule[];
  calendarAlign?: string;
  renderType?: string | null;
  format?: string;
  action?: string;
  hideLabel?: boolean;
  textDark?: boolean;
  isEdited?: boolean;
  isMultiSelect?: boolean;
  isSingleDate?: boolean;
  fieldUri?: string;
}

export type FormValuesType = {
  formValues: any;
  formErrors: any;
  formFields: FormFieldsType;
  hasChanged?: boolean;
};

export type FormFieldsType = { [key: string]: FormFieldType };

export type FormGroupsStateType = {
  formErrors: { [key: string]: string };
  hasChanged: boolean;
  formFields: FormFieldsType;
  formValues: { [key: string]: string | number | boolean | undefined };
  formInitialChanges: { [key: string]: string | number | boolean | undefined };
};

export type FormGroupsType = {
  [key: string]: FormFieldsType;
};

export type Form = {
  [key: string]: FormGroupsStateType;
};
