import { Tooltip, withStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useState } from 'react';
import { SubMenuIcon } from 'src/icons/SubMenuIcon';
import { Route } from 'src/page/models/route';
import styled from 'styled-components';

const StyledListItemIcon = styled(ListItemIcon)`
  color: white;
  margin-right: 8px;
  min-width: unset;
`;

const StyledListItem = styled(ListItem)`
  cursor: pointer;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
`;

const StyledListItemText = styled(ListItemText)`
  color: white;
  padding: 0px;
  font-size: 16px;
  font-family: 'Nunito Sans', 'Roboto', 'Helvetica Neue', sans-serif !important;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  text-overflow: ellipsis;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledTooltip = withStyles({
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
    maxWidth: 'none',
    fontWeight: 600,
  },
})(Tooltip);

interface SideMenuItemProps extends Route {
  onClick: (fullPath?: string, subroute?: Route) => void;
  style?: React.CSSProperties;
  subroute?: Route;
  menuItemColor?: string;
}

const SideMenuItem: React.FC<SideMenuItemProps> = props => {
  const [hover, setHover] = useState(false);
  const { fullPath, name, icon, onClick, style, menuItemColor, context, hasSubmenu } = props;
  return (
    <StyledListItem
      button={false as any}
      // This change is to bring back <li></li> tags in side menu list DOM,
      // which was missing after upgrading material UI to v4
      // (https://github.com/mui/material-ui/issues/14971)
      data-testid="sidemenu-item"
      style={{
        ...style,
        background: hover ? menuItemColor || 'var(--fis-green)' : 'inherit',
      }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={() => onClick(`${fullPath}`, props)}
    >
      {icon && icon.component && icon.enableInSideBar && (
        <StyledListItemIcon>{React.createElement(icon.component, icon.sideBarProps)}</StyledListItemIcon>
      )}
      <StyledTooltip title={context || ''} placement="bottom-start">
        <StyledListItemText primary={name} primaryTypographyProps={{ color: 'inherit' }} />
      </StyledTooltip>
      {hasSubmenu && <SubMenuIcon />}
    </StyledListItem>
  );
};

export default SideMenuItem;
