import { InputBase } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Search as SearchIcon } from '@material-ui/icons';
import styled from 'styled-components';
import AddCircleIcon from '@material-ui/icons/AddCircle';

export const Input = styled(InputBase)<{ error?: boolean; darktext?: boolean }>`
  width: 100%;
  height: 100%;
  padding: 0;
  padding-top: 2px;
  color: ${props => (props.darktext ? 'var(--textDark)' : 'var(--text)')};
  background-color: transparent;
  font-size: 14px;
  border: ${props => (props.error ? '2px solid var(--red)' : 'none')};
`;

export const SearchFieldWithLink = withStyles({
  disabled: {
    color: 'var(--default-blue)',
    cursor: 'pointer',
  },
  input: {
    textTransform: 'capitalize',
  },
})(Input as any);

export const Search = withStyles({
  disabled: {
    color: 'var(--text)',
  },
  input: {
    textTransform: 'capitalize',
  },
})(Input as any);

export const SearchContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  height: 28px;
  background-color: ${props => (props.disabled ? 'transparent' : 'rgba(255, 255, 255, 0.2)')};
  padding-left: ${props => (props.disabled ? '10px' : '0')};
  border-radius: 2px;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  margin-left: 4px;
  margin-right: 4px;
  width: 20px;
  height: 20px;
  color: rgba(255 255 255, 0.14);
`;

export const SpinnerWrapper = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  width: 22px;
`;

export const StyledAddCircleIcon = styled(AddCircleIcon)`
  margin-left: 4px;
  margin-right: 4px;
  width: 20px;
  height: 22px;
  color: var(--blue);
  background-color: 'transparent';
  :hover {
    cursor: pointer;
  }
`;
