import styled from 'styled-components';
import { zIndex } from 'src/utils/constants';

const getDarkBackground = (dark?: boolean) => (dark ? 'var(--grey)' : 'white');
export const LayoutButton = styled.button<{ active?: boolean; dark?: boolean }>`
  display: flex;
  align-items: center;
  border: ${props => (props.dark ? 'none' : '1px solid var(--accent-grey)')};
  color: ${props => (props.active || props.dark ? 'var(--text)' : '#5a5d5f')};
  background: ${props => (props.active ? 'var(--default-blue)' : getDarkBackground(props.dark))};
  height: 28px;
  font-size: 14px;
  font-family: 'Nunito Sans', 'Roboto', 'Helvetica Neue', sans-serif;
  white-space: nowrap;
  cursor: pointer;
  min-width: 100px;
  width: auto;
`;

export const IconButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const LayoutDropdownButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 150px;
  font-size: 14px;
`;

export const SaveCancelBtnWrapper = styled.div`
  position: absolute;
  z-index: 1;
  background-color: var(--bg);
  cursor: default;
  display: inline-flex;
  padding: 5px 10px;
`;

export const LayoutButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;

  .save-cancel-btn-wrapper {
    display: none;
    z-index: ${zIndex.SaveCancelWrapper};
  }

  .save-cancel-btn-wrapper:hover,
  .layout-button-badge:hover + .save-cancel-btn-wrapper {
    display: flex;
  }
`;
