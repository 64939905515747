import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { FunctionComponent } from 'react';

/* eslint-disable max-len */
const SvgVirtusDark: FunctionComponent<SvgIconProps> = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M19.556 15.239s-1.728-1.75-1.935-1.966c-.208-.215-.208-.244-.208-.776v-.668-.833c0-.53-.02-.558.188-.768.217-.218 1.936-1.967 1.936-1.967a.68.68 0 0 0 0-.953l-2.077-2.11a.656.656 0 0 0-.938 0l-2.078 2.11a.682.682 0 0 0 0 .953s1.728 1.75 1.935 1.966c.208.215.208.244.208.776v1.501c0 .53.02.558-.188.768-.217.218-1.936 1.967-1.936 1.967a.681.681 0 0 0 0 .953l2.077 2.11c.26.264.68.264.938 0l2.078-2.11a.682.682 0 0 0 0-.953"
        fill="#0e3167"
      />
      <path
        d="M14.056 19.326s-1.721-1.703-1.928-1.913c-.207-.21-.207-.398-.207-.915v-.65-.812c0-.509-.02-.7.18-.9l.258-.256.85-.843.83-.823a.652.652 0 0 0 0-.928l-.195-.192-1.7-1.686-.034-.035c-.207-.21-.207-.398-.207-.915v-.65-.812c0-.515-.021-.704.187-.908.217-.212 1.93-1.914 1.93-1.914a.652.652 0 0 0 0-.928l-2.07-2.054a.665.665 0 0 0-.936 0l-2.07 2.054a.652.652 0 0 0 0 .928s1.721 1.703 1.928 1.913c.207.21.207.398.207.916v1.46c0 .51.02.7-.18.902l-.252.25c-.222.22-.549.543-.856.849l-.83.822a.652.652 0 0 0 0 .928l.195.192 1.7 1.686.034.035c.207.21.207.398.207.915V16.504c0 .515.021.704-.187.908-.217.212-1.93 1.914-1.93 1.914a.652.652 0 0 0 0 .928l2.07 2.054a.665.665 0 0 0 .936 0l2.07-2.054a.652.652 0 0 0 0-.928"
        fill="#F57051"
      />
      <path
        d="M3.444 15.239s1.728-1.75 1.935-1.966c.208-.215.208-.244.208-.776v-.668-.833c0-.53.02-.558-.188-.768a1551.58 1551.58 0 0 1-1.936-1.967.682.682 0 0 1 0-.953l2.077-2.11a.656.656 0 0 1 .938 0l2.077 2.11a.68.68 0 0 1 0 .953s-1.727 1.75-1.934 1.966c-.208.215-.208.244-.208.776v1.501c0 .53-.02.558.188.768.217.218 1.936 1.967 1.936 1.967a.682.682 0 0 1 0 .953l-2.077 2.11a.656.656 0 0 1-.938 0l-2.078-2.11a.682.682 0 0 1 0-.953"
        fill="#0e3167"
      />
    </g>
  </svg>
);

export default SvgVirtusDark;
