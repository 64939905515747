import Tooltip from '@material-ui/core/Tooltip';
import { Close } from '@material-ui/icons';
import { ArrowDropDown } from '@material-ui/icons';
import React from 'react';
import DropdownMenu, { DropdownMenuItem, DropdownMenuItemText } from 'src/DropdownMenu';
import { EllipsisText, Label, LoadingPlaceholderStyled, LoadingSpinner } from 'src/ViewToolbar/components/ViewLabels';
import { ViewToolbarView } from 'src/ViewToolbar/ViewToolbar.model';
import styled from 'styled-components';

interface MoreViewsSelectorSelectorProps {
  activeView?: number;
  views: ViewToolbarView[];
  onViewClick: (id: number, isReport?: boolean) => React.MouseEventHandler;
  onClose?: (id: number, isReport?: boolean) => void;
  labelStyle?: any;
}

const renderMoreViewsButton = (
  viewLabels: ViewToolbarView[],
  activeView?: number,
  labelStyle = {},
): React.ReactElement<any> => {
  const loading = viewLabels.some(v => v.loading);
  const highlight = activeView != null && viewLabels.some(view => view.id === activeView);
  return (
    <Label data-testid="more-views-dropdown-button-label" active={highlight} style={{ paddingRight: 0, ...labelStyle }}>
      {`${viewLabels.length} more`}
      {loading && <LoadingSpinner />}
      <ArrowDropDown />
    </Label>
  );
};

const MoreViewsSelector: React.FunctionComponent<MoreViewsSelectorSelectorProps> = ({
  activeView,
  views = [],
  onViewClick,
  onClose,
  labelStyle,
}) => {
  return (
    <DropdownMenu
      data-testid="more-views-dropdown"
      button={renderMoreViewsButton(views, activeView, labelStyle)}
      menuMarginTop={34}
    >
      {views.map(({ id, name, loading, isReport }: ViewToolbarView) => {
        return (
          <MenuItem
            key={id}
            onClick={loading ? () => null : onViewClick(id, isReport)}
            active={id === activeView}
            isReport={isReport}
            style={id === activeView && !isReport ? { backgroundColor: 'var(--blue)' } : {}}
          >
            <Tooltip title={name} placement="right" style={{ flex: 1, justifyContent: 'flex-start' }}>
              <DropdownMenuItemText>
                {loading ? (
                  <LoadingPlaceholderStyled />
                ) : (
                  <EllipsisText>{name}</EllipsisText> || <LoadingPlaceholderStyled />
                )}
                {onClose && isReport && (
                  <StyledClose
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onClose(id, isReport);
                    }}
                  />
                )}
              </DropdownMenuItemText>
            </Tooltip>
          </MenuItem>
        );
      })}
    </DropdownMenu>
  );
};

const MenuItem = styled(DropdownMenuItem)<{ active: boolean; isReport?: boolean }>`
  min-width: 140px;
  padding: 0 8px;
  background-color: ${props => {
    return props.active ? (props.isReport ? 'var(--fis-green)' : 'var(--blue)') : 'var(--label)';
  }};
`;

const StyledClose = styled(Close)`
  width: 13px;
  margin-top: -7px;
  margin-left: 7px;
  height: 13px;
  cursor: pointer;
  transition: 0.2s all;
  &:hover {
    background: #00000052;
  }
`;

export default MoreViewsSelector;
