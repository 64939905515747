import React from 'react';
import { AntSwitch } from './switch.styled';
export interface SwitchProps {
  onChange?: (_checked: boolean) => void;
  checked?: boolean;
  useDarkTheme?: boolean;
}

export const Switch = React.memo(({ onChange, checked = false, useDarkTheme = false }: SwitchProps) => {
  const handleOnChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (onChange) {
      onChange(checked);
    }
  };

  return (
    <AntSwitch
      disableRipple
      checked={checked}
      color={useDarkTheme ? 'dark' : 'light'}
      onChange={handleOnChange.bind(checked)}
    ></AntSwitch>
  );
});
