import { TextField } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import styled from 'styled-components';

export const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  padding-right: 1em;
`;

export const StyledRadio = styled(Radio)`
  & svg {
    fill: var(--light-blue);
  }

  &:disabled + label {
    color: var(--grey);
  }
`;

export const StyledLabel = styled.label`
  font-size: 14px;
`;

export const StyledRadioWrapper = styled.div`
  margin-right: 12px;
  &.radio-button-disabled {
    color: var(--grey);
  }

  &.radio-button-disabled svg {
    opacity: 0.5;
  }

  & > span {
    padding: 0;
    margin-right: 12px;
  }
`;

// @ts-ignore
export const StyledTextField: any = styled(TextField)`
  & > div {
    background-color: var(--bg);
    color: white;
    font-size: 14px;
  }
`;

export const StyledFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em 1em;
  & > div {
    margin: 10px 0;
  }
`;

export const FormWrapper = styled.div`
  padding: 10px;

  & > form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const Error = styled.div`
  width: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
`;
