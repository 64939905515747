import Button, { ButtonProps } from '@material-ui/core/Button';
import styled from 'styled-components';

export type BasicButtonProps = ButtonProps;

const ConfirmInputButton = styled(Button)<{ disabled: boolean }>`
  height: 28px;
  width: 28px;
  min-width: 28px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  background-color: var(--fis-green);
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    background-color: var(--fis-green);
  }
` as React.ComponentType<BasicButtonProps>;

export default ConfirmInputButton;
