import Button, { ButtonProps } from '@material-ui/core/Button';
import { Link as ReachLink } from '@reach/router';
import React from 'react';

/** add "to" to Button Props */
export interface LinkProps extends ButtonProps {
  to: string;
}

const MyReachLink: React.SFC<any> = ({ to, ...props }) => <ReachLink {...props} to={to} />;

/**
 * simple wrapper around material `<Button>` to set component as `<Link>` and set the "to" property for Reach Router
 *
 * Ongoing effort in Material UI to type all this properly: https://github.com/mui-org/material-ui/pull/13868
 */
const Link: React.SFC<LinkProps> = props => (
  <Button variant="outlined" color="secondary" component={MyReachLink as any} {...props} />
);

export default Link;
