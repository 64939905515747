import CalendarIcon from '@material-ui/icons/CalendarToday';
import styled from 'styled-components';

export const StyledCalendarIcon = styled(CalendarIcon)`
  height: 18px;
  width: 18px;
  margin-right: 6px;
`;

export const DateInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .DayPicker-Day.DayPicker-Day--disabled {
    pointer-events: none;
    cursor: not-allowed !important;
  }
`;

export const DateButton = styled.button<{ error?: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 5px;
  width: 110px;
  height: 28px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.14);
  color: #fff;
  border: ${props => (props.error ? '2px solid var(--red)' : 'none')};
  cursor: pointer;
  :disabled {
    background-color: transparent;
  }
`;

export const DateInputElement = styled.input<{ disabled?: boolean }>`
  margin: 0;
  padding: 0;
  color: ${props => (props.disabled ? 'var(--accent-grey)' : 'var(--text)')};
  font-size: 14px;
  background-color: transparent;
  border: none;
  height: 28px;
  width: 100%;
  cursor: pointer;
`;

export const MultipleCalendarWrapper = styled.div<{ align?: string }>`
  width: 270px;
  background-color: var(--bg);
  z-index: 10;
  margin: 0;
  padding: 0;
  top: 33px;
  left: ${props => (props.align === 'right' ? '-25px' : '-168px')};
  position: absolute;
  color: white;
`;
