import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text);
  height: 35vh;
  flex-flow: column wrap;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  max-width: 100vw;
  justify-content: space-between;
  flex-flow: row wrap;
`;

export const StatusCode = styled.div`
  text-align: center;
  color: var(--virtus-orange);
  font-size: 10vh;
`;

export const StatusMessage = styled.div`
  font-size: 3vh;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  margin-left: 20px;
  overflow: auto;
`;

export const FooterMessage = styled.div`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
`;
