import { Done, Edit } from '@material-ui/icons';
import React, { useState } from 'react';
import { ConfirmInputButton } from '@virtus/components/Buttons';
import DropdownMenu, { DropdownMenuItem, DropdownMenuItemText } from '@virtus/components/DropdownMenu';
import { ILayout } from 'src/components/grids/dxgrid-client-view/templates/Layouts/Layouts.model';
import { StyledInput } from 'src/components/forms/form-elements/FormElements.style';
import * as S from './layout-row.style';

interface LayoutRowProps {
  rowElement: ILayout;
  dataTestId?: string;
  onRename: (layout: ILayout, newName: string) => void;
  icon?: React.ReactElement<any>;
  moreMenuOptions?: MenuOption[];
  children?: React.ReactElement<any>;
  style?: React.CSSProperties;
  active?: boolean;
}

export interface MenuOption {
  onClick: (layout: ILayout) => void;
  text: string;
  disabled?: boolean;
  icon?: React.ReactElement<any>;
}

const iconStyle = { color: 'var(--text)', width: '16px', height: '16px' };

const renderLayoutOptions = (moreMenuOptions: MenuOption[], layout: ILayout) =>
  moreMenuOptions.map(item => (
    <DropdownMenuItem data-testid="moreMenu-item" key={item.text} onClick={() => item.onClick(layout)}>
      {item.icon}
      <DropdownMenuItemText disabled={item.disabled}>{item.text}</DropdownMenuItemText>
    </DropdownMenuItem>
  ));

export const LayoutRow = ({
  rowElement,
  dataTestId,
  onRename,
  icon,
  moreMenuOptions,
  children,
  ...props
}: LayoutRowProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newLayoutName, setNewLayoutName] = useState('');

  const onKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      setIsEditing(false);
      onRename(rowElement, newLayoutName);
      setNewLayoutName('');
    }

    if (event.key === 'Escape') {
      setIsEditing(false);
      setNewLayoutName('');
    }
  };

  const canEditOrDelete = rowElement.data.name !== 'Default' && !props.active;

  return (
    <S.LayoutRow data-testid={dataTestId || 'layout-row'} {...props}>
      <S.Left>
        <S.LayoutEditBtn
          data-testid="edit-btn"
          disabled={!canEditOrDelete}
          onClick={() => (canEditOrDelete ? setIsEditing(!isEditing) : console.warn('No action available'))}
        >
          {icon || <Edit style={{ ...iconStyle, opacity: canEditOrDelete ? 1 : 0.2 }} />}
        </S.LayoutEditBtn>
        {!isEditing ? (
          rowElement.data.name
        ) : (
          <S.EditLayoutWrapper>
            <StyledInput
              data-testid="edit-layout-input"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewLayoutName(e.target.value)}
              onKeyDown={onKeyDown}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            />
            <ConfirmInputButton
              data-testid="confirm-btn"
              disabled={newLayoutName === '' || newLayoutName === 'Default'}
              onClick={(e: React.SyntheticEvent) => {
                e.preventDefault();
                e.stopPropagation();
                setIsEditing(false);
                onRename(rowElement, newLayoutName);
              }}
            >
              <Done style={{ color: 'var(--text)', fontSize: '16px' }} />
            </ConfirmInputButton>
          </S.EditLayoutWrapper>
        )}
      </S.Left>

      {moreMenuOptions && (
        <DropdownMenu light data-testid="more-btn" menuMarginTop={10}>
          {renderLayoutOptions(moreMenuOptions, rowElement)}
        </DropdownMenu>
      )}
      {children}
    </S.LayoutRow>
  );
};
