import { IconButton } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { Menu as MenuIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { AppLink, AppNames } from 'src/NavigationBar/components/AppMenu/AppMenu';
import { TopRightMenuItemProps } from 'src/NavigationBar/components/NavigationBarMenu/NavigationBarMenu';
import NavigationBar from 'src/NavigationBar/NavigationBar';
import Sidemenu from 'src/page/Sidemenu/Sidemenu';
import * as theme from 'src/theme';
import styled from 'styled-components';

export interface HeaderProps {
  menuButtonColor?: string;
  appButton?: any;
  onAppButtonClick?: (path?: string) => void;
  isLoggedIn?: boolean;
  topRightMenuItems?: TopRightMenuItemProps[];
  open?: boolean;
  path: string;
  onLogin?: () => void;
  onRouteChange?: (path: string) => void;
  routes?: any;
  sideMenuRoutes?: any;
  misc?: any;
  appMenuValue?: any;
  appMenuLinks?: AppLink[];
  enabledApps?: AppNames[];
  topRightButtonText?: any;
  isDrawerOpen?: boolean;
  openDrawer?: () => void;
  closeDrawer?: () => void;
  appColor?: string;
  routeChangeCallback?: (path: string, routes?: any) => void;
  isHelpVisible?: boolean;
  helpURL?: string;
}

/**
 * Fixed top position Material UI App Bar with Hamburger Menu.
 *
 * Add ¬<Routing>`/`<Page>` as siblings.
 */
const Header: React.FunctionComponent<HeaderProps> = ({
  appButton,
  onAppButtonClick,
  isLoggedIn,
  onLogin,
  topRightButtonText,
  topRightMenuItems,
  enabledApps = [],
  appMenuValue = '',
  appMenuLinks,
  routes,
  sideMenuRoutes,
  misc,
  onRouteChange,
  path,
  isDrawerOpen,
  openDrawer,
  closeDrawer,
  routeChangeCallback,
  appColor = 'var(--fis-green)',
  isHelpVisible = false,
  helpURL,
}) => {
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const _onRouteChange = onRouteChange
    ? onRouteChange
    : (_path: string, routes?: any) => {
        routeChangeCallback && routeChangeCallback(_path, routes);
      };
  const isOpen = isDrawerOpen === null || isDrawerOpen === undefined ? open : isDrawerOpen;
  return (
    <>
      <StyledAppBar position="fixed" data-testid="header">
        <Toolbar style={isOpen ? { marginLeft: '275px' } : {}}>
          {isLoggedIn && !isOpen && (
            <StyledMenuButton
              data-testid="header-menu-button"
              color="inherit"
              aria-label="Menu"
              background={appColor}
              onClick={openDrawer ? openDrawer : onOpen}
            >
              <MenuIcon />
            </StyledMenuButton>
          )}
          <NavigationBar
            appMenuValue={appMenuValue}
            enabledApps={enabledApps}
            appButton={appButton}
            appMenuLinks={appMenuLinks}
            onAppButtonClick={onAppButtonClick}
            routes={routes}
            misc={misc}
            path={path}
            appColor={appColor}
            onRouteChange={_onRouteChange}
            topRightButtonText={topRightButtonText}
            topRightMenuItems={topRightMenuItems}
            isSidebarOpen={isOpen}
            isLoggedIn={isLoggedIn}
            onLogin={onLogin}
            isHelpVisible={isHelpVisible}
            helpURL={helpURL}
          />
        </Toolbar>
      </StyledAppBar>
      <Sidemenu
        isOpen={isOpen}
        onClose={closeDrawer ? closeDrawer : onClose}
        routes={sideMenuRoutes || routes}
        menuItemColor={appColor}
        onRouteChange={_onRouteChange}
        appButton={appButton}
      />
    </>
  );
};

const noop = () => null;

Header.defaultProps = {
  isLoggedIn: false,
  open: false,
  onLogin: noop,
};

const StyledAppBar = styled(AppBar)`
  background-color: var(--bgDark);
  box-shadow: none;
  /** recreate Toolbar gutters, but Orange box hugs left **/
  padding-left: 12px;
  padding-right: 24px;
  height: ${theme.variables.headerHeight};
  z-index: 11;
`;

const StyledMenuButton = styled(IconButton)<{ background?: string }>`
  width: ${theme.variables.headerHeight};
  height: ${theme.variables.headerHeight};
  margin-left: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px 0 12px;
  background-color: ${props => props.background || 'var(--fis-green)'};
  border-radius: 0;
  :focus {
    outline: none;
  }
  :hover {
    background-color: ${props => props.background || 'var(--fis-green)'};
  }
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export default Header;
