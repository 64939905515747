import React from 'react';
import { Switch, withStyles } from '@material-ui/core';

export const AntSwitch = withStyles({
  root: {
    width: 27,
    height: 14,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 0,
    color: 'var(--switchbase-color)',
    '&$checked': {
      transform: 'translateX(12px)',
      color: '#ffffff',
      '& + $track': {
        opacity: 1,
        backgroundColor: 'var(--accent)',
        borderColor: 'var(--accent)',
      },
    },
  },
  thumb: {
    width: 14,
    height: 14,
  },
  track: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: 'var(--border-color)',
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'var(--bar-background)',
    width: 26,
  },
  checked: {},
})(({ classes, color, ...other }: any) => (
  <Switch
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    style={
      color === 'light'
        ? {
            '--switchbase-color': 'rgba(0,0,0,0.87)',
            '--border-color': 'rgba(0,0,0,0.87)',
            '--bar-background': '#ffffff',
          }
        : {
            '--switchbase-color': '#ffffff',
            '--border-color': '#ffffff',
            '--bar-background': 'rgba(0,0,0,0.87)',
          }
    }
    {...other}
  />
));
