import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

export const PaperStyled = styled(Paper)<{ paperStyles?: CSSStyleDeclaration }>`
  border-left: none;
  position: relative;
  z-index: 0;
  border-radius: 0;
  box-shadow: none;
`;
