import React, { useState } from 'react';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper';
import Select from './components/Select';
import { useFieldRule } from 'src/components/forms/hooks/use-field-rule';
export const MultipleSelectInput = ({
  field,
  onChange,
  error,
  formValues,
  customWidth,
}: Omit<FormElementInputComponent, 'value'>) => {
  const [optionName, setOptionName] = useState<any>([]);

  const onHandleChange = (e: any) => {
    const fieldValues = e.target.value;
    const values: string[] = [];
    fieldValues?.map((d: any) => {
      const filteredOptions = Object.values(field?.options as any).find((x: any) => x.value === d || x.name === d);
      if (filteredOptions) {
        values.push((filteredOptions as any)?.value);
      }
    });
    setOptionName(e.target.value);
    e.target.value = values;
    onChange && onChange(e);
  };
  const fieldUpdateFn = (nextValue: any) => {
    const event: Partial<React.SyntheticEvent> = {
      target: {
        value: nextValue,
      } as HTMLInputElement,
    };
    onChange(event);
  };

  const fieldRule = useFieldRule({
    fieldName: field.displayName,
    fieldRules: field.fieldRules,
    formValues,
    fieldUpdateFn,
  });
  if (fieldRule.hidden) return null;
  const renderValues = (data: any) => {
    return Array.isArray(data) ? data.join(',') : data;
  };

  return (
    <FormErrorWrapper
      error={error}
      displayName={field.displayName}
      name={field.name}
      disabled={fieldRule.disabled || field.disabled}
      required={fieldRule.required || field.required}
      readonly={fieldRule.disabled || field.readonly}
      disableErrorWarning={field.disableErrorWarning}
      customWidth={customWidth}
    >
      <Select
        dataTestId={`${field.name}-multi-select`}
        disabled={fieldRule.disabled || field.disabled || field.readonly}
        value={!optionName ? [] : optionName}
        error={Boolean(error) && !(field.readonly || field.disabled)}
        onChange={onHandleChange}
        menuItems={field.options ? Object.values(field.options) : []}
        multiple={true}
        renderValues={renderValues}
      ></Select>
    </FormErrorWrapper>
  );
};
