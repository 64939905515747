import { Delete, Done, Share } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { ConfirmInputButton, SecondaryButton } from '@virtus/components/Buttons';
import { ILayout, WebLayouts } from 'src/components/grids/dxgrid-client-view/templates/Layouts/Layouts.model';
import { StyledInput } from 'src/components/forms/form-elements/FormElements.style';
import { CreateCopyIcon } from '@virtus/components/icons';
import { Inspector } from '@virtus/components/Inspector';
import InspectorFooter from '@virtus/components/Inspector/components/InspectorFooter';
import { InspectorProps } from '@virtus/components/Inspector/Inspector';
import { Search } from '@virtus/components/Search/Search';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import { LayoutRow, MenuOption } from './components/layout-row/layout-row';
import * as S from './layout-manager-inspector.style';
import { ClientViewTypeLookUps } from 'src/components/glide-view/glide-view.model';
import { connect } from 'react-redux';
import { RootState } from 'src/reducers';
import { layoutSelector } from 'src/api/query.selector';
import isEqual from 'lodash/isEqual';
import { moveSelectedToFirstPosition } from 'src/utils/common';
import { Dispatch } from 'redux';
import { createLayout, deleteLayout, Layout, updateLayout } from 'src/sagas/layouts/layouts.saga';

type LayoutManagerType = {
  dataGridRef: React.RefObject<any>;
  currentPivotState?: any;
};
interface ReduxProps {
  webLayouts: WebLayouts;
}

export interface LayoutManagerInspectorProps
  extends Omit<InspectorProps, 'title' | 'onExpandChange' | 'isExpanded' | 'loading'> {
  style?: React.CSSProperties;
  clientViewUri: string;
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
interface ReduxDispatch {
  createLayout: (layout: Layout) => void;
  updateLayout: (layout: Layout) => void;
  deleteLayout: (layout: Layout) => void;
}
const LayoutManagerInspectorComponent = ({
  style,
  dataGridRef,
  currentPivotState = null,
  webLayouts,
  clientViewUri,
  createLayout,
  updateLayout,
  deleteLayout,
  ...props
}: LayoutManagerInspectorProps & LayoutManagerType & ReduxProps & ReduxDispatch) => {
  const viewUri = webLayouts?.viewUri;
  const client_view_type = webLayouts?.clientViewType;
  const [visibleLayouts, setVisibleLayouts] = useState<ILayout[] | undefined>(undefined);
  const [isCreatingANewLayout, setIsCreatingANewLayout] = useState(false);
  const [newLayoutName, setNewLayoutName] = useState('');
  const [rowToDelete, setRowToDelete] = useState<ILayout | undefined>(undefined);
  const [selectedLayout, setSelectedLayout] = useState<ILayout | undefined>(undefined);

  useEffect(() => {
    if (
      (!visibleLayouts || !isEqual(visibleLayouts, webLayouts?.layouts)) &&
      webLayouts?.layoutSelected &&
      webLayouts?.layouts
    ) {
      setVisibleLayouts([...moveSelectedToFirstPosition(webLayouts?.layoutSelected, webLayouts?.layouts)]);
    }
    setSelectedLayout(webLayouts?.layoutSelected);
  }, [webLayouts?.layoutSelected, viewUri, dataGridRef?.current, webLayouts?.layouts]);

  const onKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      submitNewLayout();
    }
    if (event.key === 'Escape') {
      setIsCreatingANewLayout(false);
      setNewLayoutName('');
    }
  };

  const submitNewLayout = () => {
    const currentGridConfig =
      client_view_type === ClientViewTypeLookUps.Pivot ? currentPivotState : dataGridRef?.current?.instance?.state();
    const layoutToSave = {
      data: {
        name: newLayoutName,
        json_layout: currentGridConfig ?? {},
      },
      uri: newLayoutName,
      date: new Date().toISOString(),
    };
    createLayout({ layoutToSave: layoutToSave, uri: clientViewUri });
    setNewLayoutName('');
    setIsCreatingANewLayout(false);
  };

  const filterLayouts = (text: string) => {
    if (!webLayouts?.layouts || !webLayouts?.layoutSelected) return;
    if (!text) {
      setVisibleLayouts([...moveSelectedToFirstPosition(webLayouts?.layoutSelected, webLayouts?.layouts)]);
      return;
    }
    const nextVisibleLayouts = webLayouts?.layouts.filter((layout: any) =>
      layout.data.name.toLowerCase().includes(text.toLowerCase()),
    );
    setVisibleLayouts([...nextVisibleLayouts]);
  };

  const onDragEnd = (result: DropResult) => {
    if (!visibleLayouts) return;
    const isFiltered = visibleLayouts.length !== webLayouts?.layouts?.length;
    if (!result.destination || isFiltered) {
      return console.info(`${isFiltered ? 'Reorder is not possible in a filtered list' : 'Invalid destination'}`);
    }
    const reorderedLayouts = reorder(visibleLayouts, result.source.index, result.destination.index);
    const order = reorderedLayouts.map(layout => layout.uri);
    localStorage.setItem(`${viewUri}-${client_view_type}-layouts-order`, JSON.stringify(order));

    setVisibleLayouts(reorderedLayouts);
  };

  const delete_Layout = (uri: string, visibleLayouts: ILayout[]) => visibleLayouts.filter((x: any) => x.uri !== uri);

  const handleOnDelete = (layout: ILayout) => {
    setRowToDelete(layout);

    handleOnDeleteLayoutWithConfirmation();
  };

  const deleteSelectedRow = () => {
    if (!visibleLayouts) return;
    if (rowToDelete) {
      setVisibleLayouts([...delete_Layout(rowToDelete?.uri, visibleLayouts)]);
      deleteLayout({ layoutToSave: rowToDelete, uri: clientViewUri });
    }
    setRowToDelete(undefined);
  };

  const onDuplicateClick = (layoutToDuplicate: ILayout) => {
    if (!visibleLayouts) return;
    const layoutToSave = {
      data: {
        ...layoutToDuplicate.data,
        name: `${layoutToDuplicate.data.name}-copy`,
      },
      date: new Date().toDateString(),
      uri: layoutToDuplicate.data.name,
    };
    createLayout({ layoutToSave: layoutToSave, uri: clientViewUri });
  };
  const getMenuOptions = (layoutName: string, isSelected: boolean): MenuOption[] => {
    const iconStyle = {
      width: '16px',
      marginRight: '5px',
    };

    const disableDelete = layoutName === 'Default' || isSelected;
    const onShare = (layoutToShare: ILayout) => console.info('On share', { layoutToShare });

    return [
      { onClick: onShare, text: 'Share', icon: <Share style={iconStyle} /> },
      {
        onClick: onDuplicateClick,
        text: 'Duplicate',
        icon: <CreateCopyIcon style={iconStyle} />,
      },
      {
        onClick: !disableDelete ? handleOnDelete : () => null,
        text: 'Delete',
        icon: (
          <Delete
            style={{
              ...iconStyle,
              ...{ color: disableDelete ? 'var(--grey)' : 'var(--text)' },
            }}
          />
        ),
        disabled: disableDelete,
      },
    ];
  };
  const onRenameClick = (layoutToRename: ILayout, newName: string) => {
    const layoutToSave = {
      ...layoutToRename,
      ...{
        data: {
          ...layoutToRename.data,
          name: newName,
        },
      },
    };
    updateLayout({ layoutToSave: layoutToSave, uri: clientViewUri, isRenamed: true });
  };
  const {
    DialogComponent: DeleteRowConfirmDialog,
    onDispatcherClick: handleOnDeleteLayoutWithConfirmation,
  } = useConfirmationDialog({
    onClick: deleteSelectedRow,
    bodyTextContent: 'Are you sure you want delete this layout?',
    acceptButton: { text: 'Delete Layout' },
    cancelButton: { text: 'Cancel' },
  });

  return (
    <Inspector
      dataTestId="layout-manager-inspector"
      title="Layout Manager"
      isExpanded={false}
      loading={false} //isSavingLayout
      {...props}
      // Custom styling props
      style={{ ...style, position: 'relative' }}
    >
      <DeleteRowConfirmDialog />
      <S.SearchWrapper>
        <Search onChange={filterLayouts} />
      </S.SearchWrapper>
      <S.ScrollableContent>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {dropProvided => (
              <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
                {visibleLayouts &&
                  visibleLayouts.map((layout: any, index: number) => (
                    <Draggable key={layout.uri} draggableId={layout.uri ?? index} index={index}>
                      {(dragProvided: any, snapshot) => (
                        <div
                          key={layout.uri}
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        >
                          <LayoutRow
                            active={selectedLayout && selectedLayout.data.name === layout.data.name}
                            style={{
                              background: snapshot.isDragging ? 'var(--dark-blue)' : undefined,
                            }}
                            dataTestId="layout-element"
                            rowElement={layout}
                            moreMenuOptions={getMenuOptions(
                              layout.data.name,
                              Boolean(selectedLayout && layout.uri === selectedLayout.uri),
                            )}
                            onRename={onRenameClick}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </S.ScrollableContent>
      <InspectorFooter
        hideConfirm
        hideActions={false}
        hideCancel
        actions={
          isCreatingANewLayout ? (
            <S.NewLayoutWrapper>
              <StyledInput
                data-testid="create-new-layout-input"
                value={newLayoutName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewLayoutName(e.target.value)}
                onKeyDown={onKeyDown}
                width="100vh"
              />
              <ConfirmInputButton
                data-testid="confirm-btn"
                onClick={submitNewLayout}
                disabled={newLayoutName === '' || newLayoutName === 'Default'}
              >
                <Done style={{ color: 'var(--text)', fontSize: '16px' }} />
              </ConfirmInputButton>
            </S.NewLayoutWrapper>
          ) : (
            <SecondaryButton data-testid="create-new-layout-btn" onClick={() => setIsCreatingANewLayout(true)}>
              + Create Layout
            </SecondaryButton>
          )
        }
      ></InspectorFooter>
    </Inspector>
  );
};

const mapStateToProps = (state: RootState, ownProps: any): ReduxProps => ({
  webLayouts: layoutSelector(state, ownProps.clientViewUri),
});
const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatch => ({
  createLayout: (layout: Layout) => dispatch(createLayout(layout)),
  updateLayout: (layout: Layout) => dispatch(updateLayout(layout)),
  deleteLayout: (layout: Layout) => dispatch(deleteLayout(layout)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LayoutManagerInspectorComponent);
