import { getVirtusDate } from '@virtus/common/utils/dateFormater';
import { DataType } from 'src/DxDataGrid/utils/mapSchemaVirtus';
import { getCellExcelNumeralFormat } from 'src/ExportToExcel/ExportToExcel';
import { parseDataTypeValue } from 'src/parsers/parseDataTypeValueVirtus';

export type HorizontalAlignment =
  | 'center'
  | 'centerContinuous'
  | 'distributed'
  | 'fill'
  | 'general'
  | 'justify'
  | 'left'
  | 'right';

export const DefaultColumnWidth = 25;

export const DateFormat = 'mm/dd/yyyy';
export const DateFormatFn = getVirtusDate;
export const NumeralFormatFn = getCellExcelNumeralFormat;
export const DataFormatFn = parseDataTypeValue;

export const HorizontalAlignmentsByDataType: { [dataType in DataType]: HorizontalAlignment } = {
  id: 'left',
  string: 'left',
  boolean: 'left',
  date: 'right',
  datetime: 'right',
  number: 'right',
};

export const TitleConfig = {
  font: { bold: true, size: 20 },
};

export const DescriptionConfig = {
  font: { size: 16 },
};

export const FooterInfoConfig = {
  font: { size: 14 },
  border: { top: { style: 'thin' } },
};

export const HeaderCellConfig = {
  alignment: {
    // default alignment
    horizontal: 'center' as HorizontalAlignment,
  },
  border: { bottom: { style: 'thin' } },
  font: { size: 12, bold: true },
  style: {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFD3D3D3' },
    },
  },
};

export const GroupCellConfig = {
  alignment: {
    // default alignment
    horizontal: 'left' as HorizontalAlignment,
  },
  border: { bottom: { style: 'thin' } },
  font: { bold: true },
  style: {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFEDEDED' },
    },
  },
};

export const DataCellConfig = {
  alignment: {
    // default alignment
    horizontal: 'left' as HorizontalAlignment,
  },
};
