import styled from 'styled-components';

export const LayoutRow = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--text);
  cursor: pointer;
  padding: 10px 16px 10px 16px;
  font-size: 0.875rem;
  background-color: ${props => (props.active ? 'var(--listItemHoverDark)' : 'inherit')};
  border-left: ${props => (props.active ? '5px solid var(--default-blue)' : '5px solid transparent')};
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const LayoutEditBtn = styled.button`
  background-color: var(--label);
  cursor: pointer;
  height: 30px;
  width: 30px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 16px;
`;

export const EditLayoutWrapper = styled.div`
  width: 100%;
  display: flex;
`;
