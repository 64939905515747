import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

/* eslint-disable max-len */
export const ModellingIcon: React.SFC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g id="Icon_24x24_settings-2">
      <path id="frame" fill="none" d="M0 0h24v24H0z" />
      <path
        id="icon"
        d="M6.3 10.743H4.151a.262.262 0 0 1-.263-.225l-.2-1.424a3.939 3.939 0 0 1-.907-.526L1.439 9.1a.272.272 0 0 1-.093.016.265.265 0 0 1-.234-.134L.037 7.128A.271.271 0 0 1 .1 6.784L1.235 5.9a4.161 4.161 0 0 1-.035-.529 4.159 4.159 0 0 1 .038-.526L.1 3.959a.264.264 0 0 1-.064-.344l1.075-1.859a.263.263 0 0 1 .23-.137.268.268 0 0 1 .1.019l1.338.537a4.1 4.1 0 0 1 .907-.526l.2-1.423A.262.262 0 0 1 4.151 0H6.3a.262.262 0 0 1 .263.226l.2 1.423a3.962 3.962 0 0 1 .908.526l1.338-.537a.272.272 0 0 1 .093-.016.264.264 0 0 1 .234.134l1.075 1.859a.271.271 0 0 1-.065.344l-1.133.886a4.277 4.277 0 0 1 .038.526 4.256 4.256 0 0 1-.038.526l1.133.886a.271.271 0 0 1 .065.344L9.34 8.986a.261.261 0 0 1-.23.137.266.266 0 0 1-.1-.019l-1.335-.537a4.089 4.089 0 0 1-.908.526l-.2 1.424a.262.262 0 0 1-.267.226zM5.226 3.491a1.88 1.88 0 1 0 1.88 1.88 1.882 1.882 0 0 0-1.88-1.88z"
        className="cls-2"
        transform="translate(12.27 1)"
      />
      <path
        id="icon-2"
        d="M6.3 10.743H4.151a.262.262 0 0 1-.263-.225l-.2-1.424a3.939 3.939 0 0 1-.907-.526L1.439 9.1a.272.272 0 0 1-.093.016.265.265 0 0 1-.234-.134L.037 7.128A.271.271 0 0 1 .1 6.784L1.235 5.9a4.161 4.161 0 0 1-.035-.529 4.159 4.159 0 0 1 .038-.526L.1 3.959a.264.264 0 0 1-.064-.344l1.075-1.859a.263.263 0 0 1 .23-.137.268.268 0 0 1 .1.019l1.338.537a4.1 4.1 0 0 1 .907-.526l.2-1.423A.262.262 0 0 1 4.151 0H6.3a.262.262 0 0 1 .263.226l.2 1.423a3.962 3.962 0 0 1 .908.526l1.338-.537a.272.272 0 0 1 .093-.016.264.264 0 0 1 .234.134l1.075 1.859a.271.271 0 0 1-.065.344l-1.133.886a4.277 4.277 0 0 1 .038.526 4.256 4.256 0 0 1-.038.526l1.133.886a.271.271 0 0 1 .065.344L9.34 8.986a.261.261 0 0 1-.23.137.266.266 0 0 1-.1-.019l-1.335-.537a4.089 4.089 0 0 1-.908.526l-.2 1.424a.262.262 0 0 1-.267.226zM5.226 3.491a1.88 1.88 0 1 0 1.88 1.88 1.882 1.882 0 0 0-1.88-1.88z"
        className="cls-2"
        transform="translate(1.27 12)"
      />
    </g>
    <path
      id="icon-3"
      d="M1.759 1.75L0 0v4.375h4.4l-1.772-1.76a3.9 3.9 0 0 1 6.214 1.76L10 4a5.132 5.132 0 0 0-8.241-2.25z"
      className="cls-2"
      transform="translate(21.861 16.789) rotate(135)"
    />
    <path
      id="icon-4"
      d="M1.759 1.75L0 0v4.375h4.4l-1.772-1.76a3.9 3.9 0 0 1 6.214 1.76L10 4a5.132 5.132 0 0 0-8.241-2.25z"
      className="cls-2"
      transform="translate(1.697 7.768) rotate(-45)"
    />
  </SvgIcon>
);
