import { CheckBox } from 'devextreme-react';
import { formatNumber } from '@virtus/glide/src/utils/formatters';
import { GroupItem, Summary } from 'devextreme-react/data-grid';
import React, { useState } from 'react';
import { CustomCellRenderKeyProps, DxColumn, DxDataType, DxSummary, DxSummaryType } from 'src/DxDataGrid/DxDataGrid';
import { ValidationRules } from 'src/DxDataGrid/DxDataGrid.model';
import { ILayout } from 'src/DxDataGrid/model/DxDataGrid.model';
import { PriceChangeCellRender } from '../cellRenders/price-change-cell-render/price-change-cell-render';
import { updateOnLoadCellValues } from '@virtus/glide/src/utils/cell-calculation-field-rule';
import { getGlideFormatParams } from '@virtus/glide/src/utils/formatter';

export enum FieldRuleType {
  Filter = 'Filter',
  ReadOnly = 'Read Only',
  Required = 'Required',
  Valid = 'Valid',
  Value = 'Value',
  Hidden = 'Hidden',
}

type FieldRule = {
  ruleFn: (...args: any[]) => boolean | string | number | undefined | null;
  ruleType: FieldRuleType;
};

export interface GlideDataSource {
  schema: GlideSchema[];
  data: any;
  fieldRules?: {
    [fieldName: string]: FieldRule[];
  };
  webLayouts?: ILayout[];
  [key: string]: any;
}

export enum GlideDataType {
  DateTime = 'DateTime',
  Object = 'Object',
  ObjectCollection = 'ObjectCollection',
  String = 'String',
  LargeString = 'LargeString',
  Decimal = 'Decimal',
  Bit = 'Bit',
}

export enum GroupItemSummaryType {
  Sum = 'sum',
  Average = 'avg',
  Custom = 'custom',
  Max = 'max',
  Min = 'min',
  Count = 'count',
}

export const HIDE_FIELDS_SUMMARY_LIST = [
  'Market Map Last Price',
  'Price',
  'Price (Traded)',
  'Spot Days',
  'FX Rate',
  'Floor',
  'PX Mid(BBG)',
  'PX Mid(Markit)',
  'PX Ask(BBG)',
  'PX Ask(Markit)',
  'PX Bid(BBG)',
  'PX Bid(Markit)',
  'Coupon',
  'All in rate',
  'Spread',
  'Target Price',
  'Commitment Percentage',
  'PIK Rate',
];

interface SummaryDataTypeProps {
  dataType?: GlideDataType;
  summaryType?: DxSummaryType;
  totalItemSummary?: any;
}

export interface GlideSummaryOptions {
  summaryKeyProps?: DxSummary[];
  summaryDataTypeProps?: SummaryDataTypeProps;
}

export type GlideDataTypeMap = { [key in GlideDataType]: DxDataType | 'ObjectCollection' };

export const glideDataTypeMap: GlideDataTypeMap = {
  DateTime: 'date',
  Object: 'object', // Should have been resolved
  String: 'string',
  LargeString: 'string',
  Decimal: 'number',
  // Exception: we will resolve object collections through Glide Table Modal
  ObjectCollection: 'ObjectCollection', // Should have been resolved
  Bit: 'boolean',
};

export interface GlideSchema {
  data_type: GlideDataType;
  display_name: string; // ⚠️ ♻️  Will be deleted when migration have finished (we will use caption). We'll use caption (ask Yann): https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/39467/
  caption?: string;
  description?: string;
  accessor?: string; // ⚠️ ♻️  Will be deleted when migration have finished (we will use dataField). We'll use dataField (ask Yann): https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/39467/
  dataField?: string;
  category?: string;
  object_type?: string;
  format?: string;
  editCellComponent?: DxColumn['editCellComponent'];
  validationRules?: ValidationRules[];
  editProps?: {
    apiRules: any[];
    parentValues: object;
  };
  lookups?: any;
  calculateCellValue?: (e: any) => void;
  calculation_script_js?: string;
  style?: { [key: string]: { is_editable: boolean } };
  aggregation_calculation?: keyof typeof GroupItemSummaryType;
}

/**
 * Returns custom component for column editCellComponent.
 * @param props
 * @returns
 */
const EditCellComponent = (props: any) => {
  const checkboxValue = props.data.value != null ? props.data.value : undefined;
  const [value, setValue] = useState(checkboxValue);
  const onChange = (e: any) => {
    props.data.setValue(e.value, e.value ? 'Yes' : 'No');
    setValue(e.value);
  };
  return <CheckBox onValueChanged={onChange} value={value} />;
};

/**
 * Generate the summary: Use the default id, optionally add custom key summary and allow for default summary for data types
 * @param dataSourceSchema
 * @param options
 * @param calculateCustomSummary
 */
export const getSummaryGlide = (
  dataSourceSchema: GlideSchema[],
  { summaryKeyProps, summaryDataTypeProps }: GlideSummaryOptions,
  calculateCustomSummary?: any,
) => {
  return (
    <Summary
      recalculateWhileEditing
      skipEmptyValues
      calculateCustomSummary={!summaryKeyProps ? undefined : calculateCustomSummary}
    >
      {/* Add custom summary key */}
      {summaryKeyProps &&
        summaryKeyProps.map((gi: DxSummary) => (
          <GroupItem name={gi.name} key={gi.column} column={gi.column} alignByColumn summaryType={gi.summaryType} />
        ))}
      <GroupItem name="Glide Id" column="Glide Id" alignByColumn summaryType={GroupItemSummaryType.Count} />
      {summaryDataTypeProps && summaryDataTypeProps.totalItemSummary}

      {/* Config driven default summary grouping */}
      {dataSourceSchema &&
        dataSourceSchema
          .filter(
            (col: GlideSchema) =>
              col.aggregation_calculation &&
              col.aggregation_calculation !== 'Custom' &&
              HIDE_FIELDS_SUMMARY_LIST.indexOf(col.display_name) < 0,
          )
          .map((col: GlideSchema) => {
            const customizeTextHandler = (data: any) => {
              if (col.format) {
                const val = formatNumber(
                  data.value,
                  col.format.toLowerCase(),
                  col.style ? Object.values(col.style)[0].is_editable : true,
                );
                return `${val}`;
              }
              return Math.round(data.value * 100) / 100;
            };

            return (
              <GroupItem
                key={col.display_name}
                name={col.display_name}
                column={col.display_name}
                alignByColumn
                summaryType={GroupItemSummaryType[col.aggregation_calculation!]}
                customizeText={customizeTextHandler}
                skipEmptyValues={!(GroupItemSummaryType[col.aggregation_calculation!] === 'avg')}
              />
            );
          })}
    </Summary>
  );
};

export const mapSchemaGlide = (
  dataSource: GlideDataSource,
  customCellRenderKeyProps: CustomCellRenderKeyProps,
  customComponents: any,
) => {
  const schema: DxColumn[] = [];
  if (!dataSource?.schema?.find(({ display_name }: any) => display_name === 'Glide ID')) {
    schema.push({
      key: 'Glide ID',
      dataType: 'LargeString',
      dataField: 'Glide ID',
      allowEditing: false,
    });
  }
  // Todo: memoize schema!
  // Create Dx Columns from schema
  dataSource?.schema?.forEach(
    ({
      display_name, // ⚠️ ♻️ This is the same than caption, will be deleted (we will use caption). Migration in progress (ask Yann): https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/39467/
      format,
      data_type,
      accessor, // ⚠️ ♻️ This is the same than dataField, will be deleted (we will use dataField). Migration in progress (ask Yann): https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/39467/
      dataField,
      fixed,
      fixedPosition,
      sortOrder,
      sortIndex,
      minWidth = 100,
      caption,
      validationRules,
      lookups,
      editProps,
      calculateCellValue,
      style,
      object_type,
      calculation_script_js,
    }: GlideSchema &
      Pick<
        DxColumn,
        'fixed' | 'fixedPosition' | 'sortIndex' | 'sortOrder' | 'minWidth' | 'caption' | 'validationRules'
      >) => {
      let column: DxColumn = {
        key: caption || display_name, // ⚠️ ♻️ Remove accessor and replace display_name by caption when migration have finished (ask Yann): https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/39467/
        dataField: dataField || accessor || display_name, // ⚠️ ♻️ Remove accessor and replace display_name by caption when migration have finished (ask Yann): https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/39467/
        dataType: glideDataTypeMap[data_type] as DxDataType,
        fixed,
        fixedPosition,
        minWidth: minWidth || 100,
        sortOrder,
        allowEditing: style ? Object.values(style)[0].is_editable : true,
        sortIndex,
        caption: caption || display_name, // ⚠️ ♻️ Remove display_name and use caption when migration have finished (ask Yann): https://virtusllc.visualstudio.com/VirtusWebPortal/_workitems/edit/39467/
        validationRules,
        lookups: lookups && typeof lookups === 'string' ? { values: JSON.parse(lookups) } : undefined,
        editProps,
        calculateCellValue,
        objectType: object_type && object_type.includes('/') ? object_type.split('/')[1] : '',
      };

      const groupCellRender = (cellData: any): any => {
        const { value } =
          cellData.summaryItems.find(
            ({ summaryType }: { summaryType: string }) => summaryType === GroupItemSummaryType.Count,
          ) || {};
        return (
          <div className="numeric-cell">
            {column.key}: {cellData.text} {value ? ` (Count: ${value})` : ''}
          </div>
        );
      };

      // Custom cell render based on Glide schema
      switch (column.dataType) {
        // Displaying using locale date has performances issues which leads to crash with DX grid
        // https://virtusllc.visualstudio.com/AlphaKinetic/_workitems/edit/97584

        case glideDataTypeMap.DateTime:
          column.format = format;
          column.cellRender = (cellData: any): any => {
            const hasValueAndFormat = cellData.value && column.format;
            if (hasValueAndFormat) {
              // Below line is used to identify if we need to show DateTime or only Date
              // As we get data_type as 'DateTime' and used format if need to show time
              let columnFormat = column.format;
              if (columnFormat.display_name) {
                columnFormat = columnFormat.display_name;
              }
              const hasTime = columnFormat.split(' ').length > 1;
              const formattedValue = hasTime
                ? new Date(cellData.value).toLocaleString(navigator.language)
                : new Date(cellData.value).toLocaleDateString(navigator.language);
              return <div>{formattedValue}</div>;
            }
            if (cellData.value) {
              return new Date(cellData.value).toLocaleString(navigator.language);
            }
          };
          break;

        case glideDataTypeMap.ObjectCollection: {
          const TableModal: any = customComponents && customComponents[glideDataTypeMap.ObjectCollection];
          if (TableModal) {
            column.cellRender = (cellData: any): any => {
              if (cellData.value) {
                return (
                  <TableModal
                    isTable
                    key={cellData.data._uri}
                    uris={cellData.value}
                    title={cellData.data['Display Name']}
                    fieldName={column.dataField}
                  />
                );
              }
            };
          }
          column.filterOperations = ['=', '<>', '<', '>', '<=', '>='];
          column.headerFilter = {
            allowSearch: false,
            dataSource: (e: any) => {
              e.dataSource.paginate = false;
              e.dataSource.postProcess = (result: any) => {
                const dataSet = new Set(result.map((item: any) => (item.key ? item.key.length : 0)));
                return [...dataSet]
                  .sort((a: any, b: any) => a - b)
                  .map(value => ({
                    key: value,
                    text: value,
                    value: value,
                  }));
              };
            },
          };
          column.calculateFilterExpression = function (filterValue: any, selectedFilterOperation: any) {
            const column = this as any;
            if (selectedFilterOperation) {
              return [column.calculateDisplayValue, selectedFilterOperation, filterValue];
            }
            // eslint-disable-next-line prefer-rest-params
            return column.defaultCalculateFilterExpression(...arguments);
          };
          column.calculateDisplayValue = (rowData: any) => {
            return rowData[column.key] ? rowData[column.key].length : 0;
          };
          break;
        }
        case glideDataTypeMap.LargeString: {
          // set the fix column width for large string to manage it for grids
          column.width = 200;
          break;
        }
        case glideDataTypeMap.Decimal:
          column.groupCellRender = (cellData: any): any => groupCellRender(cellData);

          if (format) {
            const formatParams = getGlideFormatParams(format);
            column.headerFilter = {
              allowSearch: !formatParams.formatType.includes('p'),
              dataSource: (e: any) => {
                e.dataSource.postProcess = (result: any) => {
                  return result.map((item: any) => {
                    let formattedText = item.text;
                    if (!isNaN(+formattedText)) {
                      formattedText = formatNumber(+item.text, formatParams.formatType, column.allowEditing);
                    }
                    return {
                      value: item.value,
                      text: formattedText,
                    };
                  });
                };
              },
            };
          }
          if (column.key === 'Market Map Last Price') {
            column.dataType = 'String';
            column.alignment = 'right';
            column.width = 200;
            column.cellRender = (cellData: any) => <PriceChangeCellRender cellData={cellData} format={format} />;
            column.filterOperations = ['=', '<>', '<', '>', '<=', '>='];
            column.headerFilter = {
              dataSource: (e: any) => {
                e.dataSource.postProcess = (result: any) => {
                  return result.map((item: any) => {
                    const newText = item.text ? item.text.split(' ')[0] : item.text;
                    return {
                      key: item.key,
                      value: item.value,
                      text: newText,
                    };
                  });
                };
              },
            };
            column.calculateFilterExpression = function (filterValue: any, selectedFilterOperation: any) {
              const self = this as any;
              const _filterValue =
                filterValue && selectedFilterOperation ? filterValue.toString().split(' ')[0] : filterValue;
              return [self.calculateDisplayValue, selectedFilterOperation || 'contains', _filterValue];
            };
            column.calculateDisplayValue = (rowData: any) => {
              return rowData[column.key]
                ? typeof rowData[column.key] === 'number'
                  ? rowData[column.key]
                  : +rowData[column.key].split(' ')[0]
                : rowData[column.key];
            };
          } else {
            column.calculateDisplayValue = (rowData: any) => {
              const formattedVal = format
                ? formatNumber(Number(rowData[column.dataField]), format.toLowerCase(), column.allowEditing)
                : Number(rowData[column.dataField]).toLocaleString(navigator.language, {
                    minimumFractionDigits: 2,
                  });
              // Percent Values multiplied by 100 when they are for readonly mode and not for batch editing
              if (
                format?.toLocaleLowerCase().includes('p') &&
                !column.editProps?.apiRules.find((fieldRule: FieldRule) => fieldRule.ruleType === 'Value')
              ) {
                return `${(rowData[column.dataField] * 100).toFixed(+format?.charAt(format.length - 1))}%`;
              }
              return formattedVal;
            };
          }
          column.calculateCellValue = (rowData: any) => {
            if (calculation_script_js) {
              const value = updateOnLoadCellValues(rowData, calculation_script_js, dataSource.schema);
              return value ?? rowData[column.dataField];
            }
            return rowData[column.dataField];
          };
          break;
        case glideDataTypeMap.Object:
          if (column.lookups) {
            column.headerFilter = {
              dataSource: () => {
                return column.lookups?.values?.reduce(
                  (acc, curr) => [...acc, { text: curr['display_name'], value: curr['uri'] }],
                  [],
                );
              },
            };
          }
          column.filterOperations = [
            'contains',
            'notcontains',
            'startswith',
            'endswith',
            '=',
            '<>',
            'anyof',
            'noneof',
            'isblank',
            'isnotblank',
          ];
          column.calculateFilterExpression = function (filterValue: any, selectedFilterOperation: any) {
            const column = this as any;
            const customCalculateCellDefaultFilter = (rowData: any) => rowData[column.dataField];

            return selectedFilterOperation
              ? [column.calculateCellValue, selectedFilterOperation, filterValue]
              : [customCalculateCellDefaultFilter, 'contains', filterValue];
          };
          column.calculateCellValue = (rowData: any) => {
            return rowData[column.dataField] && column?.lookups
              ? column?.lookups?.values?.find(value => rowData[column.dataField] === value['uri'])['uri']
              : rowData[column.dataField];
          };
          column.groupCellRender = (cellData: any): any => groupCellRender(cellData);
          if (!column?.lookups) {
            column.calculateDisplayValue = (rowData: any) => rowData[column.dataField];
          }
          break;
        case glideDataTypeMap.Bit:
          column.cellRender = (cellData: any) => {
            const checkboxValue = cellData.value != null ? cellData.value : undefined;
            return <CheckBox value={checkboxValue} readOnly={true} />;
          };
          column.editCellComponent = (props: any) => <EditCellComponent {...props} />;
          break;
      }

      // extend column custom properties
      if (customCellRenderKeyProps && (customCellRenderKeyProps as any)[column.key]) {
        column = { ...column, ...(customCellRenderKeyProps as any)[column.key] };
      }

      schema.push(column);
    },
  );
  return schema;
};

/**
 * Method to transform all the decimal and percentage columns in excel cell while exporting data of DxDataGrid.
 * All the decimal columns original value will get transformd exaclty in the same manner how they have been transformed on UI DxDataGrid Cells.
 */
export const glideExcelExport = (data: any, dataSource: GlideDataSource) => {
  if (data.gridCell.rowType === 'data') {
    const filteredSchema = dataSource?.schema?.filter(
      (item: any) =>
        item.display_name === data.gridCell.column.dataField || item.display_name === data.gridCell.column.caption,
    )[0] as GlideSchema;
    const cellVal = data.value;
    const { format, data_type, style } = filteredSchema ?? {};
    if (format && data_type === GlideDataType.Decimal && (cellVal || cellVal === 0)) {
      const formattedValue = formatNumber(
        cellVal,
        format.toLowerCase(),
        style ? Object.values(style)[0]?.is_editable : false,
      );
      data.value = formattedValue;
    }
  }
};
