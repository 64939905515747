import React, { useState, useEffect } from 'react';
import { find, groupBy } from 'lodash';
import * as S from './glide-list.style';
import { Search as SearchIcon } from '@material-ui/icons';
import { sortByParams } from './glide-list-mapper';
import SelectMenu from '@virtus/components/SelectMenu';
import { SecondaryButton } from '@virtus/components/Buttons';
export interface ListingComponentProps {
  commentaryData: { [key: string]: any };
  itemTemplate: (data: any) => void;
  defaultSortBy?: { field: string; orderBy: 'asc' | 'desc' };
  searchExpr?: string[];
  groupByDropDownList?: any[];
  sortByDropDownList?: any[];
  onSelectedItem: (selectedItem: any) => void;
  groupTemplate?: (data: any) => void;
  setSelectedItemIndex: (index: number) => void;
  isSearchEnabled?: boolean;
  fromModal?: boolean;
  createNewCommentEditor?: (editor: any) => void;
  actionButtonText?: string;
}
export interface GlideListProps {
  id: number;
  title: string;
  category: string;
  commentdate: number;
  commenturi: string;
  comments: string;
  creditdetails: string;
  displayname: string;
  username: string;
}
export interface GroupedDataProps {
  key: string;
  items: GlideListProps[];
}
const GlideList = ({
  groupByDropDownList,
  sortByDropDownList,
  defaultSortBy,
  searchExpr,
  commentaryData,
  onSelectedItem,
  itemTemplate,
  groupTemplate,
  setSelectedItemIndex,
  isSearchEnabled = true,
  fromModal,
  createNewCommentEditor,
  actionButtonText,
}: ListingComponentProps) => {
  const [isGroup, setIsGroup] = useState<boolean>(false);
  const [isSort, setIsSort] = useState<boolean>(false);
  const [listDataSource, setListDataSource] = useState<any>([]);
  const [dataSourceList, setDataSourceList] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [groupByDropDownValue, setGroupByDropDownValue] = useState<number>(-1);
  const [sortByDropDownValue, setSortByDropDownValue] = useState<number>(-1);
  useEffect(() => {
    FilterListData(
      sortByDropDownValue !== -1,
      groupByDropDownValue !== -1,
      commentaryData,
      searchTerm,
      sortByDropDownValue,
      groupByDropDownValue,
    );
    setDataSourceList(commentaryData);
    setSelectedItemIndex(commentaryData && commentaryData.length && commentaryData[0].id);
  }, [commentaryData]);

  const changeGroupByDropdown = (event: any) => {
    setGroupByDropDownValue(event.target.value);
    changeGroupByDropdownValue(event.target.value);
  };

  const changeSortByDropdown = (event: any) => {
    setSortByDropDownValue(event.target.value);
    changeSortByDropdownValue(event.target.value);
  };

  const groupData = (dataSource: any, dropDownSelectedValue: string): GroupedDataProps[] => {
    const groupedData: GroupedDataProps[] = [];
    if (dataSource && dataSource.length) {
      const grouped = groupBy(dataSource, dropDownSelectedValue);
      Object.entries(grouped).forEach(([key, value]: any) => {
        groupedData.push({ key: key, items: value });
      });
    }
    return groupedData;
  };

  const getFilteredDropdownValue = (lists: any, selectedId: number) => {
    if (lists && lists.length) {
      const filteredData = lists.filter((c: any) => c.id == selectedId);
      if (filteredData && filteredData.length > 0) return filteredData[0];
    }
    return [];
  };

  const changeGroupByDropdownValue = (groupByOptionValue: number) => {
    if (dataSourceList && dataSourceList.length > 0) {
      if (groupByOptionValue != -1) {
        setIsGroup(true);
        FilterListData(isSort, true, dataSourceList, searchTerm, sortByDropDownValue, groupByOptionValue);
      } else {
        setIsGroup(false);
        FilterListData(isSort, false, dataSourceList, searchTerm, sortByDropDownValue, groupByOptionValue);
      }
    }
  };

  const changeSortByDropdownValue = (sortByOptionValue: number) => {
    if (dataSourceList && dataSourceList.length > 0) {
      if (sortByOptionValue > -1) {
        setIsSort(true);
        FilterListData(true, isGroup, dataSourceList, searchTerm, sortByOptionValue, groupByDropDownValue);
      } else {
        setIsSort(false);
        FilterListData(false, isGroup, dataSourceList, searchTerm, sortByOptionValue, groupByDropDownValue);
      }
    }
  };

  const groupFilteredData = (filteredData: any, groupByDropDownValues?: number) => {
    if (groupByDropDownValues && groupByDropDownValues === -1 && defaultSortBy) {
      setListDataSource(sortByParams(defaultSortBy.orderBy, dataSourceList, defaultSortBy.field));
      return;
    }
    const dropDownSelectedValue = getFilteredDropdownValue(
      groupByDropDownList,
      groupByDropDownValues ? groupByDropDownValues : groupByDropDownValue,
    );
    const groupedData = groupData(filteredData, dropDownSelectedValue.groupfield);
    return groupedData;
  };

  const sortFilteredData = (filteredData: any, sortByDropDownValues?: number) => {
    const dropDownSelectedValue = getFilteredDropdownValue(
      sortByDropDownList,
      sortByDropDownValues ? sortByDropDownValues : sortByDropDownValue,
    );
    const sortedArray = sortByParams(
      !dropDownSelectedValue && defaultSortBy ? defaultSortBy.orderBy : dropDownSelectedValue.orderby,
      filteredData,
      !dropDownSelectedValue && defaultSortBy ? defaultSortBy.field : dropDownSelectedValue.orderfield,
    );
    return sortedArray;
  };

  const searchIfTermExists = (data: any, id: number) => {
    const foundData = find(data, { id: id });
    return foundData;
  };

  const FilterListData = (
    isSort: boolean,
    isGroup: boolean,
    dataSourceList: any,
    term: string,
    sortByDropDownValue?: number,
    groupByDropDownValue?: number,
  ) => {
    let filteredData: GlideListProps[] = [];
    if (term) {
      if (dataSourceList && dataSourceList.length > 0) {
        dataSourceList.map((lds: any) => {
          Object.keys(lds).map((k: string) => {
            if (
              searchExpr &&
              searchExpr.includes(k) &&
              lds[k] &&
              lds[k].toString().toLowerCase().indexOf(term.toLowerCase()) > -1 &&
              !searchIfTermExists(filteredData, lds.id)
            )
              filteredData.push(lds);
          });
        });
      }
    }
    if (!filteredData.length && dataSourceList.length && !term) filteredData = dataSourceList;
    if (isSort && !isGroup) {
      const sortFilterData = sortFilteredData(filteredData, sortByDropDownValue);
      setListDataSource(sortFilterData);
      if (sortFilterData && sortFilterData.length > 0) {
        onSelectedItem(sortFilterData[0]);
        setSelectedItemIndex(sortFilterData[0].id);
      } else {
        onSelectedItem([]);
      }
    } else if (isGroup && !isSort) {
      const groupedFilterData = groupFilteredData(filteredData, groupByDropDownValue);
      setListDataSource(groupedFilterData);
      if (groupedFilterData && groupedFilterData.length > 0) {
        onSelectedItem(groupedFilterData[0].items[0]);
        setSelectedItemIndex(groupedFilterData[0].items[0].id);
      } else {
        onSelectedItem([]);
      }
    } else if (isGroup && isSort) {
      const sortFilterValues = sortFilteredData(filteredData, sortByDropDownValue);
      const groupedFilterValues = groupFilteredData(sortFilterValues, groupByDropDownValue);
      setListDataSource(groupedFilterValues);
      if (groupedFilterValues && groupedFilterValues.length > 0) {
        onSelectedItem(groupedFilterValues[0].items[0]);
        setSelectedItemIndex(groupedFilterValues[0].items[0].id);
      } else {
        onSelectedItem([]);
      }
    } else {
      if ((!term || (!isSort && !isGroup)) && defaultSortBy) {
        setListDataSource(sortByParams(defaultSortBy.orderBy, filteredData, defaultSortBy.field));
      } else {
        setListDataSource(filteredData);
      }
      if (filteredData && filteredData.length) {
        onSelectedItem(filteredData[0]);
        setSelectedItemIndex(filteredData[0].id);
      } else {
        onSelectedItem([]);
      }
    }
  };
  const createNewCommentEditorAction = () => {
    createNewCommentEditor && createNewCommentEditor(true);
  };
  const onChangeSearchTerm = (term: string) => {
    setSearchTerm(term);
    FilterListData(isSort, isGroup, dataSourceList, term);
  };

  const onChange = (e: any) => onChangeSearchTerm(e.target.value);
  return (
    <S.BlogOuterWrapper data-testid="glide-glidelist-container">
      <S.BlogInnerWrapper fromModal={fromModal}>
        <SelectMenu
          value={groupByDropDownValue}
          onChange={changeGroupByDropdown}
          name="groupByBlogs"
          options={groupByDropDownList}
          valueKey="id"
          displayKey="text"
          style={{ flex: 1, marginRight: '5px', padding: '4px', width: '100%' }}
          customSelectStyles={{ select: { whiteSpace: 'normal', paddingRight: '0px', width: '100%' } }}
          filterType="Group By"
          data-testid="glide-glidelist-selectmenu-groupby"
          labelFontSize="12px"
          styledinputheight="36px"
          classes={{ paperStyle: 'miui-select-paper-style' }}
        />
        <SelectMenu
          value={sortByDropDownValue}
          onChange={changeSortByDropdown}
          name="sortByBlogs"
          options={sortByDropDownList}
          valueKey="id"
          displayKey="text"
          style={{ flex: 1, marginRight: '5px', padding: '4px', width: '100%' }}
          customSelectStyles={{ select: { whiteSpace: 'normal', paddingRight: '0px', width: '100%' } }}
          filterType="Sort By"
          data-testid="glide-glidelist-selectmenu-sortby"
          labelFontSize="12px"
          styledinputheight="36px"
          classes={{ paperStyle: 'miui-select-paper-style' }}
        />
        <S.CommentButton>
          <SecondaryButton
            style={{ height: '36px', maxHeight: '36px', padding: '6px 5px' }}
            data-testid="glide-glidelist-button-newcomment"
            onClick={createNewCommentEditorAction}
          >
            {actionButtonText}
          </SecondaryButton>
        </S.CommentButton>
      </S.BlogInnerWrapper>
      {isSearchEnabled && (
        <S.StyledSearchInput key="searchInputGlidelist" style={{ padding: '10px 6px 5px 6px' }}>
          <SearchIcon style={{ color: 'white', paddingLeft: '4px', height: '100%' }} />
          <S.StyledInput
            id="Search"
            label="Search"
            className="search-input"
            onChange={onChange}
            placeholder="Search"
            data-testid="glide-glidelist-search-text-input"
            autoFocus={true}
            flex={1}
          />
        </S.StyledSearchInput>
      )}
      <S.ListItem data-testid="glide-glidelist-list-item">
        {!isGroup && listDataSource.length > 0
          ? listDataSource.map((lds: any) => itemTemplate(lds))
          : listDataSource.length > 0 && groupTemplate && listDataSource.map((lds: any) => groupTemplate(lds))}
      </S.ListItem>
    </S.BlogOuterWrapper>
  );
};

export default GlideList;
