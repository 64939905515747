export const isJson = (input: any): boolean => {
  const inputStr = typeof input === 'string' ? input : JSON.stringify(input);
  try {
    JSON.parse(inputStr);
    return true;
  } catch (e) {
    return false;
  }
};

import App from './App';
export default App;
