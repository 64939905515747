import React from 'react';
import * as S from './compliance-cell-render.style';

interface ComplianceCellRenderProps {
  renderValue: string;
  isDarkTheme: boolean;
}

interface CellColor {
  [complianceStatus: string]: string;
}

const lightTextColor: CellColor = {
  ['Breach']: 'var(--errorLight)',
  ['Rejected']: 'var(--errorLight)',
  ['Approved']: 'var(--fis-green)',
  ['Passed']: 'var(--fis-green)',
};

const darkTextColor: CellColor = {
  ['Breach']: 'var(--red)',
  ['Rejected']: 'var(--red)',
  ['Approved']: 'var(--dark-green)',
  ['Passed']: 'var(--dark-green)',
};

export const ComplianceCellRender = ({ renderValue, isDarkTheme }: ComplianceCellRenderProps) => (
  <S.ComplianceCellStyle color={isDarkTheme ? lightTextColor[renderValue] : darkTextColor[renderValue]}>
    {renderValue}
  </S.ComplianceCellStyle>
);
