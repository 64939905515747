import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';
import { variables } from 'src/theme';
import styled from 'styled-components';

/* eslint-disable max-len */
const GenesisIcon: React.SFC<Omit<SvgIconProps, 'color'> & { color?: string }> = ({ color, ...props }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <defs>
      <filter id="a" width="112.1%" height="131.3%" x="-6%" y="-15.7%" filterUnits="objectBoundingBox">
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" filter="url(#a)" transform="translate(-131 -16)">
      <path d="M131 16h24v24h-24z" />
      <path
        fill={color || '#333B43'}
        d="M138.97 20.649l3.224-3.224a1.14 1.14 0 0 1 1.612 0l3.224 3.224a1.14 1.14 0 0 1 0 1.612l-3.224 3.223a1.14 1.14 0 0 1-1.612 0l-3.224-3.223a1.14 1.14 0 0 1 0-1.612z"
      />
      <path
        fill={color || '#333B43'}
        d="M138.97 33.74l3.224-3.224a1.14 1.14 0 0 1 1.612 0l3.224 3.223a1.14 1.14 0 0 1 0 1.612l-3.224 3.224a1.14 1.14 0 0 1-1.612 0l-3.224-3.224a1.14 1.14 0 0 1 0-1.612z"
        opacity=".5"
      />
      <path
        fill={color || '#333B43'}
        d="M132.425 27.194l3.224-3.224a1.14 1.14 0 0 1 1.612 0l3.223 3.224a1.14 1.14 0 0 1 0 1.612l-3.223 3.224a1.14 1.14 0 0 1-1.612 0l-3.224-3.224a1.14 1.14 0 0 1 0-1.612z"
        opacity=".75"
      />
      <path
        fill={color || '#333B43'}
        d="M145.516 27.194l3.223-3.224a1.14 1.14 0 0 1 1.612 0l3.224 3.224a1.14 1.14 0 0 1 0 1.612l-3.224 3.224a1.14 1.14 0 0 1-1.612 0l-3.223-3.224a1.14 1.14 0 0 1 0-1.612z"
        opacity=".25"
      />
    </g>
  </SvgIcon>
);

/** correct font size for `<Header>` */
export const GenesisHeaderIcon: React.SFC<{}> = () => (
  <StyledHeaderIcon>
    <GenesisIcon fontSize="inherit" />
  </StyledHeaderIcon>
);

const StyledHeaderIcon = styled.span`
  font-size: ${variables.headerIconFontSize};
`;
export default GenesisIcon;
