import { useEffect, useRef, useState } from 'react';

// A hook that allows to use the ref as a reference to the state value

export const useStateRef = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return [value, setValue, ref];
};
