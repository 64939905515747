import { ILayout } from 'src/components/grids/dxgrid-client-view/templates/Layouts/Layouts.model';

interface UpdatedLayout {
  name: string;
  owner: string;
  json_layout: string;
}

export const addViewNameToLayoutData = (layouts: ILayout[], viewUri: string) =>
  layouts.map(layout => ({ ...layout, viewUri }));

export const parseLayouts = (webLayouts: ILayout[] = []) => {
  return Array.isArray(webLayouts) ? webLayouts : mapUpdatedLayoutsToILayout(webLayouts);
};

/* web_layouts received when save or update an existing layout */
const mapUpdatedLayoutsToILayout = (updatedLayouts: { [key: string]: UpdatedLayout }): ILayout[] =>
  Object.entries(updatedLayouts).map(([key, value]: [string, UpdatedLayout]) => ({
    data: {
      name: value.name,
      json_layout: value.json_layout,
    },
    owner: value.owner,
    date: '',
    uri: key,
  }));
