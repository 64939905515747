import InputBase from '@material-ui/core/InputBase';
import { Warning } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

export interface InputWithErrorHandlingProps {
  showError: boolean;
  errorText?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  inputName?: string;
  type?: string;
}

const InputWithErrorHandling = ({
  showError,
  errorText,
  value,
  onChange,
  id,
  inputName,
  type,
}: InputWithErrorHandlingProps) => (
  <Wrapper>
    <ErrorIconContainer>{showError && <WarningIcon />}</ErrorIconContainer>
    <StyledInput
      value={value}
      onChange={onChange}
      error={Boolean(showError)}
      id={id}
      data-testid={id}
      name={inputName}
      type={type || 'text'}
    />
    {showError && (
      <FormErrorToast>
        <ArrowUp />
        {errorText}
      </FormErrorToast>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
`;

const ErrorIconContainer = styled.div`
  width: 22px;
`;
const WarningIcon = styled(Warning)`
  width: 16px;
  height: 16px;
`;

const ArrowUp = styled.div`
  position: absolute;
  top: -5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid red;
`;

const FormErrorToast = styled.div`
  position: absolute;
  height: 25px;
  top: 32px;
  left: -78px;
  background-color: red;
  padding: 5px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledInput = styled(InputBase)<{ error: boolean }>`
  width: 100%;
  height: 30px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  border: ${props => (props.error ? '1px solid red' : 'none')};
  padding-left: 10px;
  margin-bottom: 10px;
`;

export default InputWithErrorHandling;
