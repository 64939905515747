import InspectorGroup from '@virtus/components/Inspector/components/InspectorGroup';
import { Field, HeaderLabel, HeaderValue } from '@virtus/components/Inspector/styles';
import React from 'react';
import { GlideInspector, GlideInspectorProps } from 'src/components/inspectors/glide-inspector';
import { zIndex } from 'src/utils/constants';
import styled from 'styled-components';

export type FieldProp = { display_name: string; value: string };

const renderRows = (data: any) => (
  <StyledRows>
    {Object.keys(data).map((group: any, i: number) => (
      <InspectorGroup key={group} title={group} isExpanded={i === 0}>
        {data[group]
          .filter((field: FieldProp) => field.value)
          .map((field: FieldProp) => (
            <Field key={field.display_name}>
              <HeaderLabel>{field.display_name}</HeaderLabel>
              <HeaderValue>{field.value}</HeaderValue>
            </Field>
          ))}
      </InspectorGroup>
    ))}
  </StyledRows>
);

interface SearchInspectorProps {
  searchData: any;
  title: string;
  loading: boolean;
  onCloseInspector: () => void;
  children?: React.ReactNode;
}

export const SearchInspector = ({
  searchData,
  title,
  loading,
  onBack,
  onCloseInspector,
  children,
}: SearchInspectorProps & Pick<GlideInspectorProps, 'onBack'>) => (
  <GlideInspector
    onExpand={() => console.info('@To do: on expand change')}
    isExpanded={false}
    showBackButton={true}
    title={title}
    loading={loading}
    style={{ zIndex: zIndex.SearchInspector }}
    onClose={onCloseInspector}
    onBack={onBack}
  >
    {searchData && renderRows(searchData)}
    {children}
  </GlideInspector>
);

const StyledRows = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow: overlay;
  padding: 5px 25px 16px 25px;
`;
