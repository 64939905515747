import styled, { css } from 'styled-components';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
export const BottomPanelWrapper = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
  ${props =>
    props.isExpanded &&
    css`
      flex: 2;
    `};
  ${props =>
    !props.isExpanded &&
    css`
      flex: 0.24;
    `};
`;

export const ChevronDownButton = styled.div`
  height: 18px;
  position: absolute;
  right: 45%;
  top: -15px;
  z-index: 2;
  background-color: var(--default-blue);
  cursor: pointer;
  padding: 0px;
  margin: 1px;
  align-items: center;
  padding-top: -6px;
  width: 100px;
  color: white;
`;

export const RowSelectMessage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 9px;
`;

export const ArrowDown = styled(KeyboardArrowDown)`
  margin-left: 40%;
  margin-top: -4%;
`;

export const ArrowUp = styled(KeyboardArrowUp)`
  margin-left: 40%;
  margin-top: -4%;
`;
