import styled from 'styled-components';
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 5px;
  position: relative;
  line-height: 14px;
`;
