import NetworkInterface from '@virtus/common/auth/NetworkInterface';
import config from 'src/config';

const SearchService = () => {
  const glideNetworkInterface = new NetworkInterface({ ...config });

  const search = (searchTerm: string, searchType: string, caseSensitive: boolean) => {
    // eslint-disable-next-line  max-len
    const URL = `/glide/search?search_term=${searchTerm}&search_type=${searchType}&search_case_sensitive=${caseSensitive}`;
    return glideNetworkInterface.reduxQueryNetworkInterface(URL, 'GET', {});
  };

  return {
    search,
  };
};

export default SearchService;
