import styled from 'styled-components';
import List from 'devextreme-react/list';

export const ListSelection = styled(List)`
  .dx-list-item,
  .dx-list-item.dx-state-hover,
  .dx-list-select-all-label {
    color: var(--text);
  }

  .dx-checkbox-icon {
    border: 2px solid var(--text);
  }
  .dx-list-select-all:after {
    background-color: #5a5959;
  }
  .dx-list-select-all-label {
    visibility: hidden;
    position: relative;
  }
  .dx-list-select-all-label:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 15px;
    content: 'All';
  }

  .dx-list-item-before-bag.dx-list-select-checkbox-container {
    width: 30px;
  }
`;
