import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { FormElementInputComponent } from 'src/components/forms/form-elements';
import { StyledInput } from 'src/components/forms/form-elements/FormElements.style';
import FormErrorWrapper from 'src/components/forms/form-elements/form-fields/FormErrorWrapper';
import { useFieldRule } from 'src/components/forms/hooks/use-field-rule';

export const TextInput = ({
  field,
  value = '',
  error,
  onChange,
  hideLabel,
  formValues,
  customWidth,
}: FormElementInputComponent) => {
  const fieldUpdateFn = (nextValue: any) => {
    const event: Partial<React.SyntheticEvent> = {
      target: {
        value: nextValue,
      } as HTMLInputElement,
    };
    onChange(event);
  };

  const fieldRule = useFieldRule({
    fieldName: field.displayName,
    fieldRules: field.fieldRules,
    formValues,
    fieldUpdateFn,
  });
  if (fieldRule.hidden) return null;

  return (
    <FormErrorWrapper
      error={error}
      displayName={field.displayName}
      name={field.name}
      disabled={fieldRule.disabled || field.disabled}
      required={fieldRule.required || field.required}
      readonly={fieldRule.disabled || field.readonly}
      hideLabel={hideLabel}
      disableErrorWarning={field.disableErrorWarning}
      customWidth={customWidth}
    >
      {field.disabled || field.readonly || fieldRule.disabled ? (
        <Tooltip title={value}>
          <StyledInput
            data-testid={field.name}
            autoComplete="off"
            disabled={fieldRule.disabled || field.disabled || field.readonly}
            error={Boolean(error) && !(field.readonly || field.disabled)}
            onChange={onChange}
            value={value}
            name={field.name}
          />
        </Tooltip>
      ) : (
        <StyledInput
          data-testid={field.name}
          autoComplete="off"
          error={Boolean(error) && !(field.readonly || field.disabled)}
          onChange={onChange}
          value={value}
          name={field.name}
        />
      )}
    </FormErrorWrapper>
  );
};
