import { Inspector } from '@virtus/components/Inspector';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectComponents } from 'src/reducers/components';
import { GlideInspectorProps } from './glide-inspector.model';
import { PaperStyled } from './glide-inspector.style';

export const glideInspectorStyle: React.CSSProperties = {
  position: 'relative',
  right: 'inherit',
  height: 'calc(100vh - 70px)',
};

export const glideInspectorExpandedStyle: React.CSSProperties = {
  position: 'absolute',
  right: '473px',
  height: 'calc(100vh - 70px)',
  zIndex: 1,
};

export const GlideInspector = (props: GlideInspectorProps) => {
  const { global, viewComponents } = useSelector(selectComponents);
  const isInspectorCollpased = viewComponents?.[global?.currentClientViewUri]?.['inspector']?.isCollapsed;
  const styles = isInspectorCollpased === false ? glideInspectorExpandedStyle : glideInspectorStyle;

  return (
    <Inspector
      loadingIcon={{ type: LoadingIconType.Glide, size: LoadingIconSizes.large }}
      onBack={props.onBack}
      {...props}
      // Custom styling props
      style={styles}
      paperProps={{ component: PaperStyled }}
    >
      {props.children}
    </Inspector>
  );
};
