import { Add, MoreVert, OpenInNew } from '@material-ui/icons';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const IconButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const StyledExportButton = styled(IconButton)`
  &:disabled {
    opacity: 0.5;
  }
`;

export const StyledOpenInNewIcon = styled(OpenInNew)`
  color: white;
`;

export const MoreVertIcon = styled(MoreVert)`
  color: white;
`;

export const StyledAddButton = styled.button`
  min-width: fit-content;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #ffffff;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;
export const SyledAddIcon = styled(Add)`
  color: white;
`;

export const StyledLabelContainer = styled.div`
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  width: 40vw;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 36px;
  align-items: center;
`;

export const Label = styled.div<{ active: boolean }>`
  background-color: ${props => (props.active ? 'var(--default-blue)' : 'var(--label)')};
  color: white;
  height: 28px;
  width: auto;
  border-radius: 2px;
  margin-right: 5px;
  padding: 6px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;
