import React from 'react';
import * as S from './objectcollection.style';
import { CellData } from 'src/components/grids/dxgrid-client-view/dxgrid-client-view.model';
interface ObjectCollectionCellRenderProps {
  cellData: CellData;
  onClick: (cellData: CellData) => void;
  renderValue?: string;
}

export const ObjectCollectionCellRender = ({ cellData, onClick, renderValue }: ObjectCollectionCellRenderProps) => {
  const value = renderValue ?? (cellData.value as string[])?.length ?? 0;
  const handleOnClick = () => {
    onClick(cellData);
  };

  return (
    <S.ObjectCollectionCount aria-label="cell-render-link" data-testid="cell-render-link" onClick={handleOnClick}>
      {value}
    </S.ObjectCollectionCount>
  );
};
