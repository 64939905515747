import { FormFieldsType, FormValuesType, FormFieldType } from 'src/components/forms/form-elements/FormElement.model';

interface GetFieldErrorMessage {
  value: any;
  validator?: (arg: any) => boolean;
  errorMessage?: string;
}

interface GetErrorMessageByDataType {
  field: FormFieldType;
  value?: boolean | string | number;
}

interface RequiredFieldValidationObject {
  valid: boolean;
  errorMessage: string | undefined;
}

type CustomErrorTypes = 'bit' | 'decimal';

// Tested ✅
export const dataTypeErrors = (value?: boolean | string | number, displayName?: string) => ({
  bit: getFieldErrorMessage({
    value,
    validator: bitValidator,
    errorMessage: `${displayName} must be true or false`,
  }),
  decimal: getFieldErrorMessage({
    value,
    validator: decimalValidator,
    errorMessage: `${displayName} must be a number`,
  }),
});

// Tested ✅
export const bitValidator = (v: boolean | string) => (typeof v === 'boolean' ? Boolean(v) : false);

// Tested ✅
export const decimalValidator = (v: any) => {
  const value_: any = String(v).replace(/,/g, '');
  return !isNaN(value_);
};

// Tested ✅
export const checkRequiredField = ({
  displayName,
  value,
}: {
  displayName: string;
  value: number | string | boolean | undefined;
}): RequiredFieldValidationObject => {
  const requiredFieldIsEmpty = value === '' || value === undefined;
  if (requiredFieldIsEmpty) {
    return {
      valid: false,
      errorMessage: `${displayName} is required`,
    };
  }

  return {
    valid: true,
    errorMessage: undefined,
  };
};

// Tested ✅
export const getFieldErrorMessage = ({
  value,
  validator,
  errorMessage = 'Invalid value',
}: GetFieldErrorMessage): string | undefined => {
  const valueIsInvalid = value && validator && !validator(value);
  return valueIsInvalid ? errorMessage : undefined;
};

// Tested ✅
export const getErrorMessageByDataType = ({ field, value }: GetErrorMessageByDataType): string | undefined => {
  if (field.required) {
    const requiredError: RequiredFieldValidationObject = checkRequiredField({ displayName: field.displayName, value });
    if (!requiredError.valid) {
      return requiredError.errorMessage;
    }
  }

  const shouldUseCustomError = field.dataType === 'bit' || field.dataType === 'decimal';

  if (shouldUseCustomError) {
    return dataTypeErrors(value, field.displayName)[field.dataType as CustomErrorTypes];
  }

  return undefined;
};

export const validateForm = (
  formValues: { [key: string]: string | number | boolean | any },
  formFields: FormFieldsType,
) => {
  const errors =
    formValues &&
    Object.entries(formValues).reduce((acc, [key, value]: [string, any]) => {
      const field: FormFieldType = formFields[key];

      const errorMessage = getErrorMessageByDataType({ field, value });
      if (!errorMessage) {
        return acc;
      }
      return {
        ...acc,
        [key]: errorMessage,
      };
    }, {});

  const valid = errors && Object.keys(errors).length === 0;
  return { errors, valid };
};

// Tested ✅
export const getErrorsPerFormGroup = (formGroupsState: { [key: string]: FormValuesType }) =>
  Object.entries(formGroupsState).reduce((acc, [key, value]) => {
    const validation = validateForm(value.formValues, value.formFields);
    if (validation?.errors && Object.keys(validation.errors).length === 0) {
      return acc;
    }
    return {
      ...acc,
      [key]: validation.errors,
    };
  }, {});

export const getErrorsPerFormField = (formGroupsState: FormValuesType) => {
  const validation = validateForm(formGroupsState.formValues, formGroupsState.formFields);
  if (Object.keys(validation.errors).length === 0) {
    return {};
  }
  return validation.errors;
};
// Tested ✅
export const populateFormGroupsWithErrors = (
  formValidationErrors: any,
  formGroupsState: { [key: string]: FormValuesType },
) =>
  Object.entries(formValidationErrors).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: {
        ...acc[`${key}`],
        formErrors: {
          errors: value,
          valid: false,
        },
      },
    }),
    formGroupsState,
  );

export const populateFormWithErrors = (formValidationErrors: any, formGroupsState: FormValuesType) => {
  return { ...formGroupsState, formErrors: { errors: formValidationErrors, valid: false } };
};
