import styled from 'styled-components';
import { SecondaryButton } from '@virtus/components/Buttons';

export const Button = styled(SecondaryButton)`
  margin: auto;
  :hover {
    background-color: var(--bg);
    border-bottom: 1px solid var(--blue);
  }
`;

export const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
