import { FieldChooser, FieldPanel, PivotGrid } from 'devextreme-react/pivot-grid';
import PivotGridDataSource, { PivotGridDataSourceField } from 'devextreme/ui/pivot_grid/data_source';
import { PivotGridFieldChooser } from 'devextreme-react/pivot-grid-field-chooser';
import {
  StyledDxPivotGrid,
  StyledPivotGridFieldChooser,
  StyledPivotGridWrapper,
  StyledPivotViewWrapper,
  StyledTabWrapper,
  StyledToolBarWrapper,
  StyledWrapper,
} from './dx-pivot-grid.styles';
import './themes/dx-pivot-grid-theme.light.css';
import React, { ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { getPivotFieldsFromSchema } from './utils/mapSchemaToFields';
import {
  defaultView,
  DxPivotGridDataType,
  PivotArea,
  PivotDataType,
  PivotGridField,
  PivotGridNewOrderFilter,
  PivotLayout,
} from './model/dx-pivot-grid.model';
import { PivotToolBar } from './toolbar/pivot-toolbar';
import TabPanel from 'devextreme-react/tab-panel';
import generateTreeViewData from './utils/getPivotDataTreeView';
import dxPivotGrid, { dxPivotGridPivotGridCell } from 'devextreme/ui/pivot_grid';
import { dxElement } from 'devextreme/core/element';
import GridTemplates from 'src/components/grids/grids.template';
import { Template } from 'devextreme-react/core/template';
import LayoutsTemplateRender from 'src/components/grids/dxgrid-client-view/templates/Layouts/Layouts';
import { dispatchActions } from 'src/app/store';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { DataSource } from '@virtus/components/DxDataGrid/DxDataGrid';
import { groupBy } from 'lodash';
import isEqual from 'lodash/isEqual';
import { diffPivotGrid } from './utils/pivot-grid-layout-state';
import { RootState } from 'src/reducers';
import { Components, selectComponents } from 'src/reducers/components';
import { connect } from 'react-redux';
import './themes/dx-pivot-grid-theme-blue-light-compact.css';

interface ReduxProps {
  components: Components;
}

export interface DxPivotCell {
  component?: dxPivotGrid;
  element?: dxElement;
  model?: any;
  area?: string;
  cellElement?: dxElement;
  cell?: dxPivotGridPivotGridCell;
  rowIndex?: number;
  columnIndex?: number;
}

export interface DxPivotGridProps {
  pivotGridData: DataSource;
  refreshHandler: () => void;
  children?: ReactNode;
  showFieldChooser?: boolean;
  useDarkTheme?: boolean;
  showAllFieldsColumn?: boolean;
  storageKey?: string;
  enableStorage?: boolean;
  getCustomToolBarItems?: () => any;
  toolbarIconsOrder?: string[];
  toolbarItemsFromConfig?: any[];
  onCellPrepared?: (e: any) => void;
  onCellClick?: (e: any) => void;
  expandAllFields?: boolean;
  clientViewConfiguration: ClientViewConfigurationData;
  onSettingsClick?: (_pivotGridRef?: any) => void;
}

const fieldsToIgnore = (): PivotGridField[] => {
  return [
    {
      dataField: '_uri',
      dataType: DxPivotGridDataType.string,
      visible: false,
    },
    {
      dataField: '_date',
      dataType: DxPivotGridDataType.string,
      visible: false,
    },
  ];
};

const FieldChooserDataSource = [
  {
    id: 0,
    key: 'list_view',
    display_name: 'List View',
  },
  {
    id: 1,
    key: 'tree_view',
    display_name: 'Tree View',
  },
];

export const DxPivotGrid = React.memo(
  React.forwardRef<PivotGrid, DxPivotGridProps & ReduxProps>(
    (
      {
        children,
        pivotGridData,
        showAllFieldsColumn,
        refreshHandler,
        getCustomToolBarItems,
        showFieldChooser = true,
        toolbarIconsOrder,
        expandAllFields,
        clientViewConfiguration,
        components,
        onSettingsClick,
      },
      ref,
    ) => {
      let pivotRef = useRef(null);
      pivotRef = ref ? (ref as any) : pivotRef;
      const [selectedLayout, setSelectedLayout] = useState<PivotLayout | undefined>(undefined);
      const [currentGridState, setCurrentGridState] = useState<PivotLayout | undefined>(undefined);

      const dataSource = useMemo(() => {
        const fields =
          pivotGridData &&
          ([...getPivotFieldsFromSchema({ data: pivotGridData, defaultView }), ...fieldsToIgnore()] as any);

        return new PivotGridDataSource({
          fields: fields,
          store: pivotGridData?.data,
          retrieveFields: false,
        });
      }, [defaultView, pivotGridData]);

      const [showALlFieldsPane, setShowAllFieldsPane] = useState(false);
      const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
      const { toolbarButtonsFromConfig, toolbarTemplates } = GridTemplates({
        clientViewConfiguration,
        components,
      });
      const [selectedRowPath, setSelectedRowPath] = useState<any>({});
      const [isDarkTheme, setIsDarkTheme] = useState<boolean>(components.global.isDarkTheme);

      useEffect(() => {
        setIsDarkTheme(components.global.isDarkTheme);
      }, [components.global.isDarkTheme]);

      const changePivotGridLayout = (layout: PivotLayout) => {
        setSelectedLayout(layout);
        setCurrentGridState(layout);
      };

      const allToolbarTemplates = useMemo(() => {
        const layoutTemplate = (
          <Template
            name="layouts"
            render={() => (
              <LayoutsTemplateRender
                useDarkTheme={isDarkTheme}
                onSettingsClick={onSettingsClick}
                dataGridRef={pivotRef}
                changePivotGridLayout={changePivotGridLayout}
                clientViewUri={clientViewConfiguration.uri}
              />
            )}
          />
        );
        toolbarTemplates.push(layoutTemplate);
        return toolbarTemplates;
      }, [dataSource, toolbarTemplates, isDarkTheme]);

      const getToolBarItems = useMemo(() => {
        const LayoutToolbarItem = [
          {
            name: 'layout',
            location: 'after',
            template: 'layouts',
          },
        ];
        if (getCustomToolBarItems) {
          return [...LayoutToolbarItem, ...getCustomToolBarItems()];
        }
        return LayoutToolbarItem;
      }, []);

      useEffect(() => {
        return () => {
          dispatchActions.components.update('global', { orders: { disabled: true, rowData: {} } });
        };
      }, []);

      const onCellClickCallback = (gridRowData: any) => {
        const groupedGridData = groupBy(gridRowData, PivotGridNewOrderFilter);
        if (gridRowData?.length && Object.keys(groupedGridData).length === 1) {
          dispatchActions.components.update('global', { orders: { disabled: false, rowData: gridRowData[0] } });
        } else {
          dispatchActions.components.update('global', { orders: { disabled: true, rowData: {} } });
        }
      };
      const onCellClick = (e: any) => {
        if (e?.cell?.rowType === PivotDataType.T) return;
        const pivotGridDataSource = e.component.getDataSource();
        const cellElement = {
          ...e.cell,
          rowPath: e?.cell?.rowPath ?? e?.cell?.path,
        };
        const drillDownDataSource = pivotGridDataSource.createDrillDownDataSource(cellElement);
        drillDownDataSource.load();
        const items = drillDownDataSource.items();
        const path = (e.cell.rowPath || e.cell.path || []).join('/');
        if (Object.keys(selectedRowPath).includes(path)) setSelectedRowPath({ [path]: true });
        else setSelectedRowPath({ [path]: !selectedRowPath[path] });
        (pivotRef as any)?.current?.instance?.repaint();
        onCellClickCallback && onCellClickCallback(items);
      };
      const isRowSelected = (rowPath: any) => {
        const path: string[] = [];
        let selected = false;
        rowPath.some((value: any) => {
          path.push(value);
          const pathValue = path.join('/');
          selected = selectedRowPath[pathValue];
          return selected;
        });
        return selected;
      };
      const highlightDataRow = (e: DxPivotCell) => {
        if (e?.cell?.rowType === PivotDataType.T || e?.cell?.type === PivotDataType.T || e.area === PivotArea.R) return;

        if (!!isRowSelected(e?.cell?.rowPath || e?.cell?.path || []) && e.cellElement) {
          (e.cellElement as any).style.cssText = `
              background-color: var(--dataGridSelectedRowDark);
              color: var(--textDark);
            `;
        }
      };
      const highlightTotalRow = (e: DxPivotCell) => {
        if (
          e.area == PivotArea.D &&
          (e.cell?.rowType == PivotDataType.GT || e.cell?.rowType == PivotDataType.T) &&
          (e.cell?.columnType == PivotDataType.GT || e.cell?.columnType == PivotDataType.T) &&
          e.cell?.rowPath?.length
        ) {
          (e.cellElement as any).style.cssText = `
            background-color: var(--accent);
            color: var(--text);
            font-weight: bold
          `;
        }
      };
      const onCellPrepared = (e: DxPivotCell) => {
        highlightTotalRow(e);
        highlightDataRow(e);
      };

      useLayoutEffect(() => {
        if (isDarkTheme) {
          require('./themes/dx-pivot-grid-theme.dark.css');
        }
      }, [isDarkTheme]);

      const PivotFieldChooser = (
        <PivotGridFieldChooser
          height="100%"
          className="side-field-chooser"
          dataSource={dataSource}
          layout={1}
          applyChangesMode="instantly"
          allowSearch
        />
      );

      const fieldChooserRender = (e: any) => {
        const _isTreeView = e.key === 'tree_view';
        if (selectedTabIndex === e.id) {
          if (_isTreeView) {
            generateTreeViewData({ data: pivotGridData, dataSource });
          } else {
            const fields = dataSource
              .fields()
              .map((item: PivotGridDataSourceField) => ({ ...item, displayFolder: undefined }));
            dataSource.fields(fields);
          }
        }

        return PivotFieldChooser;
      };

      const expandAllPivotRows = (pivotRef: any) => {
        if (!pivotRef?.current) return;
        // beginUpdate() prevents the pivot grid from refreshing until the endUpdate() method is called.
        (pivotRef?.current as any).instance.beginUpdate();
        const _dataSource = (pivotRef?.current as any).instance.getDataSource();
        const fields = _dataSource.fields();
        fields.forEach((f: any) => {
          if (f.area == 'row') {
            _dataSource.expandAll(f.index);
          }
        });
        // Refreshes the pivot grid after a call of the beginUpdate() method.
        (pivotRef?.current as any).instance.endUpdate();
      };

      const validateStateChanges = (currentState: PivotLayout | undefined, receivedState: PivotLayout | undefined) => {
        if (!currentState) {
          currentState = { rowExpandedPaths: [], columnExpandedPaths: [], fields: undefined };
        }
        if (currentState?.columnExpandedPaths?.hasOwnProperty('hash')) {
          delete currentState?.columnExpandedPaths['hash'];
        }
        if (currentState?.rowExpandedPaths?.hasOwnProperty('hash')) {
          delete currentState?.rowExpandedPaths['hash'];
        }
        const rowExpandedPaths = !isEqual(currentState?.rowExpandedPaths, receivedState?.rowExpandedPaths);

        const columnExpandedPaths = !isEqual(currentState?.columnExpandedPaths, receivedState?.columnExpandedPaths);

        const onlyInCurrentColumns = diffPivotGrid(currentState?.fields, receivedState?.fields);

        console.info('[Layouts] In layout but not in pivot grid schema', onlyInCurrentColumns);

        const areFieldsEqual = onlyInCurrentColumns === undefined ? true : onlyInCurrentColumns;
        console.info('[Layouts] fields are equal or not', areFieldsEqual);

        dispatchActions?.components?.updateView('gridLayout', clientViewConfiguration?.uri, {
          hasChanges: !areFieldsEqual || rowExpandedPaths || columnExpandedPaths,
        });
      };
      const checkGridStateChanges = (e: any) => {
        const receivedState = e.component.getDataSource().state();
        const currentState = selectedLayout;

        if (receivedState && currentState && currentState?.fields) {
          validateStateChanges(currentState, receivedState);
        } else {
          validateStateChanges(currentGridState, receivedState);
        }
        if (!selectedLayout) {
          setCurrentGridState(e.component.getDataSource().state());
        }
      };

      const onContentReady = (e: any) => {
        checkGridStateChanges(e);
      };
      useEffect(() => {
        window.requestAnimationFrame(() => {
          if (expandAllFields) {
            expandAllPivotRows(pivotRef);
          }
        });
      }, [expandAllFields]);

      const toggleAllFieldsPane = useCallback(() => setShowAllFieldsPane(prev => !prev), []);

      const toggleFieldChooser = useCallback(() => {
        if (pivotRef?.current) {
          (pivotRef?.current as any).instance.getFieldChooserPopup().show();
        }
      }, []);

      const fieldChooserTitleRender = (data: any) => data?.display_name || 'None';

      const onSelectionChanged = (e: any) => {
        if (e.name === 'selectedIndex' && e.previousValue === selectedTabIndex) {
          setSelectedTabIndex(e.value);
        }
      };
      const fieldChooserTabPanel = (
        <StyledTabWrapper className={isDarkTheme ? 'dark-theme' : ''}>
          <TabPanel
            height="100%"
            selectedIndex={selectedTabIndex}
            dataSource={FieldChooserDataSource}
            onOptionChanged={onSelectionChanged}
            itemTitleRender={fieldChooserTitleRender}
            itemRender={fieldChooserRender}
            animationEnabled
          />
        </StyledTabWrapper>
      );

      return (
        <StyledWrapper className={isDarkTheme ? 'dark-theme' : ''}>
          <StyledToolBarWrapper>
            <PivotToolBar
              useDarkTheme={isDarkTheme}
              getGridTemplates={allToolbarTemplates}
              getCustomToolBarItems={getToolBarItems}
              refreshHandler={refreshHandler}
              toggleAllFieldsPane={toggleAllFieldsPane}
              toggleFieldChooser={toggleFieldChooser}
              toolbarIconsOrder={toolbarIconsOrder}
              toolbarItemsFromConfig={toolbarButtonsFromConfig}
              useAllFieldsPane
            />
          </StyledToolBarWrapper>
          <StyledPivotViewWrapper>
            {showAllFieldsColumn && showALlFieldsPane ? (
              <StyledPivotGridFieldChooser>{fieldChooserTabPanel}</StyledPivotGridFieldChooser>
            ) : null}
            <StyledPivotGridWrapper data-testid="virtus-pivot-grid" className="dx-swatch-custom-pivot-grid">
              <StyledDxPivotGrid
                ref={pivotRef}
                dataSource={dataSource}
                allowSortingBySummary
                allowSorting
                allowFiltering
                allowExpandAll={false}
                onCellPrepared={onCellPrepared}
                onCellClick={onCellClick}
                onContentReady={onContentReady}
              >
                <FieldPanel showColumnFields showDataFields showFilterFields showRowFields allowFieldDragging visible />
                <FieldChooser
                  data-testid="pivot-field-chooser"
                  height="500"
                  width="500"
                  layout={0}
                  allowSearch
                  enabled={showFieldChooser}
                />
                {children}
              </StyledDxPivotGrid>
            </StyledPivotGridWrapper>
          </StyledPivotViewWrapper>
        </StyledWrapper>
      );
    },
  ),
);

const mapStateToProps = (state: RootState): ReduxProps => ({
  components: selectComponents(state),
});

export default connect(mapStateToProps)(DxPivotGrid);
