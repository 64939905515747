import styled from 'styled-components';
import { Dialog as MUIDialog } from '@material-ui/core';

export const StyledRowsWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow: overlay;
  padding: 5px 25px 25px 25px;
  height: 100%;
`;

export const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: var(--background);
  overflow-y: overlay;
  padding-right: 40px;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  @supports (-moz-appearance: none) {
    overflow-y: scroll;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: [col] 50% [col] 50%;
  grid-gap: 20px;
  @supports (-moz-appearance: none) {
    margin-bottom: 20px;
  }
`;

export const Cell = styled.div<{ takeAllHeightAvailable?: boolean }>`
  padding: 16px;
  height: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  flex: ${props => (props.takeAllHeightAvailable ? 1 : 'none')};
  background: var(--bgInspectorFullscreenContent);
`;

export const CellHeader = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 10px;
`;

export const Dialog = styled(MUIDialog)<{ display_type?: string }>`
  height: calc(${props => (props.display_type === 'preview' ? 85 : 100)}vh - 40px);
  margin: 40px auto;
  width: ${props => (props.display_type === 'preview' ? 80 : 100)}vw;
  & > div {
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    margin: 0;
    & > div {
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      margin: 0;
    }
  }
`;
