import React, { useEffect } from 'react';
import { useRequest } from 'redux-query-react';
import { GlideCommentaryApi } from 'src/api/queries';
import GlideListContainer from 'src/components/glide-list-container/glide-list-container';
import Loading from '@virtus/components/Loading';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import styled, { css } from 'styled-components';
import { Header } from 'src/components/glide-object-manager/components/header/header';
import { CreditDetailsSagaAction } from 'src/sagas/credit/credit.saga';
import { useDispatch } from 'react-redux';

export type GlideObjectCommentaryContainerProps = {
  commentaryUris: string[];
  creditUri: string;
  instanceUri?: string;
  componentType?: string;
  showHeader?: boolean;
  fromColumn?: boolean;
  onBack: () => void;
  handleExpansion: () => void;
};

const fullSize = css`
  width: 100%;
  height: 100%;
`;

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  ${fullSize}
  ${flexColumn}
  align-items: center;
  justify-content: flex-end;
  background-color: var(--bgInspectorDefaultInspector);
`;

export const Body = styled.div`
  ${fullSize}
  background: var(--darkblue);
  display: flex;
`;

export const Footer = styled.div`
  bottom: 0;
  height: 48px;
  width: 100%;
  background: var(--bgInspectorFullscreenContent);
  padding: 0 20px;
  border-top: 1px solid #495057;
`;

export const GlideObjectCommentaryContainer = ({
  commentaryUris,
  creditUri,
  instanceUri,
  onBack,
  handleExpansion,
  componentType = '',
  showHeader = true,
  fromColumn = false,
}: GlideObjectCommentaryContainerProps) => {
  const dispatch = useDispatch();
  const [{ isPending }] = useRequest(
    GlideCommentaryApi.getGlideCommentaryData({ uri: commentaryUris, type: componentType }),
  );

  useEffect(() => {
    if (fromColumn) {
      dispatch({ type: CreditDetailsSagaAction.FETCH_CREDIT_URI_FOR_COMMENTARY, payload: { uri: instanceUri } });
    }
  }, []);

  return isPending ? (
    <Loading
      type={LoadingIconType.Glide}
      size={LoadingIconSizes.large}
      style={{ backgroundColor: 'var(--bgInspectorDefaultInspector)' }}
      show
      full
    />
  ) : (
    <Wrapper data-testid="glide-object-manager">
      {showHeader && <Header onBack={onBack} title={componentType} handleExpansion={handleExpansion} />}
      <Body>
        <GlideListContainer uri={creditUri} componentType={componentType} fromModal fromColumn />
      </Body>
      <Footer></Footer>
    </Wrapper>
  );
};
