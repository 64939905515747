import React, { useState } from 'react';
import * as S from './summary-panel.style';
import { ActionButtonProps } from 'src/components/screen-wrapper/components/summary-buttons/summary-buttons';
import { RootState } from 'src/reducers';
import { selectCVC } from 'src/reducers/tabs';
import { connect } from 'react-redux';
import { ClientViewConfigurationData, ClientViewTypeLookUps } from 'src/components/glide-view/glide-view.model';
import DatePicker from 'src/components/date-picker/date-picker';
import SummaryButtons from 'src/components/page-wrapper/components/summary-buttons/summary-buttons';
import 'src/components/portfolio-fund-selector/portfolio-fund-selector';
import { ComponentProps, selectComponents } from 'src/reducers/components';

const PortfolioFundSelector = React.lazy(
  () => import('src/components/portfolio-fund-selector/portfolio-fund-selector'),
);

export interface RenderSectionProps {
  panel_left: {
    action_buttons: ActionButtonProps[];
    onButtonClickCallback?: (e: any, id: string) => void;
  };
  panel_right?: { onButtonClickCallback?: (data: any) => void };
}

export interface SummaryPanelProps {
  children?: React.ReactElement<any>;
  panelContentLeft?: any;
  panelContentRight?: any;
}

interface ReduxProps extends ComponentProps {
  clientViewConfiguration: ClientViewConfigurationData;
}

/**
 * Method to get T-1, T-2, T+1, T+2....T(+/-)any Number, where T is Today's (current) date.
 * This will include only business days (Mon-Fri) and will exclude all Saturday's and Sunday's.
 */
export const getBusinessDay = (day: number, defaultDate = new Date(), validDayCounter = 0): string => {
  day < 0 ? defaultDate.setDate(defaultDate.getDate() - 1) : defaultDate.setDate(defaultDate.getDate() + 1);
  const weekDay = defaultDate.getDay();
  if (weekDay !== 0 && weekDay !== 6) ++validDayCounter;
  return validDayCounter === Math.abs(day)
    ? defaultDate.toLocaleDateString()
    : getBusinessDay(day, defaultDate, validDayCounter);
};

export const SummaryPanel = ({
  clientViewConfiguration,
  panelContentLeft,
  panelContentRight,
  children,
  components,
}: ReduxProps & SummaryPanelProps) => {
  const [complianceDashboardError, setComplianceDahsboardError] = useState<string>('');
  const hasComplianceButtons = clientViewConfiguration?.web_summary_panel?.panel_left?.action_buttons.length > 0;
  const baseLineDate = components?.viewComponents?.[clientViewConfiguration?.uri]?.['baseLineDate'];
  const currentDate = components?.viewComponents?.[clientViewConfiguration?.uri]?.['currentDate'];
  const datePicker = components?.viewComponents?.[clientViewConfiguration?.uri]?.['datePicker'];

  const complianceButtons = () =>
    clientViewConfiguration.web_summary_panel.panel_left.action_buttons.map(
      (
        item: { id: string; text: string | number; label: string; backgroundColor: string | undefined },
        index: string | number | undefined,
      ) => (
        <SummaryButtons
          id={item.id}
          key={index}
          buttonText={item.text}
          buttonLabel={item.label}
          backgroundcolor={item.backgroundColor}
          onButtonClick={() => {}}
        />
      ),
    );

  if (!clientViewConfiguration.is_time_series && !panelContentLeft && !panelContentRight && !hasComplianceButtons)
    return null;

  return (
    <S.SummaryPanelComponent>
      <div>{panelContentLeft}</div>
      {hasComplianceButtons && complianceButtons()}
      {panelContentRight && <div>{panelContentRight}</div>}
      {children}
      {clientViewConfiguration?.client_view_type === ClientViewTypeLookUps.Custom && (
        <>
          <S.FundSelectorWrapper>
            <PortfolioFundSelector clientViewConfiguration={clientViewConfiguration} />
          </S.FundSelectorWrapper>
          <S.DatePickerWrapper>
            {clientViewConfiguration.has_baseline_date && (
              <DatePicker
                singleDateSelection
                dateFieldLabel="Baseline Date:"
                defaultDate={baseLineDate?.data?.singleDate ?? getBusinessDay(-2)}
                dateStoreKey="baseLineDate"
                validationError={complianceDashboardError}
                validationErrorCallback={setComplianceDahsboardError}
              />
            )}
            <DatePicker
              singleDateSelection
              dateFieldLabel="Current Date:"
              defaultDate={currentDate?.data?.singleDate ?? getBusinessDay(-1)}
              dateStoreKey="currentDate"
              validationError={complianceDashboardError}
              validationErrorCallback={setComplianceDahsboardError}
            />
          </S.DatePickerWrapper>
        </>
      )}
      {clientViewConfiguration?.client_view_type !== ClientViewTypeLookUps.Custom &&
        clientViewConfiguration.is_time_series && (
          <S.DatePickerWrapper>
            <DatePicker singleDateSelection isDateNetworkRequest defaultDate={datePicker?.data?.singleDate ?? ''} />
          </S.DatePickerWrapper>
        )}
    </S.SummaryPanelComponent>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  clientViewConfiguration: selectCVC(state),
  components: selectComponents(state),
});

export default connect(mapStateToProps)(SummaryPanel);
